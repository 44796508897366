import Vue from 'vue'
import Component from 'vue-class-component'
import Auth from '@/auth'

@Component({
    name:"noaccess"
})
export default class NoAccess extends Vue {

    showDialog = true;

    constructor() {
        super();
    }

    SignOut()
    {
        this.showDialog = false;
        Auth.client().signOut();
    }
   
    
}