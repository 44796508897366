import HttpClient from "@/utils/httpClient";
import localStorage from "@/store/localStorageService";


export class BaseService {


    httpClient(productCode: string){
            return new HttpClient(productCode);
    }

    store(storeName: string){
        return localStorage.getStore(storeName);
    }
  
}

export default BaseService;