<template>
    <v-container class="pa-0 no-results-container">
        <img alt="Search Jobs" height="150px" src="@/assets/img/jobs/search/no jobs-big.png" class="header-noJobs">
        <h1 class="h1-large mt-8">{{ $t("jobs.NoSearchResultsTitle") }}</h1>
        <p class="mt-3 no-results-description">{{ $t("jobs.NoSearchResultsDescription") }}</p>
        <v-btn class="mt-8 tryAgainButton" depressed block color="primary" @click="tryAgain"> {{ $t("jobs.SearchTryAgain") }}</v-btn>
    </v-container>
</template>

<script lang="ts">
    import ViewModel from './JobNoResults'  
    export default ViewModel;
</script>

<style lang="scss" scoped>
     .no-results-container {
        width: 100%;
        height: 85%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .no-results-description {
        text-align: center;
        font-size: 4.6vw;
    }
</style>