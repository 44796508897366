<template>
  <div v-if="isShowing" ref="interactElement" class="card" :class="{ isCurrent: isCurrent,
            isAnimating: isInteractAnimating }" :style="{ transform: transformString }" @click="showDetails">
           
    <v-container style="padding: 15px;">
      <div class="summary-card">
        <JobSummaryCard :job="card" :showHeart="false"></JobSummaryCard>
      </div>
      <v-row no-gutters>
        <v-col cols="12" sm="4" md="12">

          <div class="text-h2">
            {{ $t("jobs.aboutTheRoleTitle") }}
          </div>
          <div class="job-description text-body-1 text-subtitle-2 grey--text text--darken-2" :style="jobTextHeight">
            {{card.Description}}
          </div>
        </v-col>
      </v-row>
      <v-row no-gutters class="pt-4" v-if="showCommuteOptions">
        <slot name="commuteoptions"></slot>
        <v-col cols="12" sm="4" md="12" style="height: 85px">

          <div class="text-h2" >
            {{ $t("jobs.commuteOptions") }}
          </div>
          <div class="d-flex justify-space-between mb-6">
            <div class="grey--text text--darken-2 text-center travel-time">
              <img class="py-3 rounded img-round transport-icon" src="@/assets/img/icons/car.png" />
              <div class="text-caption grey--text transport-text">
                <v-progress-linear  v-if="!card.carTime"
                color="primary"
                buffer-value="0"
                stream
              ></v-progress-linear>
                {{card.carTime}}</div>
            </div>
            <div class="grey--text text--darken-2 text-center travel-time">
              <img class="px-3 rounded img-round transport-icon" src="@/assets/img/icons/bus.png" />
              <div class="text-caption grey--text transport-text">
                <v-progress-linear  v-if="!card.busTime"
                color="primary"
                buffer-value="0"
                stream
              ></v-progress-linear>
                {{card.busTime}}</div>
            </div>
            <div class="grey--text text--darken-2 text-center travel-time">
              <img class="px-3 rounded img-round transport-icon" src="@/assets/img/icons/train.png" />
              <div class="text-caption grey--text transport-text">
            

              <v-progress-linear  v-if="!card.trainTime"
              color="primary"
              buffer-value="0"
              stream
            ></v-progress-linear>
                {{card.trainTime}}</div>
            </div>
          <div class="grey--text text--darken-2 text-center travel-time">
              <img class="pb-3 rounded img-round transport-icon" src="@/assets/img/icons/cycle.png" />
              <div class="text-caption grey--text transport-text">
            

              <v-progress-linear  v-if="!card.cycleTime"
              color="primary"
              buffer-value="0"
              stream
            ></v-progress-linear>
                {{card.cycleTime}}</div>
            </div>

             <div class="grey--text text--darken-2 text-center travel-time">
              <img class="px-3 rounded img-round transport-icon" src="@/assets/img/icons/walk.png" />
              <div class="text-caption grey--text transport-text">
            

              <v-progress-linear  v-if="!card.walkTime"
              color="primary"
              buffer-value="0"
              stream
            ></v-progress-linear>
                {{card.walkTime}}</div>
            </div>

          </div>
        </v-col>
      </v-row>
    </v-container>

  </div>
</template>

<script lang="ts">
  import ViewModel from './JobCard'
  export default ViewModel;
</script>

<style lang="scss" scoped>
  @import "./styles/index.scss";

  $ease-out-back: cubic-bezier(0.175, 0.885, 0.32, 1.275);
  $cardsTotal: 2;
  $cardsPositionOffset: 60vh * 0.06;
  $cardsScaleOffset: 0.08;
  $defaultTranslation: $cardsPositionOffset * $cardsTotal;
  $defaultScale: 1 - ($cardsScaleOffset * $cardsTotal);
  
  .summary-card{
    padding-bottom: 16px;
  }

  .card {
    @include absolute(0);
    height:350px;
    width:100%;
    top: calc(calc(100vh - 640px)/2);
    display: flex;
    margin: auto;
    background-color:white;
    border-radius: 4px;
    box-shadow: 0 30px 30px 0 rgba(0, 0, 0, 0.05);
    opacity: 1;
    transform: translateY(-$defaultTranslation) scale($defaultScale);
    transform-origin: 50%, 100%;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    user-select: none;
    pointer-events: none;
    will-change: transform, opacity;

    &.isCurrent {
      pointer-events: auto;
    }

    &.isAnimating {
      transition: transform 2s $ease-out-back;
    }
    
  }

  .card:nth-child(n+4)
  {
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.01);
  }

  .card:nth-child(n+5)
  {
    box-shadow: none;
  }
  
  @for $i from 1 through $cardsTotal {
    $index: $i - 1;
    $translation: $cardsPositionOffset * $index;
    $scale: 1 - ($cardsScaleOffset * $index);

    .card:nth-child(#{$i}) {
      z-index: $cardsTotal - $index;
      opacity: 1;
      transform: translateY(-$translation) scale($scale);
    }
  }

  .job-description {
    height: 36px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
   -webkit-line-clamp: 2;
   -webkit-box-orient: vertical;
  }

  .transport-icon {
    height: 40px;
    max-width: 40px;
    padding:9px 8px 8px 9px;
  }

  .transport-text{
    max-width: 45px;
  }


  .travel-time{
    width: 50px;
    overflow: hidden;
  }

 

</style>