

 
<div class="pt-0 summary-container fill-height" :id="`d${new Date(event.Start).toLocaleDateString().split('/').join('')}`">
       <div class="text-subtitle-2 date-labels" >
           <v-row class="avail-row" no-gutters>
             <v-col :class="{['text-left']: !isDeleteEvent, ['text-center font-weight-bold']: isDeleteEvent}">
               <p class="dateTimeFormat">
               {{ formatDateFromNow(event.Start) }}, 
                       {{ formatTime(event.Start ) }} - 
                       {{ formatTime(event.End ) }}
                      
               </p>        
             </v-col>
             <v-col v-show="!isDeleteEvent" class="text-right starting ">{{jobStartingTime}}
             </v-col>
           </v-row>
         </div>
         <v-card class="mx-auto rounded-corners card-border flat avail-card" outlined elevation-0 @click="selected(event)">
           <v-row no-gutters class="pl-1 pt-1">
               <v-col cols="2" sm="2" md="2" class="pl-1 pt-1" > 
                 <div :class="{['rounded  logo-image img-round']: event.SystemCode != 'ODEVNT_TYP_UNAVAILABLE' && event.SystemCode != 'ODEVNT_TYP_HOLIDAY' }">
                   <img v-if="event.HasLogo"  alt="" :src="logoUrl" height="auto" width="34px">
                   <img v-else-if="event.SystemCode == 'ODEVNT_TYP_UNAVAILABLE'" alt="" src="@/assets/img/icons/unavailable.png" height="auto" width="34px">
                   <img v-else-if="event.SystemCode == 'ODEVNT_TYP_HOLIDAY'" alt="" src="@/assets/img/icons/holiday.png" height="auto" width="34px">
                   <img v-else alt="" src="@/assets/logo.png" height="34px" width="34px">
                   
                 </div>
               </v-col>
               <v-col cols="10" sm="10" md="10" class="details-container pl-1 pt-1">
                 <div class="d-flex justify-space-between">
                   <div class="text-h1" data-jobdescription="event.JobDescription">{{event.JobDescription}}</div>
                 </div>
                 <template v-if="event.SystemCode == 'ODEVNT_TYP_UNAVAILABLE' || event.SystemCode == 'ODEVENT_AVAILABLE'">
                 <div class="text-subtitle-2 grey--text  pt-1 companyName avail-spacer avail-unavail-text" :data-description="event.Description">{{event.Description}}</div>    
                 </template>  
                 <template v-else>
                 <div class="text-subtitle-2 grey--text  pt-1 companyName avail-spacer" :data-company="event.Company">{{event.Company}}</div>
                 <div class="text-subtitle-2 grey--text text--darken-2 pt-1 work-address" :data-location="event.WorkAddress">{{event.WorkAddress}}</div>
                 </template>
                 <div class="text-subtitle-2 text-avail-rate pt-1 pb-1" style="display:inline" :data-payrate="event.PayRate">
                   {{ event.PayRate.toFixed(2) }} per {{ event.ChargeUnit.toLowerCase() }}
                   <v-icon style="padding-left:5px;" x-small>mdi-clock-outline</v-icon>
                   <span style="padding-left:5px; font-size:10px" class="font-weight-light">{{jobDuration}}</span>
                   <v-img v-if="event.CanDelete" @click.stop="deleteEvent()" style="display:inline-block; float:right; margin-right:15px;" class="mb-2" alt="" src="@/assets/img/icons/delete.png" height="26px" width="26px"></v-img>
                  </div>
                 
               </v-col>
           </v-row>
          
         </v-card>
</div>
    
