import BaseService from '@/services/baseService';
import { TimesheetEvent,  ClockStateResult, ClockStateEvent } from '@/services/checkInDomain';
import moment from "moment";
import { AxiosResponse } from 'axios';

export class TimesheetService extends BaseService {
    baseUrl: string;
    productCode = "RCRM";
    getJobShifts(shiftRequest: ShiftRequest): Promise<ShiftResult> {
        return this.httpClient(this.productCode).get("/timesheets/GetJobShiftsByApplicantId", shiftRequest).then(async res =>{
           return  res.data;
        }).then(async r => {
           return r;
        }).catch((e)=>{
              console.log("error", e);
        });
    }

    getJobShift(id: number, isJob: boolean, startDate?: Date, endDate?: Date): Promise<JobShift> {
        const params = (startDate && endDate) ? {id:id,isJob:isJob,startDate:moment(startDate).local().format("YYYY-MM-DDTHH:mm:ss"),endDate:moment(endDate).local().format("YYYY-MM-DDTHH:mm:ss")} : {id:id,isJob:isJob};
        return this.httpClient(this.productCode).get("/timesheets/RetrieveJobShift", params).then(async res =>{
            return  res.data;
         }).then(async r => {
            return r;
         }).catch((e)=>{
               console.log("error", e);
         });
    }

     timesheetSelect(timesheetRequest: TimesheetRequest): Promise<TimesheetResult> {
        return this.httpClient(this.productCode).get("/timesheets/GetTimesheets", timesheetRequest).then(res => {
        return  res.data;
        });
    }

    timesheetExpenseSelect(timesheetExpenseRequest: TimesheetExpenseRequest): Promise<TimesheetExpenseResult> {
        return this.httpClient(this.productCode).get("/timesheets/TimesheetExpenseSelect", timesheetExpenseRequest).then(res => {
        return  res.data;
        });
    }

    timesheetPeriodsInRange(timesheetRequest: TimesheetRequest): Promise<TimesheetsInRangeResponse> {
        return this.httpClient(this.productCode).get("/timesheets/GetTimesheetsInRange", timesheetRequest).then(async res => {
        return  res.data;
        }).then(async r => {
            return r;
         });
    }

    timesheetSelectById(timesheetId: number): any {
        return this.httpClient(this.productCode).get("/timesheets/TimesheetSelect", {timesheetId:timesheetId});
     }

    getCompanyLogo(placementId: number,width: number,height: number,crop = false): Promise<string> {
        return this.httpClient(this.productCode).get("/timesheets/GetCompanyLogo",
        {placementId:placementId,width:width,height:height,crop:crop},
        { responseType: 'arraybuffer' }).
        then(response => 
           {
              const u8 = new Uint8Array(response.data)
              const b64encoded = btoa([].reduce.call(new Uint8Array(response.data),function(p,c){return p+String.fromCharCode(c)},''))
              const mimetype="image/jpeg"
              return "data:"+mimetype+";base64,"+b64encoded

              }).catch((e)=>{
                  console.log(e);
                    return null;
                 });
    }

    postTimesheet(insertTimesheetRequest: InsertTimesheetRequest): Promise<InsertTimesheetResult> {
        return this.httpClient(this.productCode).post("/timesheets/InsertTimesheetFromClockState", insertTimesheetRequest).then(res =>{
           return  res.data;
        })
    }

    submitTimesheet(timesheetId: number): Promise<boolean> {
        return this.httpClient(this.productCode).post("/timesheets/SubmitTimesheet", {timesheetId:timesheetId}).then(async res => {
           return  res.data;
        });
    }

    timesheetHourUpdate(timesheetHourUpdateRequest: TimesheetHourUpdateRequest) {
        return this.httpClient(this.productCode).post("/timesheets/TimesheetHourUpdate", timesheetHourUpdateRequest).then(async res => {
            return  res.data;
         });
    }

    getAllowedExpenses(timesheetId): Promise<AllowedExpenses[]> {

        const result = new Promise<AllowedExpenses[]>((resolve,reject)=> {
            this.httpClient(this.productCode).get("/timesheets/GetAllowedExpenses",{timesheetId:timesheetId}).then((response)=>{
                resolve(response.data);
            }).catch((e)=>{
                reject(e);
            })  
        });
       return result;
     }

     getExpenses(timesheetId): Promise<TimesheetExpense[]> {
        const result = new Promise<TimesheetExpense[]>((resolve,reject)=> {
            this.httpClient(this.productCode).get("/timesheets/GetTimesheetExpenses",{timesheetId:timesheetId}).then((response)=>{
                resolve(response.data);
            }).catch((e)=>{
                reject(e);
            });
        });

      return result;
     }

     getExpense(timesheetExpenseId): Promise<TimesheetExpense> {
        const result = new Promise<TimesheetExpense>((resolve,reject)=> {
            this.httpClient(this.productCode).get("/timesheets/GetTimesheetExpense",{timesheetExpenseId:timesheetExpenseId}).then((response)=>{
                resolve(response.data);
            }).catch((e)=>{
                reject(e);
            });
        });

      return result;
     }

     insertExpense(insertExpenseRequest: InsertExpenseRequest): Promise<InsertExpenseResponse>
     {
        const result = new Promise<InsertExpenseResponse>((resolve,reject)=> {
            this.httpClient(this.productCode).post("/timesheets/InsertTimesheetExpense",insertExpenseRequest).then((response)=>{
                resolve(response.data);
            }).catch((e)=>{
                reject(e);
            });
        });

        return result;
     }

     updateExpense(updateExpenseRequest: UpdateExpenseRequest): Promise<UpdateExpenseResponse> {
        const result = new Promise<UpdateExpenseResponse>((resolve,reject)=> {
            this.httpClient(this.productCode).post("/timesheets/UpdateTimesheetExpense",updateExpenseRequest).then((response)=>{
                resolve(response.data);
            }).catch((e)=>{
                reject(e);
            });
        });

        return result;
     }

     deleteExpense(timesheetExpenseId: number): Promise<UpdateExpenseResponse> {
        const result = new Promise<UpdateExpenseResponse>((resolve,reject)=> {
            this.httpClient(this.productCode).post("/timesheets/DeleteTimesheetExpense",{timesheetExpenseId:timesheetExpenseId}).then((response)=>{
                resolve(response.data);
            }).catch((e)=>{
                reject(e);
            });
        });

        return result;
     }

     submitTimesheetExpense(timesheetExpenseId: number): Promise<UpdateExpenseResponse> {
        const result = new Promise<UpdateExpenseResponse>((resolve,reject)=> {
            this.httpClient(this.productCode).post("/timesheets/SubmitTimesheetExpense",{timesheetExpenseId:timesheetExpenseId}).then((response)=> {
                resolve(response.data);
            }).catch((e)=> {
                reject(e);
            });
        });

        return result;
     }

    expenseDocumentUpload(formData, timesheetExpenseId: number): Promise<DocumentUploadResponse> {
            const result = new Promise<DocumentUploadResponse>((resolve,reject)=> {
                return this.httpClient(this.productCode).post("/timesheets/ExpenseDocumentUpload?TimesheetExpenseId="+timesheetExpenseId.toString(), formData).then((response)=> {
                resolve(response.data);
            }).catch((e)=> {
                reject(e);
            });
        });
        return result;
    }

    getExpenseDocument(timesheetExpenseId: number): Promise<string> {     
        return this.httpClient(this.productCode).get("/timesheets/GetExpenseDocument",
        {timesheetExpenseId:timesheetExpenseId},
        { responseType: 'arraybuffer' }).
        then(response => 
           {
              const u8 = new Uint8Array(response.data)
              const b64encoded = btoa([].reduce.call(new Uint8Array(response.data),function(p,c){return p+String.fromCharCode(c)},''))

              const mimetype= response.headers["content-type"];
              return "data:"+mimetype+";base64,"+b64encoded

              }).catch((e)=>{
                  console.log(e);
                    return null;
            });
    }

    getExpenseDocumentBlob(timesheetExpenseId: number): Promise<Blob> {   
        const timestamp = Date.now().toString().replace(/[^\d]/g,'');
        return this.httpClient(this.productCode).get("/timesheets/GetExpenseDocument",
        {timesheetExpenseId:timesheetExpenseId, timestamp:timestamp},
        { responseType: 'arraybuffer' }).
            then(response =>  { 
                const headers = response.headers;
                const blob = new Blob([response.data],{type:headers['content-type']});

                return blob;
            }
        );
    }

    deleteExpenseDocument(timesheetExpenseId: number): Promise<UpdateExpenseResponse> {
        const result = new Promise<UpdateExpenseResponse>((resolve,reject)=> {
            this.httpClient(this.productCode).post("/timesheets/DeleteTimesheetExpenseDocument",{timesheetExpenseId:timesheetExpenseId}).then((response)=>{
                resolve(response.data);
            }).catch((e)=>{
                reject(e);
            });
        });

        return result;
     }
}

let TimesheetServiceInstance;
export default TimesheetServiceInstance = new TimesheetService();
export class TimesheetExpense{
    TimesheetExpenseId: number;
    Description: string;
    Quantity: number;
    PayRate: number;
    Total: number;
    Note: string;
    ExpenseApprovalStatus: number;
    ExpenseApprovalStatusDescription: string;
    ExpenseApprovalStatusSystemCode: string;
    TimesheetId: number;
    InvoiceLineItemCodeId: number;
    AllowedToEdit: boolean;
    CurrencySymbol: string;
    SupportingDocumentId = 0;
    SupportingDocumentFileExtension: string;
    SupportingDocumentDescription: string;
}

export class TimesheetsInRangeResponse{
    Timesheets: TimesheetPeriod[];
}

export class TimesheetPeriod {
    TimesheetId: number;
    PeriodStarting: Date;
    PeriodEnding: Date;
}

export class AllowedExpenses {
    PlacementExpenseId: number;
    InvoiceLineItemCodeId: number;
    Description: string;
    CurrencySymbol: string;
    PayRate: number;
}

export class InsertExpenseResponse {
    Success: boolean;
    TimesheetExpenseId: string;
}

export class UpdateExpenseResponse {
    TimesheetExpenseId: number;
    Success: boolean;
}

export class InsertExpenseRequest {
    PlacementExpenseId: number;
    Quantity: number;
    TimesheetId: number;
    Note: string;
    PayRate: number;
}

export class UpdateExpenseRequest{
    TimesheetExpenseId: number;
    Quantity: number;
    Note: string;
    TimesheetId: number;
    PayRate: number;
}


export class ShiftRequest {
    StartDate: Date;
    EndDate: Date;
}

export class ShiftResult {
    JobShifts: JobShift[];
}

export class JobShift {
    ObjectDiaryId: number;
    JobId: number;
    ClientId: number;
    PlacementId: number;
    Company: string;
    JobTitle: string;
    WorkAddress: string;
    HasLogo: boolean;
    ShiftDate: Date;
    ShiftStartTime: Date;
    ShiftEndTime: Date;
    HasTimesheet: false;
    //TimesheetEvents: TimesheetCheckInEvent[];
    ClockState: ClockStateResult;
    ShiftDuration: string;
    ShiftTimeType: string;
    ClockStateId: string;
    PayRate: string;
    ClockInOut: boolean;
    ManualEntry: boolean;
    BatchTimesheet: boolean;
    TimesheetId: number;
}

export class Timesheet {
    TimesheetId: number;
    CompanyName: string;
    PlacementId: number;
    PeriodStarting: Date;
    PeriodEnding: Date;
    CreatedOn: Date;
    HasLogo = false;
    Location: string;
    JobTitle: string;
    StatusDescription: string;
    StatusSystemCode: string;
    AllowedToEdit: boolean;
    AllowedExpenses: boolean;
    LastShiftAdded: boolean;
}

export class TimesheetHour {
    TimesheetHourId: number;
    TimesheetId: number;
    WorkedOn: Date;
    StartTime: Date;
    EndTime: Date;
    PlacementOvertimeId: number;
    PeriodStarting: Date;
    PeriodEnding: Date;
    HoursWorked: number;
    PayRate: number;
    ChargeUnit: string;
    StartTimeZone: string;
    EndTimezone: string;
    RunningHours = 0;
    RunningMin = 0;
}

export class TimesheetHourBreak {
    TimesheetHourBreakId: number;
    TimesheetHourId: number;
    StartTime: Date;
    EndTime: Date;
}

export class TimesheetRequest {
    StartDate: Date;
    EndDate: Date;
}

export class TimesheetResult {
    Timesheets: Timesheet[];
    TimesheetHours: TimesheetHour[];
    TimesheetHourBreaks: TimesheetHourBreak[];
}

export class TimesheetExpenseRequest {
    StartDate: Date;
    EndDate: Date;
}

export class TimesheetExpenseResult {
    Timesheets: Timesheet[];
    TimesheetExpenses: TimesheetExpense[];
}

export class InsertTimesheetRequest {
    ShiftDate: Date;
    ShiftStartTime: Date;
    ShiftEndTime: Date;
    PlacementId: number;
    ObjectDiaryId: number;
    ClockState: ClockStateResult = new ClockStateResult();
    StartTimeZone: string;
    EndTimeZone: string;
    OriginalShiftStartTime: Date;
}

export class InsertTimesheetResult {
    TimesheetId: number;
    LastShift: boolean;
}

export class TimesheetHourUpdateRequest {
    TimesheetHour: TimesheetHour;
    TimesheetHourBreak: TimesheetHourBreak;
}

export class DocumentUploadResponse {
    TimesheetExpenseId: number;
    DocumentId: number;
}