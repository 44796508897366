import { Vue, Component, PropSync } from 'vue-property-decorator';
import store, {AppStore} from '../store/';
import AuditEventLog from '@/services/auditEventLog';
@Component({
})

export default class EarlyPay extends Vue {
  @PropSync("isCloseable", { type: Boolean }) showClose: boolean;
  showAlert = false;
  
  constructor() {
    super();
  }

  beforeMount()
  {
    this.showAlert = store.getters.AppState.featureEnabled("RCRMEARLYPAY")
  }

  
  close(){
    this.showAlert = false;
    this.$emit("close");
  }

  earlyPayLink()
  {
   
    if(!this.isIOS())
    {
      window.open("https://play.google.com/store/apps/details?id=com.theaccessgroup.earlypay&gl=GB",'_blank')
    }
    else
    {
      window.open("https://apps.apple.com/gb/app/access-earlypay/id1457007747",'_blank')
    }
    this.earlyPayAudit();
  }

  async earlyPayAudit()
  {
      AuditEventLog.postAuditEventLog("EARLYPAY");
  }

  isIOS(): boolean {
    return /iphone|ipod|ipad/.test( navigator.userAgent ) || (navigator.userAgent.includes("Mac") && "ontouchend" in document);
  }
}