export class LocationData {
    latitude: number;
    longitude: number;
    
   
}

export class Locations {

    static instance: Locations;
     locationData = new LocationData();

   
     async getLocationData(): Promise<LocationData> {
       
            return new Promise(resolve => {
               
                const locationData = new LocationData();
                    navigator.geolocation.getCurrentPosition(
                          (position) => {
                            locationData.latitude = position.coords.latitude;
                            locationData.longitude = position.coords.longitude;
                            resolve(locationData);                        
                        },
                         
                        (err) => 
                            {
                              locationData.latitude = 0;
                              locationData.longitude = 0;
                             
                              resolve(locationData);
                             }
                        );
                    })
           
    }

}

if(Locations.instance == null){
    Locations.instance = new Locations();
}

export  default Locations.instance;