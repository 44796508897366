<template>        
<div id="checkInView" ref="checkInView">
 
  <ConnectionLostCardView :isVisible="showNetworkError" @close="retryClicked" />
    <LoadingIndicator :isVisible="isLoading"></LoadingIndicator>
   
    <!-- <div v-if="!isLoading">
    <div  v-for="group in shiftGroups" :key="group.text">
     
      <div v-for="jobShift in group.shifts"  :key="jobShift.ObjectDiaryId" :src="jobShift.ObjectDiaryId">

      <div class="pb-2"> <span class="group-text">{{formatDateFromNow(jobShift.ShiftStartTime)}}</span>, <span class="group-time">{{formatDateToTime(jobShift.ShiftStartTime)}} - {{formatDateToTime(jobShift.ShiftEndTime)}}</span></div>

        <div class="rounded-corners card-border job-list-item mb-2 pa-2">

          <JobShiftSummaryCard 
              :jobShift="jobShift"
              @checkIn="checkIn">
              <template v-slot:TimesheetEvents></template>
            </JobShiftSummaryCard>

        </div>
      </div> 

    </div>
  </div> -->
  
  <div v-if="!isLoading">
     
      <div v-for="(jobShift,index) in shifts"  :key="index" :src="jobShift.ObjectDiaryId" :id="`d${new Date(jobShift.ShiftStartTime).toLocaleDateString().split('/').join('')}`">

      <div class="pb-2"> <span class="group-text">{{formatDateFromNow(jobShift.ShiftStartTime)}}</span>, <span class="group-time">{{formatDateToTime(jobShift.ShiftStartTime)}} - {{formatDateToTime(jobShift.ShiftEndTime)}}</span></div>

        <div class="rounded-corners card-border job-list-item mb-2 pa-2">

          <JobShiftSummaryCard 
            :jobShift="jobShift"
            @checkIn="checkIn"
            @batchTimesheet="batchTimesheet">
            <template v-slot:TimesheetEvents></template>
          </JobShiftSummaryCard>

        </div>
        <div intersect.quiet="options" :data-date="jobShift.ShiftDate"></div>
      </div> 

  </div>


  

    <!-- <v-list dense> 
      <template >     
        <v-list-item v-for="jobShift in shifts" class="rounded-corners card-border job-list-item" :key="jobShift.ObjectDiaryId" :src="jobShift.ObjectDiaryId">
          <v-list-item-content>
            <div class="pb-2"> <span class="group-text">{{formatDateFromNow(jobShift.ShiftStartTime)}}</span>, <span class="group-time">{{formatDateToTime(jobShift.ShiftStartTime)}} - {{formatDateToTime(jobShift.ShiftEndTime)}}</span></div>
            <JobShiftSummaryCard 
              :jobShift="jobShift"
              @checkIn="checkIn">
              <template v-slot:TimesheetEvents></template>
            </JobShiftSummaryCard>
          </v-list-item-content>
        </v-list-item>
      </template>
      <v-list-item class="mt-5 ">
        <v-list-item-content>
          <LoadingIndicator :isVisible="isLoading"></LoadingIndicator>
        </v-list-item-content>
      </v-list-item>
    </v-list>  -->


</div>
</template>


<script lang="ts">
  import ViewMode from "./CheckInView"
  export default ViewMode;
</script>

<style scoped>
  .group-text{
    font-weight: bold;
    font-size: 12px;
  }

  .group-time
  {
    font-size: 12px;
    font-weight: normal;
  }

 
</style>