import Vue from "vue";
import Component from "vue-class-component";
import DiaryService, { DiaryEvent } from "./DiaryService";

import moment from 'moment';
import { Prop} from 'vue-property-decorator';
import 'moment-duration-format';
import global from '@/utils/global'

@Component({
    name:"availabilitycardview",
    
})

export default class AvailabilityCardView extends Vue {

  @Prop(Object) event: DiaryEvent;
  @Prop(Boolean) isDeleteEvent: boolean;
  logoUrl = "";
  isActive=false;
 
  constructor() {
         super();
    }


    beforeMount(){
     
      if(this.event.HasLogo){
        
         DiaryService.getCompanyLogo(this.event.PlacementId,36,36,false).then(e=>{
            this.logoUrl = e;
        });
    }
    

    }

   

    get class()
    {
        if(this.deleteEvent)
        {
            return "text-center bold"
        }
        return "text-left"
    }

    get jobStartingTime(): string 
    {
        //how long till event started 
       // const startDateTime =new Date(new Date(new Date(this.EventLocal.Start).getTime() - (new Date(this.EventLocal.Start).getTimezoneOffset() * 60000)));
       // console.log(startDateTime)
       // return moment(startDateTime,"YYYY-MM-DDTHH:MM:SSZ").fromNow();
        const startDateTime = moment(this.event.Start);
        const endDateTime = moment(this.event.End);
        const now = moment();
        const duration =  moment.duration(startDateTime.diff(now));
        const days = parseInt(duration.asDays().toString());
 
        if (days < 0)
        {
             return 'Completed ' + Math.abs(days).toString() + ' d ago';
        }

        if(days === 0)
        {
            let hours = parseInt(duration.hours().toString());
            hours = hours - days*24
            if(hours < 0)
            {
                //If enddateTime > now then this shift is still in progress
                if(endDateTime > now )
                {
                    return 'Started ' + Math.abs(hours).toString() + ' h ago';
                }
                return 'Completed ' + Math.abs(hours).toString() + ' h ago';

            }
            if(hours > 0 )
            {
                return 'Begins in ' + hours.toString() + ' h';
            }
            let minutes = parseInt(duration.minutes().toString());
            minutes = minutes - (days*24*60 + hours*60);
            if(minutes < 0 )
            {
            return 'Completed ' + Math.abs(minutes).toString() + " m ago";
            }
            return 'Begins in ' + minutes.toString() + " m";
        }
       
        //days >= 1      
        return 'Begins in ' + (startDateTime.diff(now,'days')+1).toString() + " d";
       
       
    }
    

    formatDateFromNow(date){
      return global.formatDateFromNowLiteralOrDate(date);
    }

    formatTime(date)
    {
        return global.formatDateToTimeUTC(date);
    }
    get jobDuration()
    {
        //holidays - sickness etc ?
        if(this.event.Start === this.event.End)
        {
            return "1 d";
        }
        const startDateTime = moment.utc(this.event.Start);
        const endDateTime = moment.utc(this.event.End);
        const duration =  moment.duration(startDateTime.diff(endDateTime));
        return Math.abs(duration.hours()).toString() + " h " + ((Math.abs(duration.minutes()) > 0 ) ? Math.abs(duration.minutes()).toString() + " m" : "" );

    }


    deleteEvent()
    {
    
       this.$emit('deleteEvent');
    }
   

    selected(event)
    {
        if(event.ClientId != 0 && event.JobId !=0)
        {
            this.$emit('selectedEvent');
            
        }
        
    }
}
