import moment from 'moment';
import Vue from "vue";
import Component from "vue-class-component";
import { Mixins, Prop, PropSync, Watch } from 'vue-property-decorator';
import VueTimepicker from 'vue2-timepicker'
import 'vue2-timepicker/dist/VueTimepicker.css'
import { DiaryEventInsertRequest, DiaryEventMultiDayInsertRequest } from "./DiaryService";
import AvailabilityCalendar from "../availability/AvailabilityCalendar.vue"
import { AvailabilityCalendarMixin } from './AvailabilityCalendarMixin';
import Alert from "@/components/Alert.vue";
import { NotificationMixin } from '@/components/mixins/notificationMixin'


@Component({
  name: "availabilityset",
  components: { VueTimepicker, AvailabilityCalendar,Alert }
})

export default class AvailabilitySet extends Mixins(AvailabilityCalendarMixin,NotificationMixin) {
  
  @Prop( Date ) startDateLocal: Date;
  @Prop(Number) readonly forceChildReload: number;
  @Prop( Array ) localErrors: any[];

  dates = [];
  availability = 0;
  allDay = true;

  addTime = false;
  addDate = false;

  startTime = "09:00";
  endTime = "17:00";
  startTimeEdit = "09:00";
  endTimeEdit = "17:00";
  errors = [];
  

  description = "";

  constructor() {
    super();
  }

  @Watch("forceChildReload")
  forceChildReloadChanged(val)
  {
    if(val != null && val != undefined)
    {
     
        this.forceReload = true;
        this.reload += 1;
      
    }
  }


  @Watch("startDateLocal")
  startDateChanged(val) {
   
    this.resetValues();

    this.selectedDate = val;
    this.startDate = this.selectedDate;
    this.dates = [];
    // this.dates.push(
    //   new Date(
    //     this.selectedDate.getTime() -
    //       this.selectedDate.getTimezoneOffset() * 60000
    //   )
    //     .toISOString()
    //     .slice(0, 10)
    // );
    this.monthStart = moment(this.selectedDate)
      .startOf("month")
      .toDate();
    this.modelDate = new Date(
      this.selectedDate.getTime() -
        this.selectedDate.getTimezoneOffset() * 60000
    )
      .toISOString()
      .substr(0, 10);
    this.reload += 1;
  }

  @Watch('localErrors')
    availabilityOverrideChanged(val, oldVal) {
      if(val){
          this.localErrors = val;
      }
    }

  mounted() {
    this.storeStartDate = true;
    this.reload = 0;
    this.selectedDate = this.startDateLocal;

    this.startDate = this.selectedDate;
    /*this.dates.push(
      new Date(
        this.selectedDate.getTime() -
          this.selectedDate.getTimezoneOffset() * 60000
      )
        .toISOString()
        .slice(0, 10)
    );*/

    this.reloaded = true;
    this.monthStartLocalDate = moment(this.selectedDate)
      .startOf("month")
      .toDate();
    this.setUp();
    this.reloaded = false;
    this.firstLoad = false;
  }

  allDayChanged(e) {
    if (e == false) {
      this.startTime = "09:00";
      this.endTime = "17:00";
      return;
    }

    this.startTime = "00:00";
    this.endTime = "00:00";
  }

  setTimes() {
    if (this.allDay) {
      return;
    }
    this.startTimeEdit = this.startTime;
    this.endTimeEdit = this.endTime;
    this.addTime = true;
  }

  pickDateChanged(val) {
    this.pickerDate = val;
  }

  cancelTime() {
    this.startTimeEdit = this.startTime;
    this.endTimeEdit = this.endTime;
    this.addTime = false;
    this.errors = [];
  }

  submitTime() {
    this.startTime = this.startTimeEdit;
    this.endTime = this.endTimeEdit;

    this.addTime = false;
  }

  isTimeValid() {
    this.errors = [];
    if (this.endTimeEdit == "00:00") {
      return;
    }
    if (this.endTimeEdit < this.startTimeEdit) {
      this.errors.push({
        message: this.$t("availability.timeWarningMessage").toString(),
      });
    }

    if(this.startTimeEdit === this.endTimeEdit){
      this.errors.push({message: this.$t("alertMessages.startTimeEndTimeAvailability").toString()});
    }
  }

  back() {
   
    if (this.selectedDate.getMonth() != this.startDateLocal.getMonth()) {
      this.forceReload = true;
      this.reload += 1;
    }
    this.resetValues();
    
    this.$emit("close");
  }

  resetValues() {
    this.selectedDate = this.startDateLocal;

    this.startTime = "09:00";
    this.endTime = "17:00";
    this.errors = [];
    this.description = "";
    this.allDay = true;
    this.availability = 0;
    this.dates = [];
    /*this.dates.push(
      new Date(
        this.selectedDate.getTime() -
          this.selectedDate.getTimezoneOffset() * 60000
      )
        .toISOString()
        .slice(0, 10)
    );*/
    document.getElementById("setAvailDialog").scrollIntoView({ behavior: "smooth" });
    
  }

  cancel() {
  
    if (this.selectedDate.getMonth() != this.startDateLocal.getMonth()) {
      this.forceReload = true;
      this.reload += 1;
    }
    this.resetValues();

    this.$emit("close");
  }

  save() {
    if(this.dates.length == 0)
    {
      return;
    }
    const diaryEvent = new DiaryEventMultiDayInsertRequest();
    diaryEvent.ObjectDiaryId = 0;
    diaryEvent.Availablity = this.availability == 0 ? true : false;
    diaryEvent.AllDayEvent = this.allDay;
    diaryEvent.Description = this.description;
    diaryEvent.StartTime = this.allDay ? "00:00" : this.startTime;

    diaryEvent.EndTime = this.allDay ? "00:00" : this.endTime;
    diaryEvent.Dates = this.dates;

    this.forceReload = true;
    this.resetValues();
    this.$emit("save", diaryEvent);
  }

  showWarningAlert(message: string){
    this.messageBoxWarning(message);
  }

  saveMulti(date) {
    
    if (date.length > 31) {
    
      this.showWarningAlert(this.$t("availability.daysSelectedErrorMessage").toString())

      return;
    }
    this.dates = date;
    this.dates.sort(function(a, b) {
      return +new Date(a) - +new Date(b);
    });
    
  }


  clearData() {
    this.dates = [];
  }
}
