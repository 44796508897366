import { Vue, Component, PropSync } from 'vue-property-decorator'
import store from "../store/index";
import axios, { AxiosInstance } from "axios";
import BaseService from '@/services/baseService';

interface AuditLogger {
    logEvent(eventType: string);
    getEventLog();
}



export class AuditLoggerImp extends BaseService implements AuditLogger {

    static instance: AuditLoggerImp;
    intervalRef = 0;
    constructor() {
       super();

       if(import.meta.env.NODE_ENV === 'production'){
       
                this.intervalRef  = setInterval(() =>{
                        this.syncAudit();
                },10000);
        }
        
        
    }



    logEvent(eventType: string, multiplePerDay = false){
        const key = new Date();
        const item = sessionStorage.getItem("auditEventLog");
        if(item){
            const eventlog = JSON.parse(item);
            eventlog.push({eventType:eventType,eventDate:key, multiple: multiplePerDay});
            sessionStorage.setItem("auditEventLog",JSON.stringify(eventlog));
        }
        else{
            const eventlog = [];
            eventlog.push({eventType:eventType,eventDate:key, multiple: multiplePerDay});
            sessionStorage.setItem("auditEventLog",JSON.stringify(eventlog));
        }

        
    }

    getEventLog(){
        let results = [];
        
        if(sessionStorage && sessionStorage.length > 0){
            const eventLog = sessionStorage.getItem("auditEventLog");
            results = JSON.parse(eventLog);
        }

        return results;
    }
  
    syncAudit(){   
        
        const request ={EventLog:[]}
        const eventLog =   this.getEventLog();
        if(eventLog && eventLog.length > 0){
            eventLog.forEach(e => {
                request.EventLog.push({
                    eventType:e.eventType,
                    eventDate:e.eventDate,
                    multiple:e.multiple
                    });
            });

            if(request.EventLog.length > 0){
                this.httpClient("MOB").post("/AuditEventLog/InsertEvents",request).catch((e) =>{
                        clearInterval(this.intervalRef);
                });
                sessionStorage.removeItem("auditEventLog");
            }
        }
    }
}

if(AuditLoggerImp.instance == null){
    AuditLoggerImp.instance = new AuditLoggerImp();
}

export  default AuditLoggerImp.instance;

//export default new ErrorLogger();