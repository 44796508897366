<template>
    <div class="text-center">
        <v-dialog
          v-model="showDialog"
          persistent
          width="500"
        >
          <v-card>
             <v-card-title class="d-flex justify-center">
          <div class="d-flex flex-column">
            <div class="text-center">
            <img src="@/assets/img/icons/updateApp.png" height="100px" width="106px"/>
            </div>
            <b>{{$t('updateApp.updateAppTitle')}}</b>
          </div>
          
        </v-card-title>
        <v-card-text class="alertBody text-center">
          <v-spacer></v-spacer>
          <p>
              {{$t('updateApp.updateAppMessage')}}
          </p>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn class="action-update"
            color="primary"
            depressed
            block
            @click="onUpdateClicked"
          >
           {{$t('updateApp.updateAppButtonText')}}
          </v-btn>
        </v-card-actions>  

          </v-card>
        </v-dialog>
      </div>


  </template>
  
  
  <script lang="ts">
    import ViewModel from "./UpdateBanner"
    export default ViewModel;
  </script>
    