<template>

<v-dialog v-model="showDialog" width="296" persistent  >
      <v-card  min-height="353px" >
        <v-card-title class="d-flex justify-center">
          <div  class="d-flex flex-column pt-2">
            <div class="text-center">
            <img src="@/assets/img/NoAccess/Access denied.png" height="80px" width="80px"/>
            </div>
            <v-row justify="center" class="pt-1">
            <b class="h1-large">{{ $t("noAccess.noAccessTitle") }}</b>
            </v-row>
          </div>
          
        </v-card-title>
        <v-card-text class="alertBody text-center ">
          <v-spacer></v-spacer>
          
          <v-row justify="center" class="pl-4 pr-4">
             <p class="nunito-reg16">  {{ $t("noAccess.noAccessMessage") }}</p>
          </v-row>
        </v-card-text>
        <v-card-actions class="pt-2"  >
          <v-row justify="center" style="height:40px" class="ml-0 mr-0">
          <v-btn class="primary signout-btn action-signout"   text  @click="SignOut()">
            {{ $t("noAccess.noAccessButtonText") }}
          </v-btn>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>

</template>
 <script lang="ts">
      import ViewModel from "./NoAccess"
      export default ViewModel;
  </script>
<style lang="scss" scoped>

.signout-btn
{
min-width: 232px !important;
font-size:16px;

}
.nunito-reg16
{
  font-family: 'Nunito' !important;
  font-size: 16px important;
  color: #6E6E6E;
}
  

</style>
