
<div>
  <v-app-bar dense app color="white" class="top-toolbar pt-0">
    <v-tabs   v-model="selectedTabIndex" :grow="true" class="small-text-tab" style="max-width: 100%;">
      <v-tab ref="btnShortlisted" @click="selectedView = 'shortlisted'" >{{ $t("jobs.headerShortlisted") }}</v-tab>
      <v-tab ref="btnApplied" @click="selectedView = 'applied'">{{ $t("jobs.headerApplied") }}</v-tab>
      <v-tab  ref="btnView" @click="selectedView = 'recent'">{{ $t("jobs.headerRecent") }}</v-tab>
      <v-tab  ref="btnStatus" @click="selectedView = 'status'">{{ $t("jobs.headerPublished") }}</v-tab>
    </v-tabs>
  </v-app-bar>
  <v-container class="pa-0" v-if="jobOfferCount()" @click="showJobOffers = true">
      <v-row class="job-offer-background pa-3">   
        <v-col cols="2" sm="2" md="2" class="job-offer-box pt-3 pb-3 ml-3" >          
        <div class="rounded img-round logo-image notification">
          <v-badge content="!" 
              color="red"
              overlap>
          <v-icon class="pa-3">mdi-wallet-travel
          </v-icon>
          </v-badge>
        </div>
        </v-col>
        <v-col cols="7" sm="7" md="7" class="details-container job-offer-box">       
          <v-row class="text-h1">{{ $t("jobs.jobOffers") }}</v-row>
          <v-row class="text-subtitle-1 copy pa-0">
          <p class="ma-0"><strong>{{ jobOfferCount() }}</strong> {{$t('jobs.jobOfferText')}}</p>
          </v-row>
        </v-col>
        <v-col cols="2" sm="2" md="2" class="job-offer-box">
          <div class="job-text-center">
          >
          </div>
        </v-col>
    </v-row>
     </v-container>
      <v-container>
    <shortlisted-view class="pt-1" v-if="selectedView == 'shortlisted'"
      @setShortlisted="setShortlisted"
      @removeShortlisted="removeShortlisted"
      @showJob="showJob"></shortlisted-view>
    <applied-view class="pt-1" v-if="selectedView == 'applied'" 
      @setShortlisted="setShortlisted"
      @removeShortlisted="removeShortlisted"
      @showJob="showJob"></applied-view>
    <recent-view class="pt-1" v-if="selectedView == 'recent'" @showJob="showJob"></recent-view>
    <broadcast-jobs class="pt-1" v-if="selectedView == 'status'" @showJob="showJob"></broadcast-jobs>
    
  </v-container>
  <v-dialog v-model="showJobDetails" max-width="500px" fullscreen>
    <JobDetails :isOpen="showJobDetails" :selectedJob="selectedJob" @close="showJobDetails = false" :closeTopDialog="closeTopDialog"></JobDetails>
  </v-dialog>
<v-dialog v-model="showJobOffers" max-width="500px" fullscreen >
    <JobOfferView @close="showJobOffers = false"></JobOfferView>  
</v-dialog> 
</div>
