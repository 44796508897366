import BaseService from '@/services/baseService';
import ErrorLogger from '@/utils/errorLogger';
import AuditEventLog from '@/services/auditEventLog';
import AuditLogger from "@/utils/auditLogger";
import auditLogger from '@/utils/auditLogger';
export type AppStateType = {
   diaryEventsRequest: DiaryEventsRequest;
 }

export  class DiaryService extends BaseService {
    baseUrl: string;
    diaryEventsResult: DiaryEventsResult;
    productCode = "RCRM";

   getEventsBetweenDates(diaryEventsRequest: DiaryEventsRequest): Promise<any> {

    return new Promise<any>((resolve,reject)=>
    {
      
      return this.httpClient(this.productCode).get("/diary/GetDiaryEvents", diaryEventsRequest).then(async response =>{
           return response;
        
         }).then(async r =>{
           resolve(r);
         })
         .catch((e)=> {
          ErrorLogger.logError(e.toString(),e);
            reject(e);
         })
        })
    }
    


   getObjectDiaryEventTypes(): any {
      return this.httpClient(this.productCode).get("/diary/GetObjectDiaryTypes");
   }

  

   postDiaryEventInserts(diaryEventInsertRequest: DiaryEventMultiDayInsertRequest): Promise<any> {
    return new Promise<any>((resolve,reject)=>
    {
        return this.httpClient(this.productCode).post("/diary/InsertDiaryEvents", diaryEventInsertRequest).then(async response =>{
          this.postAudit();
          return response;

        }).then(async r =>{

          resolve(r);
        })
        .catch((e)=> {
        ErrorLogger.logError(e.toString(),e);
          reject(e);
        });
    });
   }

   postDiaryEventDelete(diaryEventDeleteRequest: DiaryEventDeleteRequest): Promise<any>
   {
    return new Promise<any>((resolve,reject)=>
    {
        return this.httpClient(this.productCode).post("/diary/DeleteDiaryEvent", diaryEventDeleteRequest).then(async response =>{
          return response;
        }).then(async r =>{
          
          resolve(r);
        })
        .catch((e)=> {
        ErrorLogger.logError(e.toString(),e);
          reject(e);
        })
    })
   }

   async postAudit()
   {
      
      auditLogger.logEvent("AVAILABILITY",true);
   }

   getCompanyLogo(placementId: number,width: number,height: number,crop = false): Promise<string> {
    return this.httpClient(this.productCode).get("/timesheets/GetCompanyLogo",
    {placementId:placementId,width:width,height:height,crop:crop},
    { responseType: 'arraybuffer' }).
    then(response => 
       {
          const u8 = new Uint8Array(response.data)
          const b64encoded = btoa([].reduce.call(new Uint8Array(response.data),function(p,c){return p+String.fromCharCode(c)},''))
          const mimetype="image/jpeg"
          return "data:"+mimetype+";base64,"+b64encoded

          }).catch((e)=>{
              console.log(e);
                return null;
             });
  }
}

let DiaryServiceInstance;
export default DiaryServiceInstance = new DiaryService();




export class DiaryEventsRequest {
   StartDate: string;
   EndDate: string;
 }

 export class DiaryEvent {
   RowId: number;
   ObjectDiaryId: number;
   PlacementId: number;
   JobId: number;
   ObjectDiaryEventTypeDescription: string;
   Description: string;
   Start: Date;
   End: Date;
   AllDayEvent: boolean;
   JobDescription: string;
   PayRate: number;
   ChargeUnit: string;
   Company: string;
   HasLogo: boolean;
   WorkAddress: string;
   UpcomingEvent = false;
   UniqueId: string;
   Logo = '';
   ClientId: number;
   SystemCode: string;
   CanDelete = false;
   NextShiftStartime: Date;
 }

 export class DiaryEventsResult {
   DiaryEvents: DiaryEvent[];
 }

 export class DiaryEventMultiDayInsertRequest {
   ObjectDiaryId: number;
   Availablity: boolean;
   Description: string;
   Dates: string[];
   StartTime: string;
   EndTime: string;
   AllDayEvent: boolean;
 }

 export class DiaryEventInsertRequest {
  ObjectDiaryId: number;
  Availablity: boolean;
  Description: string;
  Start: Date;
  End: Date;
  AllDayEvent: boolean;
  Frequency: number;
}

export class DiaryEventDeleteRequest
 {
  ObjectDiaryId: number;
  Start: Date;
  End: Date;
  
 
 }

 

 export class ObjectDiaryEventType {
   ObjectDiaryEventTypeId: number;
   Description: string;
 }

 export class ObjectDiaryEventTypeResult {
   ObjectDiaryEventTypes: ObjectDiaryEventType[];
 }

 export class Booking {
    CompanyName: string;
    JobTitle: string;
    CompanyLocation: string;
    Dates = [];
    Visible = false;
 }

 export class Holiday {
   Type: number;
   Info: string;
   Dates = [];
   Visible = false;
 }