import { Vue, Component, Mixins } from 'vue-property-decorator'
import JobService, { Job, RecentJobsRequest, RecentJobsResult } from "../../../../rdb/components/jobs/JobService";
import JobSummaryCard from "../../jobs/components/JobSummaryCard.vue";
import { NetworkMixin} from '@/components/mixins/networkMixin'

@Component({
    name:"recent-view",
    components:{ JobSummaryCard }
})

export default class RecentView extends Mixins(NetworkMixin) {
    
    recentJobs: RecentJobsResult = new RecentJobsResult();

    constructor() {
        super();
    }

    mounted() {
        this.getRecentJobs();
    }

    getRecentJobs(page = 1) {
        const shortlistedJobsRequest = new RecentJobsRequest();
        shortlistedJobsRequest.Page  = page;
        this.doWork<any>(JobService.recentJobs(shortlistedJobsRequest),() =>{this.getRecentJobs()}).then((result) => {
            this.recentJobs.PageInfo = result.data.PageInfo;
            
            result.data.Jobs.forEach(i =>this.recentJobs.Jobs.push(i));
        });
    }

    setShortlisted(job: Job) {
        job.IsShortlisted = !job.IsShortlisted;
        
        if(job.IsShortlisted){
          this.$emit("setShortlisted", job);
        }else{
          this.$emit("removeShortlisted", job);
        }
    }

    jobSelected(job: Job){
        //navigate to details
        this.$emit("showJob",job);
      }

      infiniteScrolling(entries, observer, isIntersecting) {
        if (this.recentJobs.PageInfo !== undefined) {
          if (this.recentJobs.PageInfo.HasNextPage) {
            if (isIntersecting && !this.isLoading) {
                this.isLoading = true;
                   this.getRecentJobs(this.recentJobs.PageInfo.Page+1);
           }
          }
        }
      }
}