<template>
    <div>
        <v-container>
          <v-form
            ref="form"
            v-model="valid"
            lazy-validation>
            <div class="h1-large pb-3"> {{ $t("timesheets.editTimesheetHeaderTitle") }}</div>
            <v-divider></v-divider>
             <v-row>
              <div class="v-messages error--text" role="alert" v-if="errors.length > 0">
                  <p>The following errors were encountered, please correct these before continuing:</p>
                  <li v-for="error in errors" :key="error.message">
                    {{error.message}}
                  </li>
              </div>
            </v-row>
            <v-row class="d-flex justify-space-around">
              <v-col cols="6">
                <b>{{ $t("timesheets.editTimesheetCheckInTime") }}</b>
              </v-col>
              <v-col cols="6">
                <b>{{ $t("timesheets.editTimesheetCheckOutTime") }}</b>
              </v-col>              
            </v-row>
            <v-row>
              <v-col cols="6">
                <vue-timepicker class="timepicker" id="startTime" v-model="startTime" :minute-interval="1"></vue-timepicker>
              </v-col>
              <v-spacer></v-spacer>
              <v-col cols="6">
                <vue-timepicker id="endTime" class="timepicker" v-model="endTime" :minute-interval="1"></vue-timepicker>
              </v-col>
            </v-row >
            <v-row class="d-flex justify-space-around">
              <v-col cols="6">
                <b>{{ $t("timesheets.manualTimesheetTotalBreakTime") }}</b>
              </v-col>
              <v-spacer></v-spacer>
              <v-col cols="6">
                </v-col>
            </v-row>
            <v-row class="d-flex justify-space-around">
              <v-col cols="6">
                <vue-timepicker id="break" class="timepicker" v-model="breakDuration" :minute-interval="1"></vue-timepicker>
              </v-col>
              <v-spacer></v-spacer>
              <v-col cols="6">
                </v-col>
            </v-row>
            <v-row class="d-flex justify-space-around mb-6">
              <v-btn width="150" class="grey-button action-cancel" depressed @click="cancel">
                  {{ $t("cancel") }}
              </v-btn>
              <v-btn width="150" class="primary action-create-manual-timesheet" depressed @click="saveTimesheetHour">
                  {{ $t("timesheets.editTimesheetSaveTimesheetButton") }}
              </v-btn>
            </v-row>
          </v-form>
        </v-container>
    </div>
</template>
<script lang="ts">
  import ViewMode from "./TimesheetEdit"
  export default ViewMode;
</script>
<style scoped>
  .workedTime {
    color: #68B590;
  }
  
  li{
    margin-left: 15px !important;
  }

  .timepicker {
    font-size: 14px;
  }
 
  </style>