import Component from 'vue-class-component';
import Vue from 'vue';

import JobService, { BroadCastJobsResult, BroadCastJobsRequest,Job,BroadCastJob } from "@/components/rdb/components/jobs/JobService";
import store from "../../../../store/index";
import Router from '@/router/router';
import {mobileBackButtonDialogComponentMixin} from '../../../mixins/mobileDialogBackButtonHandler';
import BroadCastJobSummaryCard from './BroadCastJobSummaryCard.vue';
import BroadCastJobDetails from './BroadCastJobDetails.vue';
import { NetworkMixin} from '@/components/mixins/networkMixin'
import { Mixins } from 'vue-property-decorator';
import { EventBus } from '@/utils/events';

Component.registerHooks([
    'beforeRouteEnter',
    'beforeRouteUpdate',
    'beforeRouteLeave'
  ]);
@Component({
    name:"broadcastjobs",
    components:{BroadCastJobSummaryCard,BroadCastJobDetails},
    mixins:[mobileBackButtonDialogComponentMixin],
    watch: {
        showJobDetails: 'notifyDialogStateViaEventBus'
    }
})
export default class BroadCastJobs extends Mixins(NetworkMixin) {
   
    broadcastJobs: BroadCastJobsResult = new BroadCastJobsResult();
    showJobDetails = false;
    selectedJob: BroadCastJob = null;
    closeTopDialog = false;
    constructor() {
        super();
    }

    mounted() {
        this.getBroadCastJobs();
    }

    getBroadCastJobs(page = 1) {
        const broadcastJobsRequest = new BroadCastJobsRequest();
        broadcastJobsRequest.Page = page;
        
        this.doWork<any>(JobService.broadcastedJobs(broadcastJobsRequest),() =>{this.getBroadCastJobs()}).then((result) => {
            this.broadcastJobs.PageInfo = result.data.PageInfo;
            result.data.Jobs.forEach(i =>this.broadcastJobs.Jobs.push(i));
        });
    }

   

    jobSelected(job: BroadCastJob){
        //navigate to details
        this.showJobDetails = true;
        this.selectedJob = job;
      }

      infiniteScrolling(entries, observer, isIntersecting) {
        if (this.broadcastJobs.PageInfo !== undefined) {
          if (this.broadcastJobs.PageInfo.HasNextPage) {
            if (isIntersecting  && !this.isLoading) {
                   this.isLoading = true;
                   this.getBroadCastJobs(this.broadcastJobs.PageInfo.Page+1);
           }
          }
        }
      }

      closeAllDialogs(){
        this.showJobDetails = false;
    }

        beforeRouteUpdate(to, from, next) {
            if (this.showJobDetails) {
                this.closeTopDialog = !this.closeTopDialog;
                next(false);
            } else if (this.showJobDetails) {
                this.$emit('closeAllDialogs');
                next(false);
            } else {
                next();
            }
        }
    
        beforeRouteLeave(to, from, next) {
            if (this.showJobDetails) {
                this.closeTopDialog = !this.closeTopDialog;
                next(false);
            } else if (this.showJobDetails ) {
                this.$emit('closeAllDialogs');
                next(false);
            } else {
                next();
            }
        }

    
}