

<div v-if="isVisible">
  <v-row justify="center">
   
    <v-col cols="12" md="8"> 
     
      <div class="d-flex justify-center">
        <v-progress-circular
        indeterminate
        color="#555B70"
        ></v-progress-circular>
    </div>

    <div class="title text-center" v-if="messageText">
          {{messageText}}
    </div>
    <div v-if="!messageText" class="title text-center"> {{ $t("messages.loadingMessage") }}</div>
    </v-col>
  </v-row>
</div>
