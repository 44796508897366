
<div class="pt-0 summary-container">
<v-row no-gutters>
    <v-col cols="2" sm="2" md="1" >
      
      <div class="rounded img-round logo-image">
        <img v-if="!this.jobLocal.HasLogo"  alt="" src="@/assets/logo.png" height="auto" width="30px">
        <img v-if="logoUrl !== ''"  alt="" :src="logoUrl" height="auto" width="36px">
      </div>
    </v-col>
    <v-col cols="10" sm="10" md="10" class="details-container">
      <div class="d-flex justify-space-between">
        <div class="text-h1 ellipsis" :data-jobtitle="job.JobTitle">{{job.JobTitle}}
        </div>
        
      </div>

      
      <div class="d-flex fs-14 ellipsis text-title justify-space-between" style="max-width: 80vw;" :data-companyname="job.CompanyName">{{job.CompanyName}}</div>
      <div v-if="job.Location" class="grey--text text--darken-2 fs-13" :data-location="job.Location">{{job.Location}}</div>
      <div class="shift-description d-flex ">
        <div class="grey--text text--darken-2 fs-13" :data-term="job.Term">{{job.Term}},</div>
        <div class="grey--text text--darken-2 fs-13 pl-1" :data-shiftdescription="job.ShiftDescription"><span v-if="job.ShiftTimeType  == 'PERMANENT'" data-shift="Full Time" > Full Time </span>  <span v-if="job.ShiftTimeType  !== 'PERMANENT'" :data-shiftdescription="job.ShiftDescription"> Shift : <span :data-shift="job.ShiftTimeType == 'VARIED'?'Varied':   job.ShiftLengthHours + ' hours'">{{job.ShiftTimeType == 'VARIED'?'Varied':   job.ShiftLengthHours + ' hours' }}</span></span> </div>
      </div>
      <div class="shift-description d-inline-flex ">
        <div class="shift-indicator"><img src="@/assets/img/jobs/broadcast/CalendarMonthEnd.svg"/></div>
        <div class="grey--text text--darken-2 fs-13 shift-indicator"><span>{{formatDate(job.ShiftStartTime)}}{{formatEndDate(job.ShiftStartTime,job.ShiftEndTime)}}</span></div>
        
      </div>
      
      <div class="d-flex justify-space-between">
        <div pa-0 class="grey--text text--darken-3  fs-13" :data-displayrate="job.DisplayRate"><b>{{job.DisplayRate}}</b> </div>
        <div class="text-caption grey--text" :data-postedDate="job.CreatedOn">Posted {{ formatTimeFromNow(job.CreatedOn) }} ago</div>
      </div>
    </v-col>
  </v-row>
  <slot name="bottom"></slot>
</div>
