
<div>
  <v-dialog v-model="isVisible" fullscreen scrollable persistant>

    <v-app-bar dense app color="white" class="top-toolbar pt-0 pl-0">
      <v-btn class="back-button navigation-button" @click="closeButtonClicked()" text>
        <v-icon v-if="!forceEntry">mdi-chevron-left</v-icon>{{$t('profile.profile')}}
      </v-btn>
      <v-spacer></v-spacer>

      <v-btn @click="closeButtonClicked()" icon v-if="!forceEntry">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-app-bar>
    <v-container class="mt-10 mb-12 overflow-y-auto">

      <Alert :alertType="alertType" :isVisible="showErrorDialog" @close='showErrorDialog = false'>
        <template v-slot:message> {{$t('updateFailed')}} <br> {{$t('allFieldsRequired')}} </template>
      </Alert>
      <ConnectionLostCardView :isVisible="showNetworkError" @close="retryClicked"></ConnectionLostCardView>
    
      <LoadingIndicator :isVisible="isLoading" :messageText="loadingMessageText"></LoadingIndicator>

      <v-form v-model="valid" ref="form" lazy-validation v-show="!showNetworkError && !isLoading">
        <div>

          <div class="text-h1 pb-2">
            {{ $t("profile.title") }}
          </div>

          <v-select :rules="requiredRule" v-model="profile.title" :items="titles" outlined dense
            :placeholder="$t('profile.titlePlaceholder')">
          </v-select>

        </div>

        <div>
          <div class="text-h1 pb-2">
            {{ $t("profile.firstName") }}
          </div>
          <v-text-field class="input-firstName" :data-firstName="profile.firstName"  :rules="requiredRule" :placeholder="$t('profile.firstNamePlaceholder')"
            v-model="profile.firstName" outlined dense></v-text-field>
        </div>

        <div>
          <div class="text-h1 pb-2">
            {{ $t("profile.middleName") }}
          </div>

          <v-text-field class="input-middlename" :data-middlename="profile.middleName" :placeholder="$t('profile.middleNamePlaceholder')" v-model="profile.middleName" outlined
            dense></v-text-field>
        </div>

        <div>
          <div class="text-h1 pb-2">
            {{ $t("profile.surname") }}
          </div>

          <v-text-field class="input-surname" :data-surname="profile.surname"  :rules="requiredRule" :placeholder="$t('profile.surnamePlaceholder')" dense outlined
            v-model="profile.surname"></v-text-field>
        </div>

        <div>
          <div class="text-h1 pb-2">
            {{ $t("profile.phoneNumber") }}
          </div>

          <PhoneNumberInput :phoneNumber="profile.phoneNumber" :phoneCountryCode="profile.phoneCountryCode"
            @phoneNumberChanged="phoneNumberChanged">
          </PhoneNumberInput>

        </div>


        <div v-if="supportsPushNotifications()">
          <div class="text-h1">
             {{ $t("profile.allowPushNotifications") }}
          </div>

          <v-switch 
          class="action-notification"
          inset
          dense
          light 
          :disabled="pushDisabled" :input-value="pushNotification" @change="setNotificationPermission">
          </v-switch>

        </div>


        <v-footer fixed class="pa-2">
          <v-btn @click="updateProfile" depressed block class="grey-button action-save-profile">
            Save
          </v-btn>
        </v-footer>


      </v-form>
    </v-container>



  </v-dialog>

</div>

