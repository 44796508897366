<template>
  <div class="pt-0">
 
    <v-app-bar dense fixed color="white" class="top-toolbar pt-0 "> 
            
      <v-btn class="back-button navigation-button action-back" @click="back()" text><v-icon>mdi-chevron-left</v-icon>Back</v-btn>
    
  </v-app-bar>

 <v-container class="div-background pt-0 mt-12">

    <div>
      <JobOfferSummaryCard :job="selectedJobLocal"
      class="pt-4 mb-2" 
                @setShortlisted="setShortlisted"
                @removeShortlisted="setShortlisted" 
                @acceptJob="showJobOfferAccept(selectedJobLocal)"
                @rejectJob="rejectJobClicked(selectedJobLocal)"
                >
        </JobOfferSummaryCard>
      </div>
      <div>
        <v-divider></v-divider>
          <LoadingIndicator :isVisible="isLoading"></LoadingIndicator>
          <ConnectionLostCardView :isVisible="showNetworkError" @close="retryClicked"></ConnectionLostCardView>
            <v-tabs  v-model="selectedTabIndex">
              <v-tab class="action-tab-job" data-id="job" ref="jobtogglebtn"  @click="selectedView = 'job'">{{ $t("jobs.job") }}</v-tab>
              <v-tab class="action-tab-map" data-id="map" ref="maptogglebtn" @click="selectedView = 'map'">{{ $t("jobs.map") }}</v-tab>
              <v-tab class="action-tab-recruiter" data-id="recruiter" ref="recruitertogglebtn" @click="selectedView = 'recruiter'">{{ $t("jobs.recruiter") }}</v-tab>
            </v-tabs>
          <div v-show="!isLoading && !showNetworkError">
         <v-row v-if="selectedView === 'job'" >
          <v-col cols="12" md="12">
            <div class="fs-14 text-color-light-grey pt-0 pb-4">
              <span class="text-h1">Reference:</span> {{ selectedJobLocal.JobRefNo }}
            </div>
            <span class="job-details-description">
              {{ selectedJobLocal.Description }}
            </span>
               </v-col>
            </v-row>
            <v-row v-if="selectedView === 'map'">
                  <v-col cols="12" md="6">
                    <div class="map-area">
                  <Map :recycle="true" :location="selectedJobLocal.location" :lattitude="selectedJobLocal.Latitude" :longitude="selectedJobLocal.Longitude" :markers="markers" >
                  </Map>
                    </div>
               </v-col>    
            </v-row>
            <v-row v-if="selectedView === 'recruiter'">
                <v-col cols="12" md="12">
                  <div class="text-h1 pt-0 pb-4">
                    Contact Information
                  </div>
                  <div class="d-flex justify-start consultant-details-row">
                    <div class="pr-2 icon-col" >
                    <img src="@/assets/img/icons/name.png" height="14px"/>
                    </div>
                    <div class="value-col fs-14 fw-800 ">
                        Name:
                    </div>
                    <div class="pr-2">  
                  <span class="job-details-recruiter fs-14 text-color-light-grey"> 
                      {{ selectedJobLocal.ContactName }}
                     </span> 
                  </div>
                </div>
                
               </v-col>  
               <v-col cols="12" md="12">

                <div class="d-flex justify-start consultant-details-row">
                  <div class="pr-2 icon-col">
                  <img src="@/assets/img/icons/email.png"  height="10"/>
                  </div>
                  <div class="fs-14 fw-800 value-col">
                      Email:
                  </div>
                  <div class="pr-1">  
                    <span class="job-details-recruiter-email fs-14 text-color-light-grey">
                    <a :href="'mailto:' + selectedJobLocal.ContactEmail">{{ selectedJobLocal.ContactEmail }}</a>
                    </span>
                </div>
                </div>
               </v-col>  
               <v-col cols="12" md="12">

                <div class="d-flex justify-start consultant-details-row">
                  <div class="icon-col">
                  <img src="@/assets/img/icons/phone.png"  width="13"/>
                  </div>
                  <div class="fs-14 fw-800 value-col">
                      Phone:
                  </div>
                  <div class="pr-1">  
                    <span class="job-details-recruiter-tel fs-14 text-color-light-grey">
                      <a :href="'tel:' +  selectedJobLocal.ContactTel">   {{  selectedJobLocal.ContactTel }}</a>
                    </span>
                </div>
                </div>
               </v-col>  
            </v-row>
          </div>
          </div>
</v-container>
</div>
</template>

<script lang="ts">
    import ViewModel from "./JobOfferDetails"
    export default ViewModel;
</script>


  <style lang="scss" scoped>  
  .div-background
  {
    background-color: white;
  }
  .div-background-white
  {
    background-color: white;
    padding: 10px;
  }
  .heart-icon-div {
    padding-bottom: 0px;
  }

  .v-list-item {
    align-items: start;
    padding-left: 10px;
  }

  .details-container{
    padding-left:8px;
  }

    .icon-col{
    min-width:30px;
    /* text-align:center; */
  }
  
  .value-col{
    min-width:50px;
  }


  .consultant-details-row
  {
    line-height: 20px!important;
  }

 .map-area{
    height: calc(100vh - 328px);
    width: 100%;
    margin-right:0px;
    margin-left:0px;
  }
</style>