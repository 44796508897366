<template>
  <v-dialog v-model="showDialog">
      <v-card>
        <v-card-title class="d-flex justify-center">
          <div v-if="type =='Warning'" class="d-flex flex-column">
            <img src="@/assets/img/icons/Warning.png" height="100px"/>
            <v-row justify="center">
            <b>{{ $t("alertMessages.warningHeaderText") }}</b>
            </v-row>
          </div>
          <div v-if="type =='Info'" class="d-flex flex-column">
            <img src="@/assets/img/icons/Info.png" height="100px"/>
            <v-row justify="center">
              <b>{{ $t("alertMessages.infoHeaderText") }}</b>
            </v-row>
          </div>
          <div v-if="type =='Success'" class="d-flex flex-column">
            <img src="@/assets/img/icons/Success.png" height="100px"/>
            <v-row justify="center">
              <b>{{ $t("alertMessages.successHeaderText") }}</b>
            </v-row>
          </div>
          <div v-if="type =='Update'" class="d-flex flex-column">
            <img src="@/assets/img/icons/Update.png" height="150px"/>
            <v-row justify="center">
              <b>{{ $t("alertMessages.updateHeaderText") }}</b>
            </v-row>
          </div>
        </v-card-title>
        <v-card-text class="alertBody">
          <v-spacer></v-spacer>
           <v-row justify="center" class="centered">
            <slot  name="message"></slot>
          </v-row>
        </v-card-text>
        <v-card-actions class="d-flex justify-space-between">
          <v-btn class="primary" block text @click="close" v-bind:style=" isConfirmationLocal ? 'display: none;' : '' ">
            <b>{{ $t("ok") }}</b>
          </v-btn>
          <v-btn class="primary" text @click="confirmation(false)"  v-bind:style=" !isConfirmationLocal ? 'display: none;' : '' ">
            <b>{{ $t("no") }}</b>
          </v-btn>
          <v-btn class="primary" text @click="confirmation(true)"  v-bind:style=" !isConfirmationLocal ? 'display: none;' : '' ">
            <b>{{ $t("yes") }}</b>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
</template>
<script lang="ts">
    import ViewModel from './Alert'  
    export default ViewModel;
</script>