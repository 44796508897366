//https://developers.google.com/web/ilt/pwa/introduction-to-push-notifications

import NotificationService, { Subscription } from './NotificationService'

//https://tests.peter.sh/notification-generator/
class Notifications {
  NotificationService;
  constructor() {
    this.NotificationService = new NotificationService();
  }
  urlB64ToUint8Array = base64String => {
    const padding = '='.repeat((4 - (base64String.length % 4)) % 4)
    const base64 = (base64String + padding).replace(/-/g, '+').replace(/_/g, '/')
    const rawData = atob(base64)
    const outputArray = new Uint8Array(rawData.length)
    for (let i = 0; i < rawData.length; ++i) {
      outputArray[i] = rawData.charCodeAt(i)
    }
    return outputArray
  }


  requestPermission() {
    return new Promise<boolean>((resolve, reject) => {
      Notification.requestPermission((status) => {

        console.log('Notification permission status:', status);
        resolve(status == "granted");
      });
    });
  }


  displayNotification(title: string, options: NotificationOptions) {
    if (Notification.permission == 'granted') {
      navigator.serviceWorker.getRegistration().then((reg) => {
        reg.showNotification(title, options);
      });
    } else if (Notification.permission !== "denied") {
      this.requestPermission().then(granted => {
        if (granted) {
          navigator.serviceWorker.getRegistration().then((reg) => {
            reg.showNotification(title, options);
          });
        }
      })
    }
  }

  async checkPushRegistration() {


   
    if (Notification.permission === 'denied') {

      this.updateSubscription(null);
      return;
    }
    if ('serviceWorker' in navigator && 'PushManager' in window) {
      navigator.serviceWorker.getRegistration().then((reg) => {
        reg.pushManager.getSubscription().then((subscription) => {
          this.updateSubscription(subscription);
        }).catch(function (e) {
          if (Notification.permission === 'denied') {
            console.warn('Permission for notifications was denied');
          } else {
            console.error('Unable to subscribe to push', e);
          }
        });
      }).catch(function (e) {
        console.log('error getting service worker')
      })
    }
  }


  getVapidKey()
  {
    const result = new Promise<string>((success, reject) => {
      this.NotificationService.getVapidKey().then((result) =>
      {
        success(result);
      }).catch(function(e)
      {
        reject('noKey');
      })
    
    });
    return result;
  }

  async subscribeUser() {
    const result = await this.getVapidKey().then(async (result) => {
     
      if(result === "noKey")
      {
        return false;
      }
      const applicationServerKey = this.urlB64ToUint8Array(
        result
      )
      const options = { applicationServerKey, userVisibleOnly: true }
  
      if ('serviceWorker' in navigator && 'PushManager' in window) {
       const result2 = await navigator.serviceWorker.getRegistration().then(async (reg) => {
          const subscribeResult = await reg.pushManager.subscribe(
            options
          ).then(async (sub) => {
            const subscribeResult2 = await this.updateSubscription(sub);
          }).catch(function (e) {
            if (Notification.permission === 'denied') {
              console.warn('Permission for notifications was denied');
            } else {
              console.error('Unable to subscribe to push', e);
              this.unsubscribeUser(false)
            }
          });
  
        }).catch(function (e) {
          console.log('error getting service worker')
        })
      }

    });
   
  }


  async unsubscribeUser(doUpdate = true) {
    if ('serviceWorker' in navigator && 'PushManager' in window) {
      navigator.serviceWorker.getRegistration().then((reg) => {
        reg.pushManager.getSubscription()
          .then(function (subscription) {
            if (subscription) {
              return subscription.unsubscribe();
            }
          })
          .catch(function (error) {
            console.log('Error unsubscribing', error);
          })
          .then(() => {
            if(doUpdate)
            {
              this.updateSubscription(null);
            }

            console.log('User is unsubscribed.');
          })
      }).catch(function (e) {
        console.log('Error getting pushmanager')
      })
    }
  }


  async updateSubscription(subscription) {

    const subscriptionData: Subscription = subscription as Subscription;
    
    return this.NotificationService.postNotificationSubscription(subscriptionData);

  }

  checkPushStatus() {
    const result = new Promise<boolean>((success, reject) => {
      if (Notification.permission === 'denied') {
    
        success(false);
      }
      if ('serviceWorker' in navigator && 'PushManager' in window) {
        navigator.serviceWorker.getRegistration().then((reg) => {
          reg.pushManager.getSubscription().then((subscription) => {
            if (subscription != null) {
          
              success(true);
            }
            else {
             
              success(false);
            }

          }).catch(function (e) {
            
            success(false);
          });
        }).catch(function (e) {
        
          success(false);
        })
      }
      else {
        success(false);
      }
    });
    return result;
  }

  checkPushDisabled() {
    const result = new Promise<boolean>((success, reject) => {

      if (Notification.permission === 'denied') {
        console.log('denied')
        success(true);
      }

      if ('serviceWorker' in navigator && 'PushManager' in window) {
        navigator.serviceWorker.getRegistration().then((reg) => {
          reg.pushManager.getSubscription().then((subscription) => {
            
            success(false);

          }).catch(function (e) {
            
            success(true);
          });
        }).catch(function (e) {
         
          success(true);
        })
      }
      else {
        success(true);
      }
    });
    return result;

  }


}

export default new Notifications()