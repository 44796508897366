<template>

  <div id="app" :key="componentKey">
   
 
    <v-app >  

     <v-main>
    
       <v-container  fluid class="app-container pl-0 pr-0 pt-0" style="overflow:hidden;width:100%"> 
        <AcceptTerms v-if="!termsAccepted" :dialog="!termsAccepted"></AcceptTerms>
        <UpdateBanner></UpdateBanner>
        <InstallBanner v-if="canInstall" ></InstallBanner>
        
        <OnBoarding :dialog="isOnBoarded"></OnBoarding>
        <v-navigation-drawer v-model="menuOpen" fixed right temporary v-if="isLoggedIn" width="310" >
          <template v-slot:prepend >
            <div class="float-right">
            <v-icon class="pr-2 pt-2" @click="menuOpen = !menuOpen">mdi-close</v-icon>
            </div>
          </template>
          <div class="pt-4 pl-3 d-flex">
            <v-btn fab elevation="0" disabled outlined color="white" class="logoside-btn">
              <v-icon class="pt-1 headerside-logo"></v-icon>
            </v-btn>
            <div class="pl-2 pt-2" >
              <div class="h1 text-h1">{{firstName}}</div>
               <div class="pt-1 fs-13 color-sidesub">{{userName}}</div>
            </div>
           
          </div>
          <v-list nav class="sidenav pl-0 pr-0" >
            <v-list-item-group class="pt-4">
             
              <v-list-item class="mb-1 mt-1 sub-list-item " to="/profile">
                <v-list-item-icon class="mt-3 mb-3 mr-4" >
                  <v-icon class="icon-color">mdi-account-outline</v-icon>
                </v-list-item-icon>
                <v-list-item-title class="list-item-text">{{$t('nav.myAccount')}}</v-list-item-title>
              </v-list-item>

              <v-list-item to="/managecv" class="mb-1 mt-1 sub-list-item">
                <v-list-item-icon class="mt-3 mb-3 mr-4">
                  <v-icon class="icon-color">mdi-text-box-outline</v-icon>
                </v-list-item-icon >
                <v-list-item-title class="list-item-text">{{$t('nav.manageCv')}}</v-list-item-title>
              </v-list-item>
              
              <v-list-group v-show="isPayslipEnabled || isEarlyPayEnabled"
                prepend-icon="mdi-apps" class="more-group ">
                <template v-slot:activator>
                  <v-list-item-title class="list-item-text">{{$t('nav.moreFromAccess')}}</v-list-item-title>
                </template>
                  <v-list-item v-show="isPayslipEnabled" class="mb-1 mt-1 sub-list-item" to="/payslip" >
                    <v-list-item-icon class="mt-3 mb-3 mr-4">
                      <v-icon class="icon-color">mdi-currency-gbp</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title class="list-item-text">{{$t('nav.paySummary')}}</v-list-item-title>
                  </v-list-item>
                  <v-list-item v-show="isEarlyPayEnabled" class="mb-1 mt-1  sub-list-item" @click="earlyPayLinks" >
                    <v-list-item-icon class="mt-3 mb-3 mr-4">
                     <v-icon class="img-earlypay" ></v-icon>
                    </v-list-item-icon>
                    <v-list-item-title class="list-item-text pt-2">{{$t('nav.earlyPay')}}</v-list-item-title>
                  </v-list-item>
                </v-list-group>

              <!-- <v-list-item v-show="isInDev" to="/debug" class="mb-1 mt-1 pl-7 sub-list-item" >
                <v-list-item-icon class="mt-3 mb-3 mr-4">
                  <v-icon class="icon-color">mdi-bug</v-icon>
                </v-list-item-icon>
                <v-list-item-title class="list-item-text">Debug</v-list-item-title>
              </v-list-item> -->
              
              <v-list-item @click="resetInstall" class="mb-1 mt-1 sub-list-item" >
                <v-list-item-icon class="mt-3 mb-3 mr-4">
                  <v-icon class="icon-color">mdi-cloud-download-outline</v-icon>
                </v-list-item-icon>
                <v-list-item-title class="list-item-text">{{$t('nav.installApp')}}</v-list-item-title>
              </v-list-item>
              <v-list-item @click="logOut" class="mb-1 mt-1  sub-list-item" >
                <v-list-item-icon class="mt-3 mb-3 mr-4">
                  <v-icon class="icon-color">mdi-power</v-icon>
                </v-list-item-icon>
    
                <v-list-item-title class="list-item-text">{{$t('nav.logout')}}</v-list-item-title>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-navigation-drawer> 
        <Confirm ref="confirm" ></Confirm>
        <Messagebox ref="messageBox"></Messagebox>
        <Toast  ref="toasts"></Toast>
    <router-view >

    </router-view>    

       </v-container>
     </v-main> 
   
      <OfflineNotification></OfflineNotification>
     
     <v-bottom-navigation class="bottom-nav"  app fixed v-model="selectedTab" v-if="isLoggedIn">
     
      <v-btn v-for="item in getNavItems" :key="item.name" :value="item.name" :to="item.url">
           <v-badge :content="jobOffers" v-if="item.name === 'jobs' & jobOffers > 0"
           :value="jobOffers"
                  color="red"
                  overlap>
              <div class="bottom-nav-button">
              <div class="mb-1"><img :alt="item.name" :class="'nav-' + item.name" class="navbar-tab-image"></div>
              <div>{{ $t(item.text) }}</div>
            </div>
          </v-badge>

          <div class="bottom-nav-button" v-if="item.name === 'jobs' & !jobOffers">
            <div v-if="item.name === 'jobs' & !jobOffers">
            <img :alt="item.name" :class="'nav-' + item.name" class="navbar-tab-image"></div>
              <div>{{ $t(item.text) }}</div>
          </div>
        <div class="bottom-nav-button" v-if="item.name !== 'jobs'">
          <div><img :alt="item.name" :class="'nav-' + item.name" class="navbar-tab-image"></div>
          <div>{{ $t(item.text) }}</div>
        </div>
      </v-btn>

      <v-btn value="more" @click.stop="menuOpen = !menuOpen">
        <div class="bottom-nav-button">
          <div><img alt="more" class="nav-more navbar-tab-image"></div>
          <div>{{ $t("nav.more") }}</div>
        </div>
      </v-btn>
    </v-bottom-navigation>
 
   </v-app>

 </div>
</template>


<script lang="ts">
  import ViewModel from './App';
  export default ViewModel;
</script>


<style lang="scss" scoped>

.noconnection{
  position: fixed;
  background-color: gray;
  width: 100%;
  color: white;
  text-align: center;
}

.navbar-tab-image {
  margin-top: 0;
  max-height: 20px;
  max-width: 20px;
}

.nav-more-label {
  transform: translatey(15px);
}

.v-btn--active .nav-search{
  content:url("./assets/img/icons/Search filled.png");
}

.nav-search {
  content:url("./assets/img/icons/Search 3.png");
}

.v-btn--active .nav-availability{
  content:url("./assets/img/icons/calendar filled.png");
}

.nav-availability {
  content:url("./assets/img/icons/calendar.png");
}

.v-btn--active .nav-timesheets{
  content:url("./assets/img/icons/Timesheet filled.png");
}

.nav-timesheets {
  content:url("./assets/img/icons/Timesheet.png");
}
 
.v-btn--active .nav-jobs{
  content:url("./assets/img/icons/jobs filled.png");
}

.nav-jobs {
  content:url("./assets/img/icons/jobs.png");
}

.v-btn--active .nav-more{
  content:url("./assets/img/icons/more 2.png");
  
}

.nav-more {
  content:url("./assets/img/icons/more 2.png");
  /* padding-top: 16px; */
}

.v-btn--active .nav-myjobs{
  content:url("./assets/img/icons/jobs filled.png");
}

.nav-myjobs {
  content: url("./assets/img/icons/jobs.png")
}

.img-earlypay {
      content: url("./assets/img/icons/Group 9799.png");
      width: 32px!important;
      height: auto !important;
    }

.v-item-group.v-bottom-navigation .v-btn {
  min-width:10px;
  font-size: 10px!important;
}

.bottom-nav-button {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  padding: 11px 0 8px;
}

.bottom-nav-button > div {
  font-weight: 400;
}

.v-btn--active{
  font-weight: bold;
  
}

.v-btn--active::before{
  font-weight: bold;
  transition: none!important;
  opacity:0!important;
  
}

.v-btn--active {
  transition: none!important;
}

.logoside-btn {
  height: 60px !important;
  width: 60px !important;
  border-color: #f1f2f5 !important;
  border-style: solid !important
}

.headerside-logo {
  content: url("./assets/logo.png");
  width: 45px  !important;
  height: 45px !important;
}

.color-sidesub
{
  color: #4E5054;
  font-weight: 600;
}

.icon-color
{
  color: #AEAEB3 !important;
}

.list-item-text
{
  color: #070707 !important;
  font-size: 14px !important;
  font-weight: 600 !important;
}



</style>

<style scoped>

.sub-list-item
{
  height:46px !important;
  border-radius: unset !important;
  padding-left: 28px!important
}

.sub-list-item.v-list-item--active 
{
 
  border-left: #555B70 5px solid !important;
  padding-left: 23px !important;
 
}
.more-group >>> .v-list-group__header.v-list-item
{
  margin-bottom: unset !important;
  height:46px !important;
  padding-left: 28px !important;
}

.more-group >>> .sub-list-item
{
   padding-left: 44px!important
}


.more-group >>> .sub-list-item.v-list-item--active
{
  padding-left: 39px!important
}

.more-group >>> .v-list-item__icon.v-list-group__header__prepend-icon
{
  margin-right: 16px !important;
  margin-top: 12px !important;
  margin-bottom: 12px !important;
}

.sidenav >>> .sub-list-item.v-item--active
{
  color: #AEAEB3 !important;
}
.sidenav >>> .v-list-item--active
{
  color: #AEAEB3 !important;
}



</style>