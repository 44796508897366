import { Vue, Component, PropSync, Watch, Prop } from 'vue-property-decorator';

 @Component({
 })
 export default class LocationAutoCompleteGoogle extends Vue {

    id="locationInput";
    autoComplete: google.maps.places.Autocomplete;
    @PropSync("textvalue", { type: String }) textvaluelocal!: string;
    @PropSync("label", { type: String }) labellocal!: string;
    @Watch('textvalue')
    textvalueChanged(val: string, oldVal: string){
      this.autocompleteText = val;
    }
    autocompleteText = "";
    placeholder ="";
    enterPressed = false;
    @Watch("autocompleteText")
    onAutocompleteTextChanged(newVal, oldVal){
        this.$emit('inputChange', newVal, this.id);
    }

    beforeMount(){
      this.autocompleteText = this.textvaluelocal;
    }

    mounted(){
       this.autoComplete = new google.maps.places.Autocomplete(document.getElementById(this.id)  as HTMLInputElement);
       this.autoComplete.addListener('place_changed', this.onPlaceChanged);
    }

    onPlaceChanged() {
        const place = this.autoComplete.getPlace();
        if (!place.geometry) {
          // User entered the name of a Place that was not suggested and
          // pressed the Enter key, or the Place Details request failed.

          if(!this.autocompleteText){
            this.$emit('placechanged',null);
            this.$emit('search');
          }else{

            const service = new google.maps.places.PlacesService(document.createElement('div'));

              const request = {
                  query: this.autocompleteText
              };

              service.textSearch(request, res => {
                  if(res.length > 0){
                    this.autocompleteText = res[0].formatted_address;
                    this.$emit('placechanged', {lat: res[0].geometry.location.lat(),lng: res[0].geometry.location.lng()});
                    this.$emit('search');
                  }else{
                    this.$emit('no-results-found', place, this.id);
                  }
              });
          }

          //this.$emit('no-results-found', place, this.id);
          return;
        }
        if (place.address_components !== undefined) {
            // return returnData object and PlaceResult object
            this.$emit('placechanged', {lat: place.geometry.location.lat(),lng: place.geometry.location.lng()});

            // update autocompleteText then emit change event
            this.autocompleteText = (document.getElementById(this.id) as HTMLInputElement).value
            this.onChange();

            if(this.enterPressed){
              this.enterPressed = false;
              this.$emit('search');
            }
          
        }
    }

    onChange() {
        this.$emit('change', this.autocompleteText);
    }

     onFocus() {
        this.$emit('focus');
     }


      onBlur() {
        this.$emit('blur');
      }

      onEnter() {
        if(!this.autocompleteText){
          this.$emit('search');
        }

        this.enterPressed = true;
      }
 }