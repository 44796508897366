
<div>
  <v-dialog v-model="dialogLocal" persistent fullscreen max-width="500">
    <v-app-bar app dense fixed color="white" class="top-toolbar pt-0 ">
      <div class="d-flex justify-center">
        <b>{{ $t("termsAndConditions.termsHeader") }} </b>
      </div>
    </v-app-bar>

    <ConnectionLostCardView
      class="mt-12"
      :isVisible="showNetworkError"
      @close="retryClicked"
    ></ConnectionLostCardView>
    <LoadingIndicator class="mt-12" :isVisible="isLoading"></LoadingIndicator>
    <iframe
      ref="iframeRef" 
      v-show="!showNetworkError && !isLoading"
      class="terms-iframe "
      :srcdoc="terms"
    >
    </iframe>

    <v-divider v-show="!showNetworkError && !isLoading"></v-divider>
    <v-footer fixed v-show="!showNetworkError && !isLoading">
      <v-row class="d-flex justify-space-around">
        <v-col cols="6">
          <v-btn class="action-toc-cancel" block depressed @click="cancel">
            {{ $t("decline") }}
          </v-btn>
        </v-col>
        <v-col cols="6">
          <v-btn
            class="primary action-toc-accept"
            block
            depressed
            @click="submit"
            :disabled="!readTerms"
          >
            {{ $t("accept") }}
          </v-btn>
        </v-col>
      </v-row>
    </v-footer>
  </v-dialog>
</div>
