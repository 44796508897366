<template>
<div>
  <JobResultsSummary :keywords="jobSearchRequest.Keywords" :location="jobSearchRequest.LocationText" :totalRecords="totalResults"></JobResultsSummary>
  <JobNoResults  v-if="totalResults ==0 && !isLoadingLocal" @retrySearch="retrySearch"></JobNoResults>

  <div class="map-loading">
    <LoadingIndicator :isVisible="isLoadingLocal" messageText=" "></LoadingIndicator>
  </div>

  <div class="map-area" v-show="showMap">
    <Map :recycle="true" :zoom="8" recyclePrefix="gm2" :lattitude="lattitude" :longitude="longitude" :markers="markers" @markerclicked="onMarkerClicked">
      <div slot="cardview" class="card" @click="showJob(selectedJob)" v-if="selectedJob && showCardView" >
        <div>

          <JobSummaryCard :job="selectedJob" 
              class="pr-4 mb-0" 
              @setShortlisted="setShortlisted"
              @removeShortlisted="removeShortlisted"
            >
          </JobSummaryCard>
      </div>
      </div>
    </Map>
  </div>
</div>
</template>

<script lang="ts">
  import ViewModel from './MapView'
  export default ViewModel;
</script>

<style scoped>

.map-loading {
  z-index: 1000;
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  top:45%;
  width: 100px;
  text-align: center;
}


  .map-area{
    height: calc(100vh - 150px);
    width: 100%;
    margin-right:0px;
    margin-left:0px;
  }
  
  .card {
    padding: 16px 2px 16px 16px;
    position: absolute;
    bottom: 40px;
    width: 96%;
    background: #fff;
    left: 2%;
    border-radius: 8px;
    box-shadow: 0 1px 4px 0 rgb(0 0 0 / 17%);
}

.jobresult-summary{
  height: 50x;
}
  

</style>