import JobService, { JobSearchResult, JobSearchRequest, Job } from "../../../../rdb/components/jobs/JobService";
import store from "../../../../../store/index";
import { Vue, Component, PropSync, Mixins } from 'vue-property-decorator'
import moment from "moment";
import LoadingIndicator from "../../../../LoadingIndicator.vue";
import JobSummaryCard from "./JobSummaryCard.vue";
import { NetworkMixin } from '@/components/mixins/networkMixin';
import { JobMixin } from '@/components/mixins/jobMixin';
import JobResultsSummary from "./JobResultSummary.vue";
import JobNoResults from "./JobNoResults.vue";
import { EventBus } from '@/utils/events';

@Component({
  components:{LoadingIndicator,JobSummaryCard,JobResultsSummary,JobNoResults}
})
export default class JobListView extends Mixins(NetworkMixin,JobMixin) {

  constructor() {
       super();
  }

 timesince(arg) {
    return moment(arg).fromNow();
  }

  beforeDestroy(){
    EventBus.$off("LoadSearchResults",this.loadSearchResult);
  }
  
  mounted(){
      EventBus.$on("LoadSearchResults",this.loadSearchResult);
      this.loadSearchResult();
  }

  loadSearchResult(pageNo = 1) {
    if(pageNo == 1){
      if(this.jobSearchResult){
        this.jobSearchResult.Jobs.splice(0);
        this.totalResults = 0;
      }
    }
    this.jobSearchRequest.Page = pageNo;
    this.jobSearchRequest.PageSize = 20;
    this.jobSearchRequest.PageOffset = 0;
    this.jobSearchRequest.IncludeExcludedJobs = true;
    this.jobSearchRequest.IncludeShortListedJobs = true;

    this.doWork<JobSearchResult>(JobService.searchJobs(this.jobSearchRequest),
    this.loadSearchResult)
    .then((res) =>{
      if(pageNo !== 1){
        this.jobSearchResult.PageInfo = res.PageInfo;
        res.Jobs.forEach(item => this.jobSearchResult.Jobs.push(item));
      }
      else{
        this.jobSearchResult = res;
        this.searchLoaded();
      }
      this.totalResults = this.jobSearchResult.PageInfo.TotalRecords;
    });
  }

  infiniteScrolling(entries, observer, isIntersecting) {
    if (this.jobSearchResult && this.jobSearchResult.PageInfo !== undefined) {
      if (this.jobSearchResult.PageInfo.HasNextPage) {
        if (isIntersecting && !this.isLoading) {
          this.loadSearchResult(this.jobSearchResult.PageInfo.Page + 1);
       }
      }
    }
  }
}