

  <div class="text-center">
     <div class="d-flex justify-space-between pb-3 range-selector pl-4 pr-4">
        <div>
          <v-btn  :disabled="disabled" text outlined fab x-small elevation="0"  @click="previous">
            <v-icon>mdi-chevron-left</v-icon>
          </v-btn></div>
          <div class="ma-auto">
            <div class="date-range">
              {{startDateText}} - {{endDateText}}, {{yearText}}
            </div>
          </div>

        <div>
          <v-btn :disabled="disabled" text outlined fab x-small elevation="0"   @click="next">
            <v-icon>mdi-chevron-right</v-icon>
        </v-btn>
      </div>
    </div> 

    
   
    <!-- <div class="tabs pt-4 tab-container">
     
      <div v-for="(item,index) in days" v-bind:key="index.text"   @click="changeDate(item)" :class="selectedDate == item.date? 'active':''">
        <div  class="tab"  >
          {{item.text.toUpperCase()}} 
        </div>
     </div>
    
  </div>
  
  <div class="d-flex justify-space-between tabs">
    <div v-for="(item,index) in days" v-bind:key="index.text"   @click="changeDate(item)" class="dayOfWeek-container" :class="selectedDate == item.date? 'active':''">
      <div class="dayOfWeek mt-3" :class="selectedDate == item.date? 'active':''">
        
          <span>
          {{item.day}} </span>
      </div>
   </div>
  
</div> -->


      

     <div class="d-flex justify-space-between tabs pt-4 pl-4 pr-4">
     
        <div v-for="(item,index) in days" v-bind:key="index.text"   @click="changeDate(item)" :class="selectedDate == item.date? 'active':''">
          <div  class="pa- tab"  >
            {{item.text.toUpperCase()}} 
          </div>
          <div class="dayOfWeek mt-3" :class="isToday(item.date) ? 'today':''">
            <span>
            {{item.day}} </span>
          </div>
       </div>
    </div> 

  </div> 
  
