import { Vue, Component, PropSync, Watch } from 'vue-property-decorator';
import moment from 'moment'
 @Component({
 })
 export default class TimesheetDateRangePicker extends Vue {
  startDate= new Date();
  endDate= new Date();
  selectedDate: Date = new Date();
  @PropSync("selectedView", { type: String,default:null }) selectedValueLocal: string;
  @PropSync("initialvalue", { type: Date,default:null }) initialvalueLocal: Date;
  
  @Watch("initialvalue")
  initialvalueChanged(val: Date, oldVal: Date) {
    this.selectedDate = val;
    this.setDates(val);
    this.$emit("datesChanged",{start:this.startDate,end:this.endDate,period:this.selectedTimesheetPeriod});
  }

  @Watch('selectedView')
  selectViewChanged(val: any, oldVal: any) {
    this.selectedTimesheetPeriod = "Weekly";
    this.setDates(this.initialvalueLocal?this.initialvalueLocal:new Date());
  }

  get dateTitle(){
    if(this.selectedTimesheetPeriod == "Weekly"){
        const today = new Date();
        if(today >= this.startDate && today <= this.endDate){
           return  this.$t("timesheets.currentWeek");
        }else if(today >= this.startDate){
          return this.$t("timesheets.previousWeek");
        }else{
          return this.$t("timesheets.futureWeek");
        }
    }

    return "";

  }

  dayInCurrentWeek(){
      return 0;
  }
  
  get startDateText(){
    if(this.selectedTimesheetPeriod == "Weekly"){
      return moment(this.startDate).format("Do MMM - ");
    }
    return moment(this.startDate).format("MMM, YYYY");
  } 

  get endDateText(){

    if(this.selectedTimesheetPeriod == "Weekly"){
      return moment(this.endDate).format("Do MMM, YYYY");
    }
      return "";

  }


  periodTypes = [
    "Weekly",
    "Monthly"
  ];

  selectedTimesheetPeriod = "Weekly";
  getStartOfWeek(date){
    let start = date;
    if(!start){
      start = new Date();
    }
    
    while(start.getDay() !== 1){
      start = moment(start).subtract(1,"d").toDate();
    }
    return start;
  }

  getEndOfWeek(date){
    return moment(this.getStartOfWeek(date)).add(6,'days').toDate();
  }

  getEndOfMonth(date){
    return moment(date).endOf('month').toDate();
  }
  
  getStartOfMonth(date){
    return moment(date).startOf('month').toDate();
  }
   

  setDates(date){
      switch(this.selectedTimesheetPeriod){
        case "Weekly":
          this.startDate = this.getStartOfWeek(date);
          this.endDate = this.getEndOfWeek(date);
          break;
        case "Monthly":
          this.startDate = this.getStartOfMonth(date);
          this.endDate = this.getEndOfMonth(date);
          break;
      }
  }

  next(){
      switch(this.selectedTimesheetPeriod){
        case "Weekly":
          this.startDate = this.getStartOfWeek(moment(this.startDate).add(7,'days').toDate());
          this.endDate = this.getEndOfWeek(this.startDate);
          break;
        case "Monthly":
          this.startDate =  this.getStartOfMonth(moment(this.startDate).add(1,'month').toDate());
          this.endDate = this.getEndOfMonth(this.startDate);
          break;
      }

      this.$emit("datesChanged",{start:this.startDate,end:this.endDate,period:this.selectedTimesheetPeriod});
  }

  previous(){
    switch(this.selectedTimesheetPeriod){
      case "Weekly":
        this.startDate = this.getStartOfWeek(moment(this.startDate).subtract(7,'days').toDate());
        this.endDate = this.getEndOfWeek(this.startDate);
        break;
      case "Monthly":
        this.startDate =  this.getStartOfMonth(moment(this.startDate).subtract(1,'month').toDate());
        this.endDate = this.getEndOfMonth(this.startDate);
        break;
    }

    this.$emit("datesChanged",{start:this.startDate,end:this.endDate,period:this.selectedTimesheetPeriod});

  }

  periodChanged(){
  
    if(this.selectedTimesheetPeriod == "Weekly"){
      this.setDates(new Date());
    }else{
      this.setDates(this.startDate);
    }
    this.$emit("datesChanged",{start:this.startDate,end:this.endDate,period:this.selectedTimesheetPeriod});
  }

  mounted(){
    this.setDates(this.initialvalueLocal?this.initialvalueLocal:new Date());
  }

 }