/* eslint-disable */
import Vue from 'vue'
import { UserManager, Log, WebStorageStateStore } from 'oidc-client'
import axios from 'axios'
import { EventBus } from '@/utils/events';
export const SignInType = Object.freeze({
  Window: 0,
  Popup: 1
  // Silent: 2
})

export const LogLevel = Object.freeze({
  None: 0,
  Error: 1,
  Warn: 2,
  Info: 3,
  Debug: 4
})

function getUrlPath(url) {
  const a = document.createElement('a')
  a.href = url
  let p = a.pathname
  if (p[0] !== '/') p = '/' + p
  return p
}

/**
 * Checks if current url's path matches given url's path.
 * @param {String} testUrl - url to test against.
 */
function matchesPath(testUrl) {
  return (
    window.location.pathname.toLocaleLowerCase() ===
    getUrlPath(testUrl).toLocaleLowerCase()
  )
}

export function createOidcAuth(
  authName,
  defaultSignInType,
  appUrl,
  config,
  mgr,
  logger = console,
  logLevel = LogLevel.Error
) {

  if (!authName) {
    throw new Error('Auth name is required.')
  }
  if (
    defaultSignInType !== SignInType.Window &&
    defaultSignInType !== SignInType.Popup
  ) {
    throw new Error('Only window or popup are valid default signin types.')
  }
  if (!appUrl) {
    throw new Error('App base url is required.')
  }
  if (!config) {
    throw new Error('No config provided to oidc auth.')
  }

  Log.level = logLevel
  Log.logger = logger

  // merge passed oidcConfig with defaults
 
  let isOnline  =  navigator.onLine;

    




  Log.debug(`Creating new oidc auth as ${authName}`)

  //const mgr = new UserManager(config)

  ///////////////////////////////
  // events
  ///////////////////////////////
  // mgr.events.addAccessTokenExpiring(() => {
  //   Log.debug(`${authName} auth token expiring`)
  // })

  // mgr.events.addAccessTokenExpired(() => {
  //   Log.debug(
  //     `${authName} auth token expired, user is authenticated=${
  //       auth.isAuthenticated
  //     }`
  //   )

  //   // auth.user = null
  //   // signInIfNecessary()
  //   // if (auth.isAuthenticated) {
  //   //   mgr
  //   //     .signinSilent()
  //   //     .then(() => {
  //   //       Log.debug(`${authName} auth silent signin after token expiration`)
  //   //     })
  //   //     .catch(() => {
  //   //       Log.debug(
  //   //         `${authName} auth silent signin error after token expiration`
  //   //       )
  //   //       signInIfNecessary()
  //   //     })
  //   // }
  // })

  

  // mgr.events.addSilentRenewError(e => {
  //   Log.debug(`${authName} auth silent renew error ${e}`)
  //   // TODO: need to restart renew manually?
  //   if (auth.isAuthenticated) {
  //     setTimeout(() => {
  //       Log.debug(`${authName} auth silent renew retry`)
  //       if(isOnline){
  //         mgr.signinSilent()
  //       }
  //     }, 5000)
  //   } else {
  //     signInIfNecessary()
  //   }
  // })

  mgr.events.addUserLoaded(user => {
    auth.user = user
  })

  mgr.events.addUserUnloaded(() => {
    auth.user = undefined

    // redirect if on protected route (best method here?)
    Log.debug(`${authName} auth user unloaded`)
    signInIfNecessary()
  })

  mgr.events.addUserSignedOut(() => {
    Log.debug(`${authName} auth user signed out`)
    auth.user = null
    signInIfNecessary()
  })

  // mgr.events.addUserSessionChanged(user => {
  //   Log.debug(`${authName} auth user session changed:`, user)
  // })

  function signInIfNecessary() {
    if (auth.myRouter && isOnline) {
      const current = auth.myRouter.currentRoute
      if (current && current.meta.authName === authName) {
        Log.debug(`${authName} auth page re-signin with ${defaultSignInType}`)

        signInReal(defaultSignInType, { state: { current } })
          .then(() => {
            // auth.myRouter()
          })
          .catch(() => {
            setTimeout(signInIfNecessary, 5000)
          })
        // window.location.reload();
        // auth.myRouter.go(); //replace('/');
      }
    }
  }

  function signInReal(type, args) {
    if(isOnline){
      switch (type) {
        case SignInType.Popup:
          return mgr.signinPopup(args)
        // case SignInType.Silent:
        //   return mgr.signinSilent(args)
      }
      return mgr.signinRedirect(args)
    }
  }

  function redirectAfterSignout(router) {
    if (router) {
      const current = router.currentRoute
      if (current && current.meta.authName === authName) {
        router.replace('/')
        return
      }
    }
    //   window.location.reload(true);
    if (appUrl) window.location = appUrl
  }

  let _inited = false
  const auth = new Vue({
    data() {
      return { user: null }
    },
    computed: {
      appUrl() {
        return appUrl
      },
      authName() {
        return authName
      },
      isAuthenticated() {
        //return !!this.user && !this.user.expired;
        return !!this.user && this.user.refresh_token;
      },
      accessToken() {
        return !!this.user && !this.user.expired ? this.user.access_token : ''
      },
      userProfile() {
        //return !!this.user && !this.user.expired ? this.user.profile : {}
        return !!this.user ? this.user.profile : {}
      }
    },
    methods: {
      startup() {
        let isCB = false // CB = callback
          if(isOnline){
            
          if (matchesPath(config.popup_redirect_uri)) {
            Log.debug(`${authName} Popup signin callback`)
            mgr.signinPopupCallback()
            isCB = true
          } else if (matchesPath(config.silent_redirect_uri)) {
            Log.debug(`${authName} Silent signin callback`)
            mgr.signinSilentCallback()
            isCB = true
          } else if (matchesPath(config.popup_post_logout_redirect_uri)) {
            Log.debug(`${authName} Popup logout callback`)
            mgr.signoutPopupCallback()
            isCB = true
          }

          if (isCB) return Promise.resolve(false)
        }

        if (_inited) {
          return Promise.resolve(true)
        } else {
          // load user from storage
          return mgr
            .getUser()
            .then(test => {
              _inited = true
              if (test) {
                 this.user = test
              }
             
              return true
            })
            .catch(err => {
              Log.warn(`Auth startup err = ${err}`)
              return false
            })
        }
      },
      refreshTokenIsValid(){
        let result = false;
        const expiryTime = new Date(this.user.expires_at * 1000);
        result = this.user != null && expiryTime >= new Date(this.user.expires_at * 1000);
        return result;
      },
      getAccessToken(force = false){

        const refreshTokenPromise =  new Promise((resolve,reject)=>{

          if(this.user) {
            const diff = -10;//Time in minutes before expiry to refresh token
            const expiryTime = new Date(this.user.expires_at * 1000);
            const cutOffTime = new Date(expiryTime.getTime() + diff*60000);
            const currentTime = new Date();
          
                if((this.user.expired || (cutOffTime <= currentTime)) || force){

                  const url = `${config.authority}connect/token`;
                  const data =  `client_id=${config.client_id}&grant_type=refresh_token&refresh_token=${encodeURIComponent(this.user.refresh_token)}`;
                  axios.post(url, data).then(refresh_result =>{
                  
                    this.user.refresh_token = refresh_result.data.refresh_token;
                    this.user.access_token = refresh_result.data.access_token;
                    let now = parseInt(Date.now() / 1000);
                    this.user.expires_at = now + refresh_result.data.expires_in;

                    mgr.storeUser(this.user);
                    

                    resolve(this.user.access_token);

                  }).catch((e)=>{
                    EventBus.$emit('Login');
                    //signInIfNecessary();
                      reject(e);
                  });
              }
              else{
                resolve(this.user.access_token);
              }
          }
          else {
            reject();
          }
          
        });
  
        return refreshTokenPromise;
      },
      useRouter(router) {
        this.myRouter = router
        const guard = (to, from, next) => {
          if (
            to.matched.some(record => record.meta.authName === this.authName)
          ) {
            if (this.isAuthenticated) {
              Log.debug(
                `${authName} auth authenticated user entering protected route ${
                  to.fullPath
                }`
              )
              next()
            } else {
              Log.debug(
                `${authName} auth anon user entering protected route ${
                  to.fullPath
                }`
              )
           
              signInReal(defaultSignInType, { state: { to } })
                .then(() => {
                  if (defaultSignInType === SignInType.Window) {
                    next(false)
                  } else {
                    next()
                  }
                })
                .catch(() => next(false))
            }
          } else {
            next()
          }
        }
        router.beforeEach(guard)
        
        if (config.redirect_uri) {
          const vroutePath =
            '/' +
            getUrlPath(config.redirect_uri).substring(
              (router.options.base || '/').length
            )

          router.addRoutes([
            {
              path: vroutePath,
              name: `signinwin-${authName}`,
              component: {
                render: h => h('div'),
                created() {
                  mgr
                    .signinRedirectCallback()
                    .then(data => {
                      Log.debug(
                        `${authName} Window signin callback success`,
                        data
                      )
                      // need to manually redirect for window type
                      // goto original secure route or root
                      const redirect = data.state ? data.state.to : null
                      if (router) router.replace(redirect || '/')
                      else window.location = appUrl
                    })
                    .catch(err => {
                      Log.error(`${authName} Window signin callback error`, err)
                      if (router) router.replace('/')
                      else window.location = appUrl
                    })
                }
              }
            }
          ])
        }
      },
      networkStatusChanged(isOnlineArg) {
        
        //debugger;
        isOnline = isOnlineArg;
        // if(!isOnline){
        //   mgr.stopSilentRenew();
          
          
        // }else{
        //   mgr.startSilentRenew();
          
        // }
      },
      signIn(args) {
        return signInReal(defaultSignInType, args)
      },
      signOut(args) {
        if (defaultSignInType === SignInType.Popup) {
          const router = this.myRouter
          return mgr
            .signoutPopup(args)
            .then(() => {
              redirectAfterSignout(router)
            })
            .catch(() => {
              // could be window closed
              redirectAfterSignout(router)
            })
        }
        return mgr.signoutRedirect(args)
      }
    }
  })

  return auth
}