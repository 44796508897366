import JobService,{  Job } from "../JobService";
import { Vue, Component, PropSync,Watch, Prop } from 'vue-property-decorator';


@Component({
  })
export default class JobResultSummary extends Vue {
  @Prop({default:""})  readonly keywords: string;
  @Prop({default:""})  readonly location: string;
  @Prop({default:0})  readonly totalRecords: number;
}