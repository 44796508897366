import { Vue, Component, PropSync, Watch, Mixins } from 'vue-property-decorator';
import CVCard from './CVCard.vue'
import moment from 'moment';
import Alert from "@/components/Alert";
import JobService, { Job, CVUploadResult, CVsResponse, CvAppliedJob, CvDeleteRequest } from "../JobService";
import AppliedJobSummaryCard from "../../myjobs/components/AppliedJobSummaryCard";
import { NetworkMixin } from '@/components/mixins/networkMixin';
import VueDropboxPicker from '@/components/DropboxPicker.vue';
import axios from "axios";
import store from '@/store';


@Component({
  name: "cvupload",
  components:{ CVCard, Alert, AppliedJobSummaryCard, VueDropboxPicker }
})
 export default class CVUpload extends Mixins(NetworkMixin) {
    uploadedFiles: CVUploadResult[] = [];
    CVs: CVUploadResult[] = [];
    appliedJobs: CvAppliedJob[] = [];
    uploadError: null;
    currentStatus = 0;
    uploadFieldName = "CV";
    fileCount = 0;
    isSaving = false;
    isSuccess = false;
    isFailed = false;
    isManageCv = false;
    recentlyUploadedCss = "list";
    cvCount = 0;
    showJobDetails = false;
    selectedJob: Job = null;
    closeTopDialog = false;
    message = false;
    messageText = "";
    alertType = "";
    isConfirmation = false;
    deleteCvConfirmation: boolean = null;
    deleteCvId = 0;

    @Watch('deleteCvConfirmation')
    deleteCvConfirmationChanged(val, oldVal) {
      if(val === true){
        this.deleteCv();
      }
      this.message = false;
      this.deleteCvConfirmation = null;
    }

    constructor() {
        super();
    }

    get dropBoxAPIKey(){
      return store.getters.AppState.discovery.dropBoxAPIKey;

    }

    mounted() {
      this.isManageCv = window.location.pathname === '/managecv';
      this.reset();
    }

    loadCvs(){

      this.doWork(JobService.getCVforApplications()).then(res =>{
        this.CVs = res.CVs;
        this.appliedJobs = res.AppliedJobs;
        this.cvCount = this.CVs.length;
      });
    }

    formatDate(arg, format) {
      return moment(arg).format(format);
    }

    filesChange(fieldName, fileList) {
      if (!fileList.length) return;
      
      if(!this.isValid(fileList[0].name)){
        this.showAlert(this.$t("alertMessages.fileTypeError").toString(), this.$t("alertMessages.warning").toString(), false);
      }
      else{
        // handle file changes
        const formData = new FormData();

        // append the files to FormData
        Array
          .from(Array(fileList.length).keys())
          .map(x => {
            formData.append(fieldName, fileList[x], fileList[x].name);
          });

        // save it
        this.save(formData);
      }
    }

    isValid(fileName: string){
      const allowedExtensions = /(\.doc|\.rtf|\.docx|\.pdf|\.txt|\.odt|\.wps)$/i;
      if(allowedExtensions.exec(fileName)){
        return true;
      } 
      return false;
    }

    reset() {
      // reset form to initial state
      this.isFailed = false;
      this.isSuccess = false;
      this.uploadedFiles = [];
      this.uploadError = null;
      this.isSaving = false;
      this.loadRecentCvs();
    }

    loadRecentCvs(){
      if(this.isManageCv){
        this.loadCvs();
      }
    }

    loadJobDetails(job: Job){
        this.selectedJob = null;
        this.showJobDetails = true;
        this.selectedJob = job;
    }

    showAlert(alertMessage: string, type: string, isConfirmation: boolean){
      this.messageText = alertMessage;
      this.alertType = type;
      this.isConfirmation = isConfirmation;
      this.message = true;
    }

    closeAlert(){
      this.message = false;
    }

    save(formData) {
      // upload data to the server
      this.isSaving = true;

      JobService.uploadCV(formData)
      .then(x => {
        this.uploadedFiles.push(x.data as CVUploadResult);
        this.isSuccess = true;
        this.isSaving = false;
        this.loadRecentCvs();
      })
      .catch(err => {
          this.uploadError = err.response;
          this.isFailed = true;
        });
    }

    removeDocument(CVId: number) {
      this.uploadedFiles.forEach( (item, index) => {
        if(item.CVId === CVId) {
          debugger;
          this.uploadedFiles.splice(index,1);
          this.isSuccess = false;
        }
      });
    }

    deleteCv(){
      if(this.deleteCvId !== 0){
        const request = new CvDeleteRequest();
        request.CvId = this.deleteCvId;
        JobService.deleteCv(request).then(result => {
            this.loadRecentCvs();
        });
      }
    }
 
    close() {
      this.$emit("close");
    }

    apply() {
      this.$emit("apply", this.uploadedFiles);
    }

    back() {
      this.close();
    }

    skip() {
      this.$emit("skip");
    }

    onDropBoxPicked(files) {
      const formData = new FormData();

        files.forEach((file) => {
            axios({
              url: file.link,
              method: 'GET',
              responseType: 'arraybuffer',
              }).then((response) => {
                const fileBlob = new File([response.data], file.name, {type: response.data.type, lastModified: Date.now()});

                formData.append("CV", fileBlob, file.name);

                //save it
                this.save(formData);
              }).catch(()=> {
                this.showAlert(this.$t("alertMessages.dropBoxDownloadError").toString(), this.$t("alertMessages.warning").toString(), false);
              });
      });
    }
  }