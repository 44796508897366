import Vue from "vue";
import Component from "vue-class-component";
import { Prop, Watch } from "vue-property-decorator";
import moment from "moment";

@Component({
  name: "availabilitycalendar",
})
export default class AvailabilityCalendar extends Vue {
  @Prop(Date) readonly minimumDate: Date;
  @Prop(Date) readonly maximumDate: Date;
  @Prop({default: false}) readonly pickerDisabled: boolean;
  @Prop(Array) readonly eventsLocal: Array<number>;
  @Prop(String) readonly pickerDateSync!: string;
  @Prop([String , Array] ) readonly modelDateLocal!: string | [];
  @Prop( {default: false} ) readonly multiple: boolean ;
  @Prop({ default: null}) readonly allowedDates;
  showMultiData = false;
  
  @Watch("modelDateLocal")
  modelDateChanged(val) {
    this.vmodelDate = val;
  }

  @Watch("pickerDateSync")
  pickDateSyncChanged(val, oldVal) {
    this.pickDate = val;
  }

  get pickDate() {
    return this.pickerDateSync;
  }

  set pickDate(val) {
    this.$emit("pickDate", val);
  }
  get allowDates()
  {
    return this.allowedDates;
  }
  get vmodelDate() {
    return this.modelDateLocal;
  }

  set vmodelDate(val) {
    this.$emit("vmodelDate", val);
  }

  @Watch("pickDate")
  pickerChanged(val: string, oldVal: string) {
    if (val != oldVal && val != undefined) {
      const [newYear, newMonth] = val.split("-");

      if (newMonth === undefined) {
        return;
      }

      if (oldVal == null) {
        this.$emit("pickerChanged", newMonth, newYear);
        return;
      }

      const [oldYear, oldMonth] = oldVal.split("-");

      if (oldMonth != newMonth || oldYear != newYear) {
        this.$emit("pickerChanged", newMonth, newYear);
      }
    }
  }

  mounted() {
    this.pickDate = this.pickerDateSync;
  }

  get minDate() {
    const newDate =
      this.minimumDate == undefined ? new Date(1900, 1, 1) : this.minimumDate;
    return newDate.toISOString().substr(0, 10);
  }

  get maxDate() {
    const newDate =
      this.maximumDate == undefined ? new Date(2099, 1, 1) : this.maximumDate;
    return newDate.toISOString().substr(0, 10);
  }

  dayFormat(d) {
    const dt = moment(d, "YYYY-MM-DD HH:mm:ss");
    return dt.format("ddd");
  }

  eventColors(date) {
    if (this.eventsLocal == undefined) {
      return false;
    }

    const [, , day] = date.split("-");
    const count = {};
    this.eventsLocal.forEach(
      (number) => (count[number] = (count[number] || 0) + 1)
    );
    const occurences = count[day];
    if (occurences === 0) return false;
    if (occurences === 1) return "red";
    if (occurences === 2) return ["red", "orange"];
    if (occurences >= 3) return ["red", "orange", "blue"];
  }

  dateChanged(date) {
    this.$emit("dateChanged", date);
  }

  saveMulti(date) {
    this.$emit("input", date);
  }

  get Count() {
    return this.vmodelDate.length == 0 ? "0" : this.vmodelDate.length;
  }

  showDates() {
    this.showMultiData = true;
    
  }

  
  formatDateMultiShow(date) {
    const weekday = new Array(7);
    weekday[1] = "Mon";
    weekday[2] = "Tue";
    weekday[3] = "Wed";
    weekday[4] = "Thu";
    weekday[5] = "Fri";
    weekday[6] = "Sat";
    weekday[0] = "Sun";

    const dt = new Date(date);
    const dayWeek = dt.getUTCDay();

    return `${weekday[dayWeek]}, ${dt.getUTCDate()}-${dt.getUTCMonth() +
      1}-${dt.getUTCFullYear()}`;
  }

  clearDates() {
    this.$emit("clearData");
  }


}
