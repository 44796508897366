import { Vue, Component, PropSync } from 'vue-property-decorator'

 @Component({
 })
 export default class LoadingIndicator extends Vue {
   
  @PropSync('isVisible', { type: Boolean }) visible!: boolean
  @PropSync('messageText', { type: String }) messageTextLocal!: string
  constructor(){
    super();
  }
 }