
  <div class="here">
   
<v-container class="summary">
  <JobResultsSummary :keywords="jobSearchRequest.Keywords" :location="jobSearchRequest.LocationText" :totalRecords="totalResults"></JobResultsSummary>
   <JobNoResults class="pa-0" v-if="totalResults == 0 && !isLoading" @retrySearch="retrySearch"></JobNoResults> 
</v-container>

    <v-container pa-0>
      <v-row justify="center"  >
        <v-col cols="12" md="6" align="center" >
          <JobCards
            :isLoading="isLoading"
            :startInteraction="startInteraction"
            :cards="cards" 
            @cardRight="handleCardRight($event)"
            @cardLeft="handleCardLeft"
            @cardDown="handleCardDown"
            @cardUp="handleCardUp"
            @hideCard="removeCardFromDeck"
            @cardMoved="cardMoved"
            @cardSelected="cardSelected"
            @cardChanged="cardChanged"
            :showNoResults="totalResults !== 0"
            />
        </v-col>
      </v-row>
    </v-container>

      <v-container pa-0>
      <v-row style="position: absolute; bottom: 3px; left: 10px; width: 100%" class="pa-0">
        <v-col cols="0" md="3" class="pa-0">
        </v-col>
        <v-col cols="12" md="6" align="center ma-0 pa-0">
          <div class="button-navigation d-flex justify-space-between">
            <div class="pa-2">
              <v-btn elevation="0"  :class="excludeButtonClass" fab @click="left">
                <v-icon class="excludeIcon">mdi-close</v-icon>
              </v-btn>
            </div>
            
            <div class="pa-2 pt-1">  
              <v-btn elevation="0" class="small-button restore-button restore-job" fab @click="restore">
                <v-icon>mdi-refresh</v-icon>
              </v-btn>
              <v-btn fab elevation="0" @click="up" class="small-button next-button skip-job">
                <v-icon>mdi-chevron-right</v-icon>
              </v-btn>
            </div>

            <div class="pa-2">
              <v-btn elevation="0" :class="shortListButtonClass" fab @click="right">
                <v-icon class="shortListIcon"></v-icon>
              </v-btn>
            </div>
          </div>
        </v-col>
           <v-col cols="0" md="3" class="pa-0">
        </v-col>
      </v-row>
    </v-container>
  </div>
