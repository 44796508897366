import { Vue, Component, Mixins } from 'vue-property-decorator';
import CheckInView from "./components/CheckInView.vue";
import CheckOutView from "./components/CheckOutView.vue"
import CheckOutConfirmation from "./components/CheckOutConfirmation.vue";
import TimesheetView from "./components/TimesheetView.vue";
import TimesheetOnboarding from "./components/TimesheetOnboarding.vue";
import store from "../../../../store/index";
import LogTimeDialogCopy from "./components/LogTimeDialogCopy.vue"
import TimesheetService, { ShiftRequest, JobShift, InsertTimesheetRequest, ShiftResult } from "./TimesheetService";
import moment from "moment";
import QRScanMessage from "./components/QRScanMessage.vue"
import {mobileBackButtonDialogComponentMixin} from '../../../mixins/mobileDialogBackButtonHandler';
import CheckInService  from "@/services/checkInService";
import {TimesheetEvent, TimesheetCheckInEventRequest, ClockStateResult, ClockStateEvent} from "@/services/checkInDomain";
import Alert from "@/components/Alert.vue";
import WeekDatePicker from "@/components/WeekDatePicker/WeekDatePicker.vue"
import TimesheetDateRangePicker from "./components/TimesheetDateRangePicker.vue";
import EarlyPay from "../../../EarlyPay.vue"
import { NetworkMixin } from '@/components/mixins/networkMixin';
import ExpenseView from "./components/ExpenseView.vue";
import  Locations  from "@/utils/locations"


Component.registerHooks([
    "beforeRouteEnter",
    "beforeRouteUpdate",
    "beforeRouteLeave"
  ]);
@Component({
    name:"timesheets",
    components: { CheckInView, TimesheetView, TimesheetOnboarding, QRScanMessage, Alert, ExpenseView,
        LogTimeDialogCopy, WeekDatePicker, TimesheetDateRangePicker, CheckOutView, CheckOutConfirmation, EarlyPay },
    mixins:[mobileBackButtonDialogComponentMixin],
    watch: {
        showQRMessage: 'notifyDialogStateViaEventBus'
    }
})
export default class TimesheetComponent extends Mixins(NetworkMixin) {
    el: "#app";
    timsheetDateRange: any = null;
    expenseDateRange: any = null;
    selectedDate = new Date();
    initialDate = new Date();
    initialDateTimesheet = new Date();
    initialDateExpenses = new Date();
    checkInService = new CheckInService();
    checkoutConfirmation = false;
    manualTimesheet = false;
    logTimeDialogVisible = false;
    showQRCode = true;
    showQRMessage = false;
    showQRScan = false;
    qrCodeResult = "";
    message = false;
    messageText = "";
    alertType = "";

    selectedView = "checkIn";
    lastSelectedView = "checkIn";
    onboarding = true;
    autosave = false;
    breakDuration = 0;
    checkInTime = 0;
    checkOutTime = 0;
    jobShift = new JobShift();
    closeTopDialog = false;
    forceRouterBack = false;
    jobShiftStart: Date = null;
    jobShiftEnd: Date = null;
    shifts = [];
    staticExtensionHeight = "170px";
    extensionHeightEarlyPay = "296px";
    earlyPay = false;
    selectedTimesheet = 0;
    toRoute = "";
    get selectedJobShift(){
        return this.jobShift;
    }

    set selectedJobShift(value){
        this.jobShift = value;
    }
    
    constructor() {
        super();
    }

    getDateUTC(date){
       return new Date(moment.utc(moment(date).format("YYYYMMDD")).toDate());
    }

    beforeMount()
    {
      if(store.getters.AppState.featureEnabled("RCRMEARLYPAY"))
      {
        this.earlyPay = true;
      }
     
    }

    mounted() {
        const urlParams = new URLSearchParams(window.location.search);
        this.jobShiftStart =  moment().clone().weekday(1).toDate();
        this.jobShiftEnd = moment().clone().weekday(7).toDate();
        this.selectedDate = new Date();

        if(window.location.pathname.toLowerCase() === '/viewshift' && urlParams.has('Id')){
            let startDate = new Date();
            let endDate = new Date();
            if(urlParams.has('StartDate')){
                startDate = new Date(Date.parse(urlParams.get('StartDate')));
            }
            if(urlParams.has('EndDate')){
                endDate = new Date(Date.parse(urlParams.get('EndDate')));
            }

            this.getJobShiftData(urlParams.get('Id'), false, false, startDate, endDate);
        }
        else if(window.location.pathname.toLowerCase() === '/clock' && urlParams.has('jobId')){
            this.getJobShiftData(urlParams.get('jobId'), true);
        }
        else if(window.location.pathname.toLowerCase() === '/viewtimesheet' && urlParams.has('timesheetId')){
            this.selectedView = "timesheet";
            this.selectedTimesheet = parseInt(urlParams.get('timesheetId'));
            this.setTab();
        }
        else{
            this.setTab();
            this.refreshData();
        }

        if (store.getters.AppState.timesheetOnboardingStatus) {
            this.completeOnboarding();
        }
    }

    timesheetloaded(){
        this.selectedTimesheet = 0;
    }

    getJobShiftData(id: string, isJob: boolean, fromQrCode = false, startDate: Date = null, endDate: Date = null, showExistingShift = false): Promise<boolean>{
        let result = false;
        const timesheetEvents = new TimesheetCheckInEventRequest;
        timesheetEvents.StartDate = this.getDateUTC(moment().add(-2, 'day'));
        timesheetEvents.EndDate = this.getDateUTC(moment().add(2, 'day'));

        if(!isJob){
            let objectDiaryId = 0;
            if(/^[-]?\d+$/.test(id)){
                objectDiaryId = parseInt(id);
            }
            else{
                timesheetEvents.Id = id;
            }

            this.isLoading = true;
            this.checkInService.getTimesheetEvents(timesheetEvents).then(tr =>{
                
                if(tr.length > 0){
                    const associatedData = JSON.parse(tr[0].associatedData);                    
                    if(!objectDiaryId && objectDiaryId === 0){
                        objectDiaryId = associatedData.ObjectDiaryId;
                        startDate = associatedData.ShiftStartTime;
                        endDate = associatedData.ShiftEndTime;
                    }                    
                }
                else{
                    if(fromQrCode){
                        this.showAlert(this.$t("alertMessages.invalidQrCodeError").toString(), this.$t("alertMessages.warning").toString());
                    }
                    this.refreshData();
                }

                TimesheetService.getJobShift(objectDiaryId, isJob, startDate, endDate).then(r => {
                    this.isLoading = false;
                    if(r !== null){
                        this.updateJobShiftDataNew([r],tr);
                        this.selectedJobShift = r;
                        this.logTimeDialogVisible = true;
                        result = true;
                        if (showExistingShift) {
                            this.showAlert(this.$t("alertMessages.existingCheckin").toString(), this.$t("alertMessages.warning").toString());
                        }
                    }
                    else{
                        if(fromQrCode){
                            this.showAlert(this.$t("alertMessages.invalidQrCodeError").toString(), this.$t("alertMessages.warning").toString());
                        }
                        else{
                            if (!showExistingShift) {
                               this.showAlert(this.$t("alertMessages.invalidJobShift").toString(), this.$t("alertMessages.warning").toString());
                            } else {
                                this.logEvent("CHECKIN", this.selectedJobShift, new Date(), true);
                            }
                        }
                        this.refreshData();
                    }
                }).catch(()=>{
                    this.isLoading = false;
                });
            }).catch(()=>{
                this.isLoading = false;
            });
        }
        else{
            TimesheetService.getJobShift(parseInt(id), isJob).then(r => {
                if(r !== null){
                    timesheetEvents.StartDate = moment(r.ShiftStartTime).toDate();
                    timesheetEvents.EndDate = moment(r.ShiftEndTime).toDate();
                    
                    this.checkInService.getTimesheetEvents(timesheetEvents).then(tr =>{
                        this.updateJobShiftDataNew([r],tr);
                        this.selectedJobShift = r;
                        this.logTimeDialogVisible = true;
                        result = true;
                    });
                }
                else{
                    if(fromQrCode){
                        this.showAlert(this.$t("alertMessages.invalidQrCodeError").toString(), this.$t("alertMessages.warning").toString());
                    }
                    else{
                        this.showAlert(this.$t("alertMessages.invalidJobShift").toString(), this.$t("alertMessages.warning").toString());
                    }
                    this.refreshData();
                }
            });
        }

        return Promise.resolve(result);
    }

    get extensionHeight()
    {
        if(this.earlyPay)
        {
            return this.extensionHeightEarlyPay
        }
        
        return this.staticExtensionHeight;

    }

    closeEarlyPay()
    {
      this.earlyPay = false;
    }

    loadData(): Promise<boolean>{
        const shiftRequest = new ShiftRequest();
        shiftRequest.StartDate = this.getDateUTC(this.jobShiftStart);
        shiftRequest.EndDate = this.getDateUTC(this.jobShiftEnd);

        return TimesheetService.getJobShifts(shiftRequest).then(r =>{
            const timesheetEvents = new TimesheetCheckInEventRequest;
            timesheetEvents.StartDate = this.getDateUTC(this.jobShiftStart);
            timesheetEvents.EndDate = this.getDateUTC(this.jobShiftEnd);

            return this.checkInService.getTimesheetEvents(timesheetEvents).then(tr =>{
                this.updateJobShiftDataNew(r.JobShifts,tr);
                this.shifts  = r.JobShifts;
                return true;
            }).catch(e =>{
                this.showNetworkError = true;
                this.shifts = null;
                console.log(e);
                return false;
            });
        }).catch(e =>{
            console.log(e);
            return false;
        });
    }

    retryClicked() {
        this.showNetworkError = false;
        this.refreshData();
    }

    async refreshData(){
        this.doWork<any>(this.loadData(),this.refreshData);
    }

    updateJobShiftDataNew(shifts: JobShift[], timesheetEvents: ClockStateResult[]){
        for (let x = 0; x < shifts.length; x++) {
            const shift: JobShift = shifts[x];
            
            for (let y = 0; y < timesheetEvents.length; y++) {
                const clockState: ClockStateResult = timesheetEvents[y];
                
                const associatedData = JSON.parse(clockState.associatedData);//JSON.parse(JSON.stringify(clockState.associatedData));

                if(shift.ObjectDiaryId == associatedData.ObjectDiaryId && shift.PlacementId == associatedData.PlacementId && shift.ShiftEndTime == associatedData.ShiftEndTime ){
                    shift.ClockState = clockState;
                }
            }

            // if(shifts[x].ObjectDiaryId === this.selectedJobShift.ObjectDiaryId){
            //     this.selectedJobShift.ClockState = clockState; 
            // }
            shifts[x].ClockState =  shifts[x].ClockState? shifts[x].ClockState: new ClockStateResult();
            // shifts[x].ClockState.events = events;
            // shifts[x].ClockStateId = events.length > 0 ? events[0].clockStateId:null;
        }
    }

    selecteDateChanged(date){
        this.selectedDate = date.date;
    }

    selecteDateRangeChanged(dateRange){
        this.jobShiftStart = dateRange.start;
        this.jobShiftEnd = dateRange.end;
        this.initialDate = dateRange.start;
        this.refreshData();
    }

    refreshJobShiftData(dateRange){
        this.jobShiftStart = dateRange.start;
        this.jobShiftEnd = dateRange.end;
        this.refreshData();
    }

    setTab(){
        let el;
        switch (this.selectedView){
            case "checkIn":
                el = ((this.$refs.btnCheckIn as Vue).$el as HTMLElement);
                break;
            case "timesheet":
                el = ((this.$refs.btnTimesheet as Vue).$el as HTMLElement);
                break;
            case "expense":
                el = ((this.$refs.btnExpense as Vue).$el as HTMLElement);
                break;
        }
        if(el){
            el.focus();
        }
    }

    hideOnboarding() {
        this.onboarding = false;
    }

    checkIntoShift(jobShift: JobShift){
        this.selectedJobShift = jobShift; 

        if(jobShift.ClockState && jobShift.ClockState.hasEvents()){
            this.logTimeDialogVisible = true;
        }
        else if(moment(jobShift.ShiftDate).format("YYYY-MM-DD") > moment().format("YYYY-MM-DD")){
            if(!this.jobShift.ClockInOut && this.jobShift.ManualEntry)
            {
                this.showAlert(this.$t("alertMessages.futureShiftManual").toString(), this.$t("alertMessages.warning").toString());
            }
            else{
            this.showAlert(this.$t("alertMessages.futureShift").toString(), this.$t("alertMessages.warning").toString());
            }
        }
        else if(!this.jobShift.ClockInOut && this.jobShift.ManualEntry)
        {
            this.manualTimesheet = true;
        }
        else if(moment(jobShift.ShiftDate).format("YYYY-MM-DD") < moment().format("YYYY-MM-DD")){
            this.logTimeDialogVisible = true;
        }
        else if(moment(jobShift.ShiftDate).format("YYYY-MM-DD") == moment().format("YYYY-MM-DD")){
            if(this.showQRCode){
                this.showQRMessage = true; 
            }
            else{
                this.logTimeDialogVisible = true;
            }
        }
        else{
            if(this.showQRCode){
                this.showQRMessage = true;    
            }
            else{
                this.logTimeDialogVisible = true;
            }
        }        
    }

    completeOnboarding() {
        store.commit.AppState.UpdateTimesheetOnboardingStatus(true);
        this.onboarding = false;
    }

    checkOutOfShift(jobShift: JobShift){
        this.checkoutConfirmation = true;
    }

    checkOutConfirmation(visible: boolean){
        this.checkoutConfirmation = visible;
    }

    cancelCheckOut(){
        this.manualTimesheet = false;
    }

    closeLogTimeDialog(){
        this.logTimeDialogVisible = false;
        this.refreshData();
    }

    timesheetSubmitted(timesheetSubmitted: boolean){
        this.closeLogTimeDialog();
        this.manualTimesheet = false;

        if(timesheetSubmitted){
            this.showAlert(this.$t("alertMessages.timesheetSuccessfullyInserted").toString(), this.$t("alertMessages.success").toString());
        } else {
            this.showAlert(this.$t("alertMessages.timesheetHoursSuccessfullyInserted").toString(),
                this.$t("alertMessages.success").toString());
        }
        this.refreshData();
    }

    //QRCode Stuff
    close(){
        this.qrCodeResult = "no result - closed";
        this.showQRMessage = false;
    }

    result(value){
        this.qrCodeResult = value;

        if(this.qrCodeResult.toLowerCase().includes("clock") && this.qrCodeResult.toLowerCase().includes("jobid")){
            const split = this.qrCodeResult.toLowerCase().split('jobid=');
            if(split.length == 2){
                this.getJobShiftData(split[1], true, true);
            }            
        }
        else{
            this.showAlert(this.$t("alertMessages.unrecognisedQrCodeError").toString(),
                this.$t("alertMessages.warning").toString());
        }

        this.showQRMessage = false;
        //this.logTimeDialogVisible = true;
    }

    skip(){
        this.qrCodeResult = "Skipped";
        this.showQRCode = false;
        this.showQRMessage = false;
        this.logTimeDialogVisible = true;
    }

    back(args) {
        if(this.toRoute){
            this.forceRouterBack = true;
            this.$router.push(this.toRoute);
            this.toRoute = null;
        }
    }

    beforeRouteUpdate(to, from, next) {
         if (this.forceRouterBack) {
            this.forceRouterBack = false;
            next();
        } else if (this.showQRMessage) {
            this.$emit('closeAllDialogs');
            next(false);
        } else if (this.selectedView === "timesheet" || this.selectedView === "expense") {
            this.closeTopDialog = !this.closeTopDialog;
            next(false);
            this.toRoute = to.fullPath;
        } else if (this.logTimeDialogVisible) {
            if (this.manualTimesheet) {
                this.manualTimesheet = false;
            } else if (this.checkoutConfirmation) {
                this.checkOutConfirmation(false);
            } else {
                this.closeLogTimeDialog();
            }
            next(false);
        } else {
            next();
        }
    }

    beforeRouteLeave(to, from, next) {
        this.toRoute = null;
       if (this.forceRouterBack) {
            this.forceRouterBack = false;
            next();
        } else if (this.showQRMessage) {
            this.$emit('closeAllDialogs');
            next(false);
        } else if (this.selectedView === "timesheet" || this.selectedView === "expense") {
            this.closeTopDialog = !this.closeTopDialog;
            next(false);
            this.toRoute = to.fullPath;
        } else if (this.logTimeDialogVisible) {
            if (this.manualTimesheet) {
                this.manualTimesheet = false;
            } else if (this.checkoutConfirmation) {
                this.checkOutConfirmation(false);
            } else {
                this.closeLogTimeDialog();
            }
            next(false);
        } else {
            next();
        }
    }

    closeAllDialogs(){
        this.qrCodeResult = "no result closed";
        this.showQRMessage = false;
    }

    checkedIntoShift(jobShift: JobShift, eventTime: Date) {
        this.logEvent("CHECKIN", jobShift, eventTime);
    }

    pauseShift(jobShift: JobShift, eventTime: Date){
        this.logEvent("PAUSE", jobShift, eventTime);
    }

    unpauseShift(jobShift: JobShift, eventTime: Date){
        this.logEvent("UNPAUSE", jobShift, eventTime);
    }

    checkOutConfirmed(jobShift: JobShift, eventTime: Date){
        this.manualTimesheet = true;
        this.checkoutConfirmation = false;
    }

    async logEvent(eventType: string, jobShift: JobShift, eventTime: Date, ignoreActiveClocks = false) {
        const event = new TimesheetEvent;
        event.Time = eventTime;
        event.UniqueId = eventType;

        let checkInLat = 0;
        let checkinLong = 0;
      
        if(eventType === "CHECKIN")
        {
            
            await Locations.getLocationData().then((r)=>
            {
                checkInLat = r.latitude;
                checkinLong = r.longitude;
                
            });
        }

      
        event.Options = JSON.stringify({"ObjectDiaryId": jobShift.ObjectDiaryId, "PlacementId": jobShift.PlacementId, "ShiftEndTime": jobShift.ShiftEndTime, "ShiftStartTime": jobShift.ShiftStartTime, "CheckInLat":checkInLat, "CheckInLong":checkinLong});


        event.ClockStateId = jobShift.ClockState.clockStateId;
        event.IgnoreActiveClocks = ignoreActiveClocks;
        event.IanaTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        this.checkInService.postEvent(event).then(
            result => {
                this.selectedJobShift.ClockState.updateClock(result);
                if(eventType === "CHECKOUT"){
                    this.manualTimesheet = true;
                    this.checkoutConfirmation = false;
                }
            }).catch((e)=> {
                if (e.response.data["Existing Event"]) {
                    this.getJobShiftData(e.response.data["Existing Event"].errors[0].errorMessage, false, false, null, null, true);
                }
                if(e.isNetworkError){
                    this.showAlert(this.$t("alertMessages.networkError").toString(), this.$t("alertMessages.warning").toString());
                }
            });
    }

    showAlert(alertMessage: string, type: string){
        this.messageText = alertMessage;
        this.alertType = type;
        this.message = true;
    }

    closeAlert(){
        this.message = false;
    }

    scrolledToDate(e){
        this.initialDate = new Date(e);
    }

    timesheetDateRangeChanged(e){
        this.timsheetDateRange = e;
    }

    expenseDateRangeChanged(e){
        this.expenseDateRange = e; 
    }

    batchTimesheet(jobShift: JobShift){
        this.selectedJobShift = jobShift;  
        if(this.selectedJobShift.TimesheetId === 0)
        {
            this.showAlert(this.$t("alertMessages.batchTimesheetNotReady").toString(), this.$t("alertMessages.warning").toString());
            return;
        }

               
        this.selectedView = "timesheet";
        
        this.selectedTimesheet = this.selectedJobShift.TimesheetId;

        const el = ((this.$refs.btnTimesheet as Vue).$el as HTMLElement);
      
        if(el){
           el.click()
        }
    }
}
