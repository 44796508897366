import { Vue, Mixins } from 'vue-property-decorator'
import Component from "vue-class-component";
import ShortlistedView from "./components/ShortlistedView.vue"
import AppliedView from "./components/AppliedView.vue"
import RecentView from "./components/RecentView.vue"
//import AppliedJobStatusView from "./components/AppliedJobStatusView.vue"
import JobService, { JobSearchResult, JobSearchRequest, Job, JobOffersResult, JobOffersRequest } from "../../../rdb/components/jobs/JobService";
import store, {AppStore} from "../../../../store/index";
import {mobileBackButtonDialogComponentMixin} from '../../../mixins/mobileDialogBackButtonHandler';
import JobDetails from "../jobs/JobDetails.vue";
import JobOfferView from "./components/JobOfferView.vue";
import { NetworkMixin } from '@/components/mixins/networkMixin';
import BroadcastJobs from "@/components/rdb/components/broadcastjobs/BroadCastJobs.vue"
Component.registerHooks([
    'beforeRouteEnter',
    'beforeRouteUpdate',
    'beforeRouteLeave'
  ]);

@Component({
    name:"myjobs",
    components: { ShortlistedView, AppliedView, RecentView,  JobDetails, JobOfferView,BroadcastJobs },
    mixins:[mobileBackButtonDialogComponentMixin],
    watch: {
        showJobDetails: 'notifyDialogStateViaEventBus'
    }
})

export default class MyJobs extends Mixins(NetworkMixin) {
    el: "#app";
    selectedView = "shortlisted";
    lastSelectedView = "shortlisted";
    selectedTabIndex = 0;
    showJobDetails = false;
    selectedJob: Job = null;
    closeTopDialog = false;

    showJobOffers = false;
    selectedJobId = 0;
    jobOffers: JobOffersResult = new JobOffersResult;

    constructor() {
        super();
        this.setTab();
    }


    jobOfferCount() {
        return store.getters.AppState.jobOffers;
    }

    setTab(){
        let el;
        switch (this.selectedView){
            case "shortlisted":
                el = ((this.$refs.btnShortlisted as Vue).$el as HTMLElement)
                break;
            case "applied":
                el = ((this.$refs.btnApplied as Vue).$el as HTMLElement)
                break;
            case "recent":
                el = ((this.$refs.btnView as Vue).$el as HTMLElement)
                break;
            case "status":
                    el = ((this.$refs.btnStatus as Vue).$el as HTMLElement)
                    break;
        }
        if(el){
            el.focus();
        }
    }

    mounted(){
        const urlParams = new URLSearchParams(window.location.search);

        if(window.location.pathname.toLowerCase() === "/jobs" && urlParams.has("jobId")){
          
            this.selectedJobId = parseInt(urlParams.get("jobId"));
            this.showJobOffers = true;
        }

        this.loadJobOfferCount();
        //this.loadJobOffers();
    }

    loadJobOfferCount() {
        this.doWork<any>(JobService.jobOfferCount(),() => {
            this.loadJobOfferCount();
        }).then((result) => {
            store.commit.AppState.UpdateJobOffers(result.data);
        });
      }


    loadJobOffers(page = 1) {
        const jobOffersRequest = new JobOffersRequest();
        jobOffersRequest.Page = page;

        this.doWork<any>(JobService.jobOffers(jobOffersRequest),() => {
            this.loadJobOffers();
        }).then((result) => {
            this.jobOffers = result.data;
            if (this.jobOffers.Jobs.length > 0)
            {
                store.commit.AppState.UpdateJobOffers(this.jobOffers.Jobs.length);
                this.showJobOffers = true;
            }
        });
      }


    getTabIndex(){
        switch(this.selectedView){
        case "shortlisted":
            return 0;
        case "applied":
            return 1;
        case "recent":
            return 2;
        case "status":
            return 3;

        }
    }

    beforeMount(){
        if(this.$route.params.view){
            this.selectedView = this.$route.params.view;
            this.selectedTabIndex = this.getTabIndex();
        }
    }

    removeShortlisted(job: Job) {
        job.IsShortlisted = false;
        JobService.removeShortListJob(job.JobId).then(res=>{
            console.log(res);
        }).catch(e =>{
            console.log(e);
        });
    }

    setShortlisted(job: Job) {
        job.IsShortlisted = true;
        JobService.shortListJob(job.JobId).then(res=>{
            console.log(res);
        }).catch(e =>{
            console.log(e);
        });
    }

    showJob(job: Job){
        this.selectedJob = null;
        this.showJobDetails = true;
            this.selectedJob = job;
    }

    closeAllDialogs(){
        this.showJobDetails = false;
    }

        beforeRouteUpdate(to, from, next) {
            if (this.showJobDetails) {
                this.closeTopDialog = !this.closeTopDialog;
                next(false);
            } else if (this.showJobDetails) {
                this.$emit('closeAllDialogs');
                next(false);
            } else {
                next();
            }
        }
    
        beforeRouteLeave(to, from, next) {
            if (this.showJobDetails) {
                this.closeTopDialog = !this.closeTopDialog;
                next(false);
            } else if (this.showJobDetails ) {
                this.$emit('closeAllDialogs');
                next(false);
            } else {
                next();
            }
        }
}