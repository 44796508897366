<template>
  <div class="pt-0">
    <v-app-bar app dense fixed color="white" class="top-toolbar pt-0 "> 
            <v-btn class="back-button navigation-button" @click="back()" text>
          <v-icon>mdi-chevron-left</v-icon>{{ $t("apply")}}
        </v-btn>
          <v-spacer></v-spacer> 
      <v-btn class="action-close-apply back-button navigation-button action-back" @click="back()" icon><v-icon>mdi-close</v-icon></v-btn>
    
  </v-app-bar>

 <v-container class="div-background pt-0 mt-12">
    <v-dialog v-model="showCVUpload" max-width="500px" fullscreen>
      <div>
        <v-app-bar app fixed color="white" class="top-toolbar pt-0"> 
          <div class="h1-extra-large">{{ $t("apply")}}</div>
          <v-spacer></v-spacer> 
        <v-btn class="action-close-apply back-button navigation-button" @click="showCVUpload =false;" icon><v-icon>mdi-close</v-icon></v-btn>
      
        </v-app-bar>


            <CVUpload class="mt-14" :isOpen="showCVUpload" :selectedJob="selectedJobLocal" @close="showCVUpload = false" @apply="submitApplication" @skip="submitWithoutCV"></CVUpload>
      </div>
    </v-dialog>

    <v-dialog v-model="showCVSelect" max-width="500px" fullscreen>
      <div>
              <v-app-bar app fixed color="white" class="top-toolbar pt-0"> 
                <div class="h1-extra-large"> {{ $t("apply")}}</div>
                <v-spacer></v-spacer> 
              <v-btn class="action-close-apply back-button navigation-button" @click="showCVSelect =false;" icon><v-icon>mdi-close</v-icon></v-btn>
            
          </v-app-bar>

            <CVSelect class="mt-14" :isOpen="showCVSelect" :selectedJob="selectedJobLocal" @close="showCVSelect = false" @apply="submitApplication"></CVSelect>
          </div>
    </v-dialog>

  

    <v-dialog v-model="isComplete" max-width="500px" fullscreen>
      <v-container>


        <div class="background-cloud centered">

          <img  class="mt-10" style="max-width:80vw"  alt="Vue logo" src="@/assets/img/jobs/apply/success-apply-a-job.png"> 
                 
        </div>

        <div class="centered pt-12">
          <img src="@/assets/img/icons/Success.png" height="60px" class="center-content"/>
          <h1 style="text-align:center" class="h1-large">
          {{ $t("jobs.ApplicationSuccessMessage") }}
          </h1>
        </div>

        <v-footer fixed class="pa-2">
            <v-btn class="action-return-to-jobs-results" depressed block color="primary" @click="backToJobs()"> {{ $t("jobs.ApplicationSuccessMessageButton") }}</v-btn>
       </v-footer>


      </v-container>
    </v-dialog>

    <div>
      <JobSummaryCard :job="selectedJobLocal"
      class="pt-4 mb-3 summary-card; background-color:"
      @setShortlisted="setShortlisted"
      @removeShortlisted="removeShortlisted"></JobSummaryCard>
      <v-divider></v-divider> 
      <div class="h1-extra-large mt-12"> {{$t("jobs.JobApplyCVMessageHeading")}}</div>
      <div class="nunito-reg16 secondary-text-color"> {{$t("jobs.JobApplyCVMessage")}}</div>
    </div>

</v-container>


<v-footer fixed class="pa-2 ">
 <v-row fixed class="pr-2 mb-2 skiprow">
 <v-spacer />
            <v-btn class="back-button navigation-button" @click="submitWithoutCV()" text >
          {{ $t("skip")}}<v-icon>mdi-chevron-right</v-icon>
        </v-btn>
</v-row>
  <v-btn class="action-upload-cv"  depressed block color="primary" @click="uploadCV">{{ $t("jobs.uploadCVButtonTitle") }}</v-btn>
  <v-btn class="action-skip-upload-cv white-button mt-3" depressed block @click="selectExistingCV">{{ $t("jobs.useExistingCVButtonTitle") }}</v-btn>
</v-footer>

</div>
</template>

<script lang="ts">
    import ViewModel from "./JobApply"
    export default ViewModel;
</script>


  <style lang="scss" scoped>  

.background-cloud {
      background-image: url("../../../../assets/img/onboarding/Path 10739.png");
       background-size: 100% 100%;
    }


  .div-background
  {
    background-color: white;
  }
  .div-background-white
  {
    background-color: white;
    padding: 10px;
  }
  
  .v-list-item {
    align-items: start;
    padding-left: 10px;
  }

  .details-container{
    padding-left:8px;
  }

  .summary-card{
    min-height: 160px;
  }

  .v-footer {
    border: none !important;
  }

  .skiprow {
    border-bottom: 1px solid #EBECF5;
  }

</style>