import TimesheetService, { JobShift } from "../TimesheetService";
import { Vue, Component, PropSync, Watch } from 'vue-property-decorator';
import moment from 'moment';

@Component({
})

export default class CheckinSummaryCard extends Vue {
  @PropSync("checkInTime", { type: String }) checkInTimeLocal!: string;
  @PropSync("checkOutTime", { type: String }) checkOutTimeLocal!: string;
  @PropSync("breaks", { type: Array }) breaksLocal!: any[];
  @PropSync("shiftStartTime", { type: String }) shiftStartTimeLocal!: string;
  @PropSync("shiftEndTime", { type: String }) shiftEndTimeLocal!: string;
  @PropSync("daySpan", { type: Number }) daySpanLocal!: number;
  @PropSync("groupBreaks", { type: Boolean }) groupBreaksLocal!: boolean;

  get StartTime(){
    return moment(this.shiftStartTimeLocal).format("HH:mm");
  }

  get EndTime(){
    return moment(this.shiftEndTimeLocal).format("HH:mm");
  }

  get totalBreakDuration(){
    const duration = moment.duration();
    this.breaksLocal.forEach(element => {
      duration.add(moment.duration(element.duration));
    });

    return moment.utc(duration.asMilliseconds()).format("HH:mm");
  }
}