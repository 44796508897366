import Vue from 'vue';
import Component from 'vue-class-component';
import store, {AppStore} from "../../../../../store/index";

@Component({
    name:"timesheetonboarding",
})
export default class TimesheetOnboardingComponent extends Vue {

    stepControlsHidden = false;
    currentIndex = 0;
    constructor() {
        super();
      
    }

    onChange(index){
        this.stepControlsHidden = index == 3?true:false;
    }

    next(){
        this.currentIndex++;
        this.stepControlsHidden = this.currentIndex == 3?true:false;
    }

    gotToStep(index){
        this.currentIndex = index;
        this.stepControlsHidden = this.currentIndex == 3?true:false;
    }

    previous(){
        this.currentIndex--;
    }

    skip() {
        this.$emit("closeOnboarding");
    }

    complete() {
        this.$emit("completedOnboarding");
    }
}
