<template>

  <v-text-field class="input-search-location-text search-textfield" dense solo  prepend-inner-icon="mdi-map-marker" hide-details
      ref="autocomplete"
      :id="id"
      type="text"
      :placeholder="placeholder"
      v-model="autocompleteText"
      @focus="onFocus()"
      @blur="onBlur()"
      @change="onChange"
      v-on:keyup.13="onEnter"
      :label="labellocal"
    >
        <template v-slot:prepend-inner>
          <div class="location-icon">
            <img class="header-searchJobs" src="@/assets/img/icons/location.png" height="27px" />
          </div>
        </template>
      </v-text-field>
  </template>
  
  
  <script lang="ts">
    import ViewModel from "./LocationAutoCompleteGoogle"
    export default ViewModel;
  </script>

<style scoped>
  .location-icon{
    padding-right:27px;
    padding-left:5px;
    height: 30px;
    width: 30px;
  }

  .location-icon > img {
    margin-bottom: 7px;
  }
  </style>
    