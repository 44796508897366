import {
  Vue,
  Component,
  PropSync,
  Watch,
  Mixins,
} from "vue-property-decorator";
import JobService, { Job, BroadCastJob, BroadCastJobsRequest, BroadCastJobShiftRequest, BroadCastJobShiftResult, AcceptBroadCastShiftsRequest } from "../jobs/JobService";
import * as _ from "lodash";
import store from "@/store";
import Map from '@/components/Map.vue';
import BroadCastJobSummaryCard from "./BroadCastJobSummaryCard.vue";
import { NetworkMixin } from "@/components/mixins/networkMixin";
import { GoogleMarkerConfig } from "@/components/GoogleMap";
import googleMapsHelper from "@/utils/googleMapsHelper";
import { NotificationMixin } from '@/components/mixins/notificationMixin'
@Component({
  name: "broadcastjobdetails",
  components: { BroadCastJobSummaryCard, Map },
})
export default class BroadCastJobDetails extends Mixins(NetworkMixin, NotificationMixin) {
  @PropSync("selectedJob", { type: Object }) selectedJobLocal!: BroadCastJob;
  @PropSync("isOpen", { type: Boolean }) isOpenLocal!: boolean;
  @PropSync("closeTopDialog", { type: Boolean }) isCloseDialog!: boolean;

  job: BroadCastJob = new BroadCastJob();
 
  selectedView = "shift";
  selectedTabIndex = 0;
  markers: GoogleMarkerConfig[] = [];
  
  jobShifts: BroadCastJobShiftResult = new BroadCastJobShiftResult();
  jobLoadError = false;
  isLoaded = true;

  @Watch("isOpen")
  isOpenChanged(val: BroadCastJob, oldVal: BroadCastJob) {
    if (val) {
      this.selectedTabIndex = 0;
      this.selectedView = "shift";
      this.jobShifts.Shifts = [];
      this.isLoaded = true;
      this.loadJob();
    }
  }

  get mapVisible() {
    if (
      !this.job.Location &&
      this.job.Longitude == 0 &&
      this.job.Longitude == 0
    ) {
      return false;
    }
    return true;
  }

  get isMapsEnabled() {
    return store.getters.AppState.featureEnabled("RCRMGOOGLEMAPS");
  }

  @Watch("closeTopDialog")
  closeDialogChanged() {
    this.$emit("close");
  }

  mounted() {
    this.loadJob();
  }

  getItemText(item)
  {
    switch(item.Status)
    {
      case "":
        {
          return "";
          break;
        }
        case "BS_ACCEPTED":
          {
            
            return this.$t("broadcastJobs.approved").toString()
            break;
          }
          case "BS_PENDING":
        {
          
          return this.$t("broadcastJobs.awaitingApproval").toString()
          break;
        }
        case "BS_REJECTED":
        {
          
          return this.$t("broadcastJobs.rejected").toString()
          break;
        }
        default:
          {
            return "";
            break;
          }
    }

    
  }

  loadJob() {
    let jobId = 0;
    this.jobLoadError = false;
    if (this.$route.params.jobId !== undefined) {
      jobId = parseInt(this.$route.params.jobId);
      if(isNaN(jobId))
      {
        this.jobLoadError = true;
        return;
      }
    } else if (this.selectedJobLocal) {
      //jobId = this.selectedJobLocal.JobId;
      this.job = this.selectedJobLocal;     
        this.markers.splice(0);
        this.markers.push({
          height: 64,
          width: 48,
          lat: this.job.Latitude,
          lng: this.job.Longitude,
          imageUrl: store.getters.AppState.url + "img/icons/google_marker.png",
          uniqueId: this.job.JobId.toString(),
          imageUrlSelected:
            store.getters.AppState.url + "img/icons/google_marker.png",
        });
        this.loadShifts(this.job.JobId);
        return;
    }

    if (jobId > 0) {
      const broadcastJobsRequest = new BroadCastJobsRequest();
      broadcastJobsRequest.Page = 1;
      broadcastJobsRequest.JobId = jobId;
      this.isLoaded = false;
      this.doWork<any>(JobService.broadcastedJobs(broadcastJobsRequest),() =>{this.loadJob()}).then((res) => {
        if(res.data.Jobs.length <= 0)
        {
          this.jobLoadError = true;
          return;
        }
        this.isLoaded = true;
        this.job = res.data.Jobs[0];
        JobService.updateFromLocalStorage(this.job);
        
        this.markers.splice(0);
        this.markers.push({
          height: 64,
          width: 48,
          lat: this.job.Latitude,
          lng: this.job.Longitude,
          imageUrl: store.getters.AppState.url + "img/icons/google_marker.png",
          uniqueId: this.job.JobId.toString(),
          imageUrlSelected:
            store.getters.AppState.url + "img/icons/google_marker.png",
        });
        this.loadShifts(this.job.JobId);
    });
      
    }
  }

  loadShifts(jobId)
  {
    this.isLoading = true;
    this.jobShifts.Shifts = [];
    const broadcastJobShiftRequest = new BroadCastJobShiftRequest();
    broadcastJobShiftRequest.JobId = jobId;
    this.doWork<any>(JobService.broadcastedJobShifts(broadcastJobShiftRequest),() =>{this.loadJob()}).then((result) => {
      if(result.data.Shifts.length <= 0)
      {
        this.jobLoadError = true;
        return;
      }
      result.data.Shifts.forEach(i =>this.jobShifts.Shifts.push(i));
    });
  }

  back() {
    this.isLoaded = true;
    if (this.$route.params.jobId !== undefined) {
      this.$router.push({ name: 'MyJobs',params:{"view":"status"} });
    } else {
      this.job = new BroadCastJob();
      this.selectedView = "shift";
      this.$emit("close");
    }
  }

  openDirectionsClicked() {
    googleMapsHelper.loadDirections(this.job.Latitude, this.job.Longitude);
  }
  upperFirstChar(word)
  {
    return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
  }


  get anyShifts()
  {
    const availableShifts = this.jobShifts.Shifts.filter(function(data)
    {
      return data.Status === "USER_ACCEPTED" || data.Status === "" ;
    });
    return availableShifts.length === 0;

  }

  claimShifts()
  {
    const acceptedItems = this.jobShifts.Shifts.filter(function(data)
    {
      return data.Status === "USER_ACCEPTED";
    }).map(function(data)
    {
      return data.PublishedJobShiftId;
    });
    if(acceptedItems.length <= 0)
    {
      
      this.messageBoxWarning(this.$t("broadcastJobs.noShiftsSelectedMessage").toString());
      return;
    }
    
    this.acceptShifts(acceptedItems);

  }

  acceptShifts(shifts)
  {
    this.isLoading = true;
    const request = new AcceptBroadCastShiftsRequest();
    request.PublishedJobShiftIds = shifts;
    JobService.acceptBroadCastShifts(request).then(res=>{
        this.isLoading = false;  
       
        if(res === false)
        {
          this.messageBoxWarning(this.$t("broadcastJobs.errorMessage").toString());
        }
        else
        {
          this.loadShifts(this.job.JobId);
        this.messageBox(this.$t("broadcastJobs.successMessage").toString(),"Success","Thank You!");
        }
        
    }).catch(e =>{
        this.messageBoxWarning(this.$t("broadcastJobs.errorMessage").toString());
        this.isLoading = false;
    });
  }


  get dateGroup()
  {
    if(this.jobShifts.Shifts.length <= 0)
    {
      return;
    }
    const values =  _.keys(_.countBy(this.jobShifts.Shifts, function(payload) { return payload.Date }));
    return values.sort(function(a,b){ return b-a});
  }

  dataByDate(strDate)
  {
    
    return this.jobShifts.Shifts.filter(function(data)
    {
     
      return data.Date == strDate;
    })
  }

  getMonth(strDate) {
      const y = strDate.substr(6,4),
      m = strDate.substr(3,2) - 1,
      d = strDate.substr(0,2);
      return new Date(y,m,d).toLocaleDateString('default', { month: 'short' });
  
  }

  getShiftCount(strDate)
  {
    const yearData = this.jobShifts.Shifts.filter(function(data)
      {
        return data.Date === strDate;
      })
      return this.formatNumberAddLeadingZero(yearData.length);
  }

  formatNumberAddLeadingZero(number)
  {
    return (number < 10 ? '0' : '') + number;
  }

}
