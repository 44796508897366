import { Component, Vue, Prop } from 'vue-property-decorator';

declare global {
    interface Window { Dropbox: Chooser }
}

@Component({})
export default class DropboxPicker extends Vue {
  @Prop({
    required: true,
  })
  apiKey!: string;

  @Prop({
    required: false,
    default: 'preview',
  })
  linkType!: 'preview' | 'direct';

  @Prop({
    required: false,
    default: false,
  })
  multiselect!: boolean;

  @Prop({
    required: false,
    default: () => [],
  })
  extensions!: string[];

  @Prop({
    required: false,
    default: false,
  })
  folderselect!: boolean;

  @Prop({
    required: false,
    default: 0,
  })
  sizeLimit!: number;

  @Prop({
    required: false,
    default: false,
  })
  disabled!: boolean;

  scriptLoaded = false;
  dropboxChooserIsSupported = false;

  mounted() {
      const dropBoxScript = document.createElement('script');
      dropBoxScript.onload = () => {
        this.scriptLoaded = true;
        this.dropboxChooserIsSupported = window.Dropbox!.isBrowserSupported();

        if (!this.dropboxChooserIsSupported) {
          console.warn('Dropbox Picker: This browser is not supported');
        }
      };
      dropBoxScript.setAttribute('src', 'https://www.dropbox.com/static/api/2/dropins.js');
      dropBoxScript.setAttribute('id', 'dropboxjs');
      dropBoxScript.setAttribute('data-app-key', this.apiKey);
      document.head.appendChild(dropBoxScript);
  }

  openChooser() {
    if (!this.disabled) {
      const options: ChooserOptions = {
        multiselect: this.multiselect,
        folderselect: this.folderselect,
        linkType: this.linkType,
        success: (files: ReadonlyArray<ChooserFile>) => {
          this.$emit('picked', files);
        },
        cancel: () => {
          this.$emit('cancel');
        }
      };

      if (this.extensions.length) {
        options.extensions = this.extensions;
      }

      if (this.sizeLimit) {
        options.sizeLimit = this.sizeLimit;
      }

      window.Dropbox!.choose(options);
    }
  }
}


export interface Chooser {
  choose(options: ChooserOptions): void;

  isBrowserSupported(): boolean;
}

export interface ChooserOptions {
  linkType?: 'preview' | 'direct';
  multiselect?: boolean;
  extensions?: string[];
  folderselect?: boolean;
  sizeLimit?: number;

  success(files: ReadonlyArray<ChooserFile>): void;

  cancel?(): void;
}

export interface ChooserFile {
  id: string;
  name: string;
  link: string;
  bytes: number;
  icon: string;
  thumbnailLink?: string;
  isDir: boolean;
}