import localStorageService from './localStorageService';

const shouldSkipCache = (mutation) => {
  return false;
};

const plugin = (store) => {
  store.subscribe((mutation, state) => {
    if (!shouldSkipCache(mutation)) {
        Object.keys(state.AppState).forEach((key) => {
            localStorageService.getStore("state").setItem(key, state.AppState[key]);
        });
    }
  });
};

export default plugin;