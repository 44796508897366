

<div class="main-container">

  <div class="map-container" ref="hereMapControl">
      <div class="pt-4 pl-5"  v-if="showMapLink">
        <v-btn class="primary action-open-map"  :href="'https://google.com/maps/place/' + locationString + '/'" target="_blank">
            <v-icon left>
              mdi-map-marker
            </v-icon>
          {{$t('maps.viewOnMap')}}
         </v-btn>
      </div>
  </div>

    
  <!-- <slot name="cardview" v-if="cardVisible">

  </slot> -->
  
</div>

