<template>

  <div class="main-container">

  
    
    <GoogleMap v-if="mapType == 0" :recycle="true" :location="location" :lattitude="lattitude" :longitude="longitude" :markers="markers" @markerclicked="markerClicked" @hideCard="hideCard"></GoogleMap>
    <HereMap v-if="mapType == 1" :recycle="false" :location="location" :lattitude="lattitude" :longitude="longitude" :markers="markers" @markerclicked="markerClicked" @hideCard="hideCard"></HereMap>

      
    <slot name="cardview" v-if="cardVisible">
       
    </slot>
    
  </div>

</template>


<script lang="ts">
  import ViewModel from "./Map"
  export default ViewModel;
</script>
  

<style>
  .map-container{
    height: 100%;
    width: 100%;
  }

  .main-container{
    height: 100%;
    width: 100%;
  }

  /* .card-view{
    height: 100px;
    width:100px;
    position: absolute;
    bottom: 0;
  } */

</style>