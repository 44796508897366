import store from '@/store';
import BaseService from './baseService';



export class AuditEventLog  extends BaseService{

    baseUrl: string;

    

    async postAuditEventLog(eventType: string) {
        

        return new Promise<boolean>((resolve,reject)=>
        {
            return this.httpClient("MOB").post("/AuditEventLog",{eventType:eventType}).catch((e) =>
            {
                reject(e);
            });
        }
        );
    }


}

export default new AuditEventLog();