import { Vue, Component, PropSync,Watch } from 'vue-property-decorator'
import interact from "interactjs";
import JobSummaryCard from "./JobSummaryCard.vue";
import store from "@/store/index";

@Component({
  components:{JobSummaryCard}
})
export default class JobCard extends Vue {
  @PropSync("card", { type: Object }) cardLocal!: any;
  @PropSync("isCurrent", { type: Boolean }) isCurrentLocal!: boolean;
  @Watch('isCurrent')
  onIsCurrentChanged(val: boolean, oldVal: boolean) {
    this.$emit("cardChanged");
    
  }
  @PropSync("startInteraction", { type: String }) startInteractionLocal!: string;

  @Watch('startInteraction')
  onPersonChanged2(val: string, oldVal: string) {
      if(val && val != oldVal && this.isCurrentLocal){
        this.playCard(val);
      }
  }


    CARD_RIGHT = "cardRight";
    CARD_LEFT = "cardLeft";
    CARD_DOWN = "cardDown";
    CARD_UP = "cardUp";
    CARD_RESTORE = "cardRestore";

    interactMaxRotation= 15;
    interactOutOfSightXCoordinate= 500;
    interactOutOfSightYCoordinate= 600;
    interactOutOfSightYCoordinateUp= -300;
    interactYThreshold=150;
    interactXThreshold=100;
    interactYThresholdUp=-150;

  isInteractAnimating = true;
  isInteractDragged = null;
  interactPosition =  {
    x: 0,
    y: 0,
    rotation: 0
  };
  isRestored = false;
  isShowing = true;
  constructor() {
        super();
     
    }

    get showCommuteOptions(){
        return store.getters.AppState.jobSearchRequest.Latitude !== 0 && store.getters.AppState.jobSearchRequest.Longitude !== 0 && store.getters.AppState.featureEnabled("RCRMGOOGLEMAPS");
    }

    showDetails(){
      //this.$router.push("/job/" + this.cardLocal.JobId.toString());
      this.$emit("cardSelected",{card:this.cardLocal});
    }

    interactSetPosition(coordinates) {
      const { x = 0, y = 0, rotation = 0 } = coordinates;
      this.interactPosition = { x, y, rotation };

      this.$emit("cardMoved",{card:this.cardLocal,interactPostion: this.interactPosition});
    }


    resetCardPosition() {
      this.interactSetPosition({ x: 0, y: 0, rotation: 0 });
    }


    get jobTextHeight()
    {
       
       if(store.getters.AppState.featureEnabled("RCRMGOOGLEMAPS"))
       {
         return "height:36px;"
       }
       else
       {
        return "height:146px;"
       }
    }

   get transformString() {
    if(this.isShowing){

    
        if (!this.isInteractAnimating || this.isInteractDragged || this.isRestored) {
          const { x, y, rotation } = this.interactPosition;
          return `translate3D(${x}px, ${y}px, 0) rotate(${rotation}deg)`;
        }
        else{
          if((this.cardLocal as any).isRestoring && this.isShowing ){
            return `translate3D(-${this.interactXThreshold + 100}px, ${0}px, 0) rotate(-${this.interactMaxRotation}deg)`;
          }else{
            return ``;
          }
        }
    }

    return null;
    }


    beforeDestroy() {
      if(this.$refs.interactElement){
        interact(this.$refs.interactElement as any).unset();
      }
   }
    
   interactUnsetElement() {
    interact(this.$refs.interactElement as any).unset();
    this.isInteractDragged = true;
  }

   hideCard() {
    setTimeout(() => {
      this.isShowing = false;
      this.$emit("hideCard", this.cardLocal);
      }, 300);
    }

   playCard(interaction) {
     
    if(!(this.cardLocal as any).isRestoring && interaction !== this.CARD_DOWN){
      this.interactUnsetElement();
    }
   

    switch (interaction) {
      case this.CARD_RIGHT:
        this.interactSetPosition({
          x: this.interactOutOfSightXCoordinate,
          rotation: this.interactMaxRotation
        });
        this.$emit(this.CARD_RIGHT);
        break;
      case this.CARD_LEFT:
        this.interactSetPosition({
          x: -this.interactOutOfSightXCoordinate,
          rotation: -this.interactMaxRotation
        });
        this.$emit(this.CARD_LEFT);
        break;
      case this.CARD_DOWN:
        this.resetCardPosition();
        // this.interactSetPosition({
        //   y: this.interactOutOfSightYCoordinate
        // });
        // this.$emit(this.CARD_DOWN);
        break;
        case this.CARD_UP:
        this.interactSetPosition({
          y: this.interactOutOfSightYCoordinateUp
        });
        this.$emit(this.CARD_UP);
        break;
        case this.CARD_RESTORE:
          //this.isInteractDragged = true;
          //this.isInteractAnimating = true;
          this.isRestored = true;
          (this.cardLocal as any).isRestoring = false;
          this.resetCardPosition();
          this.isRestored = false;
          //this.interactSetPosition({x:"0",y:"0"})
        break;
    }

    if(interaction !== this.CARD_RESTORE && interaction !== this.CARD_DOWN ){
      this.hideCard();
    }
   
  }

    mounted() {



      const element = this.$refs.interactElement as any;
      
      interact(element).draggable({
        onmove: event => {
         
          const x = this.interactPosition.x + event.dx;
          const y = this.interactPosition.y + event.dy;
  
          let rotation = this.interactMaxRotation * (x / this.interactXThreshold);
  
          if (rotation > this.interactMaxRotation) rotation = this.interactMaxRotation;
          else if (rotation < -this.interactMaxRotation)
            rotation = -this.interactMaxRotation;
  
          this.interactSetPosition({ x, y, rotation });
          
          
        },
        onend: () => {
          const { x, y } = this.interactPosition;
          this.isInteractAnimating = true;
   
          if (x > this.interactXThreshold) this.playCard(this.CARD_RIGHT);
          else if (x < -this.interactXThreshold) this.playCard(this.CARD_LEFT);
          else if (y > this.interactYThreshold) this.playCard(this.CARD_DOWN);
          else if (y < this.interactYThresholdUp) this.playCard(this.CARD_UP);
          else this.resetCardPosition();
        },
        onstart: () => {
          
          this.isInteractAnimating = false;
        }
      })
   }

}
