import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import locationServices, { TextSearchResponse } from '@/utils/locationServices'
import store from "@/store/index";
import { MapType } from '@/store/AppState';
import hereMapsHelper from '@/utils/hereMapsHelper'
@Component({
})
export default class LocationAutoCompleteInput extends Vue {

  @Prop() textvalue!: string;
  @Prop() label!: string;

  searchText = "";
  id = "locationInput";
  items: TextSearchResponse[] =[];
  selectedItem: TextSearchResponse = null;
  isLoading = false;
  searchTimerId: number;

  @Watch("searchText")
  searchTextChanged(val) {

  if (val && val.trim() !== "") {
      clearTimeout(this.searchTimerId);

      this.searchTimerId = setTimeout(() => {

        const name = (this.selectedItem) ? this.selectedItem.locationText : "";
        if(val && val !== name)
        {
          this.loadItems();
        }
      }, 500);
    }
    this.$emit('inputChange', val);
  }

  mounted(){
    this.items = [{lat:0,lng:0,locationText:this.textvalue}];
    if (this.textvalue === "") {
      this.selectedItem = null;
    } else {
      this.selectedItem = this.items[0];
    }
    this.searchText = this.textvalue;
  }

  loadItems(){
    this.isLoading = true;
    locationServices.textSearch(this.searchText).then((result) => {
      this.items = result;
    }).finally(() => (this.isLoading = false));
  }

  onItemSelected() {  
    //Here maps requires 2 API requests, 1 for suggestions and another to obtain geocode data i.e Lat,lng
    if(this.selectedItem) {
      if (this.selectedItem.id) {
        hereMapsHelper.getGeoCodeData(this.selectedItem.id).then(result => {
            this.selectedItem.lat = result.lat;
            this.selectedItem.lng = result.lng;
            this.$emit('placechanged', {lat: this.selectedItem.lat,lng: this.selectedItem.lng});
        });
      }
    }
    else{
        this.items = [{lat:0,lng:0,locationText:""}];
        this.selectedItem = null;
        this.searchText = this.textvalue;
        this.$emit('placechanged', {lat: this.items[0].lat,lng: this.items[0].lng});
    }
  }

  onEnter(){
    if(store.getters.AppState.mapService == MapType.HereMaps){
      if(this.selectedItem.lat == 0 && this.selectedItem.lng == 0)
      {
          locationServices.textSearch(this.searchText).then(i=>{
            if(i.length > 0){
               hereMapsHelper.getGeoCodeData(i[0].id).then(x=>{
                  this.selectedItem.lat = x.lat;
                  this.selectedItem.lng = x.lng;
                  this.selectedItem.locationText = i[0].locationText;

                  this.$emit('placechanged', {lat: this.selectedItem.lat,lng: this.selectedItem.lng});
                  this.$emit('inputChange', this.selectedItem.locationText);
                  this.$emit('search');
               });
              }
          });
      }
    }
    else{
      this.$emit('search');
    }

    
  }
}

