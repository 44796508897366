<template>
  <div>
    <div v-if="multiple" class="mb-0 pl-1">
      <v-badge color="primary" :content="Count"> </v-badge>
      <div class="pl-6 nunito-small-caption-12" style="display:inline-block">
       {{$t("availability.daysSelectedCaption")}} 
      </div>

      <v-icon class="pl-2" @click="showDates">mdi-eye-outline</v-icon>
      <img
        src="@/assets/img/icons/delete.png"
        alt="clear"
        style="float: right;"
        v-on:click="clearDates"
        height="28px"
      />
    </div>

    <v-date-picker
      class="availability-calendar"
      full-width
      no-title
      light
      first-day-of-week="1"
      v-model="vmodelDate"
      :weekday-format="dayFormat"
      :events="eventColors"
      :min="minDate"
      :max="maxDate"
      :multiple="multiple"
      :allowedDates="allowDates"
      :picker-date.sync="pickDate"
      
      @input="saveMulti"
      @change="dateChanged"
      
    >
    </v-date-picker>

    <div v-if="showMultiData">
      <v-dialog
        v-model="showMultiData"
        max-width="500px"
        fullscreen
        scrollable
        persistent
      >
        <v-app-bar dense fixed color="white" class="top-toolbar pt-0 ">
          <div  class="text-h1 pt-6">{{$t("availability.selectedDatesHeading")}} - {{ Count }} {{$t("availability.daysSubHeading")}}</div>
        </v-app-bar>
        <v-container
          class="div-background pt-0 mt-12 mb-14"
          style="max-wdith:500px"
        >
          <v-list class="d-flex flex-wrap justify-center ">
            <v-list-item-group style="width:100%">
              <template v-for="(item, i) in vmodelDate">
                <v-list-item :key="i">
                  <v-list-item-avatar>
                    <v-btn fab elevation="0" small dark color="#e8ebf3">
                      <p
                        class="font-weight-black"
                        style="color:black;padding-top:15px;"
                        v-text="
                          `${new Date(item).toLocaleDateString('default', {
                            month: 'short',
                          })}`
                        "
                      ></p>
                    </v-btn>
                  </v-list-item-avatar>

                  <v-list-item-content>
                    <v-list-item-title
                      class="text-h2"
                      v-text="formatDateMultiShow(item)"
                    ></v-list-item-title>
                  </v-list-item-content>

                  <v-list-item-action>
                    <img
                      src="@/assets/img/icons/delete.png"
                      style="float: right;"
                      v-on:click="vmodelDate.splice(i, 1)"
                      height="28px"
                    />
                  </v-list-item-action>
                </v-list-item>
                <v-divider
                  v-if="i < vmodelDate.length - 1"
                  :key="i + 500"
                ></v-divider>
              </template>
            </v-list-item-group>
          </v-list>
          <v-footer fixed color="white">
            <v-container class="pt-0 pb-0">
              <v-row justify="center">
                <v-col cols="12">
                  <v-btn
                    @click="showMultiData = false"
                    class="action-cancel-availability"
                    depressed
                    block
                  >
                    {{ $t("close") }}
                  </v-btn>
                </v-col>
              </v-row>
            </v-container>
          </v-footer>
        </v-container>
      </v-dialog>
    </div>
  </div>
</template>

<script lang="ts">
import ViewModel from "./AvailabilityCalendar";
export default ViewModel;
</script>

<style scoped>

@media screen and (min-width: 360px) {
  .availability-calendar {
    min-height: 360px;
    margin-bottom: 15px;
  }
}


.availability-calendar >>> .v-date-picker-table table{
   -webkit-transition: none !important;
  -moz-transition: none !important;
  -o-transition: none !important;
  transition: none !important;
}
.availability-calendar >>> .v-date-picker-table--disabled .v-btn--active {
  background-color: #ffffff !important;
}
  .availability-calendar >>> .v-date-picker-header__value .accent--text{
  -webkit-transition: none !important;
  -moz-transition: none !important;
  -o-transition: none !important;
  transition: none !important;
  -webkit-transition-duration: 0.01ms !important;
  -moz-transition-duration: 0.01ms !important;
  -o-transition-duration: 0.01ms !important;
  transition-duration: 0.01ms !important;
  
  }

.availability-calendar >>> .v-date-picker-header {
  padding-top: 12px;
  font-weight: bold !important;
}

.availability-calendar >>> .v-date-picker-table {
  padding: 0px;
  margin-top: -8px;
}
.availability-calendar >>> .v-date-picker-table--date td {
  padding-top: 6px;
  padding-bottom: 6px;
}

.availability-calendar >>> .v-date-picker-table .v-btn {
  border-style: solid !important;
  border-color: #dbe0e5 !important;
  border-width: 1px;
  width: 26px;
  height: 26px;
}

.availability-calendar >>> .v-date-picker-table .v-btn::before {
  background-color: #ffffff !important;
}

.availability-calendar >>> .v-date-picker-table .v-btn {
  font-weight: bold;
  font-size: 13px;
}
.availability-calendar >>> .v-date-picker-table__current.v-btn {
  background-color: #555b70 !important;
  color: white !important;
}
.availability-calendar >>> .v-date-picker-table__current.v-btn::before {
  background-color: #555b70 !important;
}

.availability-calendar >>> .v-date-picker-table__events {
  height: 4px !important;
  bottom: 1px !important;
}
.availability-calendar >>> .v-date-picker-table__events > div {
  height: 4px !important;
  width: 4px !important;
}

.availability-calendar
  >>> .theme--light.v-btn:not(.v-btn--flat):not(.v-btn--text):not(.v-btn--outlined) {
  border-color: #dbe0e5 !important;
  background-color: #d4d8e6 !important;
}
.availability-calendar
  >>> .theme--light.v-btn:not(.v-btn--flat):not(.v-btn--text):not(.v-btn--outlined):not(.v-date-picker-table__current)
  .v-btn__content {
  color: #000000 !important;
}

.availability-calendar
  >>> .v-btn.v-date-picker-table__current.v-btn--active.v-btn--text.v-btn--rounded.theme--light.accent:not(.v-date-picker-table__current) {
  border-color: #dbe0e5 !important;
  background-color: #d4d8e6 !important;
}

.availability-calendar
  >>> :not(.v-date-picker-table__current).v-btn--active.v-btn--text.v-btn--rounded.theme--light.accent
  .v-btn__content {
  color: #000000 !important;
}

@media screen and (max-width: 320px) {
  .availability-calendar >>> .v-date-picker-table {
    font-size: 12px;
  }
  .availability-calendar {
    height: 300px;
  }

  .availability-calendar >>> .v-date-picker-header {
    padding-top: 4px;
    font-weight: bold !important;
  }
  .availability-calendar >>> .v-date-picker-table .v-btn {
    border-style: solid !important;
    border-color: #dbe0e5 !important;
    border-width: 1px;
    width: 20px;
    height: 20px;
  }

  .availability-calendar >>> .v-date-picker-table__events {
    height: 4px !important;
    bottom: 1px !important;
    width: 25px;
    margin-left: -5px;
  }
  .availability-calendar >>> .v-date-picker-table .v-btn {
    font-weight: bold;
    font-size: 12px;
  }
}
.nunito-small-caption-12 {
  font-size: 12px;
  color: #8c9097;
}




</style>
