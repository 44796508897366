import { Vue, Component, PropSync } from 'vue-property-decorator';

@Component({
})

export default class Toast extends Vue {
 
  message = "";
  resolve =  null;
  reject = null;
  isVisible = false;
  timeout = 1000;
  color = "success"

  constructor() {
    super();
  }

  show(message,type, delay = 1000) {

    switch(type){
      case "Success":
          this.color = "success";
        break;
      case "Error":
          this.color = "red";
        break;
    }
    
    this.isVisible = true;
    this.message = message;
    this.timeout = delay;
    
    
    
  }
}