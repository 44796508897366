<template>
    <div class="pa-2 summary-container">
     <v-row no-gutters>
       
        <v-col cols="9" sm="9" md="9" class="border-right">
          <v-row class="d-flex justify-space-between pl-3">
            <div class="text-h1" :data-Description="TimesheetExpenseLocal.Description">{{TimesheetExpenseLocal.Description}}
            </div>
          </v-row>          
          <v-row class="text-subtitle-1 padding-2 notes pl-3" :data-notes="TimesheetExpenseLocal.Note">
            <span class="note-text">  {{TimesheetExpenseLocal.Note}}</span>
          </v-row>
          <v-row>
            <v-col cols="7" sm="4" md="4" class="text-subtitle-2 grey--text text--darken-2 pt-1" :data-quantity="TimesheetExpenseLocal.Quantity">
              Quantity: {{TimesheetExpenseLocal.Quantity}}
            </v-col>
            <v-col cols="5" sm="6" md="6"  class="text-subtitle-1  pt-1 total"  :data-total="TimesheetExpenseLocal.Total">
              {{TimesheetExpenseLocal.CurrencySymbol}}{{TimesheetExpenseLocal.Total.toFixed(2)}}
            </v-col>
          </v-row>        
        </v-col>
         <v-col cols="3" sm="3" md="3" class="centered" >
          <div class="ma-auto">            
            <img  alt="" :class="statusClass"  height="auto" width="30px" />
          </div>
           <div class="text-subtitle-1 padding-2" :data-timesheetstatus="TimesheetExpenseLocal.ExpenseApprovalStatusDescription">{{TimesheetExpenseLocal.ExpenseApprovalStatusDescription}}</div>
        </v-col>
      </v-row>  
    </div>
</template>

<script lang="ts">
    import ViewModel from './TimesheetExpenseSummaryCard'  
    export default ViewModel;
</script>



<style lang="scss" scoped>
  .logo-image{
    height:38px;
    width:38px;
  }


  .note-text{
    padding-right: 10px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
  
  .notes{
    font-size: 14px!important;
    color: #000000;
    min-height: 20px;    
    min-width:0;
  }

  .pending-icon {
    content:url('../../../../../assets/img/timesheets/status/pending.png');
  }

  .approved-icon {
    content:url('../../../../../assets/img/timesheets/status/approved.png');
  }

  .query-icon {
    content:url('../../../../../assets/img/timesheets/status/query.png');
  }

  .cancel-icon {
    content:url('../../../../../assets/img/timesheets/status/cancel.png');
  }

  .payslip-icon {
    content:url('../../../../../assets/img/timesheets/status/payslip.png');
  }

   .border-right {
    border-right: 1px solid #DBE0E580;
  }

  .total {
    font-weight: bold;
  }

</style>