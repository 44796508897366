<template>
      <v-snackbar
      v-model="isVisible"
      :timeout="timeout"
      :color="color"
    >
       {{message}}
    </v-snackbar>
 
</template>
<script lang="ts">
    import ViewModel from './Toast'  
    export default ViewModel;
</script>
