import { Vue, Component, PropSync, Prop } from 'vue-property-decorator'
import JobCard from "./JobCard.vue"
import LoadingIndicator from "@/components/LoadingIndicator.vue";

@Component({
  
  components:{JobCard,LoadingIndicator}  
})
export default class JobCards extends Vue {
  @PropSync("cards", { type: Array }) cardsLocal!: [];
  @PropSync("startInteraction", { type: String }) startInteractionLocal!: string;
  @PropSync("isLoading", { type: Boolean }) isLoadingLocal!: boolean;
  @Prop({default:false}) showNoResults;
  constructor() {
        super();
    }

    cardMoved(e){
      this.$emit("cardMoved",e);  
    }

    cardSelected(e){
      this.$emit("cardSelected",e);  
    }
}
