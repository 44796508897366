<template>
  <div class="cards"> 
    <JobCard
      v-for="(card, index) in cardsLocal"
      :key="card.JobId"
      :card="card"
      :is-current="index === 0"
      @cardRight="$emit('cardRight',card);"
      @cardLeft="$emit('cardLeft',card);"
      @cardDown="$emit('cardDown',card);"
      @cardUp="$emit('cardUp',card);"
      @hideCard="$emit('hideCard',card);"
      @cardMoved="cardMoved"
      @cardSelected="cardSelected"
      @cardChanged="$emit('cardChanged',card);"
      :startInteraction="startInteractionLocal"
      :data-jobid="card.JobId"
    >
    <template v-slot:commuteoptions>
    </template>
  </JobCard>
  <v-container class="centered" v-if="isLoading">
      <LoadingIndicator :isVisible="isLoading"></LoadingIndicator>
  </v-container>

  <v-container class="centered" v-if="cardsLocal.length  === 0 && !isLoading && showNoResults">
 
                <v-row justify="center" class="background-cloud">
                   <div class="header-noJobs ma-auto">
                    </div>      
                </v-row>
                
                <v-row justify="center">
                <v-col cols="12" md="6">
                    <h1 style="text-align:center" class="h1-large">
                    {{ $t("jobs.NoMoreSearchResultsTitle") }}
                    </h1>
                </v-col>
                </v-row>
  </v-container>
  </div>  
</template>

<script lang="ts">
  import ViewModel from './JobCards'
  export default ViewModel;
</script>

<style lang="scss" scoped>
    .header-noJobs {
      content: url("../../../../../assets/img/jobs/search/no jobs-big.png");
      width:120px;
      height:120px ;
    }

  .cards {
    position: relative;
    display: flex;
    
    max-width: 100vw;
    touch-action: none;
   
    /* overscroll-behavior: none;touch-action:none;overflow-x: scroll;overflow-y: hidden;margin: 0;height: 90vh;min-width: 80vw */
    
  }

  .background-cloud {
      background-image: url("../../../../../assets/img/onboarding/Path 10739.png");
       background-size: 100% 100%;
    }
  </style>