import { Vue, Component, PropSync, Watch, Mixins } from 'vue-property-decorator';
import JobService, { Job, CVUploadResult, JobApplicationRequest } from "./JobService";
import JobSummaryCard from "./components/JobSummaryCard.vue";
import store from '@/store';
import CVUpload from "./components/CVUpload.vue";
import CVSelect from "./components/CVSelect.vue";
import { NotificationMixin } from '@/components/mixins/notificationMixin';

@Component({
    name:"JobApply",
    components:{JobSummaryCard, CVUpload, CVSelect}
})
export default class JobApply extends Mixins(NotificationMixin)  {
    @PropSync("selectedJob", { type: Object }) selectedJobLocal!: Job;
    @PropSync("isOpen", { type: Boolean }) isOpenLocal!: boolean;
    @PropSync("closeDialog", { type: Boolean }) isCloseDialog!: boolean;
    job: Job = new Job;
    isLoading = false;
    isLoaded = false;
    selectedView = "job";
    isComplete = false;
    showCVUpload = false;
    showCVSelect = false;

    // @Watch('selectedJob')
    // selectedJobChanged(val: Job, oldVal: Job) {
    //      this.job = val;
    //      this.loadJob();
    // }

    @Watch('closeDialog')
    closeDialogChanged() {
        if (this.showCVSelect || this.showCVUpload) {
                this.closeAllDialogs();
            }
            else {
                this.$emit('closeApply');
            }
    }

    @Watch('isOpen')
    isOpenChanged(val: Job, oldVal: Job) {
        if(val){
            this.loadJob();
        }
    }
    constructor() {
        super();
         this.isLoading = true;
    }

    uploadCV(){
        this.showCVUpload = true;
   }

   selectExistingCV(){
       this.showCVSelect = true;
   }

        mounted(){
            this.loadJob();
        }

   
     loadJob() {

        let jobId = 0;
        if (this.$route.params.jobId !== undefined) {
            jobId = parseInt(this.$route.params.jobId)
        }else if(this.selectedJobLocal){
            jobId =  this.selectedJobLocal.JobId;
        }

         if (jobId > 0) {
         JobService.selectJob(jobId).then(async (res) =>  {
             this.job = res.data as Job;
             JobService.updateFromLocalStorage(this.job);

             this.isLoading = false;
             this.isLoaded = true;
            }).catch((e) =>{
                 this.isLoading = false;
                 //Message to user
            });
         }
     }

    back() {
        if(this.$route.params.jobId !== undefined){
            this.$router.back();
        }
        else{
            this.$emit("closeApply");
        }
    }

    backToJobs() {
        this.closeAllDialogs();
        this.$emit("closeApply", true);
        this.$router.push({ name: 'MyJobs',params:{"view":"applied"} });
    }

    submitApplication(cvs: CVUploadResult[]) {
        const jobApplicationRequest = new JobApplicationRequest();
        jobApplicationRequest.JobId = this.selectedJobLocal.JobId;
        jobApplicationRequest.CVId = cvs[0].CVId;
        jobApplicationRequest.AdditionalInfo = "";
        JobService.submitJobApplication(jobApplicationRequest).then((res) => {
           if (res.data) {
               this.isComplete = true;
           }
           }).catch((e) => {
                this.isLoading = false;
           });
    }

    closeAllDialogs(){
        this.showCVSelect = false;
        this.showCVUpload = false;
    }

    removeShortlisted(job: Job) {
        job.IsShortlisted = false;
        JobService.removeShortListJob(job.JobId).then(res=>{
            console.log(res);

        }).catch(e =>{
            console.log(e);
        });
     }


    setShortlisted(job: Job) {
        job.IsShortlisted = true;
        JobService.shortListJob(job.JobId).then(res=>{
            console.log(res);
        }).catch(e =>{
            console.log(e);
        });
    }

    submitWithoutCV() {
        this.confirm(this.$t("jobs.noCVConfirm").toString(),this.$t("confirm").toString()).then((result) => {
                if(result) {
                    const jobApplicationRequest = new JobApplicationRequest();
                    jobApplicationRequest.JobId = this.selectedJobLocal.JobId;
                    jobApplicationRequest.AdditionalInfo = "";
                    JobService.submitJobApplication(jobApplicationRequest).then((res) => {
                    if (res.data) {
                        this.isComplete = true;
                    }
                }).catch((e) => {
                    this.isLoading = false;
                });
            }
        });
    }
    
}
