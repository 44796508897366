
 <div class="pt-0">

<v-container class="div-background">
   <div class="h1-large">Select your CV</div>
   <ConnectionLostCardView :isVisible="showNetworkError" @close="retryClicked"></ConnectionLostCardView>
   <LoadingIndicator class="mt-n10 pt-5" :isVisible="isLoading"></LoadingIndicator>
   <div class="mb-12">
          <v-list dense>
            <v-radio-group
                v-model="selectedCV"
                column
              >
              <template v-for="file in CVs">              
                      <v-list-item  class="rounded-corners card-border" :key="file.CVId" @click="cvSelected(file)">
                          <v-list-item-content>
                            <v-row no-gutters>
                              <v-col cols="2" sm="2" md="1" >                                
                                <div class="pa-2  logo-image" >
                                  <!-- <div :class="fileTypeImageClass(file)">

                                  </div> -->
                                  <v-icon v-if="file.FileExtension !== '.pdf'">{{'mdi-microsoft-word '}}</v-icon>
                                  
                                  <v-icon v-if="file.FileExtension === '.pdf'"  alt="PDF document">mdi-file-pdf</v-icon>
                                </div>
                              </v-col>
                              <v-col cols="8" sm="8" md="6" class="details-container">
                              <div class="d-flex justify-space-between">
                                <div class="text-h1 ellipsis" :data-fileName="file.FileName">{{file.FileName}}
                                </div>                               
                              </div>
                                 <div class="d-flex justify-space-between mt-2">
                                <div class="text-secondary" :data-Created="file.CreatedOn">{{ formatDate(file.CreatedOn, 'DD MMM YYYY') }}
                                </div>                               
                              </div>
                            </v-col>
                            <v-spacer></v-spacer>
                            <v-col cols="2" sm="2" md="1" class="action-container pt-3">
                              <v-radio 
                              :value="file"
                              hide-details
                            ></v-radio>                           
                                <!-- <v-btn class="action-remove-document" icon small @click="removeDocument(file.CVId)">
                                  <v-icon>mdi-close</v-icon>
                                </v-btn> -->
                              </v-col>
                            </v-row>
                        </v-list-item-content>
                      </v-list-item>
                    </template>
              </v-radio-group>
          </v-list>
        </div>

          <v-footer fixed class="pa-2" v-if="selectedCV">
  
            <v-btn class="action-uploaded-cv" depressed block color="primary" @click="apply">{{ $t("jobs.uploadCVContinueButtonTitle") }}</v-btn>
        
        </v-footer>

        
</v-container>
    </div>
