export const mobileBackButtonDialogComponentMixin = {
    methods: {
        notifyDialogStateViaEventBus(open) {
            if (open) {
                this.$emit('dialogOpen');
                this.$on("closeAllDialogs", () => {
                    this.closeAllDialogs();
                });
            } else {
                this.$emit('dialogClosed');
                this.$off("closeAllDialogs");
            }
        }
    },
};