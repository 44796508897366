<template>
    <div>
        <v-form ref="form">
          <div style="padding-top: 16px; padding-left: 16px;">
            <div v-if="autosave" class="h1-large pb-3"> {{ $t("timesheets.autoTimesheetHeaderTitle") }}</div>
            <div v-if="!autosave && !manualEntryOnly" class="h1-large pb-3"> {{ $t("timesheets.manualTimesheetHeaderTitle") }}</div>
            <div v-if="!autosave && manualEntryOnly" class="h1-large pb-3"> {{ $t("timesheets.manualEntryOnlyTimesheetHeaderTitle") }}</div>
          </div>
          <v-divider class="divider"></v-divider>
          <v-container style="padding-top: 0px;">    
            <v-tabs v-show="!manualEntryOnly" v-model="selectedTab">
              <v-tab  data-id="autosaveTab" class="no-transition"  @click="autosave = true">{{ $t("timesheets.autoSaveTabTitle") }}</v-tab>
              <v-tab  data-id="manualTab" class="no-transition"  @click="autosave = false">{{ $t("timesheets.manualTabTitle") }}</v-tab>
            </v-tabs>
            <v-divider v-show="!manualEntryOnly"></v-divider>
            <v-row>
              <div class="v-messages error--text" role="alert" v-if="errors.length > 0">
                  <p>{{ $t("timesheets.timesheetErrorNote") }}</p>
                  <li v-for="error in errors" :key="error.message">
                    {{error.message}}
                  </li>
              </div>
            </v-row>
            <v-row class="d-flex justify-space-between" align="center" dense v-if="0 > 1">
              <b>{{ $t("timesheets.manualTimesheetAutoSave") }}</b>
              <v-switch dense v-model="autosave" :disabled="localJobShift.ClockState.events == 0"></v-switch>
            </v-row>
            <v-row class="body-1" style="line-height: 5px; padding-top: 16px;">
              <v-col :data-shiftDate="localJobShift.ShiftDate">
                {{ formatDateFromNow(localJobShift.ShiftDate) }}
              </v-col>
              <v-col v-if="0 > 1">
                <div class="shift-description d-flex justify-space-between">
                  <div v-if="localJobShift.ShiftTimeType == 'MORNING'"  class="text-caption shift-indicator morning-shift"><img src="@/assets/img/jobs/shifts/morning-shift.png"/><span :data-shifttimetype="localJobShift.ShiftTimeType">Morning</span></div>
                  <div v-if="localJobShift.ShiftTimeType == 'AFTERNOON'" class="text-caption  shift-indicator afternoon-shift "><img src="@/assets/img/jobs/shifts/afternoon-shift.png"/><span :data-shifttimetype="localJobShift.ShiftTimeType">Afternoon</span></div>
                  <div v-if="localJobShift.ShiftTimeType == 'EVENING'" class="text-caption  shift-indicator evening-shift"><img src="@/assets/img/jobs/shifts/evening-shift.png"/><span :data-shifttimetype="localJobShift.ShiftTimeType">Evening</span></div>
                  <div v-if="localJobShift.ShiftTimeType == 'NIGHT'" class="text-caption  shift-indicator night-shift "><img src="@/assets/img/jobs/shifts/night-shift.png"/><span :data-shifttimetype="localJobShift.ShiftTimeType">Night</span></div>
                </div>
              </v-col>
            </v-row>
            <v-row class="d-flex justify-space-between worked-text" v-if="autosave" style="line-height: 5px;">
              <v-col style="font-size: 12px !important;"><b class="worked-text">{{ $t("timesheets.autoTimesheetWorkedTime")}}</b> {{ "(" + localJobShift.PayRate + ")" }}</v-col>
              <v-col class="workedTime text-right" style="max-width: 30% !important;">
                <b>{{ workedTimeHours }}h {{ workedTimeMinutes }}m</b>
              </v-col>
            </v-row>
            <v-row class="d-flex justify-space-between" v-if="autosave" style="line-height: 5px;">
              <v-col>{{ $t("timesheets.autoTimesheetOverTime") }}</v-col>
              <v-col class="text-right">
                {{ overTimeHours }}h
                {{ overTimeMinutes }}m
              </v-col>
            </v-row>
            <v-row v-if="autosave">
              <v-container>
                <CheckinSummaryCard 
                  :breaks="breaks" 
                  :checkInTime="checkIn" 
                  :checkOutTime="checkOut"
                  :shiftStartTime="localJobShift.ShiftStartTime"
                  :shiftEndTime="localJobShift.ShiftEndTime"
                  :daySpan="daySpan"
                  :groupBreaks="true"
                ></CheckinSummaryCard> 
              </v-container>
            </v-row>
            <v-row class="d-flex justify-space-between" v-if="!autosave" style="line-height: 5px;">
              <v-col style="font-size: 12px !important;"><b style="font-size: 20px !important;">{{ $t("timesheets.manualTimesheetDayRate")}}</b> {{ "(" + localJobShift.PayRate + ")" }}</v-col>
            </v-row>
            <v-row class="d-flex" style="font-size: 14px !important;" v-if="!autosave">
              <v-col class="d-flex flex-column" style="max-width: 50% !important;">
                <b>{{ $t("timesheets.manualTimesheetCheckInTime") }}</b>
                <vue-timepicker id="checkIn" :disabled="clockInOnly" v-model="checkIn" :minute-interval="1" ></vue-timepicker>
              </v-col>
              <v-col class="d-flex flex-column" style="max-width: 50% !important;">
                <b>{{ $t("timesheets.manualTimesheetCheckOutTime") }}</b>
                <vue-timepicker id="checkOut" v-model="checkOut" :minute-interval="1"></vue-timepicker>
              </v-col>
            </v-row >
            <v-row class="d-flex" style="font-size: 14px !important;" v-if="!autosave">
              <v-col class="d-flex flex-column" style="max-width: 50% !important;">
                <b>{{ $t("timesheets.manualTimesheetTotalBreakTime") }}</b>
                <vue-timepicker id="break" :disabled="clockInOnly" v-model="breakDuration" :minute-interval="1"></vue-timepicker>
              </v-col>
            </v-row>
            <v-row class="d-flex justify-space-around mb-6">
              <v-btn class="grey-button action-cancel" style="width: 150px;" depressed @click="cancel">
                  {{ $t("cancel") }}
              </v-btn>
              <v-btn class="primary action-create-manual-timesheet" depressed @click="submitTimesheet" :disabled="createTimesheetDisabled">
                  {{ $t("timesheets.manualTimesheetCreateTimesheetButton") }}
              </v-btn>
            </v-row>
          </v-container>
        </v-form>
        
        <Alert 
            :isVisible="message"
            :alertType="alertType"
            :isConfirmation="isConfirmation"
            @close="closeAlert">
            <template v-slot:message>{{messageText}}</template>
            <template v-slot:isConfirmation>{{isConfirmation}}</template>
          </Alert>
    </div>
</template>
<script lang="ts">
  import ViewMode from "./CheckOutView"
  export default ViewMode;
</script>
<style scoped>


.worked-text 
  {
    font-size: 20px !important;
  }
@media screen and (max-width: 360px) {
  .worked-text 
  {
    font-size: 14px !important;
  }
}

  .workedTime {
    color: #68B590;
  }
  
  li{
    margin-left: 15px !important;
  }

  ::v-deep .vue__time-picker .dropdown ul li:not([disabled]).active{
    background-color: #555b70 !important;
  }
 
  </style>