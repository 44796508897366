<template>
    <div>
        <v-container>  
          <p class="text-h1">{{ $t("timesheets.checkoutHeaderTitle") }}</p>
          <v-divider style="padding-bottom: 20px;"></v-divider>
          <p>{{ $t("timesheets.checkoutBodyText") }}</p>
          <v-spacer></v-spacer>
          <v-row class="d-flex justify-space-around mb-6">
            <v-btn class="grey-button action-cancel" style="width: 150px;" depressed @click="cancelCheckOut">
                {{ $t("cancel") }}
            </v-btn>
            <v-btn class="primary action-checkout-confirmation" style="width: 150px;" depressed @click="confirmCheckOut">
                {{ $t("timesheets.checkoutCheckoutButtonText") }}
            </v-btn>
          </v-row>            
        </v-container>
    </div>
</template>
<script lang="ts">
  import ViewMode from "./CheckOutConfirmation"
  export default ViewMode;
</script>