import moment from 'moment';

export class Global{
    formatDate(date: Date){
        return moment(date);
    }

    formatDateSpecific(date: Date, format: string){
        return moment(date).format(format);
    }

    formatDateToTime(date){
        return moment(date).format("HH:mm");
    }

    formatDateToTimeUTC(date)
    {
        return moment.utc(date).format("HH:mm");
    }

    formatDateFromNow(date){
       
        if(moment().isSame(date,'d')){
            return `Today ${ moment(date).format("DD/MM/yyyy") }`;
        }

        if(moment().subtract(1,'days').isSame(date,'d')){
            return `Yesterday ${ moment(date).format("DD/MM/yyyy") }`;
        }

        if(moment().add(1,'days').isSame(date,'d')){
            return `Tomorrow ${ moment(date).format("DD/MM/yyyy") }`;
        }

        return moment(date).format("DD/MM/yyyy");
    }

    formatDateFromNowLiteralOrDate(date)
    {
        if(moment().isSame(date,'d')){
            return 'Today';
        }

        if(moment().subtract(1,'days').isSame(date,'d')){
            return 'Yesterday';
        }

        if(moment().add(1,'days').isSame(date,'d')){
            return 'Tomorrow';
        }

        return moment(date).format("DD/MM/yyyy");
    }


    getDurationBetweenDates(start: Date, end: Date){
        return moment(this.formatDate(end).diff(this.formatDate(start))).utc();
    }

    getTotalHoursBetween(start: Date, end: Date){

      const s = moment(start);
      const e = moment(end);

      const totaHours = e.diff(s,'hours');
      return totaHours;
    }

    getTotalMinutesBetween(start: Date, end: Date){

        const s = moment(start);
        const e = moment(end);
  
        const totalMinutes = e.diff(s,'minutes');
        return totalMinutes;
      }

    formatTimeFromNow(date){
        return moment(date).fromNow(true);
    }


    setTimeOnDate(date,timeString){
        let result = new Date();
        let shiftStart = moment(date).utc(true);
        shiftStart = shiftStart.startOf('day');
        
        shiftStart = shiftStart.add(timeString);
        result =  new Date(shiftStart.years(),shiftStart.months(),shiftStart.date(),shiftStart.hours(),shiftStart.minutes(),shiftStart.seconds());
        return result;
     }

}

export default new Global();