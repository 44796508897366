
<div>
    <v-dialog v-model="showDialog" fullscreen persistent>
      <v-app-bar color="white" class="top-toolbar pt-0"> 
          <v-btn class="action-back back-button navigation-button" @click="close()" text><v-icon>mdi-chevron-left</v-icon>Back</v-btn>
      </v-app-bar>  
      <v-container class="div-background pt-0 mt-4">
    
       <!-- <div v-if="currentEvent && currentEvent.type">
          Current Clock State : {{currentClockState}}
        <br/>
          Current Event : {{currentEvent.type.uniqueId}}
        <br/>
      </div>
          Current CssClasss  : {{currentStateClass}} -->
        <div class="shift-description d-flex justify-space-between" v-if="0 > 1">
         
          <div v-if="shift.ShiftTimeType == 'MORNING'"  class="text-caption shift-indicator morning-shift"><img src="@/assets/img/jobs/shifts/morning-shift.png"/><span :data-shifttimetype="shift.ShiftTimeType"> Morning</span></div>
          <div v-if="shift.ShiftTimeType == 'AFTERNOON'" class="text-caption  shift-indicator afternoon-shift "><img src="@/assets/img/jobs/shifts/afternoon-shift.png"/><span :data-shifttimetype="shift.ShiftTimeType">Afternoon</span></div>
          <div v-if="shift.ShiftTimeType == 'EVENING'" class="text-caption  shift-indicator evening-shift"><img src="@/assets/img/jobs/shifts/evening-shift.png"/><span :data-shifttimetype="shift.ShiftTimeType">Evening</span></div>
          <div v-if="shift.ShiftTimeType == 'NIGHT'" class="text-caption  shift-indicator night-shift "><img src="@/assets/img/jobs/shifts/night-shift.png"/><span :data-shifttimetype="shift.ShiftTimeType">Night</span></div>
        </div>
        <div class="d-flex justify-center pb-3">
          <img block v-if="!shift.HasLogo"  alt="" src="@/assets/logo.png" height="36" width="36" class="rounded img-round logo-image">
          <img block v-if="logoUrl !== ''"  alt="" :src="logoUrl" height="36" width="36" class="rounded img-round logo-image">
        </div>
        <div class="text-h1 d-flex  justify-center">{{shift.JobTitle}}</div>
        <div class="text-subtitle-2 d-flex  companyName justify-center pb-2">{{shift.Company}}</div>
        <div class="centered fs-14 text-color-light-grey fw-800 pb-1">{{ shiftDate }}</div>
        <!--Clock-->
        <div class="d-flex justify-center">
        <div class="circle-outer"> 
          <div class="circle-outer-two">
            <div class="circle-inner " :class="currentStateClass">
              <div class="circle-contents"> 
              
                <div class="d-flex justify-center time">
                    <div>
                      <div >{{currentDuration.hours}}</div>
                      <div class="time-description">Hours</div>
                    </div>

                    <div >
                      <div>:</div>
                    </div>

                    <div>
                      <div>{{currentDuration.minutes}}<span class="time-seconds" >.{{currentDuration.seconds}}</span></div> 
                      <div class="time-description">Minutes</div>
                      
                    </div>
               </div>
              </div>
            </div>
          </div>
        </div>
      </div>
       
      <!--Buttons-->
    <div class="d-flex">
      <div class="pl-3">
            <div class="pb-3" v-if="!historicJobShift">
              <v-btn id="btnPause" :class="currentStateClass" fab large :color="currentClockState == 'paused' ? '#ea5876' : 'white'" @click="pauseShift" v-if="currentClockState  == 'active' || currentClockState  == 'paused'" :disabled="currentClockState  == 'stopped'">
                <v-img src="@/assets/img/timesheets/timelogging/PauseFilled.png" height="28" width="28" v-if="currentClockState == 'paused'"></v-img>
                <v-img src="@/assets/img/timesheets/timelogging/Pause.png" height="28" width="28" v-else></v-img>
              </v-btn>
             
                <v-btn id="btnCheckIn" class="action-checkIn pa-2 active"  fab  large :color="playButtonColor" @click="checkIntoShift" v-if="currentClockState  == 'notStarted'">
                  <v-icon color="black">mdi-play</v-icon>
                </v-btn>
          
            </div>
            
            <div class="text-center text-subtitle-2">
              <p v-if="currentClockState  == 'paused'">{{ $t("logTimeDialog.resume") }}</p>
              <p  v-if="currentClockState  == 'active'">{{ $t("logTimeDialog.pause") }}</p>
              
            </div>
      </div>

        <div class="ml-auto pr-3" v-if="currentClockState != 'submitted' && !getCheckOutEvent()">
            <div class="pb-3">
            <v-btn id="btnCheckOut"  class="action-checkOut ml-auto" fab large color="white" @click="checkOutOfShift" :disabled="currentClockState  == 'submitted'">
              <v-img src="@/assets/img/timesheets/timelogging/Stop.png" height="28" width="28"></v-img>
            </v-btn>
          </div>
          <div class="text-center text-subtitle-2">
            {{ $t("logTimeDialog.checkOut") }}
          </div>
        </div>
      </div>

      <!-- Checkin Summary -->
      <CheckinSummaryCard 
        :breaks="breaks" 
        :checkInTime="checkInTime" 
        :checkOutTime="checkOutTime"
        :shiftStartTime="jobShiftLocal.ShiftStartTime"
        :shiftEndTime="jobShiftLocal.ShiftEndTime"
        :daySpan="daySpan">
      </CheckinSummaryCard> 
      </v-container>
      <Alert 
        :isVisible="message"
        :alertType="alertType"
        @close="closeAlert"
      >
        <template v-slot:message>{{messageText}}</template>
      </Alert>
    </v-dialog>
</div>
