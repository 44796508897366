
<div>
  <div class="rounded-corners card-border pa-3">
    <div class="d-flex justify-space-between " v-if="0 > 1">
      <div>
        <div class="h1-large text-center">
          {{StartTime}}
        </div>
        <div class="text-subtitle-1 text-center">
          {{ $t("logTimeDialog.shiftStartTime") }}
        </div>
      </div>
      <div>
        <div class="h1-large text-center">
          {{EndTime}}
        </div>
        <div class="text-subtitle-1 text-center">
          {{ $t("logTimeDialog.shiftEndTime") }}
        </div>
      </div>
    </div>

    <div class="d-flex justify-space-between" v-if="checkInTime != ''">

        <div>
            <div class="h1-large text-center">
              {{checkInTime}}
            </div>
            <div class="text-subtitle-1 text-center">
            
              {{ $t("logTimeDialog.checkIn") }} {{ $t("logTimeDialog.time") }}
            </div>
        </div>
        <div>
          <img id="arrowImage" src='data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAALUAAABoCAYAAAC387FYAAAABHNCSVQICAgIfAhkiAAABUxJREFUeF7t3L1rNFUUx/F7sqKFjaWk0c5/wkbBF9BCi4iFYCMoSpLBpBBU2EItLOLcxUoFQZsHRLCwUBB9Ou3U0j/BalNYCHm5MpKFNW6yM3NfZs6Zb9q9c+ae3/lwmSW7K46/ySYwn8/vOT09/SyE8KP3/gsrQYiVRuijWwJ7e3uz3d3dr0Tk+RBCEJGX67r+sluVca4G9TjnknVX66BXN7IEG9RZ+Yyv+CbQ1mCDenzusu3oNtBrN728vLx8ZbFYfJ5tI5kLgzpzwGMpP5/P710ul3eaZ+gWe1ING9QtJqx9yRXob0XkiQ69qIUN6g5T1rp0f3//vp2dnW9E5OmOPaiEDeqOU9a6fEqwQa1VaY99TwU2qHvg0HzJFGCDWrPQnnu3DhvUPWFov8wybFBr1xmxf6uwQR2BwsKlFmGD2oLMyB5iYIcQXvDefx25haSXgzppnHqLRcA+DyG8OCbYoNbrMPnOrcAGdXIaugtagA1q3Qaz7F47bFBnYaG/qGbYoNbvL1sHWmGDOhsJG4U1wga1DXtZu9AGG9RZOdgprgk2qO24y96JFtigzk7B1g00wAa1LXNFuhk7bFAXYWDvJmOGDWp73op1NFbYoC5GwOaNxggb1DatFe3qCvYPIvJoxxtn+dgqqDtOgeWbEzg+Pr7/7OzsuzHABjVKkyUwFtigTjZSCjUJRMJ+1nv/fWySoI5NkOv/l0Bf2CGEv51zz8XCBjUosyQwJGxQZxkpRWMeRWJPbFDjL2sCQ5zYoM46UooPcWKDGndFEih5YoO6yEi5SckTG9R4K5pAiRMb1EVHys1KnNigxtkgCUSe2C/d9tt9oB5kpNw08sQOzrnae//mpiRBja9BE4g4sYOIfFTX9dH1BqSqqkY9fySgLoEQQmP3fe/9u+ubB7W6UbLh9QQ2wQY1RtQncAX7He/9B00zoFY/UhpoEliHDWpMmElgBRvUZkZKIw1qEXkL1FgwkcAKdF3XH4LaxEin3cTVY8er3vtPeaM4bQsmur8OGtQmxjrdJjaB/hf1dCOh8zEkcHh4+Ihz7q6IPNhlPzeBBnWXFFmbPIEI0BchhNcXi8UnmzbFSZ18VBRsk0AE6L9E5Mm6rn++6T6gbjMB1iRNICdoHj+SjopibRLIDRrUbabAmmQJlAAN6mTjotC2BEqBBvW2SfB6kgRKggZ1kpFR5LYESoMGNR6zJjAEaFBnHem0iw8FGtTTdpet+yFBgzrbWKdbeGjQoJ6uvSydHx0dPXR+fv5Ljw8nbf3Xd5cN82/yLmmx9sYEGtAXFxd3nXMPd4kphJAUNCd1l/RZqwI0qIEancCYTuhVMzx+RI91ugUiQC9F5JnbPj4akyqoY9Kb8LUxoGez2WMnJye/54oP1LmSNVx3zKB5pjYML1drYwcN6lyTN1pXA2hQG8WXoy0toEGdY/oGa2oCDWqDAFO3pA00qFMLMFZPI2hQG0OYsh2toEGdUoGhWppBg9oQxFStaAcN6lQSjNSxABrURjCmaMMKaFCn0GCghiXQoDYAMrYFa6BBHStC+fUWQYNaOcqY7VsFDeoYFYqvtQwa1Iph9t26ddCg7itD6XVTAA1qpTj7bHsqoEHdR4fCa6qqesA592uPH5ppvvX9eF3Xv2lqmy/eappWxF6rqnrbOfde2xIhhGXub3233UvXdaDumpji9VVVveGc+3hbC5pB8/ixbboGX98GWztoUBtE26alm2BbAA3qNgKMrrkO2wpoUBsF27atFewQwp+z2eypnD8F1nZPKdbxRjFFioprHBwcvCYiP3nv/1Dcxn+2/g8TMSYy8t3IjwAAAABJRU5ErkJggg=='>
        </div>
        <div>
          <div v-if="checkOutTime != ''">
            <div class="h1-large text-center d-flex justify-center">
              {{checkOutTime}} <div class="pl-1" v-if="daySpan && daySpan > 0">+{{daySpan}}d</div>
            </div>
            <div class="text-subtitle-1">
              {{ $t("logTimeDialog.checkOut") }} {{ $t("logTimeDialog.time") }}
            </div>
           
          </div>
          <div>

          </div>
        </div>

    </div>
    <div class="pt-3">
        <v-progress-linear
       
        color="#555B70"
        rounded
        style="height:6px"
      ></v-progress-linear>
     </div>

     <div class="d-flex justify-space-between pt-3 flex-wrap" v-if="!groupBreaksLocal">

          <div class="d-flex" v-for="(bre,index) in breaks" v-bind:key="index">
            <div class="ma-auto">
              <v-icon color="black">mdi-coffee</v-icon>
            </div>
                <div class="pl-1">
                  <div class="text-h1 text-center">
                    {{bre.duration}}
                  </div>
                  <div class="text-subtitle-1 text-center">
                  
                    {{ $t("logTimeDialog.breaks") }} 
                  </div>
              </div>
          </div>
          
  </div>

  <div v-if="groupBreaksLocal">
    <div class="d-flex justify-center align-center ma-auto groupBreak">
      <div class="pa-1"><b>{{breaks.length}}</b></div>
      <div class="pa-1"><v-img src="@/assets/img/timesheets/timelogging/coffee.png" height="15" width="15"></v-img></div>
      <div class="text-center pa-1">
        <div><b>{{totalBreakDuration}}</b></div>
        <div class="text-subtitle-1 ">Breaks</div>
      </div>
    </div>
  </div>


  </div>

</div>
