import { Vue, Component, PropSync } from "vue-property-decorator";
import QRCodeScan from "../components/QRCodeScan.vue"
import {mobileBackButtonDialogComponentMixin} from '../../../../mixins/mobileDialogBackButtonHandler';
@Component({
  name: "QRMessage",
  components:{QRCodeScan},
  mixins:[mobileBackButtonDialogComponentMixin],
  watch: {
    showQRScan: 'notifyDialogStateViaEventBus'
  }
})
export default class QRScanMessage extends Vue {
   
    showQRScan = false;

    constructor() {
        super();

    }
    close()
    {
        this.showQRScan = false;
        this.$emit("close");
    }

    skip()
    {
        this.showQRScan = false;
        this.$emit("skip");
    }

    closeQR()
    {
        this.showQRScan = false;
        this.$emit("close")
    }

    result(value)
    {
        this.showQRScan = false;
        this.$emit("result",value)
    }

    beforeRouteUpdate(to, from, next) {
        if (this.showQRScan) {
            this.$emit('closeAllDialogs');
            next(false);
        } else {
            next();
        }
    }

    beforeRouteLeave(to, from, next) {
        if (this.showQRScan) {
            this.$emit('closeAllDialogs');
            next(false);
        } else {
            next();
        }
    }

    closeAllDialogs(){
        this.showQRScan = false;
    }
}