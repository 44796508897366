<template>
  <div class="timesheetToolbar">
    <!-- <v-app-bar dense app color="white" class="top-toolbar pt-0" extension-height="selectedView = 'checkIn'? 170px: 130px"> -->
      <v-app-bar dense app color="white" class="top-toolbar pt-0" :extension-height="extensionHeight">
      <v-tabs class="job-tabs" >
        <v-tab data-id="checkIn" class="no-transition"  ref="btnCheckIn"  @click="selectedView = 'checkIn'">{{ $t("timesheets.checkIn") }}</v-tab>
        <v-tab data-id="timesheet" class="no-transition"  ref="btnTimesheet" @click="selectedView = 'timesheet'">{{ $t("timesheets.timesheet") }}</v-tab>
        <v-tab data-id="expense" class="no-transition"  ref="btnExpense" @click="selectedView = 'expense'">{{ $t("timesheets.expense") }}</v-tab>
      </v-tabs>
      <v-spacer/>
      <!-- <v-btn icon v-if="selectedView === 'checkIn'" @click="logTimeDialogVisible = true" class="action-magnify">
        <v-icon>mdi-magnify</v-icon>
      </v-btn>
      <v-btn icon v-if="selectedView === 'checkIn'" @click="manualTimesheet = !manualTimesheet" class="action-addTimesheet">
        <v-icon>mdi-plus</v-icon>
      </v-btn> -->

      <template v-slot:extension>
        <div v-if="earlyPay == true" style="width:100%;background-color:white;">
        <EarlyPay  @close="closeEarlyPay" :isCloseable="true"></EarlyPay>
        </div>
        <div v-if="selectedView == 'checkIn'" class="pt-4 bottom-seperator" style="width:100%;background-color:white">
            <WeekDatePicker  :disabled="isLoading" :initialvalue="initialDate" @dateChanged="selecteDateChanged" @dateRangeChanged="selecteDateRangeChanged" ></WeekDatePicker>
        </div>

        <div v-if="selectedView == 'timesheet'" style="width:100%;background-color:transparent">
            <TimesheetDateRangePicker :initialvalue="initialDateTimesheet" @datesChanged="timesheetDateRangeChanged" :selectedView="selectedView"></TimesheetDateRangePicker>
        </div>

        <div v-if="selectedView == 'expense'" style="width:100%;background-color:transparent">
            <TimesheetDateRangePicker :initialvalue="initialDateExpenses" @datesChanged="expenseDateRangeChanged" :selectedView="selectedView"></TimesheetDateRangePicker>
        </div>
      </template>
    </v-app-bar>
  <v-container>
    <div>
       <ConnectionLostCardView :isVisible="showNetworkError" @close="retryClicked" />
      <checkIn-view 
       @scrolledToDate="scrolledToDate"
       :isLoading="isLoading"
        :selectedDate="selectedDate"
        v-if="selectedView == 'checkIn'"
        :jobShifts="shifts"
        :clockState="selectedJobShift.ClockState"
        :startDate="jobShiftStart"
        :endDate="jobShiftEnd"
        @refreshJobShifts="refreshJobShiftData"
        @checkIn="checkIntoShift"
        @checkOutConfirmation="checkOutConfirmation"
        @batchTimesheet="batchTimesheet"></checkIn-view>
      </div>
      <div>
      <timesheet-view :dateRange="timsheetDateRange" v-if="selectedView == 'timesheet'"
       @close="back()" :closeTopDialog="closeTopDialog" :selectedTimesheet="selectedTimesheet" @loaded="timesheetloaded()"></timesheet-view>
      </div>
       <div>
      <expense-view :dateRange="expenseDateRange" v-if="selectedView == 'expense'"
       @close="back()" :closeTopDialog="closeTopDialog"></expense-view>
      </div>
      <LogTimeDialogCopy @close="logTimeDialogVisible=false"
              v-bind:visible="logTimeDialogVisible"
              v-bind:jobShift="selectedJobShift"
              :clockState="selectedJobShift.ClockState"
              :qrCode="qrCodeResult"
              @closedialog="closeLogTimeDialog"
              @checkOutShift="checkOutOfShift"              
              @checkInShift="checkedIntoShift"
              @shiftpause="pauseShift"
              @shiftUnpause="unpauseShift">
              <template v-slot:Timesheet></template>
        </LogTimeDialogCopy>
      <v-bottom-sheet v-model="manualTimesheet" persistent >
        <v-sheet full-width class="rounded-corners-top">
          <CheckOutView
            v-bind:jobShift="selectedJobShift"
            @cancel="cancelCheckOut"
            :checkoutConfirmation="manualTimesheet"
            @submit="timesheetSubmitted">
          </CheckOutView>
        </v-sheet>
      </v-bottom-sheet>
      <v-bottom-sheet v-model="checkoutConfirmation" >
        <v-sheet full-width class="rounded-corners-top">
          <v-container>
            <CheckOutConfirmation
              v-bind:jobShift="selectedJobShift"
              @confirm="checkOutConfirmed"
              @cancel="checkOutConfirmation(false)">
            </CheckOutConfirmation>
          </v-container>
        </v-sheet>
      </v-bottom-sheet>
    <v-bottom-sheet v-model="onboarding" >
      <v-sheet full-width class="rounded-corners-top">
        <TimesheetOnboarding @closeOnboarding="hideOnboarding" @completedOnboarding="completeOnboarding"></TimesheetOnboarding>  
      </v-sheet>
    </v-bottom-sheet>
    <v-dialog v-model="showQRMessage" max-width="500px" fullscreen persistent >
      <QRScanMessage  @close="close" @result="result" @skip="skip"></QRScanMessage>  
    </v-dialog>
    <Alert 
      :isVisible="message"
      :alertType="alertType"
      @close="closeAlert"
    >
      <template v-slot:message>{{messageText}}</template>
    </Alert>

  </v-container>

  </div>
</template>
<script lang="ts">
  import ViewMode from "./Timesheets"
  export default ViewMode;
</script>

<style scoped>
  .fixed-bar {
    position: absolute;
    position: -webkit-sticky; /* for Safari */
    top: 6em;
    z-index: 2;
  }
  
  .timesheetToolbar >>> .top-toolbar > .v-toolbar__extension
  {
    display: unset !important;
  }
</style>

<style>
  .v-slide-group__prev {
    background-color: red !important;
  display: none !important;
  }
  
  </style>