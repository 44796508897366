<template>
 <div v-if="show">
       <div  class="d-flex flex-column pt-2">
         <div class="text-center">
         <img src="@/assets/img/icons/intruption.png" height="80px" width="80px"/>
         </div>
         <v-row justify="center" class="pt-1">
         <b class="h1-large">{{ $t("connectionLost.connectionLostTitle") }}</b>
         </v-row>
       </div>
       
       <v-spacer></v-spacer>
       
       <v-row justify="center" class="pl-4 pr-4 centered">
          <p class="nunito-reg16">  {{ $t("connectionLost.connectionLostMessage") }}</p>
       </v-row>
       <v-row justify="center" style="height:40px" class="ml-0 mr-0">
       <v-btn class="primary retry-btn action-retry centered"   text  @click="Retry()">
         <slot>{{ $t("connectionLost.connectionLostButtonText") }}</slot>
       </v-btn>
       </v-row>
    </div>
</template>


<script lang="ts">
      import ViewModel from "./ConnectionLostCardView"
      export default ViewModel;
  </script>
<style lang="scss" scoped>

.retry-btn
{
min-width: 232px !important;
font-size:16px;

}
.nunito-reg16
{
  font-family: 'Nunito' !important;
  font-size: 16px important;
  color: #6E6E6E;
}
  

</style>