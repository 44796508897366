

<div class="pt-0">
<v-app-bar dense fixed color="white" class="top-toolbar pt-0 ">
  <v-btn
    class="back-button navigation-button action-back"
    @click="back()"
    text
    ><v-icon>mdi-chevron-left</v-icon>{{ $t("back") }}</v-btn
  >
</v-app-bar>
<v-container class="mt-12">
 
  <v-row cols="12">
    <v-card class="elevation-0" height="148" color="#E8EAF1" width="100%">
      <v-card-text class="pt-1" >
        <v-row >
          <v-col cols="6">
            <div class="pl-4 fs-14 color-pd-1">{{ $t("payslip.netPay") }}</div>
            <p class="pl-4  font-bold-30 pt-5">{{NetPay}}</p>
          </v-col>
          <v-col cols="6">
            <div style="float:right" class="pr-2 fs-14 color-pd-1">{{payDateText}}</div>
          </v-col>    
        </v-row> 
        
      </v-card-text>  
    </v-card>
  </v-row>  
 
  <v-row justify="center" class="mt-n14">
     <v-col cols="6">
       <v-card class="rounded-corners card-border flat elevation-1" height="88px" >
          <v-card-text>
            <div class="fs-14 color-pd-2">{{ $t("payslip.grossPay") }}</div>
            <p class="h1-extra-large small-screen color-pd-3 pt-2">£{{data.grossPay.toFixed(2)}}</p>
          </v-card-text>  
        </v-card>  
     </v-col>  
     <v-col cols="6">
       <v-card class="rounded-corners card-border flat elevation-1" height="88px" >
          <v-card-text>
            <div class="fs-14 color-pd-2">{{ $t("payslip.deductions") }}</div>
            <p class="h1-extra-large small-screen color-pd-3 pt-2">£{{data.deductions.toFixed(2)}}</p>
          </v-card-text>  
        </v-card>  
     </v-col>  
   </v-row>  

    <div v-for="(group,index) in this.data.clients" :key="index" class="pt-2">
      <v-card class="mb-2 pl-2 pt-2 pb-2 rounded-corners card-border flat avail-card" outlined elevation-0 >
        <v-row no-gutters class="pl-1 pt-1">
            
            <v-col cols="10" sm="10" md="10" class="details-container pl-1 pt-1">
              <div class="d-flex justify-space-between">
                <div class="text-h1" data-jobdescription="group.jobCategoryDescription">{{group.jobCategoryDescription}}</div>
              </div>
              <div class="text-subtitle-2 grey--text  pt-1 fs-14 companyName fw-600 avail-spacer" :data-company="group.clientName">{{group.clientName}}</div>
              <div class="text-subtitle-2 grey--text text--darken-2 pt-1 work-address" :data-location="group.address">{{group.address}}</div>
            
            </v-col>
            <v-col cols="2" class="v-line ">
              <div class="pt-3" v-if="group.totalHours >= 0 && group.totalDays == 0">
              <div class="fs-14  font-weight-bold text-center" :data-location="group.totalHours">{{group.totalHours}}</div>
              <div class="fs-10 olor-pd-3 text-center" >{{ $t("payslip.hours") }}</div>
              </div>
              <div v-if="group.totalDays > 0" class="pt-3">
              <div class="fs-14 font-weight-bold text-center" :data-location="group.totalHours">{{group.totalDays}}</div>
              <div class="fs-10 olor-pd-3 pt-1 text-center" >{{ $t("payslip.days") }}</div>
              </div>
            </v-col>  
        </v-row>
       
      </v-card> 
        

    </div>            
</v-container>
</div>      
