import { PhoneNumberMatcher } from 'libphonenumber-js';
import HttpClient from "../utils/httpClient";
import BaseService from './baseService';
import store from '@/store'
import axios, { AxiosInstance,AxiosResponse, AxiosError } from "axios";

export class VerifyEmailResponse {
    success: boolean;
    alreadyConfirmed: boolean;
    errorMessage: string;
    redirectUri: string;
}

export  class UserLoginService extends BaseService {
    baseUrl: string;
    productCode = "MOB";
    
    allowedToLogin(): Promise<any> {
        
        return this.httpClient(this.productCode).post("/Login", null).then(res => {
                store.commit.AppState.UpdateHasLoggedIn(true);
                return  true;
        }).catch((e)=> {
            if(e.isNetworkError){
                return store.getters.AppState.hasLoggedIn;
            }
            return false;
        });
        
    }

    verifyEmailAddress(id: string, verificationCode: string): Promise<VerifyEmailResponse> {

        return new Promise<VerifyEmailResponse>((resolve,reject)=>
        {
            return this.httpClient(this.productCode).postAnnonymous("/RegisterConfirmation", {id:id,confirmationKey:verificationCode}).then(res => {
                resolve(res.data);
             }).catch((e)=> {
                reject(e);
             });
            }
        );
    }


}

export default new UserLoginService();

