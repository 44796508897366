<template>
  <div >
    
    <JobResultsSummary :keywords="jobSearchRequest.Keywords" :location="jobSearchRequest.LocationText" :totalRecords="totalResults"></JobResultsSummary>
    <JobNoResults v-if="totalResults ==0 && !isLoading" @retrySearch="retrySearch"></JobNoResults>

    <v-list dense  class="mt-n4">
      <div v-if="jobSearchResult">
      <template  v-for="job in jobSearchResult.Jobs">              
        <v-list-item  :data-jobid="job.JobId" class="rounded-corners card-border job-list-item" :key="job.JobId" :src="job.JobId" @click="showJob(job)">

            <v-list-item-content class="pb-2">

              <JobSummaryCard :job="job"
                @setShortlisted="setShortlisted"
                @removeShortlisted="removeShortlisted">
              </JobSummaryCard>
              
            </v-list-item-content>
        </v-list-item>
      </template>
    </div>
      <v-list-item class="mt-5 ">

           <v-list-item-content >
            <LoadingIndicator :isVisible="isLoading"></LoadingIndicator>
           </v-list-item-content>
          
       </v-list-item>

    </v-list> 
     <v-row justify="center">
      <v-col cols="12" md="8"> 
       
        
     
      </v-col>
    </v-row> 

    <v-card intersect="infiniteScrolling"></v-card>
</div>

</template>

  <script lang="ts">
    import ViewModel from './JobListView'  
    export default ViewModel;
  </script>

  <style lang="scss" scoped>
  @import "./styles/index.scss";

  
  .image-heart {
    content: url("../../../../../assets/img/icons/heart.png");
    width: 20px!important;
    height: 19px!important;
  }
  .image-heart-filled {
    content: url("../../../../../assets/img/icons/heart filled.png");
    width: 20px!important;
    height: 19px!important;
  }

  .heart-icon-div {
    padding-bottom: 0px;
  }

  .v-list-item:not(:first-child) {
    margin-top: 15px;
    align-items: start;
    /* padding-left: 10px; */
  }

  .details-container{
    /* padding-left:8px; */
  }

  .heart-button{
    width: 29px;
    height: 19px;
  }

  


</style>