
  <div>

    <div  class="padding-test" v-if="showNoresults && !isLoading"> 
      <div class="header-noTimesheets ma-auto">
  
      </div>
      <div class="text-align-center no-results-text">
        <span class="no-results-title "  v-if="selectedTimesheetPeriod === 'Weekly'">
          {{ $t("timesheets.noSearchResultsTitleWeek") }}
        </span>
        <span class="no-results-title"  v-if="selectedTimesheetPeriod === 'Monthly'">
          {{ $t("timesheets.noSearchResultsTitleMonth") }}
        </span>
      </div>
      <div class="pt-2 ma-auto no-results-text">
        {{ $t("timesheets.noResultsDescription") }} <b>{{ $t("timesheets.noResultsNextPrevious") }} </b>  {{ $t("timesheets.noResultsbuttons") }}
      </div>
  </div>


  <ConnectionLostCardView :isVisible="showNetworkError" @close="retryClicked" />
  <LoadingIndicator class="mt-n10 pt-5" :isVisible="isLoading" />


  <div  class="mt-n10 pt-5" v-if="this.timesheetGroups !== undefined && !showNoresults && !isLoading && !showNetworkError">

    <div v-for="(group,index) in this.timesheetGroups" :key="index" >  
      <div class="text-h1 centered pb-2" :key="group.WorkedOn" v-if="selectedTimesheetPeriod === 'Weekly'" :data-WorkedOn="group.WorkedOn">{{ formatDate(group[0].WorkedOn , "Do MMM, YYYY") }}</div>
      <div class="text-h1 centered pb-2" :key="group.WorkedOn" v-if="selectedTimesheetPeriod !== 'Weekly'" :data-WorkedOn="group.WorkedOn">{{ formatDate(group[0].PeriodStarting, 'Do MMM') }} - {{ formatDate(group[0].PeriodEnding,  'Do MMM, YYYY') }}</div>
  
  
        <div v-for="timesheetHour in group" :key="timesheetHour.TimesheetHourId">   
          <div :data-WorkedOn="timesheetHour.WorkedOn" class="pb-2"> <span class="time-description">{{getShiftDateText(timesheetHour)}}, </span>  <span class="time-shift">{{getShiftDateSummary(timesheetHour)}}</span></div>

              <div :data-TimesheetId="timesheetHour.TimesheetHourId" class="rounded-corners card-border job-list-item mb-3"  :src="timesheetHour.TimesheetHourId" @click="timesheetSelected(timesheetHour)">
                  <div>
                    
                    <TimesheetSummaryCard :timesheet="timesheets.Timesheets.find(x => x.TimesheetId === timesheetHour.TimesheetId)" />
                    
                  </div>
              </div>
        </div>
        
    </div>
  </div>

   
     
      <v-dialog v-model="showTimesheetDetails"  fullscreen v-if="selectedTimesheetId > 0">
            <TimesheetDetails :isOpen="showTimesheetDetails" @submitTimesheet="submitTimesheet" :selectedTimesheetId="selectedTimesheetId" @close="showTimesheetDetails = false" :closeDialog="closeTimesheetDialog" @loaded="timesheetloaded()"></TimesheetDetails>
        </v-dialog>

        <v-dialog v-model="showTimesheetSubmitted" fullscreen>
           <v-row justify="center" class="background-cloud">
                <v-col cols="6" md="1" >
                    <img alt="Timesheet Submitted" height="200px" src="@/assets/img/timesheets/empty1.png" class="header-timesheetSubmitted">
                </v-col>
                </v-row>

                
                <v-row justify="center">
                    <v-icon>mdi-check</v-icon>
                
                </v-row>

                
                <v-row justify="center">
                <v-col cols="12" md="6">
                    <h1 style="text-align:center" class="h1-large">
                     {{ $t("timesheets.yourTimesheetSubmitted") }}
                    </h1>
                </v-col>
                </v-row>
<v-footer fixed>
      <v-container>
                <v-row justify="center">
                    <v-col cols="12" md="6">
                        <v-btn depressed block color="primary" @click="showTimesheetSubmitted = false"> {{ $t("timesheets.yourTimesheetSubmittedButton") }}</v-btn>
                    </v-col>
                    </v-row>
      </v-container>
      </v-footer>
        </v-dialog> 

  </div>
