import JobService, {
  Job,
  BroadCastJob,
} from "@/components/rdb/components/jobs/JobService";
import { Vue, Component, PropSync, Watch } from "vue-property-decorator";
import global from "@/utils/global";
import moment from "moment";

@Component({})
export default class BroadCastJobSummaryCard extends Vue {
  @PropSync("job", { type: Object }) jobLocal!: BroadCastJob;
  logoUrl = "";

  @Watch("job")
  jobChanged(val: BroadCastJob, oldVal: BroadCastJob) {
    this.logoUrl = "";
    if (val.HasLogo) {
      this.setImage();
    }
  }

  constructor() {
    super();
  }

  setImage() {
    JobService.getCompanyLogo(this.jobLocal.JobId, 40, 40, false).then((e) => {
      this.logoUrl = e;
    });
  }

  mounted() {
    if (this.jobLocal.HasLogo) {
      this.setImage();
    }
  }

  formatTimeFromNow(date) {
    return global.formatTimeFromNow(date);
  }

  formatEndDate(start, end)
  {
    if(moment.utc(end,"DD-MM-YY").isAfter(moment.utc(start,"DD-MM-YY"), 'day'))
    {
      return " - " + this.formatDate(end);
    }
    
    return "";
  }

  formatDate(date) {
    return moment(date).format("DD/MM/yyyy");
  }
}
