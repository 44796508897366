import JobService, { JobLocations, JobSearchRequest ,RecentSeachesResult,RecentSearchInfo} from "../JobService";
import store from "../../../../../store/index";
import UserToolbar from "../../../../UserToolbar.vue";
import LocationAutoCompleteGoogle from  '@/components/LocationAutoComplete/LocationAutoCompleteGoogle.vue'
import { NotificationMixin } from '@/components/mixins/notificationMixin';
import { PropSync, Mixins, Component } from 'vue-property-decorator';
import ErrorLogger from '@/utils/errorLogger'
import LocationAutoComplete from '@/components/LocationAutoComplete/LocationAutoComplete.vue';
import { MapType } from '@/store/AppState';
import locationServices from '@/utils/locationServices';
import hereMapsHelper from '@/utils/hereMapsHelper';

@Component({
    name:"Search-Jobs-View",
    components:{UserToolbar,LocationAutoCompleteGoogle,LocationAutoComplete}
})
export default class SearchJobsView extends Mixins(NotificationMixin) {
    @PropSync('name', { type: String }) syncedName!: string;
    address: string;
    addressObj: any;
    recentSearches: RecentSearchInfo[] =[];
    selectedPlace: {lat: number;lng: number};
    minDistance = 1;
    maxDistance = 100;
    radius = 1;
    jobLocation: JobLocations = new JobLocations;
    
    constructor() {
        super();
    }
   
   
    get jobSearchRequest(){
        return store.getters.AppState.jobSearchRequest;
    }

    set jobSearchRequest(value: JobSearchRequest){
        store.commit.AppState.UpdateJobSearchRequest(value);
    }

    get jobSearchRadiusMiles()
    {
        //convert KM to miles
        return  +(this.radius * 0.62137).toFixed(2);
    }

    set jobSearchRadiusMiles(e)
    {
        //convert miles to KM
        this.radius = +(e / 0.62137).toFixed(2);

    }

    get isMapsEnabled()
    {
       return store.getters.AppState.featureEnabled("RCRMGOOGLEMAPS");
    }

    get mapService()
    {
       return store.getters.AppState.mapService == MapType.Google?"GOOGLE":"HEREMAPS";
    }

    async search() {
        try{
        this.jobSearchRequest.Radius = this.radius;
        if(this.jobSearchRequest.LocationText){
            this.jobSearchRequest.LocationText = this.jobSearchRequest.LocationText.trim();
        }

        if(this.jobSearchRequest.LocationText === ""){
            this.jobSearchRequest.Latitude = 0;
            this.jobSearchRequest.Longitude = 0;
        }

        if(this.selectedPlace){
            this.jobSearchRequest.Latitude = this.selectedPlace.lat;
            this.jobSearchRequest.Longitude = this.selectedPlace.lng;
            store.commit.AppState.UpdateJobSearchRequest(this.jobSearchRequest);
            this.$router.push("/search/results");
        }
        else if(this.selectedPlace == null && this.jobSearchRequest.LocationText && store.getters.AppState.featureEnabled("RCRMGOOGLEMAPS")){

            locationServices.textSearch(this.jobSearchRequest.LocationText).then(textResults=> {

                if(textResults.length >0) {
                    if(store.getters.AppState.mapService === MapType.HereMaps) {
                        hereMapsHelper.getGeoCodeData(textResults[0].id).then(x=> {
                            this.jobSearchRequest.Latitude = x.lat;
                            this.jobSearchRequest.Longitude = x.lng;
                            this.jobSearchRequest.LocationText = textResults[0].locationText;
                            store.commit.AppState.UpdateJobSearchRequest(this.jobSearchRequest);
                            this.$router.push("/search/results");
                         });
                    } else {
                        this.jobSearchRequest.LocationText = textResults[0].locationText;
                        this.jobSearchRequest.Latitude = textResults[0].lat;
                        this.jobSearchRequest.Longitude = textResults[0].lng;
                        store.commit.AppState.UpdateJobSearchRequest(this.jobSearchRequest);
                        this.$router.push("/search/results");
                    }
                }
            });

        }
        else if(this.jobSearchRequest.Latitude && this.jobSearchRequest.Latitude !== 0){
            this.$router.push("/search/results");
        }
        else{
             if(!this.jobSearchRequest.LocationText){
                this.jobSearchRequest.Latitude = 0;
                this.jobSearchRequest.Longitude = 0;
            }
            store.commit.AppState.UpdateJobSearchRequest(this.jobSearchRequest);
            this.$router.push("/search/results");
        }
        }
        catch(e){
            this.messageBoxWarning(this.$t("genericErrorTryAgain").toString());
            ErrorLogger.logError("Error occured try to perform search",e);
        }
    }

    clearLocation() {
        this.jobSearchRequest.Longitude = undefined;
        this.jobSearchRequest.Latitude = undefined;
    }

    
   clearFilters() {
    const keywords = this.jobSearchRequest.Keywords;
    const jobSearchRequest = new JobSearchRequest;
    jobSearchRequest.Keywords = this.jobSearchRequest.Keywords;
    jobSearchRequest.LocationText = this.jobSearchRequest.LocationText;
    jobSearchRequest.Latitude = this.jobSearchRequest.Latitude;
    jobSearchRequest.Longitude = this.jobSearchRequest.Longitude;
    jobSearchRequest.Radius = this.jobSearchRequest.Radius;
    store.commit.AppState.UpdateJobSearchRequest(jobSearchRequest);
}

    mounted() {
        this.clearFilters();
        this.radius = this.jobSearchRequest.Radius;
        this.loadRecentSearches();
        
       
    }

    onLocationChanged(e){
        this.jobSearchRequest.LocationText = e;
        if (!e) {
            this.jobSearchRequest.LocationText = "";
        }

        if(!e){
            this.selectedPlace = null;
            this.jobSearchRequest.Latitude = 0;
            this.jobSearchRequest.Longitude = 0;
        }
    }

    onLocationChangedLocal(e){

        if(!e){
            this.selectedPlace = null;
            this.jobSearchRequest.LocationText = "";
            this.jobSearchRequest.Latitude = 0;
            this.jobSearchRequest.Longitude = 0;
        }
        else
        {
            this.jobLocation.Id = e.Id;
            this.jobLocation.Name = e.Name;
            this.jobLocation.Latitude = e.Latitude;
            this.jobLocation.Longtitude = e.Longtitude;
            this.selectedPlace = null;
            this.jobSearchRequest.LocationText = this.jobLocation.Name;
            this.jobSearchRequest.Latitude = this.jobLocation.Latitude;
            this.jobSearchRequest.Longitude = this.jobLocation.Longtitude;
        }
    }

    onPlaceChanged(e: {lat: number;lng: number}){
        this.selectedPlace = {lat:e.lat,lng:e.lng};
    }

    loadRecentSearches(){
        JobService.getRecentSearches().then(e =>{
            this.recentSearches = e.RecentSearches;
        });
    }

    recentSearchSelected(item: RecentSearchInfo){
        item.SearchRequest.EmploymentTypeIds = "";
        store.commit.AppState.UpdateJobSearchRequest(item.SearchRequest);
        this.$router.push("/search/results");
    }
}