<template>
  <div class="payslip">
    <v-app-bar
      app
      color="white"
      class="top-toolbar pt-0"
      extended
      :extension-height="earlyPay"
    >
      <div class="h1-large">{{ $t("payslip.payslipTitle") }}</div>
      <template v-slot:extension>
        <EarlyPay :isCloseable="false"></EarlyPay>
      </template>
    </v-app-bar>

    <v-spacer />
    <v-container>
      <ConnectionLostCardView :isVisible="showNetworkError" @close="retryClicked"></ConnectionLostCardView>

      <div v-show="!showNetworkError && !isLoading && !showRegister && !hasData">
        <div class="text-align-center no-results-text">
          <span class="no-results-title">
            {{ $t("payslip.nothingToShowTitle") }}
          </span>
        </div>
        <div class="pt-2 ma-auto no-results-text">
          {{ $t("payslip.nothingToShowMessage") }}
        </div>
      </div>
      <v-expansion-panels class="pay-panel" flat tile accordion>
        <v-expansion-panel class="mt-1" v-for="(item, i) in yearGroup" :key="i">
          <v-expansion-panel-header color="#e8ebf3">
            <template v-slot:actions>
              <v-icon class="icon">$expand</v-icon>
            </template>
            <span class="h1-large pay-line-23"> {{ item }}</span>
            <span class="h1-large pay-line-23 pr-5" style="text-align: right">
              £{{ yearTotal(item) }}
            </span>
          </v-expansion-panel-header>

          <v-expansion-panel-content
            v-for="(items, j) in dataByYear(item)"
            :key="j">
            <v-list-item class="pl-0 pr-0">
              <v-list-item-icon class="mr-5">
                <div>
                  <v-icon class="group9799"></v-icon>
                </div>
              </v-list-item-icon>
              <v-list-item-content class="pl-1 pay-pr-10">
                <v-list-item-title
                  class="fs-16 font-weight-bold"
                  v-text="getMonth(items.payDay)">
                </v-list-item-title>
                <div style="display: flex" class="pay-line-18">
                  <v-list-item-title class="fs-14 secondary-text-color fw-600 pay-no-overflow"
                        v-text="getFormattedDate(items.payDay)">
                  </v-list-item-title>
                  <v-list-item-subtitle
                      class="pl-2 mt-n2 fs-14 font-weight-bold pay-color-black"
                      style="text-align: right"
                      v-text="getNetPay(items)">
                   </v-list-item-subtitle>
                </div>
              </v-list-item-content>
              <v-list-item-action class="ml-3">
                <v-btn elevation="0"
                  icon
                  class="action-show-details"
                  @click="showDetails(items)"
                >
                  <v-icon>mdi-chevron-right</v-icon>
                </v-btn>
              </v-list-item-action>
            </v-list-item>

            <v-divider></v-divider>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>

      <v-form
        v-model="valid"
        ref="form"
        class="mt-n2"
        lazy-validation
        v-show="!showNetworkError && !isLoading && showRegister"
      >
        <div>
          <div>
            <p>{{ $t("payslip.verifyMessage") }}</p>
          </div>
          <div>
            <div class="text-h1 pb-1">
              {{ $t("profile.firstName") }}
            </div>
            <v-text-field
              class="input-firstName"
              :data-firstName="firstName"
              :rules="requiredRule"
              :placeholder="$t('profile.firstNamePlaceholder')"
              v-model="firstName"
              outlined
              dense
            ></v-text-field>
          </div>
          <div>
            <div class="text-h1 pb-2">
              {{ $t("profile.surname") }}
            </div>

            <v-text-field
              class="input-surname"
              :data-surname="surname"
              :rules="requiredRule"
              :placeholder="$t('profile.surnamePlaceholder')"
              dense
              outlined
              v-model="surname"
            ></v-text-field>
          </div>
          <div>
            <div class="text-h1 pb-2">
              {{ $t("payslip.niNumber") }}
            </div>

            <v-text-field
              class="input-niNumber"
              :data-niNumber="niNumber"
              :rules="niRule"
              :placeholder="$t('payslip.niNumberPlaceholder')"
              dense
              outlined
              v-model="nino"
            ></v-text-field>
          </div>
          <v-btn @click="verify" depressed block class="primary action-verify">
            {{ $t("payslip.verifyButtonText") }}
          </v-btn>
        </div>
      </v-form>

      <v-dialog
        v-model="showDetail"
        max-width="500px"
        fullscreen
        scrollable
        persistent
      >
        <PayslipDetail
          @close="showDetail = false"
          :data="dataToPass"
        ></PayslipDetail>
      </v-dialog>

      <LoadingIndicator :isVisible="isLoading"></LoadingIndicator>
    </v-container>
  </div>
</template>

<script lang="ts">
import ViewModel from "./Payslip";
export default ViewModel;
</script>

<style scoped>
.pay-panel >>> .v-expansion-panel > .v-expansion-panel-header {
  min-height: unset !important;
  height: 40px !important;
}
.payslip >>> .top-toolbar > .v-toolbar__extension {
  margin-top: -6px !important;
}
.payslip >>> .v-expansion-panel-content__wrap {
  padding: unset !important;
}

.no-results-title {
  font-size: 22px;
  font-weight: bold;
  text-align: center;
}

.no-results-text {
  font-size: 16px;
  text-align: center;
}

.pay-line-23 {
  line-height: 23px !important;
}

.pay-line-18 {
  line-height: 18px !important;
}
.group9799 {
  content: url("../../../../assets/img/icons/Payslip icon.jpg");
  width: 40px !important;
  height: 40px !important;
}

.pay-color-black {
  color: #070707 !important;
}

.pay-no-overflow {
  overflow: visible;
}

.pay-pr-10 {
  padding-right: 10px;
}
</style>
