import { Vue, Component, PropSync, Watch } from 'vue-property-decorator';
import LoadingIndicator from "../../../../LoadingIndicator.vue";
import JobShiftSummaryCard from "./JobShiftSummaryCard.vue";
import TimesheetService, { ShiftRequest, JobShift, InsertTimesheetRequest, ShiftResult } from "../TimesheetService";
import moment, { Moment } from 'moment';

@Component({
    name:"checkIn",
    components:{ LoadingIndicator, JobShiftSummaryCard }
})

export default class CheckOutConfirmation extends Vue {
    @PropSync("jobShift", { type: Object }) localJobShift: JobShift;

    constructor() {
        super();
    }

    confirmCheckOut(){
        this.$emit("confirm", this.localJobShift, new Date());
    }

    cancelCheckOut(){
        this.$emit("cancel");
    }
}