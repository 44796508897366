import { Vue, Component, PropSync, Watch, Mixins } from 'vue-property-decorator';
import LoadingIndicator from "../../../../LoadingIndicator.vue";
import JobShiftSummaryCard from "./JobShiftSummaryCard.vue";
import TimesheetService, { ShiftRequest, JobShift, InsertTimesheetRequest, ShiftResult } from "../TimesheetService";
import CheckinSummaryCard from './CheckinSummaryCard.vue'
import moment, { Moment } from 'moment';
import VueTimepicker from 'vue2-timepicker'
import 'vue2-timepicker/dist/VueTimepicker.css'
import CheckInService from '@/services/checkInService';
import  { ClockStateEvent, ClockStateResult, TimesheetEvent, ClockStateEventInsertResponse, BreakItem, ClockState } from '@/services/checkInDomain';
import { mdiConsoleNetwork } from '@mdi/js';
import global from '@/utils/global';
import { NotificationMixin } from "@/components/mixins/notificationMixin";
import Alert from "@/components/Alert";
import HttpHelpers  from '@/utils/httpHelpers';
import  Locations  from '@/utils/locations';



@Component({
    name:"checkIn",
    components:{ LoadingIndicator, JobShiftSummaryCard, VueTimepicker, CheckinSummaryCard, Alert }
})

export default class CheckOutView extends Mixins(NotificationMixin) {
    @PropSync("jobShift", { type: Object }) localJobShift: JobShift;
    @PropSync("checkoutConfirmation", { type: Boolean }) localcheckoutConfirmation: boolean;

    @Watch('checkoutConfirmation')
    checkoutConfirmationChanged(val: boolean, oldVal: boolean){
        if(val){
            this.checkIn = this.checkInTime;
            this.checkOut = this.getcheckOutTime();
            this.breakDuration = this.localJobShift.ClockState.getTotalBreakDurationString();
            if(!this.localJobShift.ClockInOut && this.localJobShift.ManualEntry)
            {
              this.autosave = false; 
              this.manualEntryOnly = true; 
            }
            else
            {
                this.autosave = this.localJobShift.ClockState.hasEvents();
                this.manualEntryOnly = false; 
            }
            this.autosave = this.localJobShift.ClockState.hasEvents();
            this.selectedTab = this.autosave ? 0 : 1;
            this.errors = [];
        }
    }

    @Watch('autosave')
    autosaveChanged(val: boolean, oldVal: boolean){
        if(val){
            this.checkIn = this.checkInTime;
            this.checkOut = this.getcheckOutTime();
            this.breakDuration = this.localJobShift.ClockState.getTotalBreakDurationString();
        }
    }

    @Watch('jobShift') 
    jobshiftChanged(val: boolean, oldVal: boolean){
        if(!this.localJobShift.ClockInOut && this.localJobShift.ManualEntry)
        {
            this.autosave = false; 
            this.manualEntryOnly = true; 
        }
        else
        {
            this.autosave = this.localJobShift.ClockState.hasEvents();
            this.manualEntryOnly = false; 
        }
        this.checkIn = "HH:mm";
        this.checkOut = this.getcheckOutTime();
        this.breakDuration = "HH:mm";
        if (this.autosave) {
            this.breakDuration = this.localJobShift.ClockState.getTotalBreakDurationString();
        }
        this.errors = [];
    }
    autosave = false;
    checkIn = "";
    checkOut = "";
    checkOutDate: Date;
    breakDuration = "";
    errors = [];
    createTimesheetDisabled = false;
    checkInService = new CheckInService();
    message = false;
    messageText = "";
    alertType = "";
    isConfirmation = false;
    selectedTab = 0;
    manualEntryOnly = false;
   

    get breaks(){
        if(this.localJobShift && this.localJobShift.ClockState){
            return this.localJobShift.ClockState.getBreaks();
        }

        return [];
    }

    get workedTimeHours() {
        const jobShiftCheckIn: ClockStateEvent = this.getCheckInEvent();
        if (jobShiftCheckIn) {
            return this.localJobShift.ClockState.getClockDuration().hours;
        }
        return 0;
    }

    get workedTimeMinutes(){
        const jobShiftCheckIn: ClockStateEvent = this.getCheckInEvent();
        if (jobShiftCheckIn) {
            return this.localJobShift.ClockState.getClockDuration().minutes;
        }

        return 0;
    }

    get overTimeHours(){
        return 0;
    }

    get overTimeMinutes(){
        return 0;
    }

    get checkInTime() {
        const checkinEvent = this.getCheckInEvent();
        if(checkinEvent){
            return global.formatDate(checkinEvent.eventTime).format("HH:mm").toString();
        }

        return "";
    }

    get clockInOnly()
    {
        if(this.localJobShift.ClockInOut && !this.localJobShift.ManualEntry)
        {

            let result = ClockState.NotStarted;
        
            if(this.localJobShift && this.localJobShift.ClockState && this.localJobShift.ClockState.hasEvents()){
                const hasCheckInEvent = this.getCheckInEvent() == null?false:true;
    
                if(hasCheckInEvent || this.localJobShift.ClockState.eventCount() == 1){
                        result = this.localJobShift.ClockState.getClockState();
                }
                else{
                    result = ClockState.NotStarted;
                }
            }
            //if the clock was never started, then let them enter the start and break times
            if(result == ClockState.NotStarted)
            {
                return false;
            }
            return true;
        }

        return false;
    }

    getcheckOutTime() {
        this.checkOutDate = new Date;
        return global.formatDate(this.checkOutDate).format("HH:mm").toString();
    }

    get daySpan(){
        const checkInEvent = this.getCheckInEvent();

        //We only need to do this check if we have both a checkin event and a checkout event
        if(checkInEvent) {
            const checkInDateTime = moment(checkInEvent.eventTime).startOf('day');
            const checkOutDateTime = moment(this.checkOutDate).startOf('day').add(this.checkOut).startOf('day');

            if(!checkInDateTime.isSame(checkOutDateTime, 'day')){
                return checkOutDateTime.diff(checkInDateTime, 'day');
            }
        }
        return 0;
    }

    formatDateFromNow(date){
        return global.formatDateFromNow(date);
    }

    get shiftDate(){
        return moment(this.localJobShift.ShiftDate).format("DD/MM/yyyy");
    }

    get shiftStartTime(){
        return moment(this.localJobShift.ShiftStartTime).format("HH:mm");
    }

    get shiftEndTime(){
        return moment(this.localJobShift.ShiftEndTime).format("HH:mm");
    }

    get isValid() {
        this.errors = [];
        const start = moment(this.localJobShift.ShiftStartTime).startOf('day').add(this.checkIn);
        const end = moment(new Date).startOf('day').add(this.checkOut).add(this.daySpan, 'd');
        const hoursWorked = global.getDurationBetweenDates(start.toDate(), end.toDate());
        const breaks = global.getDurationBetweenDates(moment(this.localJobShift.ShiftDate).startOf('day').toDate(), moment(this.localJobShift.ShiftDate).startOf('day').add(this.breakDuration).toDate());
        const checkInTimeSet = !this.checkIn.includes("HH") && !this.checkIn.includes("mm") && !(this.checkIn.length == 0);
        const checkOutTimeSet = !this.checkOut.includes("HH") && !this.checkOut.includes("mm") && !(this.checkOut.length == 0);

        if(this.checkIn.length == 0){
            this.errors.push({message: this.getText("checkInTimeNotSelectedError")});
        }

        if(this.checkIn.length == 5 && this.checkIn.includes("HH")){
            this.errors.push({message: this.getText("checkInHourNotSelectedError")});
        }

        if(this.checkIn.length == 5 && this.checkIn.includes("mm")){
            this.errors.push({message: this.getText("checkInMinuteNotSelectedError")});
        }

        if(this.checkOut.length == 0){
            this.errors.push({message: this.getText("checkOutTimeNotSelectedError")});
        }

        if(this.checkOut.length == 5 && this.checkOut.includes("HH")){
            this.errors.push({message: this.getText("checkOutHourNotSelectedError")});
        }

        if(this.checkOut.length == 5 && this.checkOut.includes("mm")){
            this.errors.push({message: this.getText("checkOutMinutesNotSelectedError")});
        }

        if(start.isAfter(end) && checkInTimeSet && checkOutTimeSet){
            this.errors.push({message: this.getText("jobShiftCheckInAfterCheckOutError")});
        }

        if(start.isSame(end) && checkInTimeSet && checkOutTimeSet){
            this.errors.push({message: this.getText("jobShiftCheckInSameAdCheckOutError")});
        }

        if(hoursWorked.isSame(breaks) && checkInTimeSet && checkOutTimeSet){
            this.errors.push({message: this.getText("jobShiftBreakDurationSameAsShiftDurationError")});
        }

        if(breaks.isAfter(hoursWorked) && checkInTimeSet && checkOutTimeSet){
            this.errors.push({message: this.getText("jobShiftBreakDurationGreaterThanShiftDurationError")});
        }

        return this.errors.length == 0;
    }

    constructor() {
        super();
    }

    mounted() {
        if(!this.localJobShift.ClockInOut && this.localJobShift.ManualEntry)
        {
          this.autosave = false; 
          this.manualEntryOnly = true; 
        }
        else
        {
            this.autosave = this.localJobShift.ClockState.hasEvents();
            this.manualEntryOnly = false; 
        }
        this.checkIn = this.checkInTime;
        this.checkOut = this.getcheckOutTime();
        this.breakDuration = this.localJobShift.ClockState.getTotalBreakDurationString();
        this.errors = [];
    }

    
    
    getText(key: string){
        return this.$t(`alertMessages.${key}`).toString(); 
    }

    cancel(){
        this.$emit("cancel");
    }

    getCheckInEvent(){
        return (this.localJobShift) ? this.localJobShift.ClockState.getCheckInEvent() : null;
    }

    getCheckOutEvent(){
        return (this.localJobShift) ? this.localJobShift.ClockState.getCheckOutEvent() : null;
    }


    async submitTimesheet(){
        
        //Check to see if the form is valid before proceeding
        if(this.isValid){

            //Check Dates 
            //Need to log event time as the date of the diary event, not current time otherwise it cannot be retrieved for the period

            //need to check this only do this for manual? Debug and test with historic logging and also with spans longer than 24 hours
             
            const eventDate = this.localJobShift.ClockState.generateCheckOutDate(this.localJobShift.ShiftStartTime,this.checkOut);

            //Only proceed with the checkout and timesheet changes if we have been able to successfully log the checkout event

            this.logCheckOut(this.localJobShift,eventDate).then(async result => {
                if(result){
                    if( this.localJobShift.ClockState){
                        this.localJobShift.ClockState.updateClock(result);
                    }

                    let checkOutLat = 0;
                    let checkOutLong = 0;
                   
                    
                    await Locations.getLocationData().then((r)=>
                    {
                        checkOutLat = r.latitude;
                        checkOutLong = r.longitude;
                       
                    });

                    let shiftStartTime;
                    const timesheet = new InsertTimesheetRequest();
                    timesheet.StartTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
                    timesheet.EndTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
                    timesheet.ShiftDate = this.localJobShift.ShiftDate;
                    timesheet.ObjectDiaryId = this.localJobShift.ObjectDiaryId;
                    timesheet.PlacementId = this.localJobShift.PlacementId;
                    timesheet.ClockState = this.localJobShift.ClockState;
                    timesheet.OriginalShiftStartTime = this.localJobShift.ShiftStartTime;
                    //timesheet.ClockState.addEvent(result.event);//QQQQ 

                    if(!this.localJobShift.ClockState.associatedData){
                        this.localJobShift.ClockState.associatedData = JSON.stringify({
                            ObjectDiaryId: this.localJobShift.ObjectDiaryId,
                            PlacementId: this.localJobShift.PlacementId,
                            ShiftEndTime: this.localJobShift.ShiftEndTime,
                            ShiftStartTime: this.localJobShift.ShiftStartTime,
                            CheckInLat: checkOutLat,
                            CheckInLong: checkOutLong,
                            
                        });
                    }

                    

                    if(this.autosave){
                        timesheet.ClockState = this.localJobShift.ClockState;
                        timesheet.ShiftStartTime = this.getCheckInEvent().eventTime;
                        if (moment(this.checkOutDate).startOf('day').add(this.checkOut).toDate() <
                            moment(this.checkOutDate).startOf('day').add(this.checkIn).toDate()) {
                                timesheet.ShiftEndTime =  moment(this.getCheckInEvent().eventTime).startOf('day').add(1, "days").add(this.checkOut).toDate();
                        } else {
                            timesheet.ShiftEndTime = result.event.eventTime; 
                        }
                    }
                    else{
                        timesheet.ShiftStartTime = global.setTimeOnDate(this.localJobShift.ShiftStartTime,this.checkIn);// moment(this.localJobShift.ShiftStartTime).startOf('day').add(this.checkIn).toDate();

                        if (moment(this.checkOutDate).startOf('day').add(this.checkOut).toDate() <
                            moment(this.checkOutDate).startOf('day').add(this.checkIn).toDate()) {
                                timesheet.ShiftEndTime = moment(this.localJobShift.ShiftStartTime).startOf('day').add(1, "days").add(this.checkOut).toDate();
                        } else {
                            timesheet.ShiftEndTime = global.setTimeOnDate(this.localJobShift.ShiftStartTime,this.checkOut); // moment(this.localJobShift.ShiftStartTime).startOf('day').add(this.checkOut).toDate();
                        }

                        timesheet.ClockState = new ClockStateResult();
                        timesheet.ClockState.associatedData = this.localJobShift.ClockState.associatedData;
                        timesheet.ClockState.clockStateId = result.clockStateId;
                        
                        if(this.breakDuration.length > 0){
                            //Add generic break
                            const pauseEvent: ClockStateEvent = {
                                eventTime: moment(shiftStartTime).toDate(),
                                uniqueId: "PAUSE"
                            };

                            const unpauseEvent: ClockStateEvent = {

                                eventTime: moment(shiftStartTime).add(this.breakDuration).toDate(),
                                uniqueId: "UNPAUSE"
                            };
                            timesheet.ClockState.addEvent(pauseEvent);
                            timesheet.ClockState.addEvent(unpauseEvent);
                        }
                    }
                    
                    this.createTimesheetDisabled = true;
                    TimesheetService.postTimesheet(timesheet).then(tr => {
                        if(tr.TimesheetId > 0){
                            let associatedData = JSON.parse(this.localJobShift.ClockState.associatedData);
                            associatedData.TimesheetId = tr.TimesheetId;
                            associatedData.TimeSheetStartTime = timesheet.ShiftStartTime;
                            associatedData.TimeSheetEndTime = timesheet.ShiftEndTime;
                            associatedData.TimeSheetBreakTimeTotalMilliSeconds = timesheet.ClockState.getBreakTimeTotalMilliSeconds();
                            associatedData.CheckOutLat = checkOutLat;
                            associatedData.CheckOutLong = checkOutLong;
                           


                            associatedData = HttpHelpers.updateDates(associatedData);

                            this.checkInService.timesheetSubmitted({associatedData:JSON.stringify(associatedData),
                                clockStateId:result.clockStateId,timesheetDate:this.localJobShift.ShiftDate,ianaTimeZone:Intl.DateTimeFormat().resolvedOptions().timeZone}).then((r)=> {
                                    this.checkIn = "";
                                    this.checkOut = "";
                                    this.breakDuration = "";
                            });
                            if (tr.LastShift) {
                                this.confirm(this.$t("timesheets.lastShiftSubmit").toString(),this.$t("confirm").toString()).then((result) => {
                                    if(result) {
                                        TimesheetService.submitTimesheet(tr.TimesheetId).then((submitResponse)=> {
                                                if(submitResponse) {
                                                    this.$emit("submit", true);
                                                    this.createTimesheetDisabled = false;
                                                } else {
                                                    this.toastError(this.$t("alertMessages.timesheetInsertUnsuccessful").toString());
                                                }
                                        }).catch((e)=>{
                                            this.toastError(this.$t("alertMessages.timesheetInsertUnsuccessful").toString());
                                        });
                                    } else {
                                        this.$emit("submit", false);
                                        this.createTimesheetDisabled = false;
                                    }
                                });
                            } else {
                                this.$emit("submit", false);
                                this.createTimesheetDisabled = false;
                            }
                        }
                        else{
                            this.$emit("submit", false);
                            this.createTimesheetDisabled = false;
                        }
                    }).catch((e)=> {
                        if(!e.isNetworkError){
                            this.toastError(this.$t("updateFailed").toString());
                        }
                        this.createTimesheetDisabled = false;
                    });
                }
            }).catch((e)=> {
                if(e.isNetworkError){
                    this.toastError(this.$t("alertMessages.networkError").toString());
                }
                else{
                    let errorMessage = "";
                    Object.entries(e.response.data).forEach(
                        ([key, value]) => errorMessage += (`${key}: ${value.toString()}`)
                    );
                    this.showAlert(errorMessage, "Warning", false);
                }
            });
        } 
    }

    logCheckOut(jobShift: JobShift, eventTime: Date): Promise<ClockStateEventInsertResponse>{
        const event = new TimesheetEvent;
        event.Time = eventTime;
        event.UniqueId = "CHECKOUT";
        event.Options = JSON.stringify({"ObjectDiaryId": jobShift.ObjectDiaryId, "PlacementId": jobShift.PlacementId, "ShiftEndTime": jobShift.ShiftEndTime, "ShiftStartTime": jobShift.ShiftStartTime});
        event.ClockStateId = jobShift.ClockState.clockStateId;
        event.IanaTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;


        return this.checkInService.postEvent(event).then(
            result => {
                return result;
            });          
           
    }

    showAlert(alertMessage: string, type: string, isConfirmation: boolean){
        this.messageText = alertMessage;
        this.alertType = type;
        this.isConfirmation = isConfirmation;
        this.message = true;
    }

    closeAlert(){
        this.message = false;
    }

}