import { Vue, Component, PropSync, Watch, Mixins, Prop } from 'vue-property-decorator';
import JobService, { Job } from "../../../rdb/components/jobs/JobService";
import store from '@/store';
import Map from '@/components/Map.vue'
import { NetworkMixin } from '@/components/mixins/networkMixin';
import { GoogleMarkerConfig } from '@/components/GoogleMap'
import { DiaryEvent } from './DiaryService';
import AvailabilityCardView from "./AvailabilityCardView.vue";
@Component({
    name: "AvailabilityJobDetails",
    components: { Map, AvailabilityCardView }
})
export default class AvailabilityJobDetails extends Mixins(NetworkMixin) {
    @Prop(Object) event: DiaryEvent;
    @PropSync("isOpen", { type: Boolean }) isOpenLocal!: boolean;


    job: Job = new Job;
    isLoaded = false;
    selectedView = "job";
    selectedTabIndex = 0;
    markers: GoogleMarkerConfig[] = [];

    @Watch('isOpen')
    isOpenChanged(val: DiaryEvent, oldVal: DiaryEvent) {
        if (val) {
            this.selectedTabIndex = 0;
            this.loadJob();
        }
    }

    get mapVisible() {

        if (!this.job.Location && this.job.Longitude == 0 && this.job.Longitude == 0) {
            return false;
        }
        return true;
    }




    mounted() {
        this.loadJob();
    }


    loadJob() {
        let jobId = 0;

        jobId = this.event.JobId;
        if (jobId > 0) {

            this.doWork<any>(JobService.selectJob(jobId), () => { this.loadJob() }).then((res) => {
                this.job = res.data as Job;
                JobService.updateFromLocalStorage(this.job);
                this.isLoaded = true;
                this.markers.splice(0);
                this.markers.push({ height: 64, width: 48, lat: this.job.Latitude, lng: this.job.Longitude, imageUrl: store.getters.AppState.url + "img/icons/google_marker.png", uniqueId: this.job.JobId.toString(), imageUrlSelected: store.getters.AppState.url + "img/icons/google_marker.png" });
            });
        }
    }

    back() {

        this.job = new Job();
        this.selectedView = "job";
        this.$emit("close");

    }


}