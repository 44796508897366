import Vue from 'vue'
import Component from 'vue-class-component'
import { Mixins, PropSync } from 'vue-property-decorator';
import UserTerms from "@/services/userTerms";
import { NetworkMixin } from '@/components/mixins/networkMixin';
import LoadingIndicator from "@/components/LoadingIndicator.vue";
import ConnectionLostCardView from '@/components/ConnectionLostCardView.vue';
import Auth from '@/auth';
import store,{AppStore} from '@/store';
import { NotificationMixin } from './mixins/notificationMixin';
@Component({
   name: "acceptterms",
   components: { ConnectionLostCardView, LoadingIndicator },
   methods: {
   }
})

export default class AcceptTerms extends Mixins(NetworkMixin,NotificationMixin) {
   @PropSync("dialog") dialogLocal!: boolean;

   terms = "";
   readTerms = false;
   constructor() {
      super();
   }


   mounted() {      
      if(store.getters.AppState.isAuthenticated)
      {
         this.fetchTerms();
         const el = (this.$refs.iframeRef as HTMLIFrameElement) 
         el.onload = () =>
         {
            if(el.contentWindow.document.body.offsetHeight != 0 && el.contentWindow.document.body.offsetHeight <= el.clientHeight)
            {
               this.readTerms = true;
            }
            else
            {
               el.contentWindow.addEventListener('scroll', e =>{
               
                  if(el.contentWindow.document.body.offsetHeight - el.clientHeight  <= el.contentWindow.scrollY)
                  {
                     this.readTerms = true;
                  }
               })
            } 
         }
      }
   }


  
    fetchTerms() {
     
          this.doWork<any>(UserTerms.getTerms(), this.fetchTerms)
         .then(
            (result) => {               
               this.terms = result;
               
            }
         );
   }

   cancel() {
      Auth.client().signOut();

   }

   async submit() {
      await this.doWork<any>(UserTerms.postUserAcceptedTerms(), this.fetchTerms)
         .then(
            (result) => {
               if (result == true) {
                  this.dialogLocal = false;
               }

            }
         ).catch((e) =>
         {
            this.showWarningAlert(this.$t("termsAndConditions.updateErrorMessage").toString())
         });
   }

   showWarningAlert(message: string){
      this.messageBoxWarning(message);
    }

}