import { Vue, Component, PropSync, Watch, Mixins } from 'vue-property-decorator';
import JobService, { CVUploadResult, CVsResponse } from "../JobService";
import moment from 'moment';
import { NetworkMixin } from '@/components/mixins/networkMixin';

@Component({
  components:{}
})
 export default class CVSelect extends Mixins(NetworkMixin) {
   uploadFieldName = "CV";
    selectedCV: CVUploadResult = null;
    CVs: CVUploadResult[] = [];
    isLoading = true;

    constructor() {
      super();
    }

    mounted() {
      this.loadCVs();
    }

    fileTypeImageClass(value: CVUploadResult){

      if(value){
        switch(value.FileExtension){
          case "1":
            break;
          case "2":
            break;
          default:
            break;
        }
      }
      return "";
    }

    loadCVs(){

      this.doWork(JobService.getCVforApplications()).then((res)=>{
        this.CVs = res.CVs;
      });

      }
 
      removeDocument(CVId: number) {
        this.CVs.forEach( (item, index) => {
          if(item.CVId === CVId) {
            this.CVs.splice(index,1);
          }
        });
      }

    close() {
      this.selectedCV = new CVUploadResult;
      this.$emit("close");
    }

    apply() {
      const cvs: CVUploadResult[] = [];
      cvs.push(this.selectedCV);

      this.$emit("apply", cvs);
      this.selectedCV = new CVUploadResult;
    }

    cvSelected(item: CVUploadResult) {
      const cvv = (item as any);

      if(this.selectedCV){
        if(this.selectedCV && cvv.CVId == this.selectedCV.CVId) {
          // cvv.selected = !cvv.selected;
          if(!cvv.selected){
           this.selectedCV = item;
          }else{
            (this.selectedCV as any).selected = false;
            cvv.selected = false;
          }
         }else{
           (this.selectedCV as any).selected = false;
           cvv.selected = true;
           this.selectedCV = item;
         }
      }
      else{
        cvv.selected = true;
        this.selectedCV = item;
      }
    }

    // onCVSelected(cv: CVUploadResult){

    // }

    formatDate(arg, format) {
      return moment(arg).format(format);
    }

  
  }