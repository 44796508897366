import Vue from 'vue'
import Component from 'vue-class-component'
import ConnectionLostCardView from '@/components/ConnectionLostCardView.vue';
import LoadingIndicator from "@/components/LoadingIndicator.vue";

import DiaryService, { DiaryEvent, DiaryEventInsertRequest, DiaryEventMultiDayInsertRequest, DiaryEventsRequest, DiaryEventDeleteRequest } from "./DiaryService";
import moment from 'moment';
import * as easings from "vuetify/es5/services/goto/easing-patterns"
import { Mixins, Watch } from 'vue-property-decorator';
import { NetworkMixin } from '@/components/mixins/networkMixin';
import { NotificationMixin } from '@/components/mixins/notificationMixin';

@Component({ components:{ConnectionLostCardView,LoadingIndicator}})
export class AvailabilityCalendarMixin extends Mixins(NetworkMixin,NotificationMixin) {
   
    startDate: Date;
    minimumDate: Date;
    maximumDate: Date;
    storeStartDate: boolean;

    reload = 0;
    forceReload = false;

    monthStartLocalDate = moment.utc().startOf('month').toDate();

    monthStart: Date;
    monthEnd: Date;

    selectedDate: Date = new Date();

    eventDays = [];
   
    diaryEvents = [];
    diaryEventsFinal = [];

    pickerDate= null;
    pickerDateMove = false;
    
    reloaded = false;
    isToday = false;
    
    scroll = false;
    saveError = false;

    modelDate = new Date(this.selectedDate.getTime() - (this.selectedDate.getTimezoneOffset() * 60000)).toISOString().substr(0, 10);
 
    selector = '';

    duration = 300;
    offset = 0;
    easings = Object.keys(easings);
    firstLoad = true;
   
   

    @Watch('reload')
    reloadChanged(val,oldVal)
    {
        if(val != oldVal && !this.firstLoad )
        {
            if((!moment.utc(this.monthStart).isSame(moment.utc(this.monthStartLocalDate))) || this.forceReload)
           {
            this.monthStartLocalDate = this.monthStart;
            this.reloaded = true;
            this.setUp();
            //after save reload main calendar
            if(this.forceReload)
            {
                this.forceReload = false;
                this.retrieveDiaryEvents();  
            }
           }
        }
        this.firstLoad = false;
     }


    pickerChanged(newMonth, newYear)
    {
        this.selectedDate = new Date(parseInt(newYear),parseInt(newMonth) -1 ,1, 0,0,0,0);  
            this.monthStart = moment(this.selectedDate).startOf('month').toDate();
            this.monthEnd = moment(this.selectedDate).endOf('month').toDate();
            if(this.storeStartDate)
            {
                if(moment(this.startDate).isBetween(this.monthStart, this.monthEnd) && this.startDate != null)
                {
                   this.selectedDate = new Date(this.startDate);
                }
            }
            else{
                if(moment().isBetween(this.monthStart,this.monthEnd))
                {
                    this.selectedDate = new Date();
                    this.isToday = true;
                }
            }
            this.eventDays = [];
            this.pickerDateMove = true;
            this.modelDate = new Date(this.selectedDate.getTime() - (this.selectedDate.getTimezoneOffset() * 60000)).toISOString().substr(0, 10);
            this.monthStartLocalDate = this.monthStart;
          
            //use timeout so picker moves quickly
           // this.$nextTick(() =>{
               //this.reloadData = !this.reloadData;
               this.retrieveDiaryEvents();
              
            //})    
    }
  

//    get pickerDisabled()
//    {
//       // if(this.showNetworkError)
//       // {
//       //     return false;
//       // }
//        return this.pickerDateMove;
//        //return false;
//    }

   setUp()
    {
        this.monthStart = this.monthStartLocalDate != null ? this.monthStartLocalDate : moment.utc().startOf('month').toDate();
        this.monthEnd = this.monthStartLocalDate != null ?  moment(new Date(this.monthStartLocalDate.getTime() - (this.monthStartLocalDate.getTimezoneOffset() * 60000))).endOf('month').toDate() :  moment().endOf('month').toDate();
        this.selectedDate = this.startDate != null ? this.startDate : new Date(this.monthStart);
        this.selectedDate.setHours(0, 0, 0, 0);
        this.modelDate = new Date(this.selectedDate.getTime() - (this.selectedDate.getTimezoneOffset() * 60000)).toISOString().substr(0, 10);
        if(this.reloaded)
        {
            this.pickerDate = this.selectedDate.getFullYear() + "-" + (('0' + (this.selectedDate.getMonth() + 1)).slice(-2));
            
        }
        
       
    }

    async deleteDiaryEvent(event: DiaryEventDeleteRequest): Promise<any>{
        const result = new Promise<boolean>((resolve,reject)=> {
            this.doWork<any>(DiaryService.postDiaryEventDelete(event)).then((response)=>{
                this.toastSuccess(this.$t("alertMessages.availabilityDeleteOK").toString());
                this.reload += 1;
                this.firstLoad = false;
                this.forceReload = true;
                this.pickerDateMove = true;
                resolve(response.data);
            }).catch((e)=>{
                this.toastError(this.$t("alertMessages.availabilityDeleteFail").toString());
                reject(false);
            })  
        });

      return result;
    }

    async saveEventData(event: DiaryEventMultiDayInsertRequest) {
        this.diaryEvents = [];
        this.lastAction = () =>{this.saveEventData(event)};

        this.doWork<any>(DiaryService.postDiaryEventInserts(event)).then(() =>
        { 
            this.toastSuccess(this.$t("alertMessages.availabilitySavedOK").toString());
            this.firstLoad = false;
            this.forceReload = true;
            this.pickerDateMove = true;
            this.reload += 1;
        });
    }


   async retrieveDiaryEvents() {
    
    this.isLoading = true;
    this.diaryEventsFinal = [];
    this.diaryEvents = [];
    this.eventDays = [];
    const diaryEventsRequest = new DiaryEventsRequest();

    diaryEventsRequest.StartDate =  new Date(this.monthStart.getTime() - (this.monthStart.getTimezoneOffset() * 60000)).toISOString(); // moment.parseZone(this.monthStart, 'ddd MMM DD YYYY HH:mm:ss').toISOString(false);

    diaryEventsRequest.EndDate =  new Date(this.monthEnd.getTime() - (this.monthEnd.getTimezoneOffset() * 60000)).toISOString().split('.')[0]; // moment.parseZone(this.monthEnd, 'ddd MMM DD YYYY HH:mm:ss').toISOString(false);

    this.doWork<any>(DiaryService.getEventsBetweenDates(diaryEventsRequest),this.retrieveDiaryEvents)
    .then(
            (result) => {
                this.diaryEvents = result.data.DiaryEvents;
                this.bindData(this.diaryEvents)

                setTimeout(() => {
                    this.scrollIntoView();

               }, 50);
            }
    );
}




  bindData(diaryEvents: DiaryEvent[]) {

    let newKey = 99999999;
    let changedArray = false;

    this.eventDays = [];
    let length = diaryEvents.length;
        let indexLength = length - 1;
        let i = 0;

    while(i < length){

        //this.diaryEvents[i].UpcomingEvent = true;
        //This will need changing

        if(this.diaryEvents[i].ClientId === 0)
        {
            switch(this.diaryEvents[i].SystemCode)
            {
                case 'ODEVNT_TYP_UNAVAILABLE':
                    {
                        this.diaryEvents[i].CanDelete = true;
                        break;
                    }
                case "ODEVENT_AVAILABLE":
                    {
                        this.diaryEvents[i].CanDelete = true;
                        break;
                    } 
            }
        }

        this.diaryEvents[i].UniqueId = (this.diaryEvents[i].RowId + this.diaryEvents[i].ObjectDiaryId + Math.random()).toString();

        const checkDate = new Date(new Date(this.diaryEvents[i].Start).getTime());
        if (checkDate >=  this.monthStart &&
            checkDate <= this.monthEnd) {
            this.diaryEvents[i].UpcomingEvent = true;

            const start = this.diaryEvents[i].Start.toString().substr(0,10);
            const [,, day] = start.split('-');
            this.eventDays.push(day);
        }
        else {
            this.diaryEvents[i].UpcomingEvent = false;
        }
        const spansDays = !moment(this.diaryEvents[i].Start).isSame(this.diaryEvents[i].End, 'day') &&
                          !moment(this.diaryEvents[i].Start).isAfter(this.monthEnd)
        // events that span multiple days
        if (spansDays) {
            changedArray = true;
            let controlDate = this.diaryEvents[i].End;
            if(moment.utc(controlDate).isAfter(this.monthEnd))
            {
               controlDate = new Date(this.monthEnd.getTime() - (this.monthEnd.getTimezoneOffset() * 60000)).toISOString();     
            }
            let startOfEvent = this.diaryEvents[i].Start;
            let newDate = new Date(this.diaryEvents[i].Start);
            newDate.setHours(0, 0, 0, 0);
            newDate = new Date(newDate.getTime() - (newDate.getTimezoneOffset() * 60000));
    

            newDate = moment(newDate).add(1, 'days').toDate();
            this.diaryEvents[i].End = moment.utc(newDate).format("YYYY-MM-DDTHH:mm:ss"); // new Date(newDate.getTime() - (newDate.getTimezoneOffset() * 60000)).toISOString();


            startOfEvent = moment.utc(startOfEvent).add(1, 'days').format("YYYY-MM-DDTHH:mm:ss");
            let index = 1;
            while (!moment.utc(startOfEvent).isSame(moment.utc(controlDate).add(1, 'days'), 'day')) {
                let newEvent: DiaryEvent = new DiaryEvent;
                newEvent = {...this.diaryEvents[i]}; // new DiaryEvent();
                newEvent.RowId = newKey;
                const newStart =  new Date(moment.utc(startOfEvent).format("YYYY-MM-DD"));
                newStart.setHours(0,0,0,0);
                newEvent.Start = newStart;
                const newEnd =  new Date(new Date(startOfEvent).getTime() - (new Date(startOfEvent).getTimezoneOffset() * 60000));
                newEnd.setHours(0,0,0,0);
                newEvent.End = newEnd; 
                //have we reached final day
                if (moment.utc(startOfEvent,"DD-MM-YY").isSame(moment.utc(controlDate,"DD-MM-YY"), 'day')) {
                    //set time to the original end time
                    newEvent.End.setHours(new Date(controlDate).getHours(), new Date(controlDate).getMinutes(), new Date(controlDate).getSeconds(), 0);
                }

                newEvent.UniqueId = (newEvent.RowId + newEvent.ObjectDiaryId + Math.random()).toString();

                this.diaryEvents.splice(indexLength + index, 0, newEvent);
                this.diaryEvents[indexLength + index].Start = new Date(this.diaryEvents[indexLength + index].Start.getTime() - (this.diaryEvents[indexLength + index].Start.getTimezoneOffset() * 60000)).toISOString();
                this.diaryEvents[indexLength + index].End =  new Date(this.diaryEvents[indexLength + index].End.getTime() - (this.diaryEvents[indexLength + index].End.getTimezoneOffset() * 60000)).toISOString();
                const checkDate = new Date(new Date(this.diaryEvents[indexLength + index].Start).getTime() - (new Date(this.diaryEvents[indexLength + index].Start).getTimezoneOffset() * 60000))
                if (checkDate >=  this.monthStart &&
                    checkDate <= this.monthEnd) {
                    this.diaryEvents[indexLength + index].UpcomingEvent = true;
                    const start = this.diaryEvents[indexLength + index].Start.toString().substr(0,10)
                    const [,, day] = start.split('-');
                    this.eventDays.push(day);
                }
                else {
                    this.diaryEvents[indexLength + index].UpcomingEvent = false;
                }


                newKey++;
                index++;
                startOfEvent = moment.utc(startOfEvent).add(1, 'days').format("YYYY-MM-DDTHH:mm:ss");
            }
            indexLength = indexLength + index - 1;
        }
        else
        {
            if((moment(this.diaryEvents[i].End).format("hh:mm:ss a")) === "11:59:59 pm") {
                let newDate2 = new Date(this.diaryEvents[i].End);
                newDate2.setHours(0, 0, 0, 0);
                newDate2 = new Date(newDate2.getTime() - (newDate2.getTimezoneOffset() * 60000));
                if ((moment(this.diaryEvents[i].Start).format("hh:mm:ss a")) === "12:00:00 am") {
                    this.diaryEvents[i].End = moment.utc(newDate2).format("YYYY-MM-DDTHH:mm:ss");
                }
                else {
                    this.diaryEvents[i].End = moment.utc(newDate2).add(1, 'days').format("YYYY-MM-DDTHH:mm:ss");
                }
            }
        }
        i++;
    }
    if (changedArray) {
        //resort the array if inserted new events
        this.diaryEvents.sort(function (a, b) { return +new Date(a.Start) - +new Date(b.Start) });
    }

    if(this.diaryEvents.length > 0 && this.pickerDateMove && !this.isToday && !this.storeStartDate)
    {
        const index = this.diaryEvents.findIndex(x => x.UpcomingEvent == true);
        if(index !== -1)
        {
            if(!moment(this.diaryEvents[index].Start).startOf('day').isSame(moment(this.selectedDate).startOf('day'),'days'))
            {
                this.selectedDate = new Date(this.diaryEvents[index].Start);
                this.modelDate = new Date(this.selectedDate.getTime() - (this.selectedDate.getTimezoneOffset() * 60000)).toISOString().substr(0, 10);
            }
        }
    }

     //loop through and set the time of the next shifts.
     length = diaryEvents.length - 1;
     i = 0;

     while(i < length) {
        if(this.diaryEvents[i].ClientId !== 0) {
             if(this.diaryEvents[i+1].ClientId !== 0) {
                 if (!moment(this.diaryEvents[i].End).isSame(moment(this.diaryEvents[i+1].Start))) {
                     this.diaryEvents[i].NextShiftStartime = this.diaryEvents[i+1].Start;
                 }
             }
         }
         i++;
     }

    this.pickerDateMove = false;
    this.isToday = false;
    this.diaryEventsFinal = [...this.diaryEvents];
}



get target() {
    this.selector = '#d' + this.selectedDate.toLocaleDateString().split("/").join("");
    return this.selector;
}

get options() {
    return {
        duration: this.duration,
        offset: this.offset
    }
}

scrollIntoView() {
    if(!this.scroll)
    {
        return;
    }
    this.$nextTick(() => {
        const findEle = document.querySelector(this.target);
        if (findEle) {
            this.$vuetify.goTo(this.target, this.options);
        }
    });
}


}