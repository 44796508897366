import store from "../store/index";
import googleHelper from "@/utils/googleMapsHelper";
import hereMapsHelper from "@/utils/hereMapsHelper";
import { MapType } from '@/store/AppState';
import errorLogger from '@/utils/errorLogger'
import JobService,{ JobLocationsRequest, JobLocationsResult, JobLocations } from '@/components/rdb/components/jobs/JobService';

export interface LocationService extends LocationTextLookup {
    getCarTravelTime(args: TraveltimeRequest): Promise<TravelTimeResponse>;
    getBusTravelTime(args: TraveltimeRequest): Promise<TravelTimeResponse>;
    getTrainTravelTime(args: TraveltimeRequest): Promise<TravelTimeResponse>;
    getBicycleTravelTime(args: TraveltimeRequest): Promise<TravelTimeResponse>;
    getWalkingTravelTime(args: TraveltimeRequest): Promise<TravelTimeResponse>;
    
}

export interface LocationTextLookup{
    textSearch(location: string): Promise<TextSearchResponse[]>;
}


export class TraveltimeRequest {
   
    start: {
        lat: number;
        lng: number;
    };
    destination: {
        lat: number;
        lng: number;
    };
}

export class TravelTimeResponse {
    travelTime: number;
    travelTimeText: string;
}


export class TextSearchResponse {
    lat: number;
    lng: number;
    locationText: string;
    id?: string;
}

export class LocationServices {

    locationService(): LocationService{
        if(store.getters.AppState.mapService == MapType.Google){
            return googleHelper;
        }else if(store.getters.AppState.mapService == MapType.HereMaps) {
           return  hereMapsHelper;
        }
    }

    locationlookUpService(): LocationTextLookup{
        if(store.getters.AppState.mapService == MapType.Google){
            return googleHelper;
        }else if(store.getters.AppState.mapService == MapType.HereMaps) {
           return  hereMapsHelper;
        }
        else{
            return JobService;
        }
    }

    getCarTravelTime(args: TraveltimeRequest): Promise<TravelTimeResponse>{

        return new Promise<TravelTimeResponse>((s,e)=>{
            this.locationService().getCarTravelTime(args).then(result=>{
                s(result);
            }).catch(r=>{
                errorLogger.logError(r);
                e(r);
            });
        });     
    }
    
    getBusTravelTime(args: TraveltimeRequest): Promise<TravelTimeResponse>{
        return new Promise<TravelTimeResponse>((s,e)=>{
            this.locationService().getBusTravelTime(args).then(result=>{
                s(result);
            }).catch(r=>{
                errorLogger.logError(r);
                e(r);
            });
        }); 
    }

    getTrainTravelTime(args: TraveltimeRequest): Promise<TravelTimeResponse>{
        return new Promise<TravelTimeResponse>((s,e)=>{
            this.locationService().getTrainTravelTime(args).then(result=>{
                s(result);
            }).catch(r=>{
                errorLogger.logError(r);
                e(r);
            });
        });
    }

    getBicycleTravelTime(args: TraveltimeRequest): Promise<TravelTimeResponse>{
        return new Promise<TravelTimeResponse>((s,e)=>{
            this.locationService().getBicycleTravelTime(args).then(result=>{
                s(result);
            }).catch(r=>{
                errorLogger.logError(r);
                e(r);
            });
        });
    }

    getWalkingTravelTime(args: TraveltimeRequest): Promise<TravelTimeResponse>{
        return new Promise<TravelTimeResponse>((s,e)=>{
            this.locationService().getWalkingTravelTime(args).then(result=>{
                s(result);
            }).catch(r=>{
                errorLogger.logError(r);
                e(r);
            });
        });
    }

    textSearch(location: string): Promise<TextSearchResponse[]>{
        return new Promise<TextSearchResponse[]>((s,e)=>{
            this.locationlookUpService().textSearch(location).then(result=>{
                s(result);
            }).catch(r=>{
                errorLogger.logError(r);
                e(r);
            });
        });
    }
}

export default new LocationServices();