import Vue from 'vue'
import Component from 'vue-class-component'
 @Component({
  methods:{
  }
 })
 //https://dev.to/drbragg/handling-service-worker-updates-in-your-vue-pwa-1pip
 export default class UpdateBanner extends Vue {
  
    showDialog=false;
    registration: any;
    updateExists = false;
    refreshing = false;
   onUpdateClicked(){
      this.showDialog = false;
      this.refreshApp();
   }

   showRefreshUI(event){
        this.showDialog = true;
        this.registration = event.detail
        this.updateExists = true;
   }

   refreshApp() {
     this.updateExists = false
     // Make sure we only send a 'skip waiting' message if the SW is waiting
     if (this.registration && this.registration.waiting) {
     // Send message to SW to skip the waiting and activate the new SW
         this.registration.waiting.postMessage({ type: 'SKIP_WAITING' })
      }
     window.location.reload();
   }

   created() {
        //document.addEventListener('swUpdated', this.showRefreshUI,{once:true});

        if(navigator && navigator.serviceWorker){
          navigator.serviceWorker.addEventListener('controllerchange', () => {
            const routeName = this.$route.name;

            if(routeName !== "registerConfirm" && routeName !== "viewshift" && routeName !== "clock" && routeName !== "viewtimesheet" ){
            
                 // We'll also need to add 'refreshing' to our data originally set to false.
                if (this.refreshing) return
                this.refreshing = true
                // Here the actual reload of the page occurs
                this.showDialog = true;
                this.updateExists = true;
            }
            //window.location.reload()
          })
        }
   }
 }