import store from '@/store';
import BaseService from './baseService';



export class UserTerms  extends BaseService{

    baseUrl: string;

    getTerms()
    {
        return new Promise<string>((resolve,reject)=>
        {
            return this.httpClient("MOB").get("/Terms").then(async res => {
                return  res.data;
             }).then(async r => {
               resolve(r);
             }).catch((e)=> {
                reject(e);
             });
        }
        );
    }


    getUserAcceptedTerms() {
        return new Promise<boolean>((resolve,reject)=>
        {
            return this.httpClient("MOB").get("/TenantUserTerms").then(async res => {
                return  res.data;
             }).then(async r => {
               resolve(r);
             }).catch((e)=> {
                reject(e);
             });
        }
        );
    }

    postUserAcceptedTerms() {
        return new Promise<boolean>((resolve,reject)=>
        {
            return this.httpClient("MOB").post("/TenantUserTerms",null).then(async res => {
                if(res.data.value == true)
                {
                    
                    store.commit.AppState.UpdateHasAcceptedTerms(true);
                    return true;
                }
                else
                {
                    
                    return false;
                }
                
             }).then(async r => {
               resolve(r);
             }).catch((e)=> {
                reject(e);
             });
        }
        );
    }


}

export default new UserTerms();