
<div class="pt-0">
  <v-app-bar dense fixed color="white" class="top-toolbar pt-0 ">
    <v-btn
      class="back-button navigation-button action-back"
      @click="back()"
      text
      ><v-icon>mdi-chevron-left</v-icon>{{ $t("back") }}</v-btn
    >
  </v-app-bar>
  <v-container
    class="div-background pt-0 mt-12 mb-14"
    style="max-wdith:500px"
  >
    <div id="setAvailDialog" class="text-h1 pt-6">{{ $t("availability.setAvailability") }}</div>

    <v-radio-group
      class="availability-radio"
      style="margin-top:7px;"
      row
      v-model="availability"
    >
      <!-- <div class="d-flex justify-space-between"> -->
        <div class="col-6 pa-0">
        <v-radio class="action-available" :value="0" :ripple="false"
          ><template v-slot:label>{{
            $t("availability.available")
          }}</template></v-radio
        >
        </div>
        <div class="col-6 pa-0">
        <v-radio
          class="my-radio action-unavailable mr-0"
          :value="1"
          :ripple="false"
          ><template v-slot:label>{{
            $t("availability.unavailable")
          }}</template></v-radio
        >
        </div>
      <!-- </div> -->
    </v-radio-group>

    <div class="text-h1 font-weight-bold ">
      {{$t("availability.selectDates")}}
      <div style="float: right;" class="nunito-small-caption-12">
        {{$t("availability.maxDays")}}
      </div>
    </div>
    <v-row>
      <v-col cols="12">
        <div class="div-cal">
          <div class="div-cal2">
            <AvailabilityCalendar
              class="action-select-date"
              :eventsLocal="eventDays"
              :pickerDateSync="pickerDate"
              :allowedDates="(date) => date >= new Date().toISOString().substr(0, 10)"
              :multiple="true"
              :modelDateLocal="dates"
              @pickDate="pickDateChanged"
              @pickerChanged="pickerChanged"                
              @input="saveMulti"
              @clearData="clearData"
            ></AvailabilityCalendar>
          </div>
        </div>
      </v-col>
    </v-row>
  <v-row v-if="true"><!-- Put in place to show validation messages on this screen, but not yet a requirement -->
    <div class="v-messages error--text" role="alert" v-if="localErrors.length > 0">
        <p>{{ $t("timesheets.timesheetErrorNote") }}</p>
        <li v-for="(error, index) in localErrors" :key="index">
          {{error.selectedDate}}: {{error.message}}  
        </li>
    </div>
  </v-row>
    <div class="text-h1 font-weight-bold ">
      {{ $t("availability.selectTime") }}
    </div>

    <v-row>
      <v-col cols="2" class="pt-0 pb-0 ">
        <v-switch
          class="switch action-allDay pt-0 mt-2"
          inset
          dense
          light
          @change="allDayChanged"
          v-model="allDay"
        >
        </v-switch>
      </v-col>
      <v-col cols="10">
        <div class="text-h2">
          {{ $t("availability.allDayEvent") }}
        </div>
      </v-col>
    </v-row>

    <div v-show="!allDay">
      <div class="text-h1 font-weight-bold ">{{ $t("availability.setTimes") }}</div>
      <v-row>
        <v-col cols="5" class="pb-1">
          <div class="text-h2 pt-2 ">
            {{ $t("availability.startTime") }}
          </div>
        </v-col>
        <v-col cols="5" class="pb-1">
          <div class="text-h2 pl-1 pt-2">
            {{ $t("availability.endTime") }}
          </div>
        </v-col>
      </v-row>
      <v-row style="height:60px">
        <v-col cols="5" class="pt-0">
          <div>
            <v-text-field
              style="font-size:13px;"
              class="action-set-start-time font-weight-medium"
              :data-fromDate="startTime"
              v-model="startTime"
              outlined
              dense
              readonly
              @click="setTimes"
            ></v-text-field>
          </div>
        </v-col>

        <div class="text font-weight-bold mt-3">:</div>

        <v-col cols="5" class="pt-0">
          <v-text-field
            style="font-size:13px;"
            class="action-set-end-time font-weight-medium"
            :data-fromDate="endTime"
            v-model="endTime"
            outlined
            dense
            readonly
            @click="setTimes"
          ></v-text-field>
        </v-col>
      </v-row>
    </div>
    <div
      class="v-messages warning--text pb-2"
      role="alert"
      v-if="errors.length > 0"
    >
      <li v-for="error in errors" :key="error.message">
        {{ error.message }}
      </li>
    </div>

    <div class="text-h2">{{ $t("availability.addDescription") }}</div>
    <v-row style="padding-bottom:90px;">
      <v-col cols="12">
        <v-textarea
          v-model="description"
          outlined
          class="action-add-description text-area-availability"
          rows="3"
          placeholder="Type here"
          maxlength="50"
        >
        </v-textarea>
      </v-col>
    </v-row>
  </v-container>

  <v-footer fixed color="white">
    <v-container class="pt-0 pb-0">
      <v-row justify="center">
        <v-col cols="6">
          <v-btn
            @click="cancel"
            class="action-cancel-availability"
            depressed
            block
          >
            {{ $t("cancel") }}
          </v-btn>
        </v-col>
        <v-col cols="6">
          <v-btn
            class="action-save-availability"
            @click="save"
            depressed
            block
            color="primary"
          >
            {{ $t("save") }}
          </v-btn>
        </v-col>
      </v-row>
      <div class="d-flex justify-center ">
        <div class="oval-large"></div>
      </div>
    </v-container>
  </v-footer>

  <v-bottom-sheet v-model="addTime" persistent>
    <v-sheet full-width class="rounded-corners-top">
      <div class="pa-4" style="width:100%; margin-right:auto; margin-left:auto">
        <div class="d-flex justify-center ">
          <div class="oval-small"></div>
        </div>
        <div
          class="v-messages warning--text"
          role="alert"
          v-if="errors.length > 0"
        >
          <li v-for="error in errors" :key="error.message">
            {{ error.message }}
          </li>
        </div>
        <div class="text-h1 font-weight-bold mt-5 ">Select Time</div>
         <v-row style="row-height:20px;">
            <v-col cols="6" class="pb-0 pt-0">
              <div class="text nunito-semibold nunito-small-caption mt-2 ">
                {{ $t("availability.startTime") }}
              </div>
            </v-col>
            <v-col cols="6" class="pb-0 pt-0 pl-0">
              <div class="text nunito-semibold nunito-small-caption mt-2">
                {{ $t("availability.endTime") }}
              </div>
            </v-col>
          </v-row>
        <v-form ref="form">
         

          <v-row style="min-height:230px" class="timepick ml-0 mr-0 mb-1" no-gutters>
            <v-col cols="4">
              <vue-timepicker 
                class="action-pick-start-time "
                @change="isTimeValid()"
                lazy
                auto-scroll
                hide-clear-button
                v-model="startTimeEdit"
                id="startTime"
                :minute-interval="1"
              ></vue-timepicker>
            </v-col>
            <v-col cols="2"></v-col>
            <v-col cols="4">
              <vue-timepicker 
                class="action-pick-end-time "
                @change="isTimeValid()"
                lazy
                auto-scroll
                hide-clear-button
                v-model="endTimeEdit"
                id="endTime"
                :minute-interval="1"
              ></vue-timepicker>
            </v-col>
          </v-row>
        </v-form>
        <v-divider></v-divider>
        <v-row class="d-flex justify-space-around">
          <v-col cols="6">
            <v-btn
              class="action-time-cancel"
              block
              depressed
              @click="cancelTime"
            >
              {{ $t("cancel") }}
            </v-btn>
          </v-col>
          <v-col cols="6">
            <v-btn
              class="primary action-time-apply"
              block
              depressed
              @click="submitTime"
            >
              {{ $t("apply") }}
            </v-btn>
          </v-col>
        </v-row>
        <div class="d-flex justify-center ">
          <div class="oval-large"></div>
        </div>
      </div>
    </v-sheet>
  </v-bottom-sheet>
</div>
