
<div>
  <v-container>
    <v-row justify="center" class="container">
      <v-col cols="12">
       
        <div class="center-content background-cloud ">
          
          <div class="text-center headingText">
            {{$t("qrcode.scanMessage")}}
          </div>
          <v-row justify="center">
            
            <v-col
              cols="8"
              class="pt-7 pb-0"
            >
            <div style="height:50vh;width=136px;position: relative;">
              <img
                
                src="@/assets/img/qrcode/path11367.png"
                height="100%"
                width="auto"
              />
              <div class="btnCamera">
                 <v-btn color="primary" fab small dark >
                <v-icon>mdi-camera</v-icon>
              </v-btn>
              </div>
            </div>  
            </v-col>
          </v-row>
        </div>
      </v-col>
    </v-row>


    <v-footer fixed class="pa-2">
  
      <v-btn
      class="action-open-scan mt-3"
      color="primary"
      depressed
      block
      @click="showQRScan = true"
      >Scan</v-btn
    >

    <v-btn class="action-cancel mt-3" depressed block @click="close">Cancel</v-btn
      >
      
    <v-btn class="action-skip mt-3" depressed block @click="skip">Not Right Now</v-btn
      >

    </v-footer>

    <v-dialog
      
      v-model="showQRScan"
      v-if="showQRScan"
      max-width="500px"
      fullscreen
      persistent
    >
      <QRCodeScan
        :isOpen="showQRScan"
        @close="closeQR"
        @result="result"
      ></QRCodeScan>
    </v-dialog>
  </v-container>
</div>
