
<div>
  <div v-if="multiple" class="mb-0 pl-1">
    <v-badge color="primary" :content="Count"> </v-badge>
    <div class="pl-6 nunito-small-caption-12" style="display:inline-block">
     {{$t("availability.daysSelectedCaption")}} 
    </div>

    <v-icon class="pl-2" @click="showDates">mdi-eye-outline</v-icon>
    <img
      src="@/assets/img/icons/delete.png"
      alt="clear"
      style="float: right;"
      v-on:click="clearDates"
      height="28px"
    />
  </div>

  <v-date-picker
    class="availability-calendar"
    full-width
    no-title
    light
    first-day-of-week="1"
    v-model="vmodelDate"
    :weekday-format="dayFormat"
    :events="eventColors"
    :min="minDate"
    :max="maxDate"
    :multiple="multiple"
    :allowedDates="allowDates"
    :picker-date.sync="pickDate"
    
    @input="saveMulti"
    @change="dateChanged"
    
  >
  </v-date-picker>

  <div v-if="showMultiData">
    <v-dialog
      v-model="showMultiData"
      max-width="500px"
      fullscreen
      scrollable
      persistent
    >
      <v-app-bar dense fixed color="white" class="top-toolbar pt-0 ">
        <div  class="text-h1 pt-6">{{$t("availability.selectedDatesHeading")}} - {{ Count }} {{$t("availability.daysSubHeading")}}</div>
      </v-app-bar>
      <v-container
        class="div-background pt-0 mt-12 mb-14"
        style="max-wdith:500px"
      >
        <v-list class="d-flex flex-wrap justify-center ">
          <v-list-item-group style="width:100%">
            <template v-for="(item, i) in vmodelDate">
              <v-list-item :key="i">
                <v-list-item-avatar>
                  <v-btn fab elevation="0" small dark color="#e8ebf3">
                    <p
                      class="font-weight-black"
                      style="color:black;padding-top:15px;"
                      v-text="
                        `${new Date(item).toLocaleDateString('default', {
                          month: 'short',
                        })}`
                      "
                    ></p>
                  </v-btn>
                </v-list-item-avatar>

                <v-list-item-content>
                  <v-list-item-title
                    class="text-h2"
                    v-text="formatDateMultiShow(item)"
                  ></v-list-item-title>
                </v-list-item-content>

                <v-list-item-action>
                  <img
                    src="@/assets/img/icons/delete.png"
                    style="float: right;"
                    v-on:click="vmodelDate.splice(i, 1)"
                    height="28px"
                  />
                </v-list-item-action>
              </v-list-item>
              <v-divider
                v-if="i < vmodelDate.length - 1"
                :key="i + 500"
              ></v-divider>
            </template>
          </v-list-item-group>
        </v-list>
        <v-footer fixed color="white">
          <v-container class="pt-0 pb-0">
            <v-row justify="center">
              <v-col cols="12">
                <v-btn
                  @click="showMultiData = false"
                  class="action-cancel-availability"
                  depressed
                  block
                >
                  {{ $t("close") }}
                </v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-footer>
      </v-container>
    </v-dialog>
  </div>
</div>
