
<v-dialog v-model="isVisible" @close="isVisible = false" hide-overlay persistent>
  <v-card>
    <v-card-title class="d-flex justify-center">

      <div class="d-flex flex-column">
        <!-- <img src="@/assets/img/icons/Success.png" height="100px"/> -->
        <v-row justify="center">
          <b>{{title}}</b>
        </v-row>
      </div>

    </v-card-title>
    <v-card-text class="alertBody">
      <v-spacer></v-spacer>
       <v-row justify="center" class="centered">
        {{message}}
      </v-row>
    </v-card-text>
    <v-card-actions>
    <div class="d-flex justify-space-between pa-4" style="width:100%">
      <div>
      <v-btn @click="cancel"> {{ $t("no") }}</v-btn>
    </div>
    <div>
      <v-btn  @click="confirm" color="primary"> {{ $t("yes") }}</v-btn>
    </div>
    </div>
      <!-- <v-btn class="primary" block text @click="close">
        <b>{{ $t("ok") }}</b>
      </v-btn> -->
    </v-card-actions>
  </v-card>

  </v-dialog>
