
 <div class="text-center">
  
<v-dialog v-model="showDialog" max-width="500px" fullscreen>
    
  <v-card>

    <v-app-bar dense fixed color="white" class="top-toolbar pt-0"> 
        <v-btn class="back-button navigation-button" @click="close()" text><v-icon>mdi-chevron-left</v-icon>Back</v-btn>
    </v-app-bar>

 
    <v-container v-if="showJobAge" class="pb-0 pt-2 " >
        <v-row justify="center">
            <v-col cols="12" class="pb-0">
                <div class="text-h1 pb-2">
                {{ $t("jobs.postedTitle") }}
                </div>

                <v-select
                style="height: 60px;"
                :items="jobAgeItems"
                v-model="selectedJobAge"
                item-value="id"
                item-text="name"
                solo
                dense class="pb-0 dropdown rounded-corners posted-date-selection"
                :menu-props="{ bottom: true, offsetY: true}"
                @change="filterUpdated"
                ></v-select>
               
            </v-col>
        </v-row>
       
    </v-container>

       <v-container class="pt-0 pb-0" v-if="employmentTypes !== undefined && employmentTypes.length > 0">
        <v-row justify="center">
            <v-col cols="12">
                <div class="text-h1 pb-4">
                    {{ $t("jobs.contractTypeTitle") }}
                </div>
            
        
            <v-btn-toggle v-model="selectedEmpTypes" class="d-flex flex-wrap employment-types" multiple group 
                @change="filterUpdated">
            <v-btn  class="rounded-corners pa-2 "  outlined v-for="filterInfo in employmentTypes" :key="filterInfo.Value" :value="filterInfo.Value" :label="filterInfo.Description">
                {{ filterInfo.Description }}
            </v-btn> 
            </v-btn-toggle>
       
    </v-col>
</v-row>
       </v-container>



       
         <v-container v-if="maxSalary !== 0" class="pt-0 pb-0">
            <v-row >
                <v-col cols="12" md="11">
                    <div class="text-h1">
                        {{ $t("jobs.salaryTitle") }}
                    </div>
                </v-col>
            </v-row>
            <v-row >
                <v-col class="pb-3 pt-1" cols="12" md="11">
                    <v-range-slider id="salarySlider"
                    
                    thumb-color="thumbColor"
                    v-model="selectedSalary"
                         thumb-label="always"          
                         :max="maxSalary"  
                         :min="minSalary"
                         @change="salaryChanged"
                         hide-details
                         class="align-center pl-3 pr-4 slider-label-over">  
                         <template v-slot:thumb-label="{ value }">
                             <div class="textSlide">
                                £{{ value.toLocaleString() }}
                            </div>
                         </template>           
                     </v-range-slider> 
                </v-col>
            </v-row>
        </v-container>

        <v-container v-if="maxHourly !== 0">
            <v-row >
                <v-col cols="12" md="11">
                    <div class="text-h1">
                    {{ $t("jobs.hourlyRateTitle") }}
                    </div>
                </v-col>
            </v-row>
            <v-row >
                <v-col class="pt-1" cols="12" md="11">
                  
                    <v-range-slider
                    id="hourlySlider"
                    thumb-color="thumbColor"
                    v-model="selectedHourly"
                    thumb-label="always"
                    :max="maxHourly"  
                    :min="minHourly"
                    @change="hourlyRateChanged"
                    hide-details
                    class="align-center pl-3 pr-4 slider-label-over">  
                    <template v-slot:thumb-label="{ value }">
                        <div class="my-label">
                            £{{ value.toLocaleString() }}
                        </div>
                      </template>           
                </v-range-slider>
                </v-col>
            </v-row>
        </v-container>   

        <v-container v-if="showRadius">
            <v-row>
                <v-col class="pt-0" cols="12" md="11">
                    <v-slider class="input-search-radius align-center pl-3 ml-0 pr-4"
                    v-model="jobSearchRadiusMiles"
                    :min="minDistance"
                    :max="maxDistance"
                    thumb-label="always"
                    thumb-color="thumbColor"                    
                @change="filterUpdated">
                <template v-slot:thumb-label="{ value } " >
                    <div style="min-width:62px;" class="pt-1">
                    {{ value }} {{$t("jobs.miles")}}
                    </div>   
                </template>    
                </v-slider>
                </v-col>
             </v-row>       
        </v-container>    

        <v-toolbar fixed flat> 
             <v-row justify="center">
                  <v-col cols="12" md="6">
                      <v-btn class="apply-filters" depressed block color="primary" @click="apply()" :disabled="applyButtonDisabled">{{ $t("jobs.filterApplyButton") }}</v-btn>
                  </v-col>
              </v-row>
        </v-toolbar> 
  </v-card>
</v-dialog> 
 </div>
