
<div>
   <JobSummaryCard :job="jobLocal" :showHeart="false" @setShortlisted="setShortlisted"
              @removeShortlisted="setShortlisted"></JobSummaryCard>

        <v-row class="d-flex justify-space-around ma-2">     
          <v-col cols="6">                   
            <v-btn type="submit" width="100%"  @click="rejectButtonClicked($event)" depressed class="grey-button action-reject">
              {{$t('reject')}}
            </v-btn>
            </v-col>
              <v-col cols="6"> 
              <v-btn color="primary" width="100%" @click="applyButtonClicked($event)" depressed class="action-accept">
              {{$t('accept')}}
              </v-btn>
            </v-col>
        </v-row>
</div>

