import { Vue, Component, Mixins } from "vue-property-decorator";
import {VueOfflineMixin}  from '../plugins/onlineOffline'

 @Component({
   
 })
 export default class OfflineNotification extends Mixins(VueOfflineMixin) {

      showOffline=false;
      showOnline=false;

     mounted(){
          this.$on('offline', () => {
              this.showOnline= false;
              this.showOffline= true;
          });

          this.$on('online', () => {
               this.showOnline= true;
               this.showOffline= false;   
          });
     }
 }