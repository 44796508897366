import Vue from 'vue'
import Home from '../views/Home.vue'
import Login from '../views/Login.vue'
import Logout from '../views/Logout.vue'
//import Debug from '../views/Debug.vue'
import NoAccess from '../views/NoAccess.vue'
import RegisterConfirm from '../views/RegisterConfirm.vue'
import mainAuth from '../auth';
import VueRouter from 'vue-router'
import store, {AppStore} from '../store/';

Vue.use(VueRouter);


import Profile from '../views/Profile.vue';
import Timesheets from '../components/rdb/components/timesheets/Timesheets.vue';
import Jobs from '../components/rdb/components/jobs/Jobs.vue';
import SearchJobs from '../components/rdb/components/jobs/components/SearchJobsView.vue';
import Availability from'../components/rdb/components/availability/Availability.vue';
import Onboarding from '../components/rdb/components/onboarding/Onboarding.vue';
import JobDetails from '../components/rdb/components/jobs/JobDetails.vue';
import MyJobs from '../components/rdb/components/myjobs/MyJobs.vue';
import QRStart from '../components/rdb/components/qrcode/QRStart.vue';
import JobApply from '../components/rdb/components/jobs/JobApply.vue';
import Payslips from '../components/rdb/components/payslip/Payslip.vue';
import Debug from '../views/Debug.vue';
import ManageCv from '../components/rdb/components/jobs/components/CVUpload.vue';
import BroadCastJobDetails from '@/components/rdb/components/broadcastjobs/BroadCastJobDetails.vue'


class NavigationRouter {
/* eslint-disable */

initialise(){

  const routes = [
    {
      path: '/',
      name: 'Home',
      component: SearchJobs,
      meta: {
        authName: mainAuth.client().authName,
        feature:"RCRMJOBS"
      },
    },
    {
      path: '/login',
      name: 'Login',
      component: Login,
    },
    {
      path: '/logout',
      name: 'Logout',
      component: Logout,
    },
    {
      path: '/home',
      component: Onboarding,
      meta: {
        authName: mainAuth.client().authName,
        feature:"RCRMJOBS"
      },
    },
    {
      path: '/profile',
      name: 'Profile',
      component:Profile,
      meta: {
        authName: mainAuth.client().authName
      },
    },
    {
      path: '/timesheets/',
      component:Timesheets,
      meta: {
        authName: mainAuth.client().authName,
        feature:"RCRMTIMESHEETS"
      },
    },
    {
      path: '/search/results',
      component:Jobs,
      meta: {
        authName: mainAuth.client().authName,
        feature:"RCRMJOBS"
      },
    },
    {
      path: '/search/',
      component:SearchJobs,
      meta: {
        authName: mainAuth.client().authName,
        feature:"RCRMJOBS"
      },
    },
    {
      path: '/job/:jobId',
      component:JobDetails,
      name: 'job',
       meta: {
        authName: mainAuth.client().authName,
        feature:"RCRMJOBS"
      },
    },
    {
    path: '/apply/:jobId',
    component:JobApply,
    name: 'jobapply',
     meta: {
      authName: mainAuth.client().authName,
      feature:"RCRMJOBS"
    },
    
  },
  {
    path: '/openshifts/:jobId',
    component:BroadCastJobDetails,
    name: 'broadcastjobdetails',
     meta: {
      authName: mainAuth.client().authName,
      feature:"RCRMJOBS"
    }
  },
  {
      path: '/availability/',
      component:Availability,
      meta: {
        authName: mainAuth.client().authName,
        feature:"RCRMAVAILABILITY"
      }
    },
    {
      path: '/debug/',
      component:Debug,
      meta: {
        authName: mainAuth.client().authName
      }
    },
    {
      path: '/onboarding',
      name: 'onboarding',
      component: Onboarding,
      meta: {
        feature:"RCRMJOBS"
      },
    },
    {
      path: '/noaccess',
      name: 'noaccess',
      component: NoAccess,
    },
    {
      path: '/jobs',
      name:'MyJobs',
      component:MyJobs,
      meta: {
        authName: mainAuth.client().authName,
        feature:"RCRMJOBS"
      },
    },
    {
      path: '/viewshift',
      component:Timesheets,
      meta: {
        authName: mainAuth.client().authName,
        feature:"RCRMJOBS"
      },
    },
    {
      path: '/viewtimesheet',
      component:Timesheets,
      meta: {
        authName: mainAuth.client().authName,
        feature:"RCRMJOBS"
      },
    },
    {
      path: '/clock',
      component:Timesheets,
      meta: {
        authName: mainAuth.client().authName,
        feature:"RCRMJOBS"
      },
    },
    {
      path: '/payslip',
      component:Payslips,
      meta: {
        authName: mainAuth.client().authName,
        feature:"RCRMPAYSLIP"
      }
    },
    {
      path: '/registerConfirm',
      component: RegisterConfirm,
      name: 'registerConfirm'
    },
    {
      path: '/managecv',
      name: 'managecv',
      component: ManageCv,
      meta: {
        authName: mainAuth.client().authName,
        feature:"RCRMJOBS"
      },
    },
  ];

    const Router = new VueRouter({routes:routes,mode:"history"});

    Router.beforeEach((to, from, next) => {
      

      //Only check once every 5 mins and only on secure paths.
      if(store.getters.AppState.checkForUpdate && to.matched.some(record => record.meta.authName)){
        store.commit.AppState.UpdateLastUpdateCheck(new Date());
          //Check or updates on navigation
          if(navigator.serviceWorker){
            navigator.serviceWorker.getRegistration().then(registration => {
              if(registration){
                registration.update();
              }
            });
          }
      }
      

      //Check if feature is enabled before allowing access
      if (to.matched.some(record => record.meta.feature)) {
        if (!store.getters.AppState.featureEnabled(to.meta.feature)) {
          next({
            path: '/noaccess',
            query: { redirect: to.fullPath }
          })
        } else {
            next();
        }
      } else {
            next();
      }
  });

  return Router;
}
}




export default new NavigationRouter();
/* eslint-enable */