<template>

    <div class="text-center">
       <div class="d-flex justify-space-between pb-3 range-selector pl-4 pr-4">
          <div>
            <v-btn  :disabled="disabled" text outlined fab x-small elevation="0"  @click="previous">
              <v-icon>mdi-chevron-left</v-icon>
            </v-btn></div>
            <div class="ma-auto">
              <div class="date-range">
                {{startDateText}} - {{endDateText}}, {{yearText}}
              </div>
            </div>

          <div>
            <v-btn :disabled="disabled" text outlined fab x-small elevation="0"   @click="next">
              <v-icon>mdi-chevron-right</v-icon>
          </v-btn>
        </div>
      </div> 

      
     
      <!-- <div class="tabs pt-4 tab-container">
       
        <div v-for="(item,index) in days" v-bind:key="index.text"   @click="changeDate(item)" :class="selectedDate == item.date? 'active':''">
          <div  class="tab"  >
            {{item.text.toUpperCase()}} 
          </div>
       </div>
      
    </div>
    
    <div class="d-flex justify-space-between tabs">
      <div v-for="(item,index) in days" v-bind:key="index.text"   @click="changeDate(item)" class="dayOfWeek-container" :class="selectedDate == item.date? 'active':''">
        <div class="dayOfWeek mt-3" :class="selectedDate == item.date? 'active':''">
          
            <span>
            {{item.day}} </span>
        </div>
     </div>
    
  </div> -->


        

       <div class="d-flex justify-space-between tabs pt-4 pl-4 pr-4">
       
          <div v-for="(item,index) in days" v-bind:key="index.text"   @click="changeDate(item)" :class="selectedDate == item.date? 'active':''">
            <div  class="pa- tab"  >
              {{item.text.toUpperCase()}} 
            </div>
            <div class="dayOfWeek mt-3" :class="isToday(item.date) ? 'today':''">
              <span>
              {{item.day}} </span>
            </div>
         </div>
      </div> 

    </div> 
    
  </template>
  
  
  <script lang="ts">
    import ViewModel from "./WeekDatePicker"
    export default ViewModel;
  </script>

<style scoped>


.tab-container{
  display: flexbox;
}
.tabs
{
  font-size: 13px;
 
}

.tab{
  min-height:35px;
  min-width:50%;
  
  border-bottom: 4px solid #f6f7f8; 
}

.tabs > .active > .tab{
  font-weight: bold;
  border-bottom: 3px solid rgb(36, 38, 46);
 
}

.tabs > .today > .tab{
  font-weight: bold;
  border-bottom: 3px solid rgb(36, 38, 46);
 
}

.dayOfWeek {
  font-weight: normal;
  height: 38px;
  width: 38px;
  vertical-align: middle;;
  display: grid;
  font-weight: normal;
  margin:auto;
}

.dayOfWeek > span{
  margin:auto;
}

.tabs > div > .today {
  font-weight: bold;
  background-color: rgb(85, 91, 112);
  border-radius: 50%;
  color: white;
}


.date-range{
  font-size:14px;
  font-weight: bold;
  vertical-align: middle;
  display:grid;
}

.range-selector{
  border-bottom: 2px solid #f6f7f8; 
}

/* .date-range-button{
  font-size:14px;
  line-height: 11px;
  font-weight: bold;
} */

.dayOfWeek-container{
  min-width:50px;
}

  </style>
    