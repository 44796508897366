import { Vue, Component, PropSync, Watch, Mixins } from 'vue-property-decorator';
import  {  DiaryEvent, DiaryEventDeleteRequest, DiaryEventMultiDayInsertRequest} from "./DiaryService";
import moment, { Moment } from 'moment';
import LoadingIndicator from "../../../LoadingIndicator.vue";
import AvailabilityCardView from "../availability/AvailabilityCardView.vue";
import AvailabilitySet from "../availability/AvailabilitySet.vue";
import AvailabilityCalendar from "../availability/AvailabilityCalendar.vue";
import ConnectionLostCardView from '@/components/ConnectionLostCardView.vue';
import { AvailabilityCalendarMixin } from './AvailabilityCalendarMixin';
import AvailabilityJobDetails from "./AvailabilityJobDetails.vue";
import Alert from "@/components/Alert.vue";
import { mdiCurrencyBdt } from '@mdi/js';

Component.registerHooks([
    "beforeRouteEnter",
    "beforeRouteUpdate",
    "beforeRouteLeave"
]);

@Component({
    name: "availability",
    components: {
        LoadingIndicator,
        AvailabilityCardView,
        AvailabilitySet,
        Alert,
        AvailabilityCalendar,
        ConnectionLostCardView,
        AvailabilityJobDetails
    }
})

export default class Availability   extends Mixins(AvailabilityCalendarMixin) {

    addDiaryEvent = false;
    monthToPass = moment.utc().startOf('month').toDate();
    dateToPass: Date = new Date();
    saveError = false;
    lengths = 0;
    childPickerMoved = false;
    eventToDelete = null;
    showDelete = false;
    forceChildReload = 0;
    showJobDetails = false;
    eventToShow: DiaryEvent = new DiaryEvent;
    message = false;
    messageText = "";
    alertType = "";
    isConfirmation = false;
    availabilityOverride: boolean = null;
    newEvents: DiaryEventMultiDayInsertRequest = null;
    availabilityErrors: any[] = [];
 
    @Watch('availabilityOverride')
    availabilityOverrideChanged(val, oldVal) {
      if(val === true){
          if(this.newEvents){
            this.addDiaryEvent = false;
            this.saveEventData(this.newEvents);
          }
      }
      this.message = false;
      this.availabilityOverride = null;
    }

    constructor() {
        super();
    }

   
    mounted()
    {
        this.scroll = true;
        this.reloaded = true;
        this.setUp();
        this.reloaded = false;
        this.reload=0;
        this.monthToPass = this.monthStart;
        this.dateToPass = this.selectedDate;
        this.storeStartDate= false;
    }

    get xsExtensionHeight()
   {
    
    if(this.$vuetify.breakpoint.width <= 320) {
        return "300px"
    }
    return "360px";
    
   }

   get visibleEvents()
   {
       return this.diaryEventsFinal.filter((event) =>{
           return event.UpcomingEvent == true;
       })
   }

    vModelChanged(val)
    {
        this.modelDate = val;
    }

    pickDateChanged(val)
    {
        this.pickerDate = val;
    }
    get anythingToShow(): boolean {
        return this.diaryEvents.filter(e => e.UpcomingEvent === true).length > 0;
    }

    timeBetweenShifts(diaryEvent: DiaryEvent): string{

        moment.relativeTimeThreshold('m', 120);

        const duration = moment.duration((moment(diaryEvent.NextShiftStartime).diff(diaryEvent.End)));

        if (duration.asMinutes() > 90) {
            let result = duration.hours() + ' hours';
            if (duration.minutes() > 0) {
                result = result + duration.minutes() + 'minutes';
            }
            return result;
        } else {
            return duration.humanize();
        }
    }


    showDiary() {
        //using these fields to stop multiple calls to api when dates changes in child calendars
        this.monthToPass = this.monthStart;
        this.dateToPass = this.selectedDate;
        this.addDiaryEvent = true;
        this.availabilityErrors = [];
    }

   
    async saveEvent(event: DiaryEventMultiDayInsertRequest) {
        this.newEvents = event;
        this.validate(this.diaryEvents, event);
    }

    validate(events: any[], request: DiaryEventMultiDayInsertRequest){
        this.availabilityErrors = []; //Errors that we cannot continue with
        const conflicts = []; //Errors that we can continue with but need confimation
        let canContinue = true;

        request.Dates.forEach(d => {
            const currentDate: Date = moment.utc(d).toDate();

            const previousAvailability = events.find(evt => moment.utc(evt.Start).startOf("day").toDate().getTime() === currentDate.getTime() 
            && (evt.ObjectDiaryEventTypeDescription.indexOf("Available") === 0 || evt.ObjectDiaryEventTypeDescription.indexOf("Unavailable") === 0));

            if(previousAvailability){
                const data = {selectedDate: currentDate.toLocaleDateString(), message: `${this.$t("alertMessages.previouslySetAvailability")} '${previousAvailability.Description}'`};
                conflicts.push(data);
            }

            if(moment.utc(d).isBefore(moment.utc(), 'D')){
                const data = {selectedDate: currentDate.toLocaleDateString(), message: this.$t("alertMessages.pastAvailability")};
                this.availabilityErrors.push(data);
                canContinue = false;
            }
        });

        if(request.StartTime === request.EndTime && !request.AllDayEvent){
            const data = {selectedDate: `${request.StartTime} - ${request.EndTime}`, message: this.$t("alertMessages.startTimeEndTimeAvailability").toString()};
            this.availabilityErrors.push(data);
            canContinue = false;
        }

        if(this.availabilityErrors && this.availabilityErrors.length > 0){
            this.showAlert(`${this.$t("alertMessages.availabilityValidationError").toString()}`, this.$t("alertMessages.warning").toString(), false);
        }

        if(conflicts && conflicts.length > 0  && this.availabilityErrors && this.availabilityErrors.length === 0){
            let dates = "<br/>";
            conflicts.forEach(c => {
                dates += `<br/>${c.selectedDate}: ${c.message}`;
            });
            
            this.showAlert(`${this.$t("alertMessages.previouslySetAvailabilityConfimation").toString()}${dates}`, this.$t("alertMessages.warning").toString(), true);
        }

        //No errors
        if(canContinue && (conflicts && conflicts.length == 0) && (this.availabilityErrors && this.availabilityErrors.length == 0)){
            if(this.newEvents){
                this.addDiaryEvent = false;
                this.saveEventData(this.newEvents);
            }
        }
    }

    showAlert(alertMessage: string, type: string, isConfirmation: boolean){
        this.messageText = alertMessage;
        this.alertType = type;
        this.message = true;
        this.isConfirmation = isConfirmation;
    }

    closeAlert(){
        this.message = false;
    }

    beforeRouteUpdate(to, from, next) {
        if (this.addDiaryEvent) {
            this.addDiaryEvent = false;
            next(false);
        } else {
            next();
        }
    }

    beforeRouteLeave(to, from, next) {
        if (this.addDiaryEvent) {
            this.addDiaryEvent = false;
            next(false);
        }
        else {
            next();
        }
    }


    dateChanged(date) {
        this.selectedDate = new Date(date);
        this.selectedDate.setHours(0, 0, 0, 0);
        this.monthStart = moment(this.selectedDate).startOf('month').toDate();
        this.modelDate = new Date(this.selectedDate.getTime() - (this.selectedDate.getTimezoneOffset() * 60000)).toISOString().substr(0, 10);
        
        this.pickerDate = this.selectedDate.getFullYear() + "-" + (('0' + (this.selectedDate.getMonth() + 1)).slice(-2));
        this.scrollIntoView();
    }

    deleteEvent(event)
    {
        this.eventToDelete = event;
        //stop delete option appearing on card 
        event.CanDelete = false;
        this.showDelete = true;
     
    }

    selectedEvent(event)
    {
       
        if (event.JobId > 0) {
             this.eventToShow = event;
             this.showJobDetails = true;
            }
    }

    closeDelete(){
        const diaryDeleteEvent = new DiaryEventDeleteRequest();
        diaryDeleteEvent.ObjectDiaryId = this.eventToDelete.ObjectDiaryId;
        diaryDeleteEvent.Start = this.eventToDelete.Start;
        diaryDeleteEvent.End = this.eventToDelete.End;
        //Call api then - remove from events rather than reloading ?

        this.deleteDiaryEvent(diaryDeleteEvent).then(result => {
            this.showDelete = false;
            this.forceChildReload += 1;
        });
    }
    
      cancelDelete()
      {
          this.showDelete = false;
          this.eventToDelete = null;
      }

}