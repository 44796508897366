<template>
<div>
  
    
  <div class="text-center">
    <v-dialog
      v-model="message"
      persistent
      width="500"
      
    >
    
    <v-card>
       <v-card-title class="d-flex justify-center">
          <div class="d-flex flex-column">
            <div class="text-center">
            <img src="@/assets/img/icons/Success.png" height="100px" width="106px"/>
            </div>
            <b class="mt-5">{{$t('installApp.installAppSuccess')}}</b>
          </div>
        </v-card-title>
        <v-card-text class="alertBody text-center">
          <v-spacer></v-spacer>
          <p>
             {{$t('installApp.installAppSuccessMessage')}}
          </p>
        </v-card-text>
        <v-card-actions>
           
          <v-btn class="action-install-success" 
            color="primary"
            depressed
            block
            @click="closeAlert"
          >
           {{$t('ok')}}
          </v-btn>
         
        </v-card-actions>
      </v-card>
      
    </v-dialog>
  </div>



  <div class="text-center">
    <v-dialog
      v-model="show"
      persistent
      width="296"
      height="434"
    >

    <v-card max-width="296"  >
       <v-card-title class="d-flex justify-center blue-back"  style="height:149px">
           
          <div class="d-flex flex-column pl-1" >
            
             <img src="@/assets/img/icons/Group 10140.png"  height="150px"/>
             
          </div>
          
        </v-card-title>
        <v-card-text class="alertBody text-center mt-10">
          <v-spacer></v-spacer>
          <div class="h1-large font-weight-bold ">{{$t('installApp.addToHomeScreenTitle')}}</div>
         
          <p class="mt-3">
             {{$t('installApp.addToHomeScreenMessage')}}
          </p>
        </v-card-text>
        <v-spacer></v-spacer>
         <div class="justify-center pb-2">
           <v-row justify="center" class="ml-0 mr-0" >
            
             <v-btn v-if="!showSelfInstall" class="action-install installbtns elevation-0" 
            color="primary"
           
            @click="promptInstall"
          >
           {{$t('installApp.installAppInstallButtonText')}}
          </v-btn>
           
           <v-btn v-if="showSelfInstall" class="action-self-install installbtns elevation-0" 
            color="primary"
           
            @click="selfInstall"
          >
           {{$t('installApp.installAppShowMeButton')}}
          </v-btn>
           </v-row>  
           <v-row justify="center" class="ml-0 mr-0">
            
            <v-btn text class="action-install-cancel installbtns conntinueBtn elevation-0"
            @click="cancelInstall"
          >
          {{$t('installApp.installAppLaterButtonText')}}
          </v-btn>
            
          </v-row>  
         </div> 
       
       
      </v-card>

   
    </v-dialog>
  </div>


   <div class="text-center">
    <v-dialog
      v-model="helpScreen"
      persistent
      width="296"
      height="306"
    >

    <v-card max-width="296px" max-height="306px" >
       <v-card-title class="d-flex justify-center">
        
             <div class="d-flex flex-column mt-5">
             <img src="@/assets/logo.png" height="80px" width="80px" />
            
          </div>
          
        </v-card-title>
        <v-card-text class="alertBody text-center mt-4">

          <p class=" pl-5 pr-5 nunito-reg16">
            {{$t('installApp.installAppShowMeMessagePt1')}} 
            <img v-if="!isIOS" src="@/assets/img/icons/App Options.png" width="4px" height="16px">
            <img v-if="isIOS" src="@/assets/img/icons/Group 10141.png" width="10pt" height="13pt">
            {{$t('installApp.installAppShowMeMessagePt2')}}
          </p>
        </v-card-text>
        <v-spacer></v-spacer>
         <div class="justify-center pb-2">
           
           <v-row justify="center" class="ml-0 mr-0 pb-5">
            
            <v-btn outlined class="action-self-help ok-btn elevation-0"
            @click="helpOk"
          >
          {{ $t("ok") }}
          </v-btn>
            
          </v-row>  
         </div> 

      </v-card>

   
    </v-dialog>
  </div>


  <div class="text-center">
    <v-dialog 
      v-model="showInstalling"
      hide-overlay
      persistent
      width="500"
      
    >
      <v-card height="300px">
        <v-layout align-center justify-center column fill-height>
            <v-flex class="installProgress" row align-center>
            <v-progress-circular
          :rotate="360"
          :size="100"
          :width="5"
          :value="value"
          color="primary"
           >
            <b>{{ value }}%</b>
            </v-progress-circular>
            </v-flex>
        </v-layout>  
      </v-card>
    </v-dialog>
  </div>


</div>
</template>


<script lang="ts">
  import ViewModel from "./InstallBanner"
  export default ViewModel;
</script>
  
 <style  scoped>
 
.installbtns
{
  min-width:232px !important;
}

.ok-btn
{
min-width: 161px !important;
border-color:#E7EBFA !important;
height: 40px !important;
}

.nunito-reg16
{
  font-family: 'Nunito' !important;
  font-size: 16px important;
  color: #6E6E6E;
}
.conntinueBtn
{
border-style:none !important; 
font-weight:600 !important;
padding-top: 2px;
}

 .installProgress >>> .v-progress-circular__overlay
 {
    fill: #e7e7ec;
 }

  .installProgress >>> .v-progress-circular__info
  {
    z-index: 9999
  }

  .blue-back
  {
    background-color: #337EB7;
  }
 </style> 