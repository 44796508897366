import JobService, { AppliedJob, Job } from "../../jobs/JobService";
import { Vue, Component, PropSync, Watch } from 'vue-property-decorator';
import JobSummaryCard from '@/components/rdb/components/jobs/components/JobSummaryCard.vue'

@Component({
  components: { JobSummaryCard }
})

export default class AppliedJobSummaryCard extends Vue {
  @PropSync("job", { type: Object }) jobLocal!: AppliedJob;
  overlay = false;

  constructor() {
    super();
  }

  get getCssClass(): string {
    let cssClass = "";

    switch(this.jobLocal.SystemCode) {
      case "APP_ACT_STT_REJECT":
      case "APP_ACT_STT_WITHDREW": 
      case "APP_ACT_STT_INTREJECT":
      case "APP_ACT_STT_OFFREJECT":
        cssClass = "applicantActionError";
        break;
      case 'APP_ACT_STT_SENDCV':
      case 'APP_ACT_STT_CVSENT':
      case 'APP_ACT_STT_INTERVIEW':
      case 'APP_ACT_STT_REINTERVIEW':
      case 'APP_ACT_STT_INTOFFER':
      case 'APP_ACT_STT_OFFACCEPT':
        cssClass = "applicantActionSuccess";
        break;
    }

    return cssClass;
  }

  get getStage(): number {
    let stage = 0;

    switch(this.jobLocal.SystemCode) {
      case "'APP_ACT_STT_SENDCV":
      case "APP_ACT_STT_CVSENT":
      case "APP_ACT_STT_REJECT'":
        stage = 1;
        break;
      case "APP_ACT_STT_INTERVIEW":
      case "APP_ACT_STT_WITHDREW":
      case "APP_ACT_STT_REINTERVIEW":
        stage = 2;
        break;
      case "APP_ACT_STT_INTOFFER":
      case "APP_ACT_STT_OFFACCEPT":
      case "APP_ACT_STT_INTREJECT":
      case "APP_ACT_STT_OFFREJECT":
        stage = 3;
        break;
     }

    return stage;
  }

  setShortlisted(job: Job){
    this.$emit("setShortlisted",this.jobLocal);
  }

  removeShortlisted(job: Job){
    this.$emit("removeShortlisted",this.jobLocal);
  }
}