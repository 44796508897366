
  <div class="pt-0">
 
  <v-app-bar dense fixed color="white" class="top-toolbar" extension-height="197px"> 
            
      <v-btn class="back-button navigation-button action-back" @click="back()" text><v-icon>mdi-chevron-left</v-icon>Back</v-btn>
      <v-spacer></v-spacer>
      
      <template v-slot:extension v-if="!showNetworkError">
        <div style="width:100%;background-color:white">
        <v-container style="background-color:white!important;" class="pt-0">
          <v-tabs v-model="selectedTab">  
            <v-tab v-for="item in tabs" :key="item" >{{item}}</v-tab>  
          </v-tabs>

             <v-row class="d-flex justify-space-around pt-4" style="min-height:40px">
                <div class="img-round">
                  <img class="centered justify-center" block v-if="!isLoading && !timesheet.HasLogo"  alt="" src="@/assets/logo.png" height="34px">
                  <img class="centered justify-center" block v-if="!isLoading && logoUrl !== ''"  alt="" :src="logoUrl" height="34px" >
                </div>
              </v-row>
        </v-container>

        <v-divider/>
        <v-toolbar flat v-show="!showNetworkError" class="pl-2 pr-2 pb-0" >
          <v-row>
         <v-btn :disabled="isLoading" text outlined fab x-small @click="prev" >
            <v-icon>mdi-chevron-left</v-icon>
         </v-btn>
          <v-spacer/>
          
          <v-toolbar-title text x-small class="text-h2 lh-33 secondary-text-color"> {{ formatDate(timesheet.PeriodStarting, 'Do MMM') }} - {{ formatDate(timesheet.PeriodEnding, 'Do MMM, YYYY') }}</v-toolbar-title>
          <v-spacer/>
          <v-btn :disabled="isLoading" text outlined fab x-small @click="next">
            <v-icon>mdi-chevron-right</v-icon>
          </v-btn>
          </v-row>
        </v-toolbar>
        <div>
          <v-divider/>
        </div>
      </div>
      </template>
  </v-app-bar>

  
  <v-container class="div-background pt-0 mt-12 centered pb-0" >


    <ConnectionLostCardView :isVisible="showNetworkError" @close="retryClicked"></ConnectionLostCardView>
  <!---->

    <div v-show="!showNetworkError"  style="min-height:35px">
    
      <!-- <div  class="image-container pa-0 logo-image d-flex justify-center rounded img-round" style="height: 34px;width: 34px;">
        <div>
       
        </div>
      </div> -->
  
   
      <!-- <v-row class="d-flex justify-space-around">
        <div class="text-h2" v-if="!isLoading">{{timesheet.JobTitle}}</div>
      </v-row>
      <v-row class="d-flex justify-space-around">
        <div class="text-subtitle-1 secondary-text-color" v-if="!isLoading">{{timesheet.CompanyName}}</div>
      </v-row> -->
    </div>

  </v-container>



  <div v-show="selectedTab === 0" style="margin-top:130px;margin-bottom:120px">
     <LoadingIndicator class="pt-5" :isVisible="isLoading"></LoadingIndicator> 
       <v-list dense v-show="!showNetworkError && !isLoading" >
           <v-list-item>

           <v-list-item-content>
                <v-row no-gutters class="text-h2 centered">
                  <v-col cols="2"   >
                    Date
                 </v-col>
                 <v-col cols="2"  >
                    Start 
                 </v-col>
                 <v-col cols="2" >
                    End 
                 </v-col>
                 <v-col cols="2" >
                    Total
                 </v-col>
                 <v-col cols="2" >
                    Pay Rate
                 </v-col>
                 <v-col cols="2"  v-if="timesheet.AllowedToEdit">
                    Edit
                 </v-col>
                </v-row>
          </v-list-item-content>
          
          </v-list-item>

      <template  v-for="timesheetHour in timesheetHours">              
        <v-list-item :data-timesheetHourId="timesheetHour.TimesheetHourId" class="rounded-corners card-border job-list-item" :key="timesheetHour.TimesheetHourId" :src="timesheetHour.TimesheetHourId">

            <v-list-item-content>
             <v-row no-gutters class="centered">
                  <v-col cols="2" >
                    <div class="">
                      <div class="text-h1 secondary-text-color fs-20">
                        {{ formatDate(timesheetHour.WorkedOn , 'D') }}
                      </div>
                      <div class="secondary-text-color text-subtitle-1">
                      {{ formatDate(timesheetHour.WorkedOn , 'MMM') }}                    
                    </div>
                  </div>
                 </v-col>
                 <v-col cols="2"  class="secondary-text-color fw-600 times  lh-33">
                    {{ formatDate(timesheetHour.StartTime , 'HH : mm') }}
                 </v-col>
                 <v-col cols="2" class="secondary-text-color fw-600 times  lh-33" >                    
                    {{ formatDate(timesheetHour.EndTime , 'HH : mm') }}
                 </v-col>
                 <v-col cols="2" class="fw-600 times lh-33">
                   {{ formatDecimalTime(getTimesheetHourTotal(timesheetHour.TimesheetHourId), 'HH : mm',timesheetHour.TimesheetHourId) }}

                 </v-col>
                 <v-col cols="2" class="fw-600 times  lh-33">
                   <div>{{ timesheetHour.PayRate.toFixed(2) }}</div>
                   <div v-if="timesheetHour.ChargeUnit" class="secondary-text-color text-subtitle-1">per {{ timesheetHour.ChargeUnit.toLowerCase() }}</div>
                 </v-col>
                 <v-col cols="2"  v-if="timesheet.AllowedToEdit " >
                   <v-btn text outlined fab x-small @click="editTimesheetRow(timesheetHour.TimesheetHourId)">
                    <v-icon>mdi-pencil</v-icon>
                  </v-btn>
                 </v-col>
                </v-row>
            </v-list-item-content>
        </v-list-item>
      </template>
    </v-list> 
  </div>

  <div v-show="selectedTab === 1" style="margin-top:130px;margin-bottom:120px">
    <LoadingIndicator class="pt-5" :isVisible="isLoading"></LoadingIndicator> 

    
    <div style="margin-top:150px" v-if="expenses.length === 0 && !isLoading" class="centered">
      <v-container>
      <div>
        <img src="@/assets/img/timesheets/NoExpenses.png" height="100px" />
      </div>
        <div class="no-results-title">
            {{$t("timesheets.noExpensesTitle")}}
        </div>
        <div class="no-results-text" v-if="timesheet.AllowedExpenses">
            {{$t("timesheets.noExpensesDescription")}}
        </div>
      </v-container>
    </div>
    
     <v-list dense v-show="!showNetworkError && !isLoading && expenses.length > 0">
           <v-list-item>

           <v-list-item-content>
                <v-row no-gutters class="text-h2 toolbar">
                  <v-col cols="4"  >
                    {{ $t("timesheets.ExpenseType") }}
                 </v-col>
                 <v-col cols="2" class="centered">
                    {{ $t("timesheets.ExpenseQuantityTitle") }}
                 </v-col>
                 <v-col cols="2"  class="centered">
                     {{ $t("timesheets.ExpenseTotalTitle") }}
                 </v-col>
                 <v-col cols="4" class="centered" >
                    {{ $t("timesheets.ExpenseStatusTitle") }}
                 </v-col>
                </v-row>
          </v-list-item-content>
          
          </v-list-item>

         

          <template  v-for="expense in expenses">              
        <v-list-item :data-expenseId="expense.TimesheetExpenseId" class="rounded-corners expense-list-item" :key="expense.TimesheetExpenseId" :src="expense.TimesheetExpenseId">
            <v-list-item-content>
              <v-row no-gutters class="text-h2 expense-list-item pt-2" @click="expenseSelected(expense)">
                <v-col cols="9" md="9" sm="9" class="border-right">
                  <v-row>
                    <v-col cols="6" >                    
                      {{expense.Description}}     
                    </v-col>
                    <v-col cols="2" class="secondary-text-color">
                      {{expense.Quantity}}   
                    </v-col>
                    <v-col cols="4">                    
                      {{expense.CurrencySymbol}} {{expense.Total.toFixed(2)}}   
                    </v-col>
                  </v-row>        
                  <v-row class="secondary-text-color ml-1 notes">
                      <span class="note-text">{{expense.Note}}</span>
                  </v-row>
                </v-col>
                <v-col cols="3" sm="3" md="1" class="centered" >
                  <div class="ma-auto">   
                    <img  alt="" :class="statusClass(expense)"  height="auto" width="30px" />
                  </div>
                  <div class="text-subtitle-1 padding-2" :data-timesheetstatus="expense.ExpenseApprovalStatusDescription">{{expense.ExpenseApprovalStatusDescription}}</div>
                </v-col>
              </v-row>
              <v-row no-gutters class="centered pt-2 expense-list-item" v-if="expense.AllowedToEdit">
                 <!-- <v-col class="ellipsis" cols="2"  >
                    {{expense.Note}}   
                 </v-col> -->
                <v-col cols="2" sm="2" >
                  <div class="d-flex ">
                    <div>
                      <v-btn text outlined fab x-small @click="expenseSelected(expense)">
                        <v-icon>mdi-pencil</v-icon>
                      </v-btn>
                    </div>
                    <div>
                      <v-btn text outlined fab x-small @click="deleteExpense(expense)">
                        <v-icon>mdi-delete</v-icon>
                      </v-btn>
                    </div>  
                  </div>                   
                </v-col>
                <v-col cols="6">
                </v-col>
                <v-col cols="4">
                  <div class="ml-auto">
                    <v-btn  depressed block color="primary" max-height="30" @click="submitExpense(expense)">
                      {{ $t("timesheets.ExpenseSubmit") }}
                    </v-btn>
                  </div>
                </v-col>
              </v-row>
            </v-list-item-content>
        </v-list-item>
      </template>

     </v-list>

    </div>


    <v-footer fixed v-show="!showNetworkError && !isLoading">
      <v-container v-show="selectedTab === 0">
      <v-row >

        <div class="fs-20 text-h1">Total  </div> <v-spacer></v-spacer>  <div class="text-h1"> {{ getTimesheetTotalHours('h : mm') }}</div>

        
      </v-row>
      <v-row>
        <v-col cols="12" md="12">
          <v-btn v-if="timesheet.StatusSystemCode === 'AWAITING_SUBMISSION' || timesheet.StatusSystemCode === 'NOT_ENTERED'" class="acion-submit-timesheet" depressed block color="primary" @click="submitTimesheet()">
             {{ $t("timesheets.submitTimesheet") }} 
          </v-btn>
          <v-btn v-if="timesheet.StatusSystemCode === 'AWAITING_APPROVAL'" class="acion-submitted-timesheet submitted-timesheet" depressed block>
            <v-icon>mdi-check</v-icon> {{ $t("timesheets.timesheetSubmitted") }}
          </v-btn>
        </v-col>
      </v-row>
       </v-container>

       <v-container class="expense-footer" v-show="selectedTab === 1">
          <v-row >
             <div class="fs-20 text-h1">Total  </div> <v-spacer></v-spacer>  <div class="text-h1">  {{ getTimesheetTotalExpense() }}</div>
          </v-row>
          <v-row>
            <v-col cols="12" md="12">
              <v-btn @click="addExpenseClicked()" v-if="timesheet.AllowedExpenses && !showNetworkError && !isLoading" class="acion-submit-timesheet" depressed block color="primary">
                {{ $t("timesheets.addExpense") }}
              </v-btn>
              
            </v-col>
          </v-row>
        </v-container>
    </v-footer>
    
 <v-bottom-sheet v-model="editTimesheet" >
        <v-sheet full-width class="rounded-corners-top">
          <v-container>
            <TimesheetEdit
              v-bind:timesheetHour="selectedtimesheetHour" v-bind:timesheetBreaks="selectedtimesheetHourBreaks" 
              @cancel="editTimesheet = false" @saveTimesheetHour="saveTimesheetHour">
            </TimesheetEdit>
          </v-container>
        </v-sheet>
  </v-bottom-sheet>

  <v-bottom-sheet v-model="showExpenseView" >
    <v-sheet full-width class="rounded-corners-top">
      <div>
        <div v-if="selectedExpense.TimesheetExpenseId > 0" class="h1-large pa-4"> {{ $t("timesheets.editExpense") }}</div>
        <div v-if="!selectedExpense.TimesheetExpenseId" class="h1-large pa-4"> {{ $t("timesheets.addExpense") }}</div>
        <v-divider></v-divider>
        <TimesheetExpenseView 
            :timesheetId="timesheetId"
            :timesheetExpenseId="selectedExpenseId" 
            @expenseSaved="expenseSaved"
            @cancelled="expenseCancelled"
            :visible="showExpenseView">
        </TimesheetExpenseView>
      </div>
    </v-sheet>
</v-bottom-sheet>



</div>
