import Vue from 'vue'
import { createOidcAuth, SignInType, LogLevel, OidcAuth } from './auth/VueOidcAuth'
import localStorageService from './store/localStorageService'
import { UserManager, Log, WebStorageStateStore, WebStorageStateStoreSettings } from 'oidc-client'
import Cookies from 'js-cookie';
import store from "./store/index";


export class CookieStorage {
  
  setItem(key, value){
       Cookies.set(key,value);
  }

  getItem(key){
      const item  = Cookies.get(key);
      return item;
  }

  removeItem(key){
      Cookies.remove(key);
  }
}

export class Authentication{
   mainOidc: OidcAuth;
   userManager: UserManager;

  userLoaded(): Promise<boolean> {
    const result = new Promise<boolean>((resolve,reject)=>{

      this.userManager.events.addUserLoaded(c =>{
        resolve(true);
      });
    });
    return result;
  }

  login() {
      this.mainOidc.signIn();
  }

  refreshTokenIsValid() {
    return this.mainOidc.refreshTokenIsValid();
  }


  removeUser(){
    return this.userManager.removeUser();
  }

  revokeAccessToken(){
    return this.userManager.revokeAccessToken();
  }
  

  client(){
      return this.initialise();
  }
  
  initialise(){
    if(!this.mainOidc){
      const settings: WebStorageStateStoreSettings = 
      {
        prefix: "oidc.", 
        store: localStorageService.getStore("state")
        //store: new CookieStorage()
      };
      
      const loco = window.location
      const appRootUrl = `${loco.protocol}//${loco.host}${import.meta.env.BASE_URL}`
      let identityUrl = store.getters.AppState.discovery.identitySettings.identityUrl;
      /* eslint-disable */
      // SignInType could be Window or Popup
      if(!identityUrl.endsWith("/")){
        identityUrl += "/";
      };

      const config = {
        authority: identityUrl,
        client_id: store.getters.AppState.discovery.identitySettings.clientId,
        //client_id: "ecc033a2-d835-4b6d-96e8-4039d4a89029",
        response_type: 'code',
        scope: 'openid profile email policy offline_access api.access.recruitmentmobile.worker',
        
        automaticSilentRenew:false,
        validateSubOnSilentRenew: false,
        monitorSession:false,
        filterProtocolClaims: true,
        loadUserInfo: true,
        revokeAccessTokenOnSignout : false,
        userStore: new WebStorageStateStore(settings),
        stateStore: new WebStorageStateStore(settings),
        //post_logout_redirect_uri: `${appRootUrl}/login`,
        
        redirect_uri: `${appRootUrl}auth/signinwin/${'main'}`,
        popup_post_logout_redirect_uri: `${'main'}auth/signoutpop/${'main'}`,
        popup_redirect_uri: `${appRootUrl}auth/signinpop/${'main'}`,
        silent_redirect_uri: `${appRootUrl}auth/signinsilent/${'main'}`,
        // popup_post_logout_redirect_uri: `${appRootUrl}/logout`,
         post_logout_redirect_uri:`${appRootUrl}`
      };
    
      this.userManager = new UserManager(config);
      
      this.mainOidc = createOidcAuth('main', SignInType.Window, appRootUrl ,config ,
      this.userManager,
      console,
      LogLevel.None);

      
      
      Vue.prototype.$oidc = this.mainOidc
    }
    return this.mainOidc;
  }
}



export default new Authentication();