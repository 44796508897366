
<div>

            <p class="text-center text-h1 pt-2">{{ $t("timesheets.onboardingTitleText") }}</p>
            <v-divider></v-divider>

            <v-carousel
            :continuous="false"
            :show-arrows="false"
            v-model="currentIndex"
            transition="fade-transition"
            :hide-delimiter-background="true"
            :hide-delimiters="true"
            light
            @change="onChange"
            height="72vh"
            
          >
          <v-carousel-item>
            <div>
              <v-container >
                <v-row >
                  <v-col cols="12">
                    <div class="center-content background-cloud " style="min-height:40vh" >
                      <img class="step-one"   />
                      </div>
                    <div class="center-content">

                    <h1 class="title">{{ $t("timesheets.onboardingSlide1Title") }}

                    </h1>
                  
                    <h2 class="secondaryText">  {{ $t("timesheets.onboardingSlide1SecondaryText") }}</h2>
                    </div>
                
                  </v-col>
                </v-row>
              </v-container>
          </div>
          </v-carousel-item>
          
          <v-carousel-item>
            <v-container>

              <v-row >
                <v-col cols="12">
                  <div class="center-content background-cloud" style="min-height:40vh" >
                    <img class="step-two" width="90%" />
                </div>
                  <div class="center-content">
                  <h1 class="title">{{ $t("timesheets.onboardingSlide2Title") }}</h1>
                
                  <h2 class="secondaryText">{{ $t("timesheets.onboardingSlide2SecondaryText") }}</h2>
                  </div>
              
                </v-col>
              </v-row>
            </v-container>

          </v-carousel-item>
          <v-carousel-item>
            <v-container>

              <v-row >
                <v-col cols="12">
                  <div class="center-content background-cloud" style="min-height:40vh">
                    <img class="step-three" />
                </div>
                  <div class="center-content">
                  <h1 class="title">{{ $t("timesheets.onboardingSlide3Title") }}</h1>
                  <h2 class="secondaryText">{{ $t("timesheets.onboardingSlide3SecondaryText") }}</h2>
                  </div>
              
                </v-col>
              </v-row>
            </v-container>

          </v-carousel-item>
          <v-carousel-item>
            <v-container>

              <v-row>
                <v-col cols="12">
                  <div class="center-content background-cloud" style="min-height:40vh">
                    <img class="step-four" />
                </div>
                  <div class="center-content">
                  <h1 class="title">{{ $t("timesheets.onboardingSlide4Title") }}</h1>
                  <h2 class="secondaryText">{{ $t("timesheets.onboardingSlide4SecondaryText") }}</h2>
                  </div>
                </v-col>
              </v-row>
            
            </v-container>
          </v-carousel-item>
          </v-carousel>



<div class="d-flex justify-space-between mb-6" v-if="!stepControlsHidden">

   <a @click="skip"  class="pa-2">{{ $t("skip") }}</a> 

  <div class="d-flex justify-space-between mb-6">
    <v-btn icon  active="true" v-for="n in 4" :key="n" @click="gotToStep(n -1)" :value="n" class="pa-2" :class="currentIndex + 1 == n ? ' v-item--active v-btn--active':''">
      <v-icon style="font-size:15px;"> mdi mdi-circle</v-icon>
    </v-btn>
  </div>

    <a @click="next" class="pa-2" >{{ $t("next") }}</a>
</div>
  
<div class="d-flex justify-center pa-3">
  <v-btn v-if="stepControlsHidden" width="90%" min-width="200px" max-width="343px" depressed   color="primary" @click="complete"> {{ $t("timesheets.onboardingSlide4ButtonText") }}</v-btn>
</div>

</div>
