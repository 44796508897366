import { Vue, Component, PropSync, Prop, Watch } from 'vue-property-decorator'
import GoogleMap from './GoogleMap.vue'
import HereMap from './HereMap.vue'
import store from '@/store';

  export interface GoogleMarkerConfig{
    imageUrl: string;
    imageUrlSelected: string;
    height: number;
    width: number;
    lat: number;
    lng: number;
    uniqueId: string;
  }

 @Component({components:{GoogleMap,HereMap}
 })
 export default class Map extends Vue {
  constructor(){
    super();
  }
  @Prop(Array) readonly appendMarkers: GoogleMarkerConfig[];
  @Prop(Array) readonly markers: GoogleMarkerConfig[];
  @Prop({default:true}) readonly recycle: boolean;
  @Prop(Number) readonly lattitude: number; 
  
  @Prop(Number) readonly longitude: number; 
  @Prop(String) readonly location: string;
  
  @Prop({default:12})  readonly zoom: number;
  @Prop({default:'gm1'})  readonly recyclePrefix: string;

  cardVisible = false;
  showMapLink = false;


  get mapType(){
    return store.getters.AppState.mapService;

  }

  markerClicked(event){
    
    this.cardVisible = true;
    this.$emit("markerclicked",event);
  }

  hideCard(){
    this.cardVisible = false;
  }

}