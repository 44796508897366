import JobService, { JobSearchResult, JobSearchRequest, FilterInfo } from "../JobService";
import { Vue, Component, PropSync, Prop } from 'vue-property-decorator';
import store from "../../../../../store/index";
import { max } from "moment";
import { Booking } from '../../availability/DiaryService';
import { EventBus } from '@/utils/events';

@Component({
  })
export default class FilterDialog extends Vue {
  // @PropSync("results", { type: Object }) jobResults!: JobSearchResult;
  @Prop({default:[]}) filters: FilterInfo[];
  @PropSync("jobsearchrequest", { type: Object }) jobSearchRequest!: JobSearchRequest;
  @PropSync("visible", { type: Boolean }) showDialog!: boolean;
  selectedEmpTypes = [];
  selectedJobAge = 0;
  minDistance = 1;
  maxDistance = 100;
  minSalaryLocal = 0;
  maxSalaryLocal = 0;
  minHourlyLocal = 0;
  maxHourlyLocal = 0;
  radius = 1;
  applyButtonDisabled = true;
  jobAgeItems =
  [ {
      name: 'All',
      id: 0
  },
  {
      name: 'Last 24 Hours',
      id: 1
  },{
      name: 'Last 3 Days',
      id: 3
  },{
    name: 'Last 7 Days',
    id: 7
  },{
    name: 'Last 14 Days',
    id: 14
  }];

    constructor() {
         super();
    }

    mounted()
    {
      if (this.jobSearchRequest !== undefined) {
        if (this.jobSearchRequest.Radius !== undefined){
              this.radius = this.jobSearchRequest.Radius;
        }
        this.applyButtonDisabled = true;
      }
    }

    get showJobAge(): boolean {
        if (this.filters.find(x => x.FilterSystemCode === "MAXJOBAGE") !== undefined){
          return true;
        }
      return false;
    }

    get showRadius(): boolean{
      if (this.jobSearchRequest !== undefined) {
        if (this.jobSearchRequest.LocationText != "")
        {
          return true;
        }
      }
      return false;
    }

    get employmentTypes(): FilterInfo[] {
      if(this.filters.length > 0){
          const empTypes = this.filters.filter(x => x.FilterSystemCode === "EmploymentTypes");
          if (this.selectedEmpTypes.length === 0) {
            empTypes.forEach(i =>{
              this.selectedEmpTypes.push(i.Value);
            });
          }
          return empTypes;
        }
          

      return undefined;
     }

    get selectedSalary(): [number, number] {
      if (this.jobSearchRequest !== undefined) {
        if (this.jobSearchRequest.MinSalary !== undefined){
          return [this.jobSearchRequest.MinSalary, this.jobSearchRequest.MaxSalary];
        } else {
          if (this.filters.length > 0) {
            return [this.filters.find(x => x.FilterSystemCode === "MINSALARY").Value,
                    this.filters.find(x => x.FilterSystemCode === "MAXSALARY").Value];
          }
        }
      }
      return [0, 0];
    }

    set selectedSalary(val: [number, number]) {
      this.applyButtonDisabled = false;
    }

    get selectedHourly(): [number, number] {
      if (this.jobSearchRequest !== undefined) {
        if (this.jobSearchRequest.MinHourly !== undefined){
          return [this.jobSearchRequest.MinHourly, this.jobSearchRequest.MaxHourly];
        } else {
            return [this.filters.find(x => x.FilterSystemCode === "MINHOURLY").Value,
            this.filters.find(x => x.FilterSystemCode === "MAXHOURLY").Value];
        }
      }
      return [0, 0];
    }

    set selectedHourly(vals: [number, number]){
      this.applyButtonDisabled = false;
    }
 
    get minSalary(): number {
      if (this.filters.length > 0) {
        return this.filters.find(x => x.FilterSystemCode === "MINSALARY").Value;
      } else {
        return 0;
      }
    }

    get maxSalary(): number {
      if (this.filters.length > 0) {
        return this.filters.find(x => x.FilterSystemCode === "MAXSALARY").Value;
      } else {
        return 0;
      }
    }

    get minHourly(): number {
      if (this.filters.length > 0) {
        return this.filters.find(x => x.FilterSystemCode === "MINHOURLY").Value;
      } else {
        return 0;
      }
    }

    get maxHourly(): number {
      if (this.filters.length > 0) {
        return this.filters.find(x => x.FilterSystemCode === "MAXHOURLY").Value;
      } else {
        return 0;
      }
    }


    get jobSearchRadiusMiles()
    {
        //convert KM to miles
        return this.radius * 0.62137;
    }

    set jobSearchRadiusMiles(e)
    {
        //convert miles to KM
        this.radius = e / 0.62137
    }

    salaryChanged(vals: [number, number]){
      this.minSalaryLocal = vals[0];
      this.maxSalaryLocal = vals[1];
    }

    hourlyRateChanged(vals: [number, number]) {
      this.minHourlyLocal = vals[0];
      this.maxHourlyLocal = vals[1];
    }

    show() {
         this.showDialog = true;
         this.applyButtonDisabled = true;
     }

     apply() {
      this.jobSearchRequest.Radius = this.radius;
      this.jobSearchRequest.EmploymentTypeIds = this.selectedEmpTypes.join(",");
      this.jobSearchRequest.JobAge = this.selectedJobAge;
      this.jobSearchRequest.MinSalary = this.minSalaryLocal;
      this.jobSearchRequest.MaxSalary = this.maxSalaryLocal;
      this.jobSearchRequest.MinHourly = this.minHourlyLocal;
      this.jobSearchRequest.MaxHourly = this.maxHourlyLocal;

      store.commit.AppState.UpdateJobSearchRequest(this.jobSearchRequest);
      this.$emit("applyfilter");
      EventBus.$emit('SearchFiltersChanged');
    }

      close() {
        this.$emit("closedialog");
      }

      filterUpdated() {
        this.applyButtonDisabled = false;
      }
}