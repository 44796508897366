<template>
    <div>
      <div class="d-flex justify-space-between text-subtitle-1 jobresult-summary" v-if="shortlistedJobs.PageInfo">
        <div class="light">
            {{ $t("shortlistedJobs.header") }}
        </div>
        <div class="text-subtitle-1">
            <b>{{shortlistedJobs.PageInfo.TotalRecords}}</b> <span class="light">{{ $t("results") }}</span>
        </div>
      </div>
      <ConnectionLostCardView :isVisible="showNetworkError" @close="retryClicked"></ConnectionLostCardView>
      <v-list dense>
        <template  v-for="job in shortlistedJobs.Jobs">              
          <v-list-item class="rounded-corners card-border job-list-item mb-5" :key="job.JobId" :src="job.JobId" @click="jobSelected(job)">
            <v-list-item-content>
              <JobSummaryCard 
                :job="job"
                @setShortlisted="setShortlisted"
                @removeShortlisted="setShortlisted">
              </JobSummaryCard>
            </v-list-item-content>
          </v-list-item>
        </template>
        <v-list-item class="mt-5 ">
          <v-list-item-content>
            <LoadingIndicator :isVisible="isLoading"></LoadingIndicator>
          </v-list-item-content>
        </v-list-item>
      </v-list>
      <v-card intersect="infiniteScrolling"></v-card>
    </div>
</template>
<script lang="ts">
  import ViewModel from "./ShortlistedView"
  export default ViewModel;  
</script>