import { Vue, Component, PropSync, Watch } from 'vue-property-decorator';
import moment from 'moment'
 @Component({
 })
 export default class WeekDatePicker extends Vue {

  selectedDate: Date = new Date();

  data = {
    startDate:new Date(),
    endDate:new Date(),
    days:[]
  };
  

  @PropSync("initialvalue", { type: Date,default:null }) initialvalueLocal: Date;
  @PropSync("disabled", { type: Boolean,default:false }) disabledLocal: Date;
  @Watch('initialvalue')
  initialvalueChanged(val: Date, oldVal: Date){
    this.selectedDate = val;
    this.setData(this.selectedDate);
  }

    isToday(date){
        return moment(date).isSame(new Date(),'day');
    }

  get days(){
    return this.data.days;
  }

  get startDateText(){
      return moment(this.startDate).format("Do MMM");

  }

  get endDateText(){
    return moment(this.endDate).format("Do MMM");
 }

  get yearText(){
    return moment(this.endDate).format("YYYY");
  }


  get startDate(){
    return this.data.startDate;
  }

  setData(date){

      let start = date;
      if(!start){
        start = new Date();
      }
      
      while(start.getDay() !== 1){
        start = moment(start).subtract(1,"d").toDate();
      }
      this.data.startDate =  start;
      this.data.endDate =  moment(this.startDate).add(6,"d").toDate();

      start = this.data.startDate
      const end = this.data.endDate
      const days = [];
      while(start <= end){
        days.push({date:start,text: moment(start).format("ddd"),day: moment(start).date()});
        start = moment(start).add(1,"day").toDate();
      }

      this.data.days = days;
      const items = this.data.days.filter(i=> moment(i.date).isSame(this.selectedDate));
      
      if(items.length > 0){
          this.selectedDate = items[0].date;
      }else{
        this.selectedDate = this.data.days[0].date;
      }
  }

  get endDate(){
        return this.data.endDate;
  }
  
  changeDate(date){
    if(!this.disabledLocal){
      this.selectedDate = date.date;
      this.$emit("dateChanged",date);
    }
  }

  mounted(){
    this.selectedDate = this.initialvalueLocal?this.initialvalueLocal:new Date();
    this.setData(this.selectedDate);
  }

  next(){
    if(!this.disabledLocal){
      const val = moment(this.startDate).add(7,"d").toDate();
      this.setData(val);
      this.$emit("dateRangeChanged",{start:this.startDate,end:this.endDate});
    }
  }

  previous(){
    if(!this.disabledLocal){
      const val = moment(this.startDate).subtract(7,"d").toDate();
      this.setData(val);
      this.$emit("dateRangeChanged",{start:this.startDate,end:this.endDate});
    }
  }
 }