
<div class="pt-0 summary-container" @click="batchTimesheetOnly ? batchTimesheetClicked($event) : checkInClicked($event)">
  <v-row no-gutters>
    <v-col cols="2" sm="2" md="1" > 
      <div class="rounded img-round logo-image">
        <img v-if="!this.jobShiftLocal.HasLogo"  alt="" src="@/assets/logo.png" height="auto" width="30px">
        <img v-if="logoUrl !== ''"  alt="" :src="logoUrl" height="auto" width="36px">
      </div>
    </v-col>
    <v-col cols="8" sm="8" md="8" class="details-container">
      <div class="d-flex justify-space-between">
        <div class="text-h1 ellipsis" :data-jobtitle="jobShift.JobTitle">{{jobShift.JobTitle}}</div>
      </div>
       <div class="companyName pt-1 ellipsis" :data-companyname="jobShift.CompanyName">{{jobShift.Company}}</div>
      <div class="text-subtitle-2 pt-1" :data-location="jobShift.Location">{{jobShift.WorkAddress}}</div>
     <div class="text-subtitle-2  pt-1 pb-1" :data-term="jobShift.Term">{{jobShift.Term}}</div>
      <div class="text-subtitle-2  pt-1 pb-1" :data-ShiftDate="jobShift.ShiftDate" v-if="0 > 1">{{shiftDate}}</div>
      <div class="text-subtitle-2  pt-1 pb-1" :data-TimesheetStatus="jobShift.TimesheetStatus"  v-if="timesheetId > 0">{{ $t("timesheets.timesheetSubmitted") }}</div> 
    </v-col>
    <v-divider v-if="canClockInOrManual"  vertical></v-divider>
    <v-col v-if="canClockIn" cols="1" sm="2" md="1" class="d-flex align-center flex-column ma-auto">
      <v-btn style="width:30px;height:30px" elevation="0" small fab color="primary" @click="checkInClicked($event)" :disabled="!canCheckIn">
        <v-icon dark>mdi-play</v-icon>
      </v-btn>
      <div class="text-subtitle-1 pt-1"><b>{{duration}}</b></div>
    </v-col>

    <v-col v-if="manualEntryOnly" cols="1" sm="2" md="1" class="d-flex align-center flex-column ma-auto">
      <v-btn style="width:30px;height:30px" elevation="0" small fab color="primary" @click="checkInClicked($event)" :disabled="!canCheckIn">
        <v-icon dark>mdi-clock-outline</v-icon>
      </v-btn>
      <div class="text-subtitle-1 pt-1"><b>{{duration}}</b></div>
    </v-col>

  <v-col v-if="batchTimesheetOnly" cols="1" sm="2" md="1" class="d-flex align-center flex-column ma-auto">
      <v-btn style="width:30px;height:30px" elevation="0" small fab color="primary" @click="batchTimesheetClicked($event)" :disabled="jobShift.TimesheetId === 0">
        <v-icon dark>mdi-file-table-outline</v-icon>
      </v-btn>
      <div class="text-subtitle-1 pt-1"><b>{{duration}}</b></div>
    </v-col>

    
  </v-row>
  <slot name="TimesheetEvents"></slot>
</div>
