<template>
  <div v-if="scriptLoaded && dropboxChooserIsSupported" @click="openChooser">
      <slot/>
      <button v-if="!this.$slots.default">Open dropbox picker</button>
  </div>
</template>

<script lang="ts">
      import ViewModel from './DropboxPicker'  
    export default ViewModel;
  </script>

<style scoped></style>
