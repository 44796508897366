import Vue from 'vue'
import Component from 'vue-class-component'
import JobService, { JobSearchResult, Job, JobSearchRequest } from '../rdb/components/jobs/JobService';
import { EventBus } from '@/utils/events';
import store from '@/store';

@Component({})
export class JobMixin extends Vue {
    jobSearchResult: JobSearchResult = null;
    totalResults = 0;
    selectedJob: Job = null;
    searchFiltersSet = false;
    showJobDetails = false;
    

    get jobSearchRequest() {
        return store.getters.AppState.jobSearchRequest;
    }

    set jobSearchRequest(value: JobSearchRequest) {
        store.commit.AppState.UpdateJobSearchRequest(value);
    }

    mounted(){
        EventBus.$on("SearchFiltersChanged",this.searchFiltersChanged);
    }

    beforeDestroy(){
        EventBus.$off("SearchFiltersChanged",this.searchFiltersChanged);
    }

    searchFiltersChanged(){
        this.searchFiltersSet = true;
        EventBus.$emit("LoadSearchResults");
    }

    showJob(job: Job){
        this.$emit("showJob",job);
    }

   removeShortlisted(job: Job) {
    job.IsShortlisted = false;

    JobService.removeShortListJob(job.JobId).then(res=>{
        this.jobSearchRequest.PageOffset++;
      console.log(res);
    }).catch(e =>{
      console.log(e);
    });
 }


  setShortlisted(job: Job) {
      job.IsShortlisted = true;

      JobService.shortListJob(job.JobId).then(res=>{
        this.jobSearchRequest.PageOffset--;
          console.log(res);
      }).catch(e =>{
          console.log(e);
      });
  }

  retrySearch(){
    if ( this.searchFiltersSet) {
     EventBus.$emit("ShowJobSearchFilters");
      
     } else {
      this.clearFilters();
      this.$router.push("/search");
     }
  }

    clearFilters() {
        const keywords = this.jobSearchRequest.Keywords;
        const jobSearchRequest = new JobSearchRequest;
        jobSearchRequest.Keywords = this.jobSearchRequest.Keywords;
        jobSearchRequest.LocationText = this.jobSearchRequest.LocationText;
        jobSearchRequest.Latitude = this.jobSearchRequest.Latitude;
        jobSearchRequest.Longitude = this.jobSearchRequest.Longitude;
        jobSearchRequest.Radius = this.jobSearchRequest.Radius;
        store.commit.AppState.UpdateJobSearchRequest(jobSearchRequest);
    }

    searchLoaded(){
        EventBus.$emit("SetSearchFilters",this.jobSearchResult.Filters);
    }

    excludeJob(job: Job) {
        job.IsExcluded = true;
 
        JobService.excludeJob(job.JobId).then(res=>{
            this.jobSearchRequest.PageOffset--;
         console.log(res);
        }).catch(e =>{
             console.log(e);
        });
     }
 
     removeExcludedJob(job: Job) {
         job.IsExcluded = false;
 
         JobService.removeExcludedJob(job.JobId).then(res=>{
            this.jobSearchRequest.PageOffset++;
          console.log(res); 
         }).catch(e =>{
              console.log(e);
         });
      }

}