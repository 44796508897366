import { Vue, Component, PropSync } from 'vue-property-decorator';

@Component({
})

export default class Messagebox extends Vue {

  message = "";
  title = "";
  resolve =  null;
  reject = null;
  isVisible = false;
  type = "Success"
  constructor() {
    super();
  }

  show(message: string ,type = 'Success',title= ''){
    this.message = message;
    this.title = title;

    if(!title){
      switch(type){
      case "Success":
        this.title = this.$t("alertMessages.successHeaderText").toString();
        break;
      case "Update":
        this.title = this.$t("alertMessages.updateHeaderText").toString();
        break;
      case "Info":
        this.title = this.$t("alertMessages.infoHeaderText").toString();
        break;
      case "Warning":
        this.title = this.$t("alertMessages.warningHeaderText").toString();
          break;
      }
    }
    this.type = type;
    this.isVisible = true;

    return new Promise((resolve, reject) => {
      this.resolve = resolve;
      this.reject = reject;
    })
  }

  close(){
    this.resolve(true);
    this.isVisible = false;
  }
}