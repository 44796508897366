import { Vue, Component, PropSync, Watch } from 'vue-property-decorator';
import LoadingIndicator from "../../../../LoadingIndicator.vue";
import JobShiftSummaryCard from "./JobShiftSummaryCard.vue";
import TimesheetService, { Timesheet, TimesheetHour, TimesheetHourBreak, TimesheetHourUpdateRequest } from "../TimesheetService";
import CheckinSummaryCard from './CheckinSummaryCard.vue'
import moment, { Moment } from 'moment';
import VueTimepicker from 'vue2-timepicker'
import 'vue2-timepicker/dist/VueTimepicker.css'
import { mdiConsoleNetwork } from '@mdi/js';
import global from '@/utils/global';

@Component({
    name:"TimesheetEdit",
    components:{ LoadingIndicator, VueTimepicker }
})

export default class TimesheetEdit extends Vue {
    @PropSync("timesheetHour", { type: Object }) localTimesheetHour: TimesheetHour;
    @PropSync("timesheetBreaks", { type: Object }) localTimesheetBreaks: TimesheetHourBreak;

    startTime = "";
    endTime = "";
    breakDuration = "";
    errors = [];
    valid = true;

    @Watch('timesheetHour')
    timesheetHourChanged(val: TimesheetHour, oldVal: TimesheetHour) {
        if(oldVal) {
            this.loadvalues();
        }
    }

    get shiftDate(){
        return moment(this.localTimesheetHour.WorkedOn).format("DD/MM/yyyy");
    }

    get hourStartTime(){
        return moment(this.localTimesheetHour.StartTime).format("HH:mm");
    }

    get hourEndTime(){
        return moment(this.localTimesheetHour.EndTime).format("HH:mm");
    }

    get isValid() {
        this.errors = [];

        const start = moment(this.localTimesheetHour.WorkedOn).startOf('day').add(this.startTime);
        const end = moment(this.localTimesheetHour.WorkedOn).startOf('day').add(this.endTime);

        const hoursWorked = global.getDurationBetweenDates(start.toDate(), end.toDate());
        const breaks = global.getDurationBetweenDates(moment(this.localTimesheetHour.WorkedOn).startOf('day').toDate(), moment(this.localTimesheetHour.WorkedOn).startOf('day').add(this.breakDuration).toDate());
        const startTimeSet = !this.startTime.includes("HH") && !this.startTime.includes("mm") && !(this.startTime.length === 0);
        const endTimeSet = !this.endTime.includes("HH") && !this.endTime.includes("mm") && !(this.endTime.length === 0);

        if(this.startTime.length === 0){
            this.errors.push({message: this.getText("checkInTimeNotSelectedError")});
        }

        if(this.startTime.length === 5 && this.startTime.includes("HH")){
            this.errors.push({message: this.getText("checkInHourNotSelectedError")});
        }

        if(this.startTime.length === 5 && this.startTime.includes("mm")){
            this.errors.push({message: this.getText("checkInMinuteNotSelectedError")});
        }

        if(this.endTime.length === 0){
            this.errors.push({message: this.getText("checkOutTimeNotSelectedError")});
        }

        if(this.endTime.length === 5 && this.endTime.includes("HH")){
            this.errors.push({message: this.getText("checkOutHourNotSelectedError")});
        }

        if(this.endTime.length === 5 && this.endTime.includes("mm")){
            this.errors.push({message: this.getText("checkOutMinutesNotSelectedError")});
        }

        if(start.isAfter(end) && startTimeSet && endTimeSet){
            this.errors.push({message: this.getText("jobShiftCheckInAfterCheckOutError")});
        }

        if(start.isSame(end) && startTimeSet && endTimeSet){
            this.errors.push({message: this.getText("jobShiftCheckInSameAdCheckOutError")});
        }

        if(hoursWorked.isSame(breaks) && startTimeSet && endTimeSet){
            this.errors.push({message: this.getText("jobShiftBreakDurationSameAsShiftDurationError")});
        }

        if(breaks.isAfter(hoursWorked) && startTimeSet && endTimeSet){
            this.errors.push({message: this.getText("jobShiftBreakDurationGreaterThanShiftDurationError")});
        }

        return this.errors.length === 0;
    }

    constructor() {
        super();
    }

    mounted(){
        this.loadvalues();
    }

    loadvalues() {
        this.startTime = this.hourStartTime;
        this.endTime = this.hourEndTime;
        if (this.localTimesheetBreaks !== undefined) {
            this.breakDuration = global.getDurationBetweenDates(moment(this.localTimesheetBreaks.StartTime).toDate(),
                moment(this.localTimesheetBreaks.EndTime).toDate()).format("HH:mm");
        }
        else {
            this.breakDuration = "HH:mm";
        }
        this.errors = [];
    }

    getText(key: string){
        return this.$t(`alertMessages.${key}`).toString();
    }

    cancel(){
        this.$emit("cancel");
    }

    saveTimesheetHour() {
        const timesheetHourUpdateRequest = new TimesheetHourUpdateRequest();

        this.localTimesheetHour.StartTime = global.setTimeOnDate(this.localTimesheetHour.WorkedOn, this.startTime);
        this.localTimesheetHour.EndTime = global.setTimeOnDate(this.localTimesheetHour.WorkedOn, this.endTime);

        let tsBreak = new TimesheetHourBreak();

        if (this.localTimesheetBreaks !== undefined) {
            tsBreak = this.localTimesheetBreaks;
        } else {
            tsBreak.StartTime = this.localTimesheetHour.StartTime;
            tsBreak.TimesheetHourId = this.localTimesheetHour.TimesheetHourId;
        }
        tsBreak.EndTime = moment(tsBreak.StartTime).add(this.breakDuration).toDate();

        timesheetHourUpdateRequest.TimesheetHour = this.localTimesheetHour;
        timesheetHourUpdateRequest.TimesheetHourBreak = tsBreak;

        if(this.isValid){
            this.$emit("saveTimesheetHour", timesheetHourUpdateRequest);
        }
    }
}