import Vue from 'vue';
import Component from 'vue-class-component';
import { PropSync } from 'vue-property-decorator';
import store, {AppStore} from "../../../../store/index";

@Component({
    name:"onboarding",
})
export default class OnboardingComponent extends Vue {
    @PropSync("dialog") dialogLocal!: boolean;
    stepControlsHidden = false;
    currentIndex = 0;
    
    constructor() {
        super();
      
    }
    
    onChange(index){
        this.stepControlsHidden = index == 3?true:false;
    }

    next(){
        this.currentIndex++;
        this.stepControlsHidden = this.currentIndex == 3?true:false;
    }

    gotToStep(index){
        this.currentIndex = index;
        this.stepControlsHidden = this.currentIndex == 3?true:false;
    }

    previous(){
        this.currentIndex--;
    }

    skip(){
        store.commit.AppState.UpdateOnBoarded(true);
        this.dialogLocal = false;
        
    }
    finishedAllSteps()
    {
        store.commit.AppState.UpdateOnBoarded(true);
        this.dialogLocal = false;
        
    }
}
