
  <div>
        <v-container style="margin-bottom:90px">
          <LoadingIndicator :isVisible="isLoading" :messageText="loadingMessageText"></LoadingIndicator>
          <ConnectionLostCardView :isVisible="showNetworkError" @close="retryClicked"></ConnectionLostCardView>
      <v-form  ref="form"  v-show="!showNetworkError && !isLoading">
      <div v-show="timesheetExpense" class="pb-3">
        <v-container class="uploadbox pa-2">
            <v-row v-if="timesheetExpense.SupportingDocumentId !== 0" no-gutters> 

                  <v-col cols="2" class="">
                <v-icon v-bind:size="36">{{retrieveFileIcon(timesheetExpense.SupportingDocumentFileExtension)}}</v-icon>
                </v-col>
                  <v-col cols="7" class="description pt-2 pr-1">
                    <span class="description-text">{{ timesheetExpense.SupportingDocumentDescription }}</span>
                  </v-col> 
                  <v-col cols="3">
                <v-btn v-if="timesheetExpense.SupportingDocumentId > 0" class="action-download-expensedoc" text outlined fab x-small @click="showFileClicked()">
                        <v-icon>mdi-arrow-down</v-icon>
                      </v-btn>
                <v-btn class="action-delete-expensedoc" text outlined fab x-small @click="removeExpenseDocClicked">
                        <v-icon>mdi-delete</v-icon>
                      </v-btn>
                </v-col>  
              </v-row>  
                    <v-row no-gutters  style="width: 100%;" v-if="timesheetExpense.SupportingDocumentId === 0" >
                        <input type="file" style="height: 100%" :key="componentKey" :name="uploadFieldName" @change="selectAFileClicked($event.target.name, $event.target.files); fileCount = $event.target.files.length"
                            accept=".doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,.pdf,application/pdf, .jpg, .jpeg, .png, .tif, .tiff, .odt, .wps" class="input-file">
                          <v-col cols="12" align="center">
                           <img alt="Upload Receipt"  v-if="!timesheetExpense.SupportingDocumentId" height="21px" src="@/assets/img/timesheets/UploadReceiptBig.png" class="pr-3">               
                       Upload a Receipt
                          </v-col>                   
                    </v-row>
                      
       </v-container>

        <div class="text-h1 pb-2 pt-3">
          {{ $t("timesheets.ExpenseType") }}
        </div>
        <v-select @change="expenseTypeSelected" v-if="timesheetExpense.TimesheetExpenseId === 0" lazy-validation :rules="requiredRule" v-model="selectedPlacementExpenseId" item-value="PlacementExpenseId" item-text="Description" :items="allowedExpenses" outlined dense
              :placeholder="$t('timesheets.selectedExpenseTypePlaceHolder')">
        </v-select>

         <div class="pb-2"> {{timesheetExpense.Description}}</div>

        <div class="d-flex justify-space-between">
          <div class="rate-width">
              <div class="text-h1 pb-2">
                {{ $t("timesheets.Quantity") }}
              </div>
              <v-text-field lazy-validation type="number" class="input-quantity" :data-quantity="timesheetExpense.Quantity"  :rules="numberRules" :placeholder="$t('timesheets.quantityPlaceholder')"
                v-model="timesheetExpense.Quantity" outlined dense></v-text-field>
            </div>

            <div class="rate-width">
              <div class="text-h1 pb-2">
                {{ $t("timesheets.Amount") }}
              </div>
              <v-text-field   :prefix="currencySymbol" lazy-validation :rules="numberRules" type="number" class="input-payrate" :data-amount="timesheetExpense.PayRate" :placeholder="$t('timesheets.amountPlaceholder')"
              v-model="timesheetExpense.PayRate" outlined dense></v-text-field>
             
            </div>

            <div>
              <div class="text-h1 pb-2">
                Total
              </div>
              <div class="centered pt-2">
                
                {{currencySymbol}} {{(timesheetExpense.PayRate * this.timesheetExpense.Quantity).toFixed(2)}}
            </div>
            </div>
      </div>
        

        <div>
          <div class="text-h1 pb-2">
            {{ $t("timesheets.Note") }}
          </div>
          <v-textarea
              v-model="timesheetExpense.Note"
              outlined
              no-resize
              rows="2"
              :placeholder="$t('timesheets.notePlaceholder')"
            >
            </v-textarea>
        </div>

    </div>
  </v-form>
</v-container>


  <Alert :alertType="'Warning'" :isVisible="showErrorDialog" @close='showErrorDialog = false'>
    <template v-slot:message>{{messageText}}</template>
  </Alert>

  <v-footer fixed class="pa-4">
      <div class="d-flex justify-space-between" style="width:100%">
        <div class="small-bottom-sheet-button">
          <v-btn :disabled="isLoading" type="submit" @click="cancelButtonClicked" large depressed block class="grey-button action-cencel-expense mb-3">
            {{$t('cancel')}}
          </v-btn>
        </div>
        <div class="small-bottom-sheet-button">
          <v-btn :disabled="isLoading" color="primary" @click="saveExpenseClicked" large depressed block class="action-save-expense mb-3">
          {{$t('save')}}
          </v-btn>
        </div>
        </div>
  </v-footer>

   <v-bottom-sheet v-model="showReceiptDialog">
    <v-sheet full-width class="rounded-corners-top">
      <v-row class="pa-3">
        <img class="pl-3" style="width:95%" alt="Upload Receipt" :src="imageUrl">
      </v-row>
      <v-footer fixed class="pa-4">
      <div class="d-flex justify-space-between" style="width:100%">
        <div class="small-bottom-sheet-button">
          <v-btn :disabled="isLoading" type="submit" @click="showReceiptDialog = false" large depressed block class="grey-button action-cancel-expensedoc mb-3">
            {{$t('cancel')}}
          </v-btn>
        </div>      
        <div class="small-bottom-sheet-button pt-3">
               <v-btn class="action-delete-expensedoc" text outlined fab x-small @click="removeExpenseDocClicked">
                        <v-icon>mdi-delete</v-icon>
                      </v-btn>
                 </div>
        <div class="small-bottom-sheet-button">
          <v-btn :disabled="isLoading" color="primary" @click="showReceiptDialog = false" large depressed block class=" action-done-expensedoc mb-3">
          {{$t('done')}}
          </v-btn>
        </div>
        </div>
      </v-footer>
  </v-sheet>
  </v-bottom-sheet>


  <v-bottom-sheet v-model="showAddReceiptDialog">
    <v-sheet full-width class="rounded-corners-top">
      <div class="h1-large pa-4"> {{ $t("timesheets.addReceipt") }}</div>      
        <v-divider></v-divider>      
        <v-row class="h1-large" @click="takeAPhotoClicked()">
          <v-col cols="3" ><img class="pl-3" alt="Upload Receipt" height="50px" src="@/assets/img/timesheets/TakeAPhotoBig.png"></v-col>
          <v-col cols="9" class="pt-6">{{ $t("timesheets.takeAPhoto")}} 
          </v-col>
        </v-row>
        <v-divider></v-divider>
      <v-row class="h1-large" @click="selectAPhotoClicked()">
          <v-col cols="3" ><img class="pl-3" alt="Upload Receipt" height="50px" src="@/assets/img/timesheets/PhotoLibraryBig.png"></v-col>
          <v-col cols="9" class="pt-6">{{ $t("timesheets.fromCamera")}} 
          </v-col>
        </v-row>
         <v-divider></v-divider>
         <input type="file" :key="componentKey" :name="uploadFieldName" @change="selectAFileClicked($event.target.name, $event.target.files); fileCount = $event.target.files.length"
            accept=".doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,.pdf,application/pdf, .jpg, .jpeg, .png, .tif" class="input-file">
       <v-row class="h1-large">
          <v-col cols="3" ><img class="pl-3" alt="Upload Receipt" height="50px" src="@/assets/img/timesheets/FileBig.png"></v-col>
          <v-col cols="9" class="pt-6">{{ $t("timesheets.fromFiles")}} 
          </v-col>
        </v-row>
      </v-sheet>
  </v-bottom-sheet>
</div>
