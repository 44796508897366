import { Vue, Component, PropSync, Mixins, Prop, Watch } from 'vue-property-decorator'
import Map from '@/components/Map.vue'
import JobSummaryCard from "./JobSummaryCard.vue";
import JobResultsSummary from "./JobResultSummary.vue";
import JobNoResults from "./JobNoResults.vue";
import store from "../../../../../store/index";
import {GoogleMarkerConfig} from '@/components/GoogleMap'
import { NetworkMixin } from '@/components/mixins/networkMixin';
import JobService, { JobSearchResult, JobSearchRequest,Job } from "@/components/rdb/components/jobs/JobService";
import { EventBus } from '@/utils/events';
import { JobMixin } from '@/components/mixins/jobMixin';
@Component({
  name:"map-view",
  components:{JobSummaryCard, Map,JobResultsSummary,JobNoResults}
})
export default class MapView extends Mixins(NetworkMixin,JobMixin) {
  isLoadingLocal = false;
  showCardView = false;
  markers: GoogleMarkerConfig[] = [];
  showMap = true;
  destroyCalled = false;
  lattitude = 0;
  longitude = 0;
  pageSize = 200;
 constructor() {
        super();
 }

  beforeDestroy(){
    EventBus.$off("LoadSearchResults",this.loadSearchResult);
    this.destroyCalled = true;
  }

  mounted(){
    this.lattitude = this.jobSearchRequest.Latitude;
    this.longitude = this.jobSearchRequest.Longitude;
    EventBus.$on("LoadSearchResults",this.loadSearchResult);
    this.loadSearchResult();
  }

  
  loadSearchResult() {

    if(this.jobSearchResult){
      this.jobSearchResult.Jobs.splice(0);
      this.markers.splice(0);
      this.totalResults = 0;
    }

    this.jobSearchRequest.Page = 1;
    this.jobSearchRequest.PageSize = this.pageSize;
    this.jobSearchRequest.PageOffset = 0;

    this.jobSearchRequest.IncludeExcludedJobs = true;
    this.jobSearchRequest.IncludeShortListedJobs = true;
    
    this.isLoadingLocal = true;
    this.doWork<JobSearchResult>(JobService.searchJobs(this.jobSearchRequest),
    this.loadSearchResult)
    .then((res) =>{
      if(this.lattitude == 0 && this.longitude == 0){
          if(res.Jobs.length > 0){
            this.lattitude = res.Jobs[0].Latitude;
            this.longitude = res.Jobs[0].Longitude;
          }
      }

        this.isLoadingLocal = false;
        this.jobSearchResult = res;
        this.createMarkers(res);
        this.totalResults = this.jobSearchResult.PageInfo.TotalRecords;
        this.searchLoaded();
        this.showMap = this.totalResults > 0;
        if(this.jobSearchResult.PageInfo.HasNextPage){
          this.loadNextPage( this.jobSearchResult.PageInfo.Page + 1);
        }
    });
  }

  loadNextPage(page) {
    this.jobSearchRequest.Page = page;
    this.jobSearchRequest.PageSize = this.pageSize;

    
    this.doWork<JobSearchResult>(JobService.searchJobs(this.jobSearchRequest),
    this.loadSearchResult)
    .then((res) =>{

      if(res.PageInfo.HasNextPage && !this.destroyCalled){
        this.loadNextPage(res.PageInfo.Page + 1);
      }

      res.Jobs.forEach(item => this.jobSearchResult.Jobs.push(item)); res.Jobs.forEach(item => this.jobSearchResult.Jobs.push(item));
      this.createMarkers(res);
    });
  }

  createMarkers(jobs: JobSearchResult){

    jobs.Jobs.forEach(i =>{
      if(i.Latitude && i.Longitude){
          this.markers.push({height:64,width:48,lat:i.Latitude,lng:i.Longitude,imageUrl:store.getters.AppState.url + "img/icons/google_marker.png",uniqueId:i.JobId.toString(),imageUrlSelected:store.getters.AppState.url + "img/icons/google_select_marker.png"});
      }
    });
  }

  onMarkerClicked(e){

    const job = this.jobSearchResult.Jobs.filter(i => i.JobId == parseInt(e.marker.config.uniqueId));
    if(job.length > 0){
       this.showCardView = true;
        this.selectedJob = job[0];
    }
  }
  
  closeCard(e){
    e.preventDefault();
    e.stopPropagation();
    this.showCardView = false;
  }


  loadMore() {

    if(this.jobSearchResult.PageInfo.HasNextPage){
      this.loadNextPage(this.jobSearchResult.PageInfo.Page + 1);
    }

  }

}
