import { Vue, Component } from "vue-property-decorator";

@Component
export class VueOfflineMixin extends Vue  {
  isOnline = false;
  isOffline = false;
  
    mounted () {
      if (typeof window !== 'undefined') {
        navigator.onLine ? this.isOnline = true : this.isOffline = true
            
          const onlineHandler = () => {
            this.$emit('online')
            this.isOnline = true
            this.isOffline = false
          }
  
          const offlineHandler = () => {
            this.$emit('offline')
            this.isOffline = true
            this.isOnline = false
          }
  
          window.addEventListener('online',  onlineHandler)
          window.addEventListener('offline',  offlineHandler)
          
          this.$once('hook:beforeDestroy', () => {
            window.removeEventListener('online', onlineHandler)
            window.removeEventListener('offline', offlineHandler)
          })
        }
    }
  }
     
   export const VueOfflinePlugin = {
    install (Vue, options = { mixin: true }) {
      const pluginOptions = {
        mixin: options.mixin
      }
      if (pluginOptions.mixin) Vue.mixin(VueOfflineMixin)
    }
  }
  
  export default VueOfflinePlugin