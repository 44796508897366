
  <div class="pt-0">
 
    <v-app-bar dense fixed color="white" class="top-toolbar pt-0 "> 
            
      <v-btn class="back-button navigation-button action-back" @click="back()" text><v-icon>mdi-chevron-left</v-icon>Back</v-btn>
    
  </v-app-bar>

 <v-container class="div-background pt-0 mt-12">

    <div>
      <JobSummaryCard :job="selectedJobLocal"
      class="pt-4 mb-2" 
                @setShortlisted="setShortlisted"
                @removeShortlisted="removeShortlisted">
        </JobSummaryCard>
      </div>
      <div>
        <v-divider></v-divider>
    
          <v-row justify="center" v-if="showApplyBtn">
              <v-col cols="12" md="12">
                  <v-btn class="action-apply-for-job" depressed block color="primary" @click="showApply">{{ $t("jobs.applyButtonTitle") }}</v-btn>
              </v-col>
          </v-row>

          <LoadingIndicator :isVisible="isLoading"></LoadingIndicator>
          <ConnectionLostCardView :isVisible="showNetworkError" @close="retryClicked"></ConnectionLostCardView>
            <v-tabs  v-model="selectedTabIndex">
              <v-tab class="action-tab-job" data-id="job" ref="jobtogglebtn"  @click="selectedView = 'job'">{{ $t("jobs.job") }}</v-tab>
              <v-tab  v-if="mapVisible" class="action-tab-map" data-id="map" ref="maptogglebtn" @click="selectedView = 'map'">{{ $t("jobs.map") }}</v-tab>
              <v-tab class="action-tab-recruiter" data-id="recruiter" ref="recruitertogglebtn" @click="selectedView = 'recruiter'">{{ $t("jobs.recruiter") }}</v-tab>
            </v-tabs>
          <div v-show="!isLoading && !showNetworkError">
         <v-row v-if="selectedView === 'job'" >
          <v-col cols="12" md="12">
             <div class="fs-14 text-color-light-grey pt-0 pb-4">
                    <span class="text-h1">Reference:</span> {{ job.JobRefNo }}
            </div>
            <span class="job-details-description fs-14 text-color-light-grey">
              {{ job.Description }}
            </span>
               </v-col>
            </v-row>
            <v-row v-if="selectedView === 'map' && mapVisible">
                  <v-col cols="12" md="12" class="pa-0">
                    <div class="map-area">
                      <button v-show="isMapsEnabled" @click="openDirectionsClicked">View Directions</button>
                      <Map :recycle="true" :location="job.location" :lattitude="job.Latitude" :longitude="job.Longitude" :markers="markers">
                      </Map>

                    </div>
               </v-col>    
            </v-row>
            <v-row v-if="selectedView === 'recruiter'">
             
                <v-col cols="12" md="12">
                  <div class="text-h1 pt-0 pb-4">
                    Contact Information
                  </div>
                  <div class="d-flex justify-start consultant-details-row">
                    <div class="pr-2 icon-col" >
                    <img src="@/assets/img/icons/name.png" height="14px"/>
                    </div>
                    <div class="value-col fs-14 fw-800 ">
                        Name:
                    </div>
                    <div class="pr-2">  
                  <span class="job-details-recruiter fs-14 text-color-light-grey"> 
                      {{ job.ContactName }}
                     </span> 
                  </div>

                  </div>
                
               </v-col>  
               <v-col cols="12" md="12">

                <div class="d-flex justify-start consultant-details-row">
                  <div class="pr-2 icon-col">
                  <img src="@/assets/img/icons/email.png"  height="10"/>
                  </div>
                  <div class="fs-14 fw-800 value-col">
                      Email:
                  </div>
                  <div class="pr-1">  
                    <span class="job-details-recruiter-email fs-14 text-color-light-grey">
                    <a :href="'mailto:' + job.ContactEmail">{{ job.ContactEmail }}</a>
                    </span>
                </div>

                </div>

               


               </v-col>  
               <v-col cols="12" md="12">

                <div class="d-flex justify-start consultant-details-row">
                  <div class="icon-col">
                  <img src="@/assets/img/icons/phone.png"  width="13"/>
                  </div>
                  <div class="fs-14 fw-800 value-col">
                      Phone:
                  </div>
                  <div class="pr-1">  
                    <span class="job-details-recruiter-tel fs-14 text-color-light-grey">
                      <a :href="'tel:' +  job.ContactTel">   {{  job.ContactTel }}</a>
                    </span>
                </div>

                </div>


                 
               </v-col>  
            </v-row>
          </div>
          </div>
</v-container>
<v-dialog v-model="showJobApplyDialog" max-width="500px" fullscreen>
            <JobApply :isOpen="showJobApplyDialog" :selectedJob="selectedJob" @closeApply="closeApply" :closeDialog="closeApplyDialog"></JobApply>
        </v-dialog>
</div>
