import {Loader, LoaderOptions} from 'google-maps';
import store from "@/store/index";
import { LocationService,TraveltimeRequest,TravelTimeResponse,TextSearchResponse } from './locationServices';
import errorLogger from '@/utils/errorLogger';

export class GoogleMapsHelper implements LocationService{

    notAvailableText = "N/A";
    
     initialise(){
        if(!window.google || !window.google.maps){
            const options: LoaderOptions = { libraries: ['places', 'geometry'] };
            const loader = new Loader(store.state.AppState.discovery.googleApiKey, options);
            return loader.load();
        }else{
            return new Promise<any>((s,r)=>{
                s();
            })
        }
    }


    private getTravelTime(request: google.maps.DistanceMatrixRequest): Promise<TravelTimeResponse> {
        const responsePromise = new Promise<TravelTimeResponse>((resolve, reject) => {

            this.initialise().then(i =>{

                const service = new google.maps.DistanceMatrixService();
                service.getDistanceMatrix(request
                    ,(result) =>{

                        const response = new TravelTimeResponse();

                        if(result.rows[0].elements[0].duration){
                            response.travelTimeText = result.rows[0].elements[0].duration.text;
                            response.travelTime = result.rows[0].elements[0].duration.value;
                            }else{
                            response.travelTime = 0;
                            response.travelTimeText = this.notAvailableText
                            }

                        resolve(response);
                  });

            }).catch(e=>{
                reject(e);
            })
        });

        return responsePromise;
    }

    iOSversion() {
        if (/iP(hone|od|ad)/.test(navigator.platform)) {
          // supports iOS 2.0 and later
          const v = (navigator.appVersion).match(/OS (\d+)_(\d+)_?(\d+)?/);
          return [parseInt(v[1], 10), parseInt(v[2], 10), parseInt(v[3] || "0", 10)];
        }
      }

   

    loadDirections(lat,lng){
        navigator.permissions.query({ name: 'geolocation' }).then(i =>{

            if(i.state !== 'denied'){
                navigator.geolocation.getCurrentPosition(position => {
                    this.openMapLink(position.coords.latitude,position.coords.longitude,lat,lng);    
                });
            }else{
                this.openMapLink(null,null,lat,lng);    
            }
        });
    }

    private openMapLink(oringLat,orignLng,destLat,destLong){
        if(oringLat && orignLng){
            window.open(`https://www.google.com/maps/dir/?api=1&origin=${oringLat},${orignLng}&destination=${destLat},${destLong}`);
        }else{
            window.open(`https://www.google.com/maps/dir/?api=1&destination=${destLat},${destLong}`);
        }
    }

    textSearch(location: string): Promise<TextSearchResponse[]> {

            return new Promise<TextSearchResponse[]>((s, e) => {

                const response: TextSearchResponse[] = [];
                const service = new google.maps.places.PlacesService(document.createElement('div'));

                const request = {
                    query: location
                };

                service.textSearch(request, res => {

                    res.forEach(i=>{
                        response.push({
                            lat:i.geometry.location.lat(),
                            lng:i.geometry.location.lng(),
                            locationText:i.formatted_address
                        });
                    });

                    s(response);
            });
        });
    }

    getCarTravelTime(args: TraveltimeRequest): Promise<TravelTimeResponse> {

        const origins = new google.maps.LatLng(args.start.lat, args.start.lng);
        const destinations = new google.maps.LatLng(args.destination.lat, args.destination.lng);

        const carRequest: google.maps.DistanceMatrixRequest = {
            destinations: [destinations],
            travelMode: google.maps.TravelMode.DRIVING,
            origins: [origins]
        };

        return new Promise<TravelTimeResponse>((s, e) => {
            this.getTravelTime(carRequest).then(result => {
                s(result);
            }).catch(r => {
                errorLogger.logError(r);
                e(r)
            });
        });
    }
    getBusTravelTime(args: TraveltimeRequest): Promise<TravelTimeResponse> {
        const origins = new google.maps.LatLng(args.start.lat, args.start.lng);
        const destinations = new google.maps.LatLng(args.destination.lat, args.destination.lng);

       const busRequest: google.maps.DistanceMatrixRequest = {
            destinations:[destinations],
            travelMode:google.maps.TravelMode.TRANSIT,
            origins:[origins],
            transitOptions:{modes:[google.maps.TransitMode.BUS]}
          };

        return new Promise<TravelTimeResponse>((s, e) => {
            this.getTravelTime(busRequest).then(result => {
                s(result);
            }).catch(r => {
                errorLogger.logError(r);
                e(r)
            });
        });
    }
    getTrainTravelTime(args: TraveltimeRequest): Promise<TravelTimeResponse> {
        const origins = new google.maps.LatLng(args.start.lat, args.start.lng);
        const destinations = new google.maps.LatLng(args.destination.lat, args.destination.lng);

     const trainRequest: google.maps.DistanceMatrixRequest = {
            destinations:[destinations],
            travelMode:google.maps.TravelMode.TRANSIT,
            origins:[origins],
            transitOptions:{modes:[google.maps.TransitMode.TRAIN]}
          };

        return new Promise<TravelTimeResponse>((s, e) => {
            this.getTravelTime(trainRequest).then(result => {
                s(result);
            }).catch(r => {
                errorLogger.logError(r);
                e(r)
            });
        });
    }

    getBicycleTravelTime(args: TraveltimeRequest): Promise<TravelTimeResponse> {
        const origins = new google.maps.LatLng(args.start.lat, args.start.lng);
        const destinations = new google.maps.LatLng(args.destination.lat, args.destination.lng);

        const cycleRequest: google.maps.DistanceMatrixRequest = {
            destinations:[destinations],
            travelMode:google.maps.TravelMode.BICYCLING,
            origins:[origins]
          };

        return new Promise<TravelTimeResponse>((s, e) => {
            this.getTravelTime(cycleRequest).then(result => {
                s(result);
            }).catch(r => {
                errorLogger.logError(r);
                e(r)
            });
        });
    }
   
    getWalkingTravelTime(args: TraveltimeRequest): Promise<TravelTimeResponse> {
        const origins = new google.maps.LatLng(args.start.lat, args.start.lng);
        const destinations = new google.maps.LatLng(args.destination.lat, args.destination.lng);

      const walkRequest: google.maps.DistanceMatrixRequest = {
            destinations:[destinations],
            travelMode:google.maps.TravelMode.WALKING,
            origins:[origins]
          };

        return new Promise<TravelTimeResponse>((s, e) => {
            this.getTravelTime(walkRequest).then(result => {
                s(result);
            }).catch(r => {
                errorLogger.logError(r);
                e(r)
            });
        });
    }
}

let GoogleMapsHelperInstance;

export default GoogleMapsHelperInstance = new GoogleMapsHelper();

//export default new GoogleMapsHelper();