
<v-container class="subtraction735">
  <div class="mainContent">
    <div>
      <LoadingIndicator :isVisible="isLoadingLocal"></LoadingIndicator>
    </div>
    <v-row justify="center" class="pt-5">
      <v-col col="12">
        <v-alert v-if="resultError != ''" dense type="error">
          {{ resultError }}
        </v-alert>
      </v-col>
    </v-row>
    <div class="mainCameraBody">
      <qrcode-stream
        :camera="camera"
        @decode="onDecodeScan"
        v-show="showvideo"
        @init="onInit"
      >
        <div class="elementOverlay"></div>
      </qrcode-stream>
    </div>
    <qrcode-capture
      ref="myref"
      @detect="onDetect"
      class="v-input theme--light v-text-field v-text-field--is-booted v-file-input"
      v-show="false"
      @decode="onDecodeFile"
    />
  </div>

  <v-row justify="center">
    <footer class="footer">
      <v-row justify="center" v-if="noStreamApiSupport">
        <v-col cols="12">
          <v-btn
            class="action-capture"
            depressed
            block
            color="primary"
            @click="
              resultError = '';
              $refs.myref.$el.value = '';
              $refs.myref.$el.click();
            "
          >
            <v-icon left>mdi-camera</v-icon> Capture
          </v-btn>
        </v-col>
      </v-row>

      <v-row justify="center">
        <v-col cols="12">
          <v-btn
            depressed
            block
            class="action-close"
            v-bind:class="{
              'grey-button': noStreamApiSupport,
              primary: !noStreamApiSupport,
            }"
            @click="close"
            >Cancel</v-btn
          >
        </v-col>
      </v-row>
    </footer>
  </v-row>
</v-container>
