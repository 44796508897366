import { Vue, Component, PropSync,Watch, Mixins } from 'vue-property-decorator';
import JobService, { Job } from "../../../rdb/components/jobs/JobService";
import JobSummaryCard from "./components/JobSummaryCard.vue";
import store from '@/store';
import Map from '@/components/Map.vue'
import JobApply from "./JobApply.vue";
import { NetworkMixin } from '@/components/mixins/networkMixin';
import {GoogleMarkerConfig} from '@/components/GoogleMap'
import googleMapsHelper from '@/utils/googleMapsHelper'


@Component({
    name:"JobDetails",
    components:{JobSummaryCard, Map, JobApply}
})
export default class JobDetails extends Mixins(NetworkMixin) {
    @PropSync("selectedJob", { type: Object }) selectedJobLocal!: Job;
    @PropSync("isOpen", { type: Boolean }) isOpenLocal!: boolean;
    @PropSync("closeTopDialog", { type: Boolean }) isCloseDialog!: boolean;
    
    job: Job = new Job;
    isLoaded = false;
    selectedView = "job";
    showJobApplyDialog = false;
    closeApplyDialog = false;
    showApplyBtn = false;
    selectedTabIndex = 0;
    markers: GoogleMarkerConfig[] =[];

     @Watch('isOpen')
    isOpenChanged(val: Job, oldVal: Job) {
        if(val) {
            this.selectedTabIndex = 0;
            this.loadJob();
        }
    }

    get mapVisible(){

        if(!this.job.Location && this.job.Longitude == 0 && this.job.Longitude == 0){
            return false;
        }
        return true;
    }

    get isMapsEnabled()
    {
       
       return store.getters.AppState.featureEnabled("RCRMGOOGLEMAPS");
    }

    @Watch('closeTopDialog')
    closeDialogChanged() {
        if (this.showJobApplyDialog) {
                this.closeApplyDialog = !this.closeApplyDialog;
            }
            else {
                this.$emit('close');
            }
    }

    removeShortlisted(job: Job) {
        job.IsShortlisted = false;
        JobService.removeShortListJob(job.JobId).then(res=>{
            console.log(res);

        }).catch(e =>{
            console.log(e);
        });
     }


    setShortlisted(job: Job) {
        job.IsShortlisted = true;
        JobService.shortListJob(job.JobId).then(res=>{
            console.log(res);
        }).catch(e =>{
            console.log(e);
        });
    }

        mounted(){
            this.loadJob();
        }

   
     loadJob() {
        let jobId = 0;
        this.showApplyBtn = false;
        if (this.$route.params.jobId !== undefined) {
            jobId = parseInt(this.$route.params.jobId)
        }else if(this.selectedJobLocal){
            jobId =  this.selectedJobLocal.JobId;
        }

        if (jobId > 0) {

            this.doWork<any>(JobService.selectJob(jobId),()=>{this.loadJob()}).then((res) =>{
                this.job = res.data as Job;
                JobService.updateFromLocalStorage(this.job);
                this.isLoaded = true;
                if (!this.job.IsApplied) {
                this.showApplyBtn = true;
                }

                this.markers.splice(0);
                this.markers.push({height:64,width:48,lat:this.job.Latitude,lng:this.job.Longitude,imageUrl:store.getters.AppState.url + "img/icons/google_marker.png",uniqueId:this.job.JobId.toString(),imageUrlSelected:store.getters.AppState.url + "img/icons/google_marker.png"});
            });
        }
    }

    back() {
        if(this.$route.params.jobId !== undefined){
            this.$router.back();
        }
        else{
            this.job = new Job();
            this.selectedView = "job";
            this.$emit("close");
        }
    }

    showApply(){
        this.closeApplyDialog = false;
        this.showJobApplyDialog = true;
        }

        closeApply(closeDetails: boolean) {
            this.showJobApplyDialog = false;
            if (closeDetails) {
               this.$emit("close");
            }
        }

        openDirectionsClicked(){
            googleMapsHelper.loadDirections(this.job.Latitude,this.job.Longitude);
        }
}