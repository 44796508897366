import { Vue, Component, PropSync, Prop } from 'vue-property-decorator';

@Component({
})

export default class Alert extends Vue {
  @PropSync("isVisible", { type: Boolean }) showDialog: boolean;
  @PropSync("alertType", { type: String }) type: boolean;
  @PropSync("isConfirmation", { type: Boolean }) isConfirmationLocal: boolean;
  @PropSync("result", { type: Boolean }) confirmationResult: boolean;

  constructor() {
    super();
  }

  close(){
    this.$emit("close");
  }

  confirmation(response: boolean){
    this.$emit("update:result", response);
  }
}