import ErrorLogger from '@/utils/errorLogger'
import BaseService from '@/services/baseService'
import auditLogger from '@/utils/auditLogger';
import { LocationTextLookup, TextSearchResponse } from '@/utils/locationServices';

export class JobLocationsRequest
{
    
    SearchString: string;
}


export class JobService extends BaseService implements LocationTextLookup {
    baseUrl: string;
    
    jobresults: JobSearchResult;
    jobStoreName = "jobsFailedRequests";
    productCode = "RCRM";
    constructor() {
       super()
      //  this.jobsStorage = localStorage.getStore("jobsFailedRequests");
    }
  

    //Checks local storage for any changes that have not been synced to the server and updates the changed props
    async updateLocalStorage(result: JobSearchResult){
      
      const toRemove = [];

      for (let index = 0; index <  result.Jobs.length; index++) {
         
         const job = result.Jobs[index];

         await this.updateFromLocalStorage(job);
         
         if(job.IsExcluded){
            toRemove.push(index);
         }
       }

      toRemove.forEach(i=>{
         result.Jobs.splice(i,1);
      });

    }

    async updateFromLocalStorage(job: Job) {
      const localDBJob = await this.store(this.jobStoreName).getItem(`${job.JobId}`) as Job;
      if(localDBJob){
         job.IsExcluded = localDBJob.IsExcluded;
         job.IsShortlisted =  localDBJob.IsShortlisted;
      }
    }


   searchJobs(jobSearchRequest: JobSearchRequest): Promise<JobSearchResult> {

      return new Promise<JobSearchResult>((resolve,reject)=>
      {
          return this.httpClient(this.productCode).get("/jobs/SearchJobs", jobSearchRequest).then(async res => {
              return  res.data;
           }).then(async r => {

             this.updateLocalStorage(r).then(() =>{
                  resolve(r);
              });
           }).catch((e)=> {
            ErrorLogger.logError(e.toString(),e);
              reject(e);
           });
      }
      );
   }

   getRecentSearches(): Promise<RecentSeachesResult> {

      return this.httpClient(this.productCode).get("/jobs/GetRecentSearches").then(async res =>{
         return  res.data;
      }).then(async r => {
         return r;
      }).catch((e)=>{
            console.log(e);
      });
   }

   async excludeJob(jobId: number): Promise<boolean> {

      return this.httpClient(this.productCode).post("/jobs/ExcludeJob", {JobId:jobId}).then(res =>{
         return res.data;
      }).catch(async(e)=>{

         const dbItem = await this.getDbItem(jobId);
         dbItem.IsExcluded = true;
         await this.setDbItem(jobId,dbItem);
      });
   }

   async removeExcludedJob(jobId: number): Promise<boolean> {
      
      return this.httpClient(this.productCode).post("/jobs/RemoveExcludedJob", {JobId:jobId}).then(res =>{
         return res.data;
      })
      .catch(async(e)=>{

         const dbItem = await this.getDbItem(jobId);
         dbItem.IsExcluded = false;
         await this.setDbItem(jobId,dbItem);

      });
   }

   async shortListJob(jobId: number): Promise<boolean> {
     
      return this.httpClient(this.productCode).post("/jobs/ShortListJob", {JobId:jobId}).then(res =>{
         return res.data;
      })
      .catch(async(e)=>{

         const dbItem = await this.getDbItem(jobId);
         dbItem.IsShortlisted = true;
         await this.setDbItem(jobId,dbItem);

      });
   }

   async removeShortListJob(jobId: number): Promise<boolean> {

      return this.httpClient(this.productCode).post("/jobs/RemoveShortListJob", {jobId:jobId}).then(res =>{
         return res.data;
      }).catch(async (e)=>{

         const dbItem = await this.getDbItem(jobId);
         dbItem.IsShortlisted = false;
         await this.setDbItem(jobId,dbItem);

      });
   }

   selectJob(jobId: number): any {
      return this.httpClient(this.productCode).get("/jobs/SelectJob", {jobId:jobId});
   }

   async getDbItem(jobId: number){
      let dbItem = await this.store(this.jobStoreName).getItem(`${jobId}`) as any;
      if(!dbItem){
         dbItem = {IsExcluded:false,IsShortlisted:false};
      }
     return dbItem;
   }

   async setDbItem(jobId: number,item){
      await this.store(this.jobStoreName).setItem(`${jobId}`,item);
   }

   getCompanyLogo(jobId: number,width: number,height: number,crop = false): Promise<string> {
         return this.httpClient(this.productCode).get("/jobs/GetCompanyLogo", 
         {jobId:jobId,width:width,height:height,crop:crop},
         { responseType: 'arraybuffer' }).
         then(response => 
            {
               const u8 = new Uint8Array(response.data)
               const b64encoded = btoa([].reduce.call(new Uint8Array(response.data),function(p,c){return p+String.fromCharCode(c)},''))
               const mimetype="image/jpeg"
               return "data:"+mimetype+";base64,"+b64encoded

               }).catch((e)=>{
                     return null;
                  });
            }

   shortlistedJobs(shortlistedJobsRequest: ShortlistedJobsRequest): any {
      return this.httpClient(this.productCode).get("/jobs/RetrieveShortlistedJobs", shortlistedJobsRequest);
   }

   appliedJobs(appliedJobsRequest: AppliedJobsRequest): any {
      return this.httpClient(this.productCode).get("/jobs/RetrieveAppliedJobs", appliedJobsRequest);
   }

   recentJobs(recentJobsRequest: RecentJobsRequest): any {
      return this.httpClient(this.productCode).get("/jobs/RetrieveRecentJobs", recentJobsRequest);
   }

   broadcastedJobs(broadcastJobsRequest: BroadCastJobsRequest): any {
      return this.httpClient(this.productCode).get("/jobs/RetrieveBroadcastJobs", broadcastJobsRequest);
   }

   broadcastedJobShifts(broadcastJobShiftRequest: BroadCastJobShiftRequest): any {
      return this.httpClient(this.productCode).get("/jobs/RetrieveBroadcastJobShifts", broadcastJobShiftRequest);
   }

   acceptBroadCastShifts(acceptBroadCastShiftsRequest: AcceptBroadCastShiftsRequest): Promise<boolean> {
      return this.httpClient(this.productCode).post("/jobs/AcceptBroadCastShifts", acceptBroadCastShiftsRequest).then(res => {
         this.postAudit("SHIFTACCEPT", true)
         return res.data;
      });
   }

   appliedJobStatus(appliedJobStatusRequest: AppliedJobStatusRequest): any {
      return this.httpClient(this.productCode).get("/jobs/RetrieveAppliedJobStatus", appliedJobStatusRequest);
   }

   uploadCV(formData): any {
      this.postAudit("UPLOADCV",true);
      return this.httpClient(this.productCode).post("/jobs/UploadCV", formData);
    }

   submitJobApplication(jobApplicationRequest: JobApplicationRequest): any {
      this.postAudit("JOBAPPLICATION", true);
      return this.httpClient(this.productCode).post("/jobs/JobApplicationInsert", jobApplicationRequest);
   }

   getCVforApplications(): Promise<CVsResponse>{
      return this.httpClient(this.productCode).get("/jobs/RetrieveCVforApplications").then((r)=>{
         return r.data;
      });
   }

   deleteCv(cvDeleteRequest: CvDeleteRequest): Promise<CvDeleteResponse>{
      return this.httpClient(this.productCode).post("/jobs/DeleteCv", cvDeleteRequest).then(res =>{
         return res.data;
      }).catch(async (e)=>{
         ErrorLogger.logError(e.toString(),e);
      });
   }

   jobOffers(jobOffersRequest: JobOffersRequest): any {
      return this.httpClient(this.productCode).get("/jobs/RetrieveJobOffers", jobOffersRequest);
   }


   rejectJobOffer(rejectJobOfferRequest: RejectJobOfferRequest): Promise<boolean> {
      return this.httpClient(this.productCode).post("/jobs/RejectJobOffer", rejectJobOfferRequest).then(res => {
         this.postAudit("JOBOFFERREJECT", true);

         return res.data;
      });
   }

   acceptJobOffer(acceptJobOfferRequest: AcceptJobOfferRequest): Promise<boolean> {
      return this.httpClient(this.productCode).post("/jobs/AcceptJobOffer", acceptJobOfferRequest).then(res => {
         this.postAudit("JOBOFFERACCEPT", true)
         return res.data;
      });
   }


   jobOfferCount(): any {
      return this.httpClient(this.productCode).get("/jobs/RetrieveJobOffersCount");
   }

   textSearch(location: string): Promise<TextSearchResponse[]> {
      const request = new JobLocationsRequest();
      request.SearchString = location;

      return new Promise<TextSearchResponse[]>((s, e) => {

         const response: TextSearchResponse[] = [];
         this.httpClient(this.productCode).get("/jobs/RetrieveJobLocations", request).then(res => {

            res.data.Locations.forEach(i => {
               response.push({
                  lat: i.Latitude,
                  lng: i.Longtitude,
                  locationText: i.Name,
                  id:i.Id
               });
            });
            
            s(response);
         }).catch(async (e) => {
            ErrorLogger.logError(e.toString(), e);
         });
      });
   }

   async postAudit(eventType: string, multiple = false)
   {
      
      auditLogger.logEvent(eventType, multiple);
   }
}


let JobServiceInstance;
export default JobServiceInstance = new JobService();



export class JobSearchRequest {
   Page = 1;
   PageSize = 10;
   Keywords = "";
   LocationText = "";
   IncludeFilters = true;
   OrderBy = "Date Created";
   OrderDirection = "DESC";
   MinSalary = 0;
   MaxSalary= 0;
   MinHourly= 0;
   MaxHourly = 0;
   JobAge = 0;
   EmploymentTypeIds = "";
   Latitude = 0;
   Longitude = 0;
   Radius = 20;
   IncludeExcludedJobs = false;
   IncludeShortListedJobs = true;
   PageOffset = 0;
 }

 export class Job {
    JobId: number;
    JobRefNo: string;
    Description?: string;
    JobTitle?: string;
    Distance?: number;
    DisplayRate?: string;
    Category?: string;
    Location?: string;
    Term?: string;
    CreatedOn: Date;
    IsShortlisted: boolean;
    IsExcluded: boolean;
    Latitude: number;
    Longitude: number;
    HasLogo: boolean;
    ShiftTimeType: string;
    ShiftStartTime: Date;
    ShiftEndTime: Date;
    ShiftLengthHours: number;
    IsApplied: boolean;
    trainTime = "";
    busTime = "";
    carTime=  "";
    walkTime = "";
    cycleTIme = "";
    contactName: string;
    contactTel: string;
    contactEmail: string;
 }

export class AppliedJob extends Job {
   ApplicantActionId: number;
   Status: string;
   SystemCode: string;
}

export class BroadCastJob extends Job
{
   //Use this class for any extended properties to job i.e. publishedjobshiftid ?
   //ShiftStartTime need to be populated with the min start date from the list of published shifts for this job
   //ShiftEndTime need to be populated with the max end date from the list of published shifts for this job
   //CreatedOn should be populated from when the shift was published as this will be used to show how long ago it was posted
}

export class JobOffer extends Job {
   OfferDate: Date;
   Consultant: string;
   ConsultantJobTitle: string;
   ApplicantActionId: number;
}

export class JobLocations
{
    Id: number;
    Name: string;
    Latitude: number;
    Longtitude: number;
}


export class JobLocationsResult
{
  Locations: JobLocations[] = [];
}



 export class PageInfo {
    TotalRecords: number;
    PageSize: number;
    Page: number;
    NoOfPages: number;
    HasNextPage: boolean;
 }

 export class FilterInfo {
   Value: number;
   Description: string;
   FilterSystemCode: string;
   Count: number;
   Order: number;
 }

 export class JobSearchResult {
   PageInfo: PageInfo;
   Jobs: Job[];
   Filters: FilterInfo[];
 }


 export class ShortlistedJobsRequest {
   Page = 1;
   PageSize = 10;
 }

 export class ShortlistedJobsResult {
   PageInfo: PageInfo = new PageInfo();
   Jobs: Job[] = [];
 }

 export class AppliedJobsRequest {
   Page = 1;
   PageSize = 10;
 }

 export class AppliedJobsResult {
   PageInfo: PageInfo;
   Jobs: AppliedJob[] =[];
 }



 export class BroadCastJobsRequest {
   Page = 1;
   PageSize = 10;
   JobId = 0;
 }

 export class BroadCastJobShiftRequest {
   JobId: number;
 }

export class BroadCastJobShift{
   PublishedJobShiftId: number;
   Date: string;
   StartTime: string;
   EndTime: string;
   ShiftTimeType: string;
   DisplayRate: string;
   Status: string;
}

export class AcceptBroadCastShiftsRequest
{
   PublishedJobShiftIds: number[]=[];
}

export class BroadCastJobShiftResult
{
   Shifts: BroadCastJobShift[] =[];
}

 export class BroadCastJobsResult {
   PageInfo: PageInfo = new PageInfo();
   Jobs: BroadCastJob[] =[];
 }

 export class RecentJobsRequest {
   Page = 1;
   PageSize = 10;
 }

 export class RecentJobsResult {
   PageInfo: PageInfo = new PageInfo();
   Jobs: Job[] =[];
 }

 export class JobOffersRequest {
   Page = 1;
   PageSize = 10;
 }

 export class JobOffersResult {
   PageInfo: PageInfo = new PageInfo();
   Jobs: JobOffer[] = [];
 }

 export class AppliedJobStatusRequest {
   Page = 1;
   PageSize = 10;
 }

 export class AppliedJobStatusResult {
   PageInfo: PageInfo = new PageInfo();
   Jobs: AppliedJob[] = [];
 }


 export class RecentSeachesResult {
   RecentSearches: RecentSearchInfo[];
 }

  export class RecentSearchInfo {
   SearchRequest: JobSearchRequest;
   CreatedOn: Date;
 }

 export class CVUploadResult {
   CVId: number;
   FileExtension: string;
   FileName: string;
   CreatedOn: Date;
   //CanDelete: boolean;
 }

 export class CvAppliedJob{
   CVId: number;
   JobId: number;
   JobRefNo: string;
   JobTitle: string;
   Company: string;
   WorkAddress: string;
 }

 export class CVsResponse {
    CVs: CVUploadResult[];
    AppliedJobs: CvAppliedJob[];
 }

 export class JobApplicationRequest {
    JobId: number;
    AdditionalInfo: string;
    CVId: number;
 }

 export class CvDeleteRequest{
    CvId: number;
 }

 export class CvDeleteResponse{
    IsError: boolean;
    ErrorMessage: string;
 }

 export class AcceptJobOfferRequest {
    JobId: number;
    Message: string;
    ApplicantActionId: number;
 }

 export class RejectJobOfferRequest {
   JobId: number;
   ApplicantActionId: number;
}
