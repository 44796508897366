<template>
  <div>

<div>

  <div class="centered ">
  <img alt="Vue logo" src="@/assets/logo.png/" height="120px"> 


<div class="background-cloud">

  <img v-if="showVerifyButton || isLoading" class="mt-10" height="200px" alt="Vue logo" src="@/assets/img/registerconfirm/ConfirmAccount.png"> 

  <img v-if="!isLoading && !errorMessage && !showVerifyButton" class="mt-10 pb-5" height="150px" alt="Vue logo" src="@/assets/img/registerconfirm/ConfirmSuccess.png"> 

  <div v-if="showVerifyButton" class="h1-extra-large pt-12"> {{ $t("confirmAccount") }} </div>
  <div v-if="showVerifyButton" class="nunito-reg16 pt-3"> {{ $t("registerConfirm.confirmAccountMessage") }} </div>

</div>

    <div v-if="isLoading">
        <LoadingIndicator :isVisible="isLoading" :messageText="loadingMessageText"></LoadingIndicator>
    </div>
 
      <div v-if="!isLoading && successMessage">
        <div  class="h1-extra-large pt-12"> {{ $t("thankYou") }} </div>
        {{successMessage}}
      </div>
  
      <div v-if="!isLoading && errorMessage">
            {{errorMessage}}
      </div>


      
      <v-footer fixed class="pa-2">
        <v-btn v-if="showVerifyButton" depressed block color="primary" class="action-find-jobs mt-4" @click="onVerifyClick">{{ $t("confirmAccount") }}</v-btn>
        <v-btn v-if="!isLoading && !errorMessage && !showVerifyButton" color="primary" depressed block class="action-find-jobs mt-4" @click="redirect">{{ $t("login") }}</v-btn>
      </v-footer>
     
  </div>

</div>
</div>

</template>
  
  <script lang="ts">
      import ViewModel from "./RegisterConfirm"
      export default ViewModel;
  </script>

  <style lang="scss" scoped>

.background-cloud {
      background-image: url("../assets/img/onboarding/Path 10739.png");
       background-size: 100% 100%;
    }

  
  </style>