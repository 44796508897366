<template>
 <div class="pt-0">
 
    <v-app-bar dense fixed color="white" class="top-toolbar pt-0 "> 
            
      <v-btn class="back-button navigation-button action-back" @click="back()" text><v-icon>mdi-chevron-left</v-icon> {{$t('jobs.jobOffers')}}</v-btn>
    
  </v-app-bar>

 <v-container v-if="!showAcceptMessage" class="div-background pt-0 mt-12" >


    <div>
      <div class="d-flex justify-space-between text-subtitle-1 jobresult-summary" v-if="jobOffers.PageInfo">
        <div class="light">
            
        </div>
        <div class="text-subtitle-1">
            <b>{{jobOffers.PageInfo.TotalRecords}}</b> <span class="light">{{ $t("results") }}</span>
        </div>
      </div>
      <ConnectionLostCardView :isVisible="showNetworkError" @close="retryClicked"></ConnectionLostCardView>
      <v-list dense >
        <template  v-for="job in jobOffers.Jobs">              
          <v-list-item class="rounded-corners card-border job-list-item mb-5" :key="job.ApplicantActionId" :src="job.ApplicantActionId" @click="jobSelected(job)">
            <v-list-item-content>
              <JobOfferSummaryCard 
                :job="job"
                @setShortlisted="setShortlisted"
                @removeShortlisted="setShortlisted" 
                @acceptJob="showJobOfferAccept(job)"
                @rejectJob="rejectJobClicked(job)">
              </JobOfferSummaryCard>
            </v-list-item-content>
          </v-list-item>
        </template>
        <v-list-item class="mt-5 ">
          <v-list-item-content>
            <LoadingIndicator :isVisible="isLoading"></LoadingIndicator>
          </v-list-item-content>
        </v-list-item>
      </v-list>
      <v-card intersect="infiniteScrolling"></v-card>
    </div>    
</v-container> 
 <v-dialog v-model="showJobOfferDetails" max-width="500px" fullscreen>
      <JobOfferDetails :isOpen="showJobOfferDetails" :selectedJob="selectedJobOffer" 
      @close="showJobOfferDetails = false" 
      :closeTopDialog="closeTopDialog"
      @setShortlisted="setShortlisted"
      @removeShortlisted="setShortlisted" 
      @acceptJob="showJobOfferAccept"
      @rejectJob="rejectJobClicked"></JobOfferDetails>
    </v-dialog>

       <v-bottom-sheet v-model="showAcceptMessage" >
        <v-sheet full-width class="rounded-corners-top">
               <div>
        <div class="h1-large pa-4"> {{ $t("jobs.jobOffers") }}</div>
        <v-divider></v-divider>
               </div>
        <v-container>
        <v-row class="pb-3">
          <v-col cols="2" sm="2" md="1" >
          
          <div class="rounded img-round logo-image">
            <img v-if="!this.selectedJobOffer.HasLogo"  alt="" src="@/assets/logo.png" height="auto" width="30px">
            <img v-if="logoUrl !== ''"  alt="" :src="logoUrl" height="auto" width="36px">
          </div>
        </v-col>
        <v-col cols="10" sm="10" md="10" class="details-container">
          <v-row class="text-h1">{{selectedJobOffer.CompanyName}}</v-row>
          <v-row class="justify-space-between">{{selectedJobOffer.ConsultantJobTitle}}
                <div v-if="selectedJobOffer.OfferDate" class="text-caption grey--text pr-3" :data-OfferDate="selectedJobOffer.OfferDate">{{ formatTimeFromNow(selectedJobOffer.OfferDate) }} ago</div>
          </v-row></v-col>
        </v-row>

      <v-form
            ref="form"
            v-model="valid"
            lazy-validation>
      
      <v-row> 
         <div class="h1-large pl-3"> {{ $t("jobs.message") }}      
      </div>
      </v-row>
      <v-row class="pa-3">
        <v-textarea height="250" v-model="AcceptMessage" outlined dense hide-details :label="$t('typeMessage')"></v-textarea>
      </v-row>
      <v-divider></v-divider>
        <v-row class="d-flex justify-space-around mb-6 pt-3">
              <v-btn width="150" class="grey-button action-cancel" depressed @click="showAcceptMessage = false;">
                  {{ $t("cancel") }}
              </v-btn>
              <v-btn width="150" class="primary action-create-manual-timesheet" depressed @click="acceptJobOfferClick">
                  {{ $t("send") }}
              </v-btn>
            </v-row>
      </v-form>
    </v-container>
        </v-sheet>
       </v-bottom-sheet>
</div>
</template>
<script lang="ts">
  import ViewModel from "./JobOfferView"
  export default ViewModel;  
</script>