import { Vue, Component, PropSync } from 'vue-property-decorator';

@Component({
})

export default class Confirm extends Vue {
 
  message = "";
  title = "";
  resolve =  null;
  reject = null;
  isVisible = false;
  constructor() {
    super();
  }

  open(title, message, options) {
    this.isVisible = true;
    this.title = title;
    this.message = message;
    //this.options = Object.assign(this.options, options)
    return new Promise((resolve, reject) => {
      this.resolve = resolve;
      this.reject = reject;
    })
  }

  confirm() {
    this.resolve(true)
    this.isVisible = false;
  }

  cancel() {
    this.resolve(false)
    this.isVisible = false;
  }

}