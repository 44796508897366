import { Vue, Component, PropSync, Watch, Mixins } from 'vue-property-decorator'
import JobService, { AppliedJob, AppliedJobsResult, AppliedJobsRequest } from '../../jobs/JobService';
import AppliedJobSummaryCard from "./AppliedJobSummaryCard.vue";
import moment from "moment";
import { NetworkMixin } from '@/components/mixins/networkMixin';

@Component({
    name:"applied-view",
    components:{ AppliedJobSummaryCard }
})

export default class AppliedView extends Mixins(NetworkMixin) {
    
    appliedJobs: AppliedJobsResult = new AppliedJobsResult();

    constructor() {
        super();
    }

    mounted() {
        this.getAppliedJobs();
    }

    getAppliedJobs(page = 1) {
        const appliedJobsRequest = new AppliedJobsRequest();
        appliedJobsRequest.Page = page;

        this.doWork<any>(JobService.appliedJobs(appliedJobsRequest),() =>{this.getAppliedJobs()}).then((result) => {
            this.appliedJobs.PageInfo = result.data.PageInfo;
            result.data.Jobs.forEach(i =>this.appliedJobs.Jobs.push(i));
        });
    }

    setShortlisted(job: AppliedJob) {
        job.IsShortlisted = !job.IsShortlisted;
        
        if(job.IsShortlisted){
          this.$emit("setShortlisted", job);
        }else{
          this.$emit("removeShortlisted", job);
        }
    }

    jobSelected(job: AppliedJob){
        //navigate to details
        this.$emit("showJob",job);
      }

      infiniteScrolling(entries, observer, isIntersecting) {
        if (this.appliedJobs.PageInfo !== undefined) {
          if (this.appliedJobs.PageInfo.HasNextPage) {
            if (isIntersecting  && !this.isLoading) {
                   this.isLoading = true;
                   this.getAppliedJobs(this.appliedJobs.PageInfo.Page+1);
           }
          }
        }
      }
}