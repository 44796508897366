import Vue from 'vue'

import App from './App.vue'
import './registerServiceWorker'
import store from './store'
import Vlf from 'vlf'
import localforage from 'localforage'
import VueInstall from "vue-pwa-install";
import '@mdi/font/css/materialdesignicons.css'
import './assets/styles.scss'

import mainAuth from './auth';
import discoveryService from './services/discovery';
/* eslint-enable */
import vuetify,{i18n} from './plugins/vuetify';
import {VueOfflinePlugin} from './plugins/onlineOffline'
import vueMoment from 'vue-moment'
import Router from './router/router'
import GoogleMapsHelper from '@/utils/googleMapsHelper'
import notifications from "../src/utils/notifications"
import ErrorLogger from '@/utils/errorLogger'
import UserLoginService from '@/services/userLogin'
import UserProfile from '@/services/userProfile'
import UserTerms from "@/services/userTerms"
import { EventBus } from '@/utils/events';
import { mdiConsoleNetwork, mdiStickerRemove } from '@mdi/js'
import JobService from "@/components/rdb/components/jobs/JobService";
import { MapType } from './store/AppState'
import hereMapsHelper from './utils/hereMapsHelper'
// import * as VueGoogleMaps from "vue2-google-maps";

Vue.use(vueMoment);
Vue.use(VueOfflinePlugin)

Vue.use(VueInstall)
Vue.use(Vlf, localforage)




Vue.directive('translate', {
  // When the bound element is inserted into the DOM...
  inserted: function (el) {
    // Focus the element

    //el.textContent = "YoYo";
    
    //el.focus()
  }
})

Vue.config.productionTip = false

const bootApp = (router) =>{
  new Vue({
    i18n,
    vuetify: vuetify,
    router:router,
    store:store.original,
    render: h => h(App),
    beforeMount(){
      EventBus.$on("Login",()=>{
        mainAuth.login();
      });
    },
    mounted(){
      const routeName = this.$route.name;
      //This is works when user already has a valid token
       if(store.getters.AppState.isAuthenticated) {
          this.onStartUp();
       }
       //This is called when sign in has been unsuccessfull checking tenantuser
       else if(routeName == "noaccess"){
           this.onStartUp();
       }
       else if(routeName == "registerConfirm"){
        this.onStartUp();
      }
       else//This is called when user was not signed in and new token was retrieved.
       {
         mainAuth.userLoaded().then(()=>{
          this.onStartUp();
         });
       }
    },
    methods:{
      onStartUp(){
        const routeName = this.$route.name;

        if(routeName == "registerConfirm"){
          mainAuth.removeUser();
          store.commit.AppState.UpdateHasLoggedIn(false);
          //mainAuth.revokeAccessToken();
          this.hideSplashScreen();
        }else{
            if(!store.getters.AppState.isAuthenticated){
               mainAuth.login();
            }else{

              UserLoginService.allowedToLogin().then(allowedToLogin =>{
              if(allowedToLogin){
                this.hasAcceptedTerms().then(() => {
                  this.hasRequiredDetails().then((result) =>{

                    this.hideSplashScreen();
                    this.checkPushNotifications();
                    this.loadJobOfferCount();

                    if(!result){
                      this.$router.push({ name: 'Profile',params:{force:true} });
                    }
                    else{
                      if(routeName == "noaccess"){
                        this.$router.push("/");
                      }
                    }

                  });
                });
                
                }
                else {

                  mainAuth.removeUser();
                  //mainAuth.revokeAccessToken();
                  this.$router.push("/noaccess").catch((e)=>{console.debug(e)});
                  this.hideSplashScreen();
                }
              });
          }


      }
      },
      hideSplashScreen(){
        const splash = document.getElementsByClassName('splash-screen')[0];
        splash.setAttribute("class","splash-screen-hidden");
      },
      checkPushNotifications(){
          if(navigator.onLine){
          setTimeout(()=>{
            notifications.checkPushRegistration();
          },4000)
        }
      },
      loadJobOfferCount() {
        if (store.getters.AppState.featureEnabled("RCRMJOBS")) {
          JobService.jobOfferCount().then (result => {
            if (result) {
              store.commit.AppState.UpdateJobOffers(result.data);
                  }
            });
        }
      },
      hasRequiredDetails(){
          const resultPromise = new Promise<boolean>((resolve,reject)=>{

            if(!store.getters.AppState.profileSaved || store.getters.AppState.firstName == null){
              UserProfile.getProfile().then((result=>{
                    if(result){
                      const isVaid =  result.phoneCountryCode && result.phoneNumber && result.firstName && result.surname  && result.title?true:false;
                      store.commit.AppState.UpdateProfileSaved(isVaid);
                      store.commit.AppState.UpdateFirstName(result.firstName);
                      resolve(isVaid);
                    }else{
                      resolve(false);
                    }
                }));
            }else{
              resolve(true);
            }

          });
          return resultPromise;
      },
      hasAcceptedTerms()
      {
        const resultPromise = new Promise<boolean>((resolve,reject)=>{

          
            UserTerms.getUserAcceptedTerms().then((result=>{
                  
                store.commit.AppState.UpdateHasAcceptedTerms(result);
                resolve(true);
                 
              })).catch((e) =>
              {
                //error - dont set flag - maybe network error
                resolve(true);
              });
          

        });
        return resultPromise;
      }
    }
    
  }).$mount('#app')
}

const startUp = () =>{
  const authClient= mainAuth.initialise();
  const router = Router.initialise();
  authClient.useRouter(router);
  
  authClient.startup().then(ok => {

    if (ok) {
          bootApp(router);
        }
    });
}


  discoveryService.getDiscovery().then(result =>{

    if(result && result.features.length > 0){

      store.dispatch.AppState.initialiseStore().then(i=>{
        
         store.commit.AppState.UpdateDiscovery(result);
         store.commit.AppState.UpdateMapService(MapType.HereMaps);

         if(store.getters.AppState.mapService == MapType.Google){
            GoogleMapsHelper.initialise().then(i =>{
              startUp();
            });
           }else if(store.getters.AppState.mapService == MapType.HereMaps){
            hereMapsHelper.initialise().then(i =>{
              startUp();
            });
           }else{
            startUp();
           }
       });
       
    }else{
      document.write("Unauthorised");
    }
  }).catch(e => {  document.write("Unauthorised");});

  