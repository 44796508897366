import JobService,{  Job } from "../JobService";
import { Vue, Component, PropSync,Watch, Prop } from 'vue-property-decorator';


@Component({
  })
export default class JobNoResults extends Vue {
 

//   tryAgain() {
//     if ( this.filterApplied === true) {
//      this.filterDialogVisible=true;
//      this.selectedView = this.lastSelectedView;
//     } else {
//      this.clearFilters();
//      this.$router.push("/search");
//     }
// }


  tryAgain(){
    this.$emit("retrySearch");
  }

}