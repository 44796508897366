import Vue from 'vue'
import Component from 'vue-class-component'
import store,{AppStore} from '@/store';
import AppState from '@/store/AppState';
import InstallBanner from '@/components/InstallBanner.vue'
import UpdateBanner from '@/components/UpdateBanner.vue'
import OfflineNotification from '@/components/OfflineNotification.vue'
import Confirm from '@/components/Confirm.vue'
import Messagebox from '@/components/Messagebox.vue'
import Auth from '@/auth'
import OnBoarding from "@/components/rdb/components/onboarding/Onboarding.vue"
import Toast from "@/components/Toast.vue"
import { NetworkMixin } from '@/components/mixins/networkMixin';
import UserProfile ,{UserProfileResponse} from '@/services/userProfile';
import { Mixins } from 'vue-property-decorator';
import AcceptTerms from "@/components/AcceptTerms.vue";
import AuditEventLog from "@/services/auditEventLog";




@Component({
    components:{InstallBanner,UpdateBanner,OfflineNotification,OnBoarding,Confirm,Messagebox,Toast, AcceptTerms},    
    computed:{
         discovery:{
            get(){
                return store.getters.AppState.discovery}
         },
            isLoggedIn(){
                 return store.getters.AppState.hasLoggedIn;
         },
         userName(){
            return store.getters.AppState.userName;
        },
        
        isOnBoarded()
        {
            if(!store.getters.AppState.isAuthenticated || !store.getters.AppState.hasAcceptedTerms)
            {
                return false;
            }
            return !store.getters.AppState.onBoarded;
        },
        canInstall()
        {
            if(!store.getters.AppState.isAuthenticated || !store.getters.AppState.hasAcceptedTerms)
            {
                return false;
            }
            return store.getters.AppState.onBoarded;
        },
        termsAccepted()
        {
            if(!store.getters.AppState.isAuthenticated)
            {
                return true;
            }
            return store.getters.AppState.hasAcceptedTerms;
        },
        isEarlyPayEnabled()
        {
            return store.getters.AppState.featureEnabled("RCRMEARLYPAY");
        },
        isPayslipEnabled()
        {
        
            return store.getters.AppState.featureEnabled("RCRMPAYSLIP");
        },
        firstName()
        {
            return store.getters.AppState.firstName;
        },
        jobOffers()
        {
            if (store.getters.AppState.featureEnabled("RCRMJOBS")) {
                return store.getters.AppState.jobOffers;
            } else {
                return null;
            }
        }
    }
})
export default class HomeComponent extends Mixins(NetworkMixin) {
    
    selectedTab = "search"
    menuOpen = false;
    componentKey = 0;

    constructor() {
        super();
    }  

    

    get getNavItems(): NavItem[] {
     
      const items: Array<NavItem> = [
            {url:"/search",text:"nav.search",name:"search",feature:"RCRMJOBS"},
            {url:"/jobs",text:"nav.jobs",name:"jobs",feature:"RCRMJOBS"},
            {url:"/timesheets/",text:"nav.timesheets",name:"timesheets",feature:"RCRMTIMESHEETS"},
            {url:"/availability/",text:"nav.calendar",name:"availability",feature:"RCRMAVAILABILITY"}
       ];
            
        return items.filter(item=>store.getters.AppState.featureEnabled(item.feature));
    }

    // get firstName()
    // {
    //     return store.getters.AppState.firstName;
        
    // }

    mounted(){
       
        (this.$root as any).$confirm = (this.$refs.confirm as any).open;
        (this.$root as any).$messageBox = (this.$refs.messageBox as any).show;
        (this.$root as any).$toasts = (this.$refs.toasts as any).show;
        
        this.$on('offline', () => {
            //debugger;
            Auth.client().networkStatusChanged(false);
          });
      
          this.$on('online', () => {
            Auth.client().networkStatusChanged(true);
          });
    }

    
    logOut(){
        Auth.client().signOut();
        // Auth.getUserManager().removeUser();
        // Auth.getUserManager().revokeAccessToken();
        // this.$router.push("/Login");
    }

    resetInstall()
    {
        store.commit.AppState.UpdateInstallAppExpiry(null);
        this.componentKey += 1;
    }

    earlyPayLinks()
    {
   
    if(!this.isIOS())
    {
      window.open("https://play.google.com/store/apps/details?id=com.theaccessgroup.earlypay&gl=GB",'_blank')
    }
    else
    {
      window.open("https://apps.apple.com/gb/app/access-earlypay/id1457007747",'_blank')
    }
    this.earlyPayAudit();
  }

  async earlyPayAudit()
  {
      AuditEventLog.postAuditEventLog("EARLYPAY");
  }

  isIOS(): boolean {
    return /iphone|ipod|ipad/.test( navigator.userAgent ) || (navigator.userAgent.includes("Mac") && "ontouchend" in document);
  }

}

interface NavItem
{
    url: string;
    text: string;
    name: string;
    feature: string;
}