import { Vue, Component, Prop,Watch, Mixins } from 'vue-property-decorator';
import store from '@/store';
import TimesheetService, { Timesheet, TimesheetHour, TimesheetHourBreak, TimesheetResult, TimesheetHourUpdateRequest, TimesheetExpense, AllowedExpenses, TimesheetPeriod } from '../TimesheetService';
import moment from 'moment';
import 'moment-duration-format';
import TimesheetEdit from "./TimesheetEdit.vue";
import TimesheetExpenseView from './TimesheetExpenseView.vue'
import { NetworkMixin } from '@/components/mixins/networkMixin';
import { NotificationMixin } from '@/components/mixins/notificationMixin';
import global from '@/utils/global';

@Component({
    name:"TimesheetDetails",
    components: {TimesheetEdit,TimesheetExpenseView}
})
export default class TimesheetDetails extends Mixins(NetworkMixin,NotificationMixin) {
    // @PropSync("selectedTimesheet", { type: Object }) selectedTimesheetLocal!: Timesheet;
    @Prop() selectedTimesheetId: number;
    @Prop() isOpen;
    @Prop() closeDialog;
    @Prop() showExpense;
    timesheet: Timesheet = new Timesheet;
    timesheetHours: TimesheetHour[] = [];
    timesheetHourBreaks: TimesheetHourBreak[] = [];
    isLoading = false;
    logoLoaded = false;
    logoUrl = "";
    weekday = [1, 2, 3, 4, 5, 6, 0];
    months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"]
    title= "";
    editTimesheet = false;
    selectedtimesheetHour: TimesheetHour = new TimesheetHour;
    selectedtimesheetHourBreaks: TimesheetHourBreak = new TimesheetHourBreak;
    selectedExpense: TimesheetExpense = new TimesheetExpense;
    selectedExpenseId = 0;
    allowedExpenses: AllowedExpenses[];
    showExpenseView = false;
    expenses: TimesheetExpense[] =[];
    timesheetPeriods: TimesheetPeriod[] = [];
    timesheetId = 0;
    tabs = ["Time","Expense"];
    selectedTab = 0;
    
    @Watch('selectedTimesheetId')
    selectedTimesheetIdChanged(val: number, oldVal: number) {
        if(val) {
            this.timesheetId = val;
            this.loadTimesheet(this.selectedTimesheetId);
        }
    }

    @Watch('isOpen')
    isOpenChanged(val: Timesheet, oldVal: Timesheet) {
        if(val) {
            this.loadTimesheet(this.selectedTimesheetId);
        }
    }

    @Watch('visible')
    visibleChanged(val: boolean, oldVal: boolean) {
        this.logoLoaded = false;
        this.logoUrl = "";
        if(val){
            this.setImage();
        }
    }

    @Watch('closeDialog')
    closeDialogChanged() {
       if (this.editTimesheet) {
        this.editTimesheet = false;
       }
       else {
        this.$emit('close');
       }
    }

    nextTimesheetId(){
        if(this.timesheetPeriods && this.timesheetPeriods.length > 0){
            const nextIndex = this.timesheetPeriods.indexOf(this.timesheetPeriods.filter(i=> i.TimesheetId == this.timesheetId)[0]) + 1;
            if(this.timesheetPeriods.length-1 >= nextIndex){
                return this.timesheetPeriods[nextIndex].TimesheetId;
            }

            return this.timesheetId;
        }

        return this.timesheetId;
    }

    previousTimesheetId(){
        if(this.timesheetPeriods && this.timesheetPeriods.length > 0){
            const prevIndex = this.timesheetPeriods.indexOf(this.timesheetPeriods.filter(i=> i.TimesheetId == this.timesheetId)[0]) - 1;
            if(prevIndex >= 0){
                return this.timesheetPeriods[prevIndex].TimesheetId;
            }

            return this.timesheetId;
        }

        return this.timesheetId;
    }

    getTimesheetHourTotal(timesheetHourId: number): number {
        let total = this.timesheetHours.find(x => x.TimesheetHourId === timesheetHourId).HoursWorked;
        const timesheethourBreaks = this.timesheetHourBreaks.forEach(x => { 
            if (x.TimesheetHourId === timesheetHourId) {
                total = total - (global.getTotalMinutesBetween(x.StartTime, x.EndTime)/60.0);
            }
        });
       
        return total;
    }

    constructor() {
        super();

         this.isLoading = true;
    }


    mounted(){
        if (this.$route.params.timesheetId !== undefined) {
            this.timesheetId = parseInt(this.$route.params.timesheetId)
        }else{
            this.timesheetId = this.selectedTimesheetId;
        }
        this.loadTimesheet(this.timesheetId);
        this.$emit('loaded');
    }


    loadExpenses(timesheetId: number) {
        if (timesheetId > 0)
        {
            TimesheetService.getExpenses(timesheetId).then(expenses=>{
                this.expenses = expenses;
            }).catch((e)=>{
                this.toastError(this.$t("failedToLoad").toString());

            });
        }
    }

    expenseSelected(expense: TimesheetExpense) {
        if (expense.AllowedToEdit) {
            this.selectedExpenseId = expense.TimesheetExpenseId;
            this.selectedExpense = expense;
            this.showExpenseView = true;
        }
    }

    expenseSaved(){
        this.toastSuccess(this.$t("savedSuccessFully").toString());
        this.loadExpenses(this.timesheetId);
        this.showExpenseView = false;
    }

    expenseCancelled(){
        this.showExpenseView = false;
    }

    addExpenseClicked(){
            
        const newExpense = new TimesheetExpense;
        newExpense.TimesheetExpenseId = 0;
        newExpense.TimesheetId = this.timesheetId;
        newExpense.AllowedToEdit = true;
        this.showExpenseView = true;
        this.selectedExpense = newExpense;
        this.selectedExpenseId = 0;
    }

    deleteExpense(expense: TimesheetExpense){

        this.confirm(this.$t("timesheets.removeExpenseMessage",{expenseDescription:`${expense.Description}`}).toString(),this.$t("confirm").toString()).then((result) =>{
            if(result){
                TimesheetService.deleteExpense(expense.TimesheetExpenseId).then((deleteResponse)=>{
                        if(deleteResponse.Success){
                            this.loadExpenses(this.timesheetId);
                            this.toastSuccess(this.$t("removedSuccessFully").toString());
                        }else{
                            this.toastError(this.$t("failedToRemove").toString());
                        }
                }).catch((e)=>{
                    this.toastError(this.$t("failedToRemove").toString());
                });
            }
        });
    }

    submitExpense(expense: TimesheetExpense){
        this.confirm(this.$t("timesheets.submitExpenseMessage",{expenseDescription:`${expense.Description}`}).toString(),this.$t("confirm").toString()).then((result) => {
            if(result){
                TimesheetService.submitTimesheetExpense(expense.TimesheetExpenseId).then((deleteResponse)=> {
                        if(deleteResponse.Success){
                            this.loadExpenses(this.timesheetId);
                            this.toastSuccess(this.$t("submittedSuccessFully").toString());
                        } else {
                            this.toastError(this.$t("failedToSubmit").toString());
                        }
                }).catch((e)=>{
                    this.toastError(this.$t("failedToSubmit").toString());
                });
            }
        });
    }

    getDateUTC(date){
        return new Date(moment.utc(moment(date).format("YYYYMMDD")).toDate());
     }

    loadPeriods(){
        TimesheetService.timesheetPeriodsInRange({StartDate:this.getDateUTC(moment(this.timesheet.PeriodStarting).subtract(12,"month")), EndDate:this.getDateUTC(moment(this.timesheet.PeriodEnding).add(12,"month"))}).then((result)=>{
                this.timesheetPeriods = result.Timesheets;
        });
    }

    loadTimesheet(timesheetId: number) {
        
        this.loadPeriods();

        if (timesheetId > 0) {

            this.timesheetHourBreaks = [];
            this.timesheetHours = [];
            this.doWork<any>(TimesheetService.timesheetSelectById(timesheetId),() => this.loadTimesheet(timesheetId)).then((res)=>{
                this.timesheet = (res.data as TimesheetResult).Timesheets[0];
                this.timesheetHours = (res.data as TimesheetResult).TimesheetHours;
                this.timesheetHourBreaks = (res.data as TimesheetResult).TimesheetHourBreaks;
                this.setTitle();
                this.setImage();

                this.loadExpenses(timesheetId);

                if (this.showExpense) {
                    this.selectedTab = 1;
                }
            }).catch((e)=>{
                this.toastError(this.$t("failedToLoad").toString());

            });
         }
     }

     editTimesheetRow(timesheetHourId: number) {
         this.selectedtimesheetHour = this.timesheetHours.find(x => x.TimesheetHourId === timesheetHourId);
         this.selectedtimesheetHourBreaks = this.timesheetHourBreaks.find(x => x.TimesheetHourId === timesheetHourId);
         this.editTimesheet = true;
     }

    back() {
        //back
        this.logoLoaded = false;
        if(this.$route.params.jobId !== undefined){
            this.$router.back();
        }
        else{
            this.timesheet = new Timesheet();

            this.$emit("close");
        }
    }

    setImage() {
        if (this.timesheet.HasLogo) {
            TimesheetService.getCompanyLogo(this.timesheet.PlacementId,100,100,false).then(e=>{
                this.logoUrl = e;
                this.logoLoaded = true;
            });
        } else {
            this.logoLoaded = true;
        }
    }

    getTimesheetTotalHours(format) {
         let totalHrs = 0;
         let totalMins = 0;
         this.timesheetHours.forEach(timesheetHour => 
            {totalHrs = totalHrs + timesheetHour.RunningHours,
             totalMins = totalMins + timesheetHour.RunningMin   
            }
        );
        
        if(totalHrs + totalMins === 0)
        {
            return '';
        }

        //if total minutes is >= 60, update the hours and min
        if(totalMins >= 60)
        {
            // add onto hours by dividing minutes and getting the hours
            totalHrs = totalHrs + Math.floor(totalMins / 60);
            //set the minutes to the remainder
            totalMins = (totalMins % 60);
        }
        return (totalHrs > 0 ? totalHrs.toString() + " h " : "" ) + (totalMins > 0 ? totalMins.toString() + " m " : "");
        
    }
    getTimesheetTotalExpense() {
        let total = 0.0;
        this.expenses.forEach(ex => total = total + (ex.PayRate * ex.Quantity));

        let currencySymbol = "";
        if (this.expenses[0] !== undefined) {
            currencySymbol = this.expenses[0].CurrencySymbol;
        }
        return currencySymbol + total.toFixed(2).toString();
    }

    next(){
        if(!this.isLoading){
            this.timesheetId = this.nextTimesheetId();
            this.loadTimesheet( this.timesheetId);
        }
    }

    prev(){
        if(!this.isLoading){
            this.timesheetId = this.previousTimesheetId();
            this.loadTimesheet(this.timesheetId);
        }
    }

    setTitle(){
        this.title = this.getDaySuffix(this.timesheet.PeriodStarting) + " " +
        this.months[moment(this.timesheet.PeriodStarting).toDate().getMonth()] + " - " +
        this.getDaySuffix(this.timesheet.PeriodEnding) + " " +
        this.months[moment(this.timesheet.PeriodEnding).toDate().getMonth()] +
         ", " + moment(this.timesheet.PeriodEnding).toDate().getFullYear();
    }

    formatDate(arg, format) {
        return moment(arg).format(format);
      }
    
      formatTime(arg) {
        return moment(arg).format('LT');
      }

      formatDecimalTime(arg, format, timesheetHourId) {

        //had to do this way as totaling up the lines would not work as we show only minutes on the 
        //line detail and this is rounded up for seconds.
        //e.g. line 1   1m 35sec  shown as 2
        //     line 2   1m 35sec  shown as 2 
        //     total    3m 10 sec would show 3 which does not add up to the two lines (they equal 4)
        // so storing each hours and mins as it is displayed - then using these values for the totals line.

        const time = (moment.duration(arg, 'hours') as Duration);  
        let hours = 0;
        let mins = 0;
        //set hours - convert days to hours also
        hours = (time.days() * 24) + time.hours();

        //set mins - if seconds >= 30 , add on a minute to round up
        mins = time.minutes() + (time.seconds() >= 30 ? 1 : 0);
        
        //check minutes again incase gone over 60 adding the 1 above
        if(mins >= 60)
        {
            // add onto hours by dividing minutes and getting the hours
            hours = hours + Math.floor(mins / 60);
            //set the minutes to the remainder
            mins = (mins % 60);
        }
       
        //if undefined - then the value has not been set yet
        if(this.timesheetHours.find(x => x.TimesheetHourId === timesheetHourId).RunningHours === undefined)
        {
            this.timesheetHours.find(x => x.TimesheetHourId === timesheetHourId).RunningHours = hours;
            this.timesheetHours.find(x => x.TimesheetHourId === timesheetHourId).RunningMin = mins;
        }
        
        return (moment.duration(arg, 'hours') as Duration).format(format);
      }

    getDaySuffix(date) {
        const i = date;
        const j = i % 10;
        const k = i % 100;

        if (j == 1 && k != 11) {
            return i + "st";
        }
        if (j == 2 && k != 12) {
            return i + "nd";
        }
        if (j == 3 && k != 13) {
            return i + "rd";
        }
        return i + "th";
    }

    submitTimesheet() {
        if (!this.timesheet.LastShiftAdded) {
            this.confirm(this.$t("timesheets.missingShiftSubmit").toString(),this.$t("confirm").toString()).then((result) => {
                if(result) {
                    this.$emit("submitTimesheet", this.timesheet.TimesheetId);
                }
            });
        } else {
            this.$emit("submitTimesheet", this.timesheet.TimesheetId);
        }
    }

    saveTimesheetHour(timesheetHourUpdateRequest: TimesheetHourUpdateRequest) {
        timesheetHourUpdateRequest.TimesheetHour.StartTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        timesheetHourUpdateRequest.TimesheetHour.EndTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        TimesheetService.timesheetHourUpdate(timesheetHourUpdateRequest).then(x => {
                this.editTimesheet = false;
                this.loadTimesheet(this.timesheetId);
        }).catch((e)=>{
            this.toastError(this.$t("updateFailed").toString());
        });
    }

    statusClass(expense: TimesheetExpense) {
        if(expense) {
        switch(expense.ExpenseApprovalStatusSystemCode) {
            case "APPROVED":
                return "approved-icon";
            case "AWAITING_APPROVAL":
                return "pending-icon";
            case "REJECTED":
              return "cancel-icon";
          default:
            return "query-icon";
          }
        }
        return "query-icon";
      }
}

interface Duration extends moment.Duration {
    format: (template?: string, precision?: number, settings?: DurationSettings) => string;
  }
  
  interface DurationSettings {
    forceLength: boolean;
    precision: number;
    template: string;
    trim: boolean | 'left' | 'right';
  }