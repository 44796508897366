import localforage from 'localforage';

class LocalStorageService{
   
  getStore(storeName: string){

    const driverPreferenceOrder = [
      localforage.INDEXEDDB,
      localforage.LOCALSTORAGE,
      localforage.WEBSQL
    ];

    const store = localforage.createInstance({
        name: "MobileWorkerDB",
        version : 1.0,
        storeName : storeName,
        driver:driverPreferenceOrder
      });
     // this.setDriver(store);
      return store;
    }

    setDriver(store: LocalForage){
      store.setDriver([
        localforage.INDEXEDDB,
        localforage.WEBSQL,
        localforage.LOCALSTORAGE
      ])
      .catch(error => {
        console.log(error);
      });
    }
}

export default new LocalStorageService();
