import global from "@/utils/global";
import moment from "moment";
import { Vue, Component, Prop } from "vue-property-decorator";

@Component({
 
    name: "PayslipDetail"
  
  })
  export default class PayslipDetail extends Vue {
    @Prop(Object) data;
    constructor()
    {
        super();
    }

    get NetPay()
    {
      const netPay = (+this.data.grossPay - +this.data.deductions).toFixed(2);
      return `£${netPay}`
    }

    get payDateText(){
      return global.formatDateSpecific(this.data.payDay, "Do MMM YYYY");
    }

    back() {

        this.$emit("close");
      }
  }