import JobService,{  Job } from "../JobService";
import { Vue, Component, PropSync,Watch } from 'vue-property-decorator';
import global from '@/utils/global';


@Component({
  })
export default class JobSummaryCard extends Vue {
  @PropSync("job", { type: Object }) jobLocal!: Job;
  @PropSync("showHeart", { type: Boolean,default:true }) showHeartLocal!: boolean;
  logoUrl = "";
  showShortlistIcon = false;
  @Watch('job')
  jobChanged(val: Job, oldVal: Job) {
    this.logoUrl = "";
    if(val.HasLogo) {
        this.setImage();
    }

    this.setShortListIcon();
  }

  constructor() {
         super();
    }

    setImage() {
      JobService.getCompanyLogo(this.jobLocal.JobId,40,40,false).then(e=> {
          this.logoUrl = e;
      });
    }

    setShortListIcon(){
      if (!this.jobLocal.IsApplied && this.showHeartLocal) {
        this.showShortlistIcon = true;
      }
    }

    mounted(){
      if(this.jobLocal.HasLogo) {
        this.setImage();
      }
      this.setShortListIcon();
    }

    formatTimeFromNow(date){
      return global.formatTimeFromNow(date);
    }

    heartClicked(e) {
      e.preventDefault();
      e.stopPropagation();
      if(!this.jobLocal.IsShortlisted) {
        this.$emit("setShortlisted",this.jobLocal);
      } else {
        this.$emit("removeShortlisted",this.jobLocal);
      }
      //
    }
}