
<div v-if="isShowing" ref="interactElement" class="card" :class="{ isCurrent: isCurrent,
          isAnimating: isInteractAnimating }" :style="{ transform: transformString }" @click="showDetails">
         
  <v-container style="padding: 15px;">
    <div class="summary-card">
      <JobSummaryCard :job="card" :showHeart="false"></JobSummaryCard>
    </div>
    <v-row no-gutters>
      <v-col cols="12" sm="4" md="12">

        <div class="text-h2">
          {{ $t("jobs.aboutTheRoleTitle") }}
        </div>
        <div class="job-description text-body-1 text-subtitle-2 grey--text text--darken-2" :style="jobTextHeight">
          {{card.Description}}
        </div>
      </v-col>
    </v-row>
    <v-row no-gutters class="pt-4" v-if="showCommuteOptions">
      <slot name="commuteoptions"></slot>
      <v-col cols="12" sm="4" md="12" style="height: 85px">

        <div class="text-h2" >
          {{ $t("jobs.commuteOptions") }}
        </div>
        <div class="d-flex justify-space-between mb-6">
          <div class="grey--text text--darken-2 text-center travel-time">
            <img class="py-3 rounded img-round transport-icon" src="@/assets/img/icons/car.png" />
            <div class="text-caption grey--text transport-text">
              <v-progress-linear  v-if="!card.carTime"
              color="primary"
              buffer-value="0"
              stream
            ></v-progress-linear>
              {{card.carTime}}</div>
          </div>
          <div class="grey--text text--darken-2 text-center travel-time">
            <img class="px-3 rounded img-round transport-icon" src="@/assets/img/icons/bus.png" />
            <div class="text-caption grey--text transport-text">
              <v-progress-linear  v-if="!card.busTime"
              color="primary"
              buffer-value="0"
              stream
            ></v-progress-linear>
              {{card.busTime}}</div>
          </div>
          <div class="grey--text text--darken-2 text-center travel-time">
            <img class="px-3 rounded img-round transport-icon" src="@/assets/img/icons/train.png" />
            <div class="text-caption grey--text transport-text">
          

            <v-progress-linear  v-if="!card.trainTime"
            color="primary"
            buffer-value="0"
            stream
          ></v-progress-linear>
              {{card.trainTime}}</div>
          </div>
        <div class="grey--text text--darken-2 text-center travel-time">
            <img class="pb-3 rounded img-round transport-icon" src="@/assets/img/icons/cycle.png" />
            <div class="text-caption grey--text transport-text">
          

            <v-progress-linear  v-if="!card.cycleTime"
            color="primary"
            buffer-value="0"
            stream
          ></v-progress-linear>
              {{card.cycleTime}}</div>
          </div>

           <div class="grey--text text--darken-2 text-center travel-time">
            <img class="px-3 rounded img-round transport-icon" src="@/assets/img/icons/walk.png" />
            <div class="text-caption grey--text transport-text">
          

            <v-progress-linear  v-if="!card.walkTime"
            color="primary"
            buffer-value="0"
            stream
          ></v-progress-linear>
              {{card.walkTime}}</div>
          </div>

        </div>
      </v-col>
    </v-row>
  </v-container>

</div>
