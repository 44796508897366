


<v-autocomplete  class="local-auto-complete input-search-location-text search-textfield" dense solo  prepend-inner-icon="mdi-map-marker"
   v-model="selectedItem"
  :items="items"
  :loading="isLoading"
  :search-input.sync="searchText"
  :debounce-search="3000"
   v-on:keyup.13="onEnter"
   clearable
   hide-no-data
   item-text="locationText"
   item-value="id"
   item-long="lng"
   item-lat="lat"
  :label="label"
   return-object
   @change="onItemSelected"
  >
    <template v-slot:prepend-inner>
      <div class="location-icon">
        <img class="header-searchJobs" src="@/assets/img/icons/location.png" height="27px" />
      </div>
    </template>
    </v-autocomplete>
 
