import BaseService from './baseService';
import global from '@/utils/global';
import moment, { Moment } from 'moment';
import {TimesheetEvent,ClockStateEventInsertResponse,TimesheetCheckInEventRequest,ClockStateResult,TimesheetSubmittedResponse,TimesheetSubmittedRequest} from '@/services/checkInDomain'

export default class CheckInService extends BaseService {
    baseUrl: string;
    productCode = "MOB";
    
    postEvent(timesheetEvent: TimesheetEvent): Promise<ClockStateEventInsertResponse> {
        timesheetEvent.Integrator = "RCRM";//this.productCode;
        return this.httpClient(this.productCode).post("/TimesheetCheckIn", timesheetEvent).then((res) => {
            res.data.event.eventTime = new Date(res.data.event.eventTime);
 
           return  res.data;
        });
    }

    getTimesheetEvents(request: TimesheetCheckInEventRequest): Promise<ClockStateResult[]> {
        return this.getClockStates(request);
        
    }

    getClockStates(request: TimesheetCheckInEventRequest): Promise<ClockStateResult[]> {
        return this.httpClient(this.productCode).get("/Clocks", request).then((res) => {

            const result = [];
            res.data.clockStates.forEach( i=>{
                
                i.events.forEach(e =>{
                    e.eventTime = new Date(e.eventTime)
                });

                result.push(new ClockStateResult(i))
            });

            return result;
        });
    }

 
    timesheetSubmitted(data: TimesheetSubmittedRequest ): Promise<TimesheetSubmittedResponse> {

        const promise =  new Promise<TimesheetSubmittedResponse>((resolve,reject)=>{
            return this.httpClient(this.productCode).post("/TimesheetSubmitted", data).then(res => {
                resolve(res.data);
            }).catch((e) => {
               reject(e);
            })
        });

        return promise;
    }
}
