import axios from 'axios'


export class DiscoveryResponse {
    endpoints: DiscoveryEndpoint[];
    features: DiscoveryFeature[];
    branding: DiscoveryBranding;
    googleApiKey: string;
    identitySettings: DiscoveryIdentitySettings;
    dropBoxAPIKey: string;
}

export interface DiscoveryIdentitySettings{
    identityUrl: string;
    clientId: string;
}


export interface DiscoveryEndpoint{
    name: string;
    url: string;
    additionalInfo: DiscoveryEndpointAdditionalInfo[];
}

export interface DiscoveryEndpointAdditionalInfo{
    key: string;
    value: string;
}

export interface DiscoveryFeature{
    name: string;
    permission: string;
    uniqueId: string;
}

export interface DiscoveryBranding{
    companyName: string;
    language: any[];
}


export class Discovery {

    getDiscovery() {
        return new Promise<DiscoveryResponse>((resolve,reject)=>
        {
            axios.get(import.meta.env.VITE_HOST_URL + 'api/discovery', {
                headers: {
                    "Cache-Control": "no-cache",
                    "content-type": "application/vnd.api+json",
                }
            })
            .then((result)=>{
                resolve(result.data);
            }).catch(rejeted =>{
                reject();
            })
        }
        );
    }
}

export default new Discovery();