<template>
  <div class="pt-0">
 
    <v-app-bar dense fixed color="white" class="top-toolbar pt-0 "  extension-height="200px"> 
            
      <v-btn class="back-button navigation-button action-back" @click="back()" text><v-icon>mdi-chevron-left</v-icon>Back</v-btn>
    <template v-slot:extension>
        <div class="div-cal" v-show="showNetworkError">
        
          <ConnectionLostCardView :isVisible="showNetworkError" @close="retryClicked"></ConnectionLostCardView>
        </div>
        
        <div class="pub-job" v-show="!showNetworkError && !jobLoadError">
          <v-divider></v-divider>
          <v-container class="div-background">
          <div >
            <broad-cast-job-summary-card class="pt-2 mb-2 pl-1 pr-1"
                      :job="job"
                      >
              </broad-cast-job-summary-card>
      
      </div>
          <v-tabs  v-model="selectedTabIndex">
              <v-tab class="action-tab-shift" data-id="shift" ref="jobtogglebtn"  @click="selectedView = 'shift'">{{ $t("broadcastJobs.ShiftsLabel") }}</v-tab>
              <v-tab class="action-tab-job" data-id="job" ref="jobtogglebtn"  @click="selectedView = 'job'">{{ $t("jobs.job") }}</v-tab>
              <v-tab  v-if="mapVisible" class="action-tab-map" data-id="map" ref="maptogglebtn" @click="selectedView = 'map'">{{ $t("jobs.map") }}</v-tab>
              <v-tab class="action-tab-recruiter" data-id="recruiter" ref="recruitertogglebtn" @click="selectedView = 'recruiter'">{{ $t("jobs.recruiter") }}</v-tab>
            </v-tabs>
            </v-container>
        </div>
        
      </template>
  </v-app-bar>

 <v-container class="div-background" style="margin-top:200px">
     <div>
       <LoadingIndicator class="mt-12" :isVisible="isLoading"></LoadingIndicator>
  <div v-show="jobLoadError">
      <div class="text-align-center no-results-text" >
        <span class="no-results-title "  >
         {{ $t("broadcastJobs.noResultsTitle") }}
        </span>
        
      </div>
      <div class="pt-2 ma-auto no-results-text">
        {{ $t("broadcastJobs.noResultsDescription") }}
        
      </div>
  </div>
          <div v-show="!isLoading && !showNetworkError && !jobLoadError && isLoaded">

         <v-row v-if="selectedView === 'shift'" class="no-gutters mb-6">
           <v-col cols="12" md="12">
             <v-list class="publist mt-4"  dense>      
            <v-list-item-group  color="primary" v-for="(group1, i) in dateGroup" :key="i">   
              <v-list-item :ripple="false" class="rounded-corners card-border mt-2" style="min-height:70px">
                <v-list-item-icon class="mr-3 pt-2">
                  <v-icon class="header-cal calendar-icon" ></v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  
                  <v-list-item-title class="d-flex justify-space-between"><span class="font-weight-black fs-16">{{getMonth(group1)}}</span><span class="secondary-text-color fs-14">{{group1}}</span><span class="font-weight-bold fs-14">{{$t("broadcastJobs.totalShiftsLabel")}}: {{getShiftCount(group1)}}</span></v-list-item-title>
                  
                </v-list-item-content>
              </v-list-item>  

              <v-list-item :ripple="false" class="rounded-corners card-border"
                v-for="(item, j) in dataByDate(group1)"
                :key="j"
              >
              
                <v-list-item-icon class="mr-3 pt-2">
                  <v-icon v-if="item.ShiftTimeType == 'MORNING'" class="days-icon morning-icon" ></v-icon>
                  <v-icon v-if="item.ShiftTimeType == 'AFTERNOON'" class="days-icon afternoon-icon" ></v-icon>
                  <v-icon v-if="item.ShiftTimeType == 'EVENING'" class="days-icon evening-icon" ></v-icon>
                  <v-icon v-if="item.ShiftTimeType == 'NIGHT'" class="days-icon night-icon" ></v-icon>
                </v-list-item-icon>

                <v-list-item-content>
                  <v-list-item-title ><span class="font-weight-black fs-14">{{upperFirstChar(item.ShiftTimeType)}}</span></v-list-item-title>
                  <v-list-item-subtitle class="pt-1" ><span class="font-weight-bold fs-12">{{item.StartTime}} to {{item.EndTime}}</span></v-list-item-subtitle>
                </v-list-item-content>
                 <v-list-item-action >
               
                <div class="d-flex d-inline-flex pt-2">
                  <v-icon v-if="item.Status == ''" class="action-select-shift days-icon select-shift-icon" @click="item.Status = 'USER_ACCEPTED'" ></v-icon>
                  <v-icon v-if="item.Status == 'USER_ACCEPTED'" class="action-deselect-shift days-icon success-icon" @click="item.Status = ''" ></v-icon>
                  <v-icon v-if="item.Status == 'BS_ACCEPTED'" class="small-icon approved-icon" ></v-icon>
                  <v-icon v-if="item.Status == 'BS_PENDING'" class="small-icon pending-icon" ></v-icon>
                  <v-icon v-if="item.Status == 'BS_REJECTED'" class="small-icon rejected-icon" ></v-icon>
                  <span class="pl-1 font-weight-bold fs-12" >{{getItemText(item)}}</span>
                </div>
              </v-list-item-action>
                
              </v-list-item>
            </v-list-item-group>
          </v-list>
       
           </v-col> 
         </v-row>     


         <v-row v-if="selectedView === 'job'" class="mr-0"  >
          <v-col cols="12" md="12">
             <div class="fs-14 text-color-light-grey pt-7 pb-4">
                    <span class="text-h1">Reference:</span> {{ job.JobRefNo }}
            </div>
            <span class="job-details-description fs-14 text-color-light-grey">
              {{ job.Description }}
            </span>
               </v-col>
            </v-row>
            <v-row v-if="selectedView === 'map' && mapVisible" class="mr-0">
                  <v-col cols="12" md="12" class=" pt-8 pr-0">
                    <div class="map-area">
                      <button v-show="isMapsEnabled" @click="openDirectionsClicked">View Directions</button>
                      <Map :recycle="true" :location="job.location" :lattitude="job.Latitude" :longitude="job.Longitude" :markers="markers">
                      </Map>
                    </div>
               </v-col>    
            </v-row>


            <v-row v-if="selectedView === 'recruiter'" class="mr-0" >
             
                <v-col cols="12" md="12">
                  <div class="text-h1 pt-7 pb-4">
                    Contact Information
                  </div>
                  <div class="d-flex justify-start consultant-details-row">
                    <div class="pr-2 icon-col" >
                    <img src="@/assets/img/icons/name.png" height="14px"/>
                    </div>
                    <div class="value-col fs-14 fw-800 ">
                        Name:
                    </div>
                    <div class="pr-2">  
                  <span class="job-details-recruiter fs-14 text-color-light-grey"> 
                      {{ job.ContactName }}
                     </span> 
                  </div>

                  </div>
                
               </v-col>  
               <v-col cols="12" md="12">

                <div class="d-flex justify-start consultant-details-row">
                  <div class="pr-2 icon-col">
                  <img src="@/assets/img/icons/email.png"  height="10"/>
                  </div>
                  <div class="fs-14 fw-800 value-col">
                      Email:
                  </div>
                  <div class="pr-1">  
                    <span class="job-details-recruiter-email fs-14 text-color-light-grey">
                    <a :href="'mailto:' + job.ContactEmail">{{ job.ContactEmail }}</a>
                    </span>
                </div>

                </div>

               


               </v-col>  
               <v-col cols="12" md="12">

                <div class="d-flex justify-start consultant-details-row">
                  <div class="icon-col">
                  <img src="@/assets/img/icons/phone.png"  width="13"/>
                  </div>
                  <div class="fs-14 fw-800 value-col">
                      Phone:
                  </div>
                  <div class="pr-1">  
                    <span class="job-details-recruiter-tel fs-14 text-color-light-grey">
                      <a :href="'tel:' +  job.ContactTel">   {{  job.ContactTel }}</a>
                    </span>
                </div>

                </div>


                 
               </v-col>  
            </v-row>
          </div>
     </div>
</v-container>
<v-footer fixed color="white" style="z-index: 9999;" v-if="selectedView === 'shift' && !jobLoadError">
      <v-container class="pt-0 pb-0">
        <v-row justify="center">
         
            <v-btn
              class="action-claim-shift"
              depressed
              block
              color="primary"
              :disabled="anyShifts"
              @click="claimShifts()"
            >
              {{ $t("claimShifts") }}
            </v-btn>

        </v-row>
        
      </v-container>
    </v-footer>

</div>
</template>

<script lang="ts">
    import ViewModel from "./BroadCastJobDetails"
    export default ViewModel;
</script>


  <style lang="scss" scoped>
  
.publist .v-list-item--link:before  {

  background-color: unset !important;
 }

.publist .v-list-item-group .v-list-item--active
{
  color: rgba(0, 0, 0, 0.87) !important;
}

  .pub-job {
  width: 100%;
  background-color: white;
} 
.calendar-icon
{
      content: url("../../../../assets/img/jobs/broadcast/Calendar.svg");
}
  .morning-icon {
      content: url("../../../../assets/img/jobs/broadcast/Morning.svg");
      
    }

.afternoon-icon {
      content: url("../../../../assets/img/jobs/broadcast/Afternoon.svg");
      
    }
.evening-icon {
      content: url("../../../../assets/img/jobs/broadcast/Sunset.svg");
      
    }

.night-icon {
      content: url("../../../../assets/img/jobs/broadcast/Night.svg");
      
    }
.select-shift-icon
{
      content: url("../../../../assets/img/jobs/broadcast/Select Shift.svg");
}

.success-icon
{
      content: url("../../../../assets/img/jobs/broadcast/Icon - Success.svg");
}
.approved-icon
{
      content: url("../../../../assets/img/jobs/broadcast/Approved.svg");
}

.pending-icon
{
      content: url("../../../../assets/img/jobs/broadcast/Clock.svg");
}

.rejected-icon
{
      content: url("../../../../assets/img/jobs/broadcast/Icon - Failure.svg");
}


  .header-cal
  {
    width: 40px!important;
    height: 40px!important;
  }

  .days-icon
  {
    width: 30px!important;
    height: 30px!important;
  }
  .small-icon
  {
    width:15px!important;
    height: 15px!important;
  }


  .icon-col{
    min-width:30px;
    /* text-align:center; */
  }
  
  .value-col{
    min-width:50px;
  }

.no-results-title{
    font-size:22px;
    font-weight: bold;
    text-align: center;;
  }

  .no-results-text{
    font-size:16px;
    text-align: center;
  }

  .consultant-details-row
  {
    line-height: 20px!important;
  }

   .image-heart {
      content: url("../../../../assets/img/icons/heart.png");
      width: 20px!important;
      height: 19px!important;
    }
    .image-heart-filled {
      content: url("../../../../assets/img/icons/heart filled.png");
      width: 20px!important;
      height: 19px!important;
    }

  .div-background
  {
    background-color: white;
    padding: 10px;
  }
  .div-background-white
  {
    background-color: white;
    
  }
  .heart-icon-div {
    padding-bottom: 0px;
  }

  .v-list-item {
    align-items: start;
    padding-left: 10px;
  }

  .details-container{
    padding-left:8px;
  }

  .map-area{
    height: calc(100vh - 328px);
    width: 100%;
    margin-right:0px;
    margin-left:0px;
  }
</style>