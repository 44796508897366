

 <div id="app" :key="componentKey">
  

   <v-app >  

    <v-main>
   
      <v-container  fluid class="app-container pl-0 pr-0 pt-0" style="overflow:hidden;width:100%"> 
       <AcceptTerms v-if="!termsAccepted" :dialog="!termsAccepted"></AcceptTerms>
       <UpdateBanner></UpdateBanner>
       <InstallBanner v-if="canInstall" ></InstallBanner>
       
       <OnBoarding :dialog="isOnBoarded"></OnBoarding>
       <v-navigation-drawer v-model="menuOpen" fixed right temporary v-if="isLoggedIn" width="310" >
         <template v-slot:prepend >
           <div class="float-right">
           <v-icon class="pr-2 pt-2" @click="menuOpen = !menuOpen">mdi-close</v-icon>
           </div>
         </template>
         <div class="pt-4 pl-3 d-flex">
           <v-btn fab elevation="0" disabled outlined color="white" class="logoside-btn">
             <v-icon class="pt-1 headerside-logo"></v-icon>
           </v-btn>
           <div class="pl-2 pt-2" >
             <div class="h1 text-h1">{{firstName}}</div>
              <div class="pt-1 fs-13 color-sidesub">{{userName}}</div>
           </div>
          
         </div>
         <v-list nav class="sidenav pl-0 pr-0" >
           <v-list-item-group class="pt-4">
            
             <v-list-item class="mb-1 mt-1 sub-list-item " to="/profile">
               <v-list-item-icon class="mt-3 mb-3 mr-4" >
                 <v-icon class="icon-color">mdi-account-outline</v-icon>
               </v-list-item-icon>
               <v-list-item-title class="list-item-text">{{$t('nav.myAccount')}}</v-list-item-title>
             </v-list-item>

             <v-list-item to="/managecv" class="mb-1 mt-1 sub-list-item">
               <v-list-item-icon class="mt-3 mb-3 mr-4">
                 <v-icon class="icon-color">mdi-text-box-outline</v-icon>
               </v-list-item-icon >
               <v-list-item-title class="list-item-text">{{$t('nav.manageCv')}}</v-list-item-title>
             </v-list-item>
             
             <v-list-group v-show="isPayslipEnabled || isEarlyPayEnabled"
               prepend-icon="mdi-apps" class="more-group ">
               <template v-slot:activator>
                 <v-list-item-title class="list-item-text">{{$t('nav.moreFromAccess')}}</v-list-item-title>
               </template>
                 <v-list-item v-show="isPayslipEnabled" class="mb-1 mt-1 sub-list-item" to="/payslip" >
                   <v-list-item-icon class="mt-3 mb-3 mr-4">
                     <v-icon class="icon-color">mdi-currency-gbp</v-icon>
                   </v-list-item-icon>
                   <v-list-item-title class="list-item-text">{{$t('nav.paySummary')}}</v-list-item-title>
                 </v-list-item>
                 <v-list-item v-show="isEarlyPayEnabled" class="mb-1 mt-1  sub-list-item" @click="earlyPayLinks" >
                   <v-list-item-icon class="mt-3 mb-3 mr-4">
                    <v-icon class="img-earlypay" ></v-icon>
                   </v-list-item-icon>
                   <v-list-item-title class="list-item-text pt-2">{{$t('nav.earlyPay')}}</v-list-item-title>
                 </v-list-item>
               </v-list-group>

             <!-- <v-list-item v-show="isInDev" to="/debug" class="mb-1 mt-1 pl-7 sub-list-item" >
               <v-list-item-icon class="mt-3 mb-3 mr-4">
                 <v-icon class="icon-color">mdi-bug</v-icon>
               </v-list-item-icon>
               <v-list-item-title class="list-item-text">Debug</v-list-item-title>
             </v-list-item> -->
             
             <v-list-item @click="resetInstall" class="mb-1 mt-1 sub-list-item" >
               <v-list-item-icon class="mt-3 mb-3 mr-4">
                 <v-icon class="icon-color">mdi-cloud-download-outline</v-icon>
               </v-list-item-icon>
               <v-list-item-title class="list-item-text">{{$t('nav.installApp')}}</v-list-item-title>
             </v-list-item>
             <v-list-item @click="logOut" class="mb-1 mt-1  sub-list-item" >
               <v-list-item-icon class="mt-3 mb-3 mr-4">
                 <v-icon class="icon-color">mdi-power</v-icon>
               </v-list-item-icon>
   
               <v-list-item-title class="list-item-text">{{$t('nav.logout')}}</v-list-item-title>
             </v-list-item>
           </v-list-item-group>
         </v-list>
       </v-navigation-drawer> 
       <Confirm ref="confirm" ></Confirm>
       <Messagebox ref="messageBox"></Messagebox>
       <Toast  ref="toasts"></Toast>
   <router-view >

   </router-view>    

      </v-container>
    </v-main> 
  
     <OfflineNotification></OfflineNotification>
    
    <v-bottom-navigation class="bottom-nav"  app fixed v-model="selectedTab" v-if="isLoggedIn">
    
     <v-btn v-for="item in getNavItems" :key="item.name" :value="item.name" :to="item.url">
          <v-badge :content="jobOffers" v-if="item.name === 'jobs' & jobOffers > 0"
          :value="jobOffers"
                 color="red"
                 overlap>
             <div class="bottom-nav-button">
             <div class="mb-1"><img :alt="item.name" :class="'nav-' + item.name" class="navbar-tab-image"></div>
             <div>{{ $t(item.text) }}</div>
           </div>
         </v-badge>

         <div class="bottom-nav-button" v-if="item.name === 'jobs' & !jobOffers">
           <div v-if="item.name === 'jobs' & !jobOffers">
           <img :alt="item.name" :class="'nav-' + item.name" class="navbar-tab-image"></div>
             <div>{{ $t(item.text) }}</div>
         </div>
       <div class="bottom-nav-button" v-if="item.name !== 'jobs'">
         <div><img :alt="item.name" :class="'nav-' + item.name" class="navbar-tab-image"></div>
         <div>{{ $t(item.text) }}</div>
       </div>
     </v-btn>

     <v-btn value="more" @click.stop="menuOpen = !menuOpen">
       <div class="bottom-nav-button">
         <div><img alt="more" class="nav-more navbar-tab-image"></div>
         <div>{{ $t("nav.more") }}</div>
       </div>
     </v-btn>
   </v-bottom-navigation>

  </v-app>

</div>
