import Vue from 'vue'
import Component from 'vue-class-component'
import store from '@/store'
import notifications from '@/utils/notifications'
import ErrorLogger from '@/utils/errorLogger'
import Alert from "@/components/Alert.vue";
import VueTimepicker from 'vue2-timepicker'
import 'vue2-timepicker/dist/VueTimepicker.css'
import { Mixins } from 'vue-property-decorator'
import { NotificationMixin } from '@/components/mixins/notificationMixin'
import googleMapsHelper from '@/utils/googleMapsHelper'


class Features{

    constructor(){
        const indexDb  = window.indexedDB || (window as any).mozIndexedDB || (window as any).webkitIndexedDB || (window as any).msIndexedDB;
        this.IndexDB = indexDb?true:false;

        const props = ["productSub","vendor","cookieEnabled","appCodeName","appName","appVersion","appVersion","product","language","languages","onLine","deviceMemory"];
        for(const property in window.navigator){ 
            if(props.indexOf(property,0) >= 0){
                const str = navigator[property]
                this.NavigatorInfo.push({name:property,value:str})
            }
        } 
    }

    IndexDB: boolean;
    NavigatorInfo: any[] = [];
}

@Component({
    components: { Alert,VueTimepicker },
    computed:{
        
        userAuth(){
            return  store.getters.AppState.accessToken;
        },

        isAuthenticated(){
            return  store.getters.AppState.isAuthenticated;
        },

        userProfile(){
            return  store.getters.AppState.userName;
        },

        discoveryDocument(){
            return  store.getters.AppState.discovery;
        },

        rcrmEndpoint(){
            return  store.getters.AppState.endPoint("RCRM");
        },
        rcrmEndpointSoftwareKey(){
            return  store.getters.AppState.endPointAdditionalInfo("RCRM","SoftwareKey");
        }
    }
})
export default class DebugComponent extends Mixins(NotificationMixin) {

    //buildTime = "t";
   // version = "t";
    
    langs = ['enCat', 'en'];
    features: Features ;
    pushNotification = false;
    pushDisabled = false;
    showErrorLogs = false;
    errorLogs  = [];
    message = false;
    messageText = "";
    alertType = "";


    
    showPicker = false;

    constructor() {
        super();
        
        
        
    }

    getMinutes()
    {
        const times = [];
        for (let _i = 0; _i < 61; _i++) {
            
            times.push(_i);
          }
          return times;
    }

    getHours()
    {
        const times = [];
        for (let _i = 0; _i < 25; _i++) {
            
            times.push(_i);
          }
          return times;
    }

   



    mounted()
    {
        notifications.checkPushDisabled().then((result) =>{
            this.pushDisabled = result;
        });
        notifications.checkPushStatus().then((result) =>{
            this.pushNotification = result;
        });

        setTimeout(() => {
            this.showPicker = true
          }, 200)
          
    }


    
   refreshErrorLogs(){
        this.errorLogs =  ErrorLogger.getErrors();
   }

   clearCache(){
       if(caches){
            caches.keys().then(cacheNames => {
                cacheNames.forEach(cacheName => {
                caches.delete(cacheName);
                });
            });
            alert("Cleared Chace Successfuly");
            document.location.reload(true);
            return false;
        }else{
            alert("Cache Unavilable");
        }
        return false;
   }

   showErrorsClicked(){
       this.refreshErrorLogs();
       this.showErrorLogs = true;
   }

    get version(){
                return import.meta.env.VITE_VERSION;
    }

    get buildTime(){
        return import.meta.env.VITE_BUILDTIME;
}

    get getFeatures(){
        if(this.features){
            this.features = new Features();
        }
        return this.features;
    }

    showUpdateBanner(){
         const UpdatedEvent = new CustomEvent('swUpdated', { detail:""});
         document.dispatchEvent(UpdatedEvent);

    }

  

    requestNotificationPermission(){
        notifications.requestPermission();
    }

    showNotification(){
        const options = {
            body: 'Here is a notification body',
            icon: 'images/example.png',
            vibrate: [100, 50, 100],
            data: {
              dateOfArrival: Date.now(),
              primaryKey: 1
            },
            actions: [
                {action: 'explore', title: 'Explore this new world',
                  icon: 'images/checkmark.png'},
                {action: 'close', title: 'Close notification',
                  icon: 'images/xmark.png'},
              ]
          };

        notifications.displayNotification("Message from Debug Window",options);
    }

    onNotificationClosed(e){
        console.log("Notificationclosed",e);
    }

    created(){
        this.features = new Features();
        document.addEventListener('notificationclosed', this.onNotificationClosed,{once:true});
    }

    deactivated(){
        document.removeEventListener('notificationclosed', this.onNotificationClosed);
    }


    setNotificationPermission(e)
    {
        if(e == false)
        {
            notifications.unsubscribeUser();
            this.pushNotification = false;
            return true; 
        }
        notifications.subscribeUser();
        this.pushNotification = true;
        return;

    }

    showAlert(alertMessage: string, type: string){
        this.messageText = alertMessage;
        this.alertType = type;
        this.message = true;
    }

    closeAlert(){
        this.message = false;
    }


    syncErrors(){
        ErrorLogger.syncErrors();
    }


    get Browser() {

                const isIE = /*@cc_on!@*/false || !!document["documentMode"];
                const isEdge = !isIE && (navigator.userAgent.indexOf("Edge") != -1 || navigator.userAgent.indexOf("Edg") != -1) ;
                if ( navigator.userAgent.indexOf("Opera") != -1 || navigator.userAgent.indexOf('OPR') != -1 )
                    {
                    return 'Opera';
                }
                else if (navigator.userAgent.indexOf('Opera Mini') != -1)
                {
                    return 'Opera Mini';    
                }
                else if(navigator.userAgent.indexOf("Chrome") != -1 && !isEdge)
                {
                    return 'chrome';
                }
                else if(navigator.userAgent.indexOf("Safari") != -1 && !isEdge)
                {
                    return 'safari';
                }
                else if(navigator.userAgent.indexOf("Firefox") != -1 ) 
                {
                    return 'firefox';
                }
                else if((navigator.userAgent.indexOf("MSIE") != -1 ) || (!!document["documentMode"] == true )) //IF IE > 10
                {
                    return 'ie';
                }
                else if(isEdge)
                {
                    return 'edge';
                }
                else 
                {
                    return'other-browser';
                }
       }


    
    

       showConfirm(){
            this.confirm("Hello World","A Title").then((result)=>{
                    alert("Selected option " + result);
            });
       }

       showSuccessAlert(){
            this.messageBoxSuccess("Success message").then(()=>{
                alert("Alert closed");
            });
       }

       showInfoAlert(){
            this.messageBoxInfo("Info message");
        }

        shoWarningAlert(){
            this.messageBoxWarning("Warning message");
        }

        shoUpdateAlert(){
            this.messageBoxWarning("Update message");
        }

        showToast(){
            this.toast("hello from toast","Error");
        }


        showToastSuccess(){
            this.toast("Removed successfully","Success");
        }

        refreshAccessToken(){
            store.dispatch.AppState.getAccessTokenForceRefresh().then((a)=>{
                console.log(a);
            });
        }

        loadDirectionsWithGoogle(){
            //googleMapsHelper.loadDirections()

            window.open(`https://www.google.com/maps/dir/?api=1&origin=51.3970398,-0.9103488&destination=51.460462,-0.475618`);
        }

        loadDirectionsWithAppleMapProtocol(){
            window.open('maps://' + 'maps.apple.com/maps?daddr=' + '51.460462,-0.475618' + '&amp;ll=');
        }

        loadDirectionsWithAppleHttpProtocol(){
            window.open('https://' + 'maps.apple.com/maps?daddr=' + '51.460462,-0.475618' + '&amp;ll=');
        }
}

