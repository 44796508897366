import { Vue, Component, PropSync } from 'vue-property-decorator'
import store from '@/store';

 @Component({
 })
 export default class UserToolbar extends Vue {
   
  @PropSync('isVisible', { type: Boolean }) visible!: boolean

  get userName(){
      return store.getters.AppState.firstName;
  }

  constructor(){
    super();
  }
 }