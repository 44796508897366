

  <div class="about">
      <v-card
  class="mx-auto"
  max-width="344"
  outlined
>
<h1>Logged Out</h1>

You have been logged out ,click below to login
  <v-card-actions>
      <v-btn large color="primary" to="/">Login</v-btn>
  </v-card-actions>

</v-card>


  
    

      <!-- <b-btn  to="/">Login</b-btn> -->
      
  </div>

