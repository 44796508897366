import store from "../store/index";
import axios, { AxiosInstance,AxiosResponse, AxiosError } from "axios";
import moment from 'moment';
import HttpHelpers from '@/utils/httpHelpers'

export default class HttpClient {

    _axios: AxiosInstance;
    constructor(productCode: string) {
        let baseUrl: string;
        if (productCode !== undefined){
            baseUrl = import.meta.env.VITE_RCRM_URL && productCode === "RCRM" ?
                import.meta.env.VITE_RCRM_URL : store.getters.AppState.endPoint(productCode)[0].url;
            if(!baseUrl.endsWith("/")){
                baseUrl += '/'
            }
            if(productCode==="RCRM") {
                baseUrl += "mobileapi/";
            } else {
                baseUrl += "api/";
            }
        }

        let softwareKey: string;
        if  (store.getters.AppState.endPointAdditionalInfo(productCode,"SoftwareKey") !== undefined) {
            softwareKey = store.getters.AppState.endPointAdditionalInfo(productCode,"SoftwareKey").value;
        }
        this._axios = axios.create({
            baseURL: baseUrl,
            headers: {
                // "Access-Control-Allow-Origin" : "*",
                "Access-Control-Allow-Methods":"GET,PUT,POST,DELETE,PATCH,OPTIONS",
                "SoftwareKey": softwareKey
                }
        });
    }



    get (path: string,queryStringObject? : any,config: any = {}) {       
        path = HttpHelpers.objectToQueryString(path,queryStringObject);

        const resultPromise =  new Promise<AxiosResponse<any>>((resolve,reject) =>{

            store.dispatch.AppState.getAccessToken().then((accessToken)=>{
                this._axios.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`;
                this._axios.get(path,config).then(result =>{
                    resolve(result);
                }).catch((e) =>{
                            if(e){

                            
                     //Set flag so we know if its a server error or a network error.
                     if(e.message == "Network Error") {
                        e.isNetworkError = true;
                    }
                    else if(e.response.data.errors){
                        e.hasValidationErrors = true;
                        e.validationMessages = e.response.data.errors;
                    }
    
                    reject(e);
                }else{
                    reject({message:"Unknown error"});
                }
                });
             }).catch((e) =>{
                 if(e){

                 
                //Set flag so we know if its a server error or a network error.
                if(e.message == "Network Error") {
                   e.isNetworkError = true;
               }
               else if(e.response.data.errors){
                   e.hasValidationErrors = true;
                   e.validationMessages = e.response.data.errors;
               }

               reject(e as CustomAxiosResponse);
            }
            else{
                reject({message:"Unknown error"});
            }
           });

            
        });
      
        return resultPromise;
    }

  

    post (path: string,data: any) {
        const resultPromise =  new Promise<AxiosResponse<any>>((resolve,reject) =>{

            store.dispatch.AppState.getAccessToken().then((accessToken)=>{
                if(data){
                    data = HttpHelpers.updateDates(data);
                }

                this._axios.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`;
                return this._axios.post(path, data).then(result =>{
                    resolve(result);
                }).catch((e) =>{
    
                    if(e){
                    //Set flag so we know if its a server error or a network error.
                    if(e.message == "Network Error") {
                        e.isNetworkError = true;
                    }
                    else if(e.response.data.errors){
                        e.hasValidationErrors = true;
                        e.validationMessages = e.response.data.errors;
                    }
                    reject(e as CustomAxiosResponse);
                    }
                    else{
                        reject({message:"Unknown error"});
                    }
                });
            }).catch((e) =>{
                if(e){
                 //Set flag so we know if its a server error or a network error.
                 if(e.message == "Network Error") {
                    e.isNetworkError = true;
                }
                else if(e.response.data.errors){
                    e.hasValidationErrors = true;
                    e.validationMessages = e.response.data.errors;
                }

                reject(e as CustomAxiosResponse);
                }
                else{
                    reject({message:"Unknown error"});
                }
            });
        });
  
        return resultPromise;
    }


    postAnnonymous(path: string,data: any) {
        const resultPromise =  new Promise<AxiosResponse<any>>((resolve,reject) =>{

            return this._axios.post(path, data).then(result =>{
                    resolve(result);
                }).catch((e) =>{
    
                    //Set flag so we know if its a server error or a network error.
                    if(e.message == "Network Error") {
                        e.isNetworkError = true;
                    }
                    else if(e.response.data.errors){
                        e.hasValidationErrors = true;
                        e.validationMessages = e.response.data.errors;
                    }
    
                    reject(e as CustomAxiosResponse);
                });
        });
  
        return resultPromise;
    }
}


export interface CustomAxiosResponse<T = any> extends AxiosError
{
    isNetworkError: boolean;
    hasValidationErrors: boolean;
    validationErrors: any[];
}