<template>
  <v-dialog v-model="isVisible" @close="close" persistent>
      <v-card>
        <v-card-title class="d-flex justify-center">
          
          <div  class="d-flex flex-column">
            <img v-if="type =='Warning'" src="@/assets/img/icons/Warning.png" height="100px"/>
            <img v-if="type =='Info'" src="@/assets/img/icons/Info.png" height="100px"/>
            <img v-if="type =='Update'" src="@/assets/img/icons/Update.png" height="100px"/>
            <img v-if="type =='Success'" src="@/assets/img/icons/Success.png" height="100px"/>
            <v-row justify="center">
              {{title}}
            </v-row>
          </div>
        </v-card-title>
        <v-card-text class="alertBody">
          <v-spacer></v-spacer>
           <v-row justify="center" class="centered">
                  {{message}}
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-btn class="primary" block text @click="close">
            <b>{{ $t("ok") }}</b>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
</template>
<script lang="ts">
    import ViewModel from './Messagebox'  
    export default ViewModel;
</script>
