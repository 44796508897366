import global from '@/utils/global';
import moment, { Moment } from 'moment';

export class ClockStateEvent {
    eventTime: Date;
    id?: string;
    uniqueId: string;
}

export interface DurationInfo {
    hours: string | number;
    minutes: string | number;
    seconds: string | number;
}

export class ClockState {
    static NotStarted = "notStarted";
    static Stopped = "stopped";
    static Active = "active";
    static Paused = "paused";
    static Submitted = "submitted";

    retrieveClockState(clockState: string){
        switch(clockState.toLocaleLowerCase()){
            case "stopped":
                return ClockState.Stopped;
            case "active":
                return ClockState.Active;
            case "paused":
                return ClockState.Paused;
            case "submitted":
                    return ClockState.Submitted;
            default:
                return ClockState.NotStarted;
        }
    }
}

export class ClockStateResult {
    clockStateId: string;
    private  currentState: string;
    associatedData: string;
    private readonly  events: ClockStateEvent[] = [];

    constructor(instanceData?: ClockStateResult | any){
        if (instanceData) {
            this.deserialize(instanceData);
          }
    }

    private deserialize(instanceData: ClockStateResult) {
            

        // Note this.active will not be listed in keys since it's declared, but not defined
        const keys = Object.keys(instanceData);
    
        for (const key of keys) {
            this[key] = instanceData[key];
        }
      }

      generateCheckOutDate(jobShiftDate: Date | string,timeString: any){
        let eventDate = new Date();// 
        if(!this.getCheckInEvent()){
           eventDate =  global.setTimeOnDate(jobShiftDate,timeString);
        }
        return eventDate;
      }

      eventCount(){
        return this.events.length;
        }

      hasEvents(){
          return this.events.length  > 0;
      }

    getClockState(){
        return new ClockState().retrieveClockState(this.currentState);
    }

    updateClock(insertResponse: ClockStateEventInsertResponse){
        this.clockStateId = insertResponse.clockStateId;
        this.currentState = insertResponse.currentState;
        this.events.push(insertResponse.event);
    }

    addEvent(event: ClockStateEvent){
        this.events.push(event);
    }

    getCheckInEvent(){
        return (this.events) ? this.events.filter((jobShift) =>{
            return jobShift.uniqueId == "CHECKIN";
        })[0] : null;
    }

    getCheckOutEvent(){
        return (this.events) ? this.events.filter((jobShift) =>{
            return jobShift.uniqueId == "CHECKOUT";
        })[0] : null;
    }


    currentEvent(){
        if(this.events && this.events.length > 0){
            return this.events[this.events.length -1];
        }

        return new ClockStateEvent();
    }

    getTotalBreakDurationString(){

        let duration = moment().startOf('day');
        const breaks = this.getBreaks();
        breaks.forEach(b => {
            duration = duration.add(b.duration);
        });

        return duration.format("HH:mm").toString() == "00:00" ? "" : duration.format("HH:mm").toString();
    }

    getBreakTimeTotalMilliSeconds(){
        let totalMilliSeconds = 0;

        const breaks = this.getBreaks();
        breaks.forEach(b => {
            totalMilliSeconds = totalMilliSeconds + b.totalMilliSeconds;
        });

        return totalMilliSeconds;
    }

    getBreakTimeTotalMinutes(){
        let totalMinutes = 0;

        const breaks = this.getBreaks();
        breaks.forEach(b => {
            totalMinutes = totalMinutes + b.totalMinutes;
        });

        return totalMinutes;
    }

    getBreaks(){
        const result: BreakItem[] = [];

        //Calculates the breaks for manually subitted events without a checkin event
        if(!this.getCheckInEvent() && this.getCheckOutEvent() && this.currentState == "Submitted"){
                const data =  JSON.parse(this.associatedData);
               
                if(data.TimeSheetStartTime && data.TimeSheetEndTime && data.TimeSheetBreakTimeTotalMilliSeconds > 0){

                    const date = new Date(0);
                    const endTime = new Date(date.getTime() + data.TimeSheetBreakTimeTotalMilliSeconds);
                    const duration = moment.utc(moment(endTime).diff(date)).format("HH:mm:ss");

                    const breakEvent: BreakItem = {
                        startTime:date,
                        endTime:endTime,
                        duration:duration,
                        totalMilliSeconds : endTime.getTime() - date.getTime(),
                        totalMinutes :(endTime.getTime() - date.getTime()) / 60000
                    };

                    result.push(breakEvent);
            }
        }
        else{
            for(let i = 0;i <=this.events.length;i++){
                
                const t = this.events[i];

                if(t){
                    if(t.uniqueId == "PAUSE"){

                        const breakItem: BreakItem = {
                            startTime:t.eventTime,
                            endTime:null,
                            duration:"",
                            totalMinutes:0,
                            totalMilliSeconds:0
                        };

                        //Check if we have a unpause event
                        if(this.events.length > i +1 && this.events[i +1].uniqueId == "UNPAUSE"){
                            breakItem.endTime = this.events[i +1].eventTime;
                            breakItem.duration = moment.utc(moment(breakItem.endTime).diff(breakItem.startTime)).format("HH:mm:ss");//global.getDurationBetweenDates(breakItem.startTime, breakItem.endTime).format("HH:mm:ss");
                            breakItem.totalMilliSeconds = breakItem.endTime.getTime() - breakItem.startTime.getTime();
                            breakItem.totalMinutes = breakItem.totalMilliSeconds / 60000;
                        }

                        result.push(breakItem);
                    }
                }
            }
        }
        return result;
    }

    getTotalMilliseconds(){

        //Calculates the totalTime for manually subitted events without a checkin event
        if(!this.getCheckInEvent() && this.getCheckOutEvent() && this.currentState == "Submitted"){
                
                const data =  JSON.parse(this.associatedData);
                if(data.TimeSheetStartTime && data.TimeSheetEndTime && data.TimeSheetBreakTimeTotalMilliSeconds >= 0){
                const startTime = new Date(data.TimeSheetStartTime);
                const endTime = new Date(data.TimeSheetEndTime);
                const breaks = this.getBreakTimeTotalMilliSeconds();
                return endTime.getTime() - startTime.getTime() - breaks;

                }
            return 0;
        }
            else{
            let totalMilliSeconds = 0;

            let totalBreaksInMilliSeconds = 0;
            const breaks = this.getBreaks();
            breaks.forEach(b => {
                totalBreaksInMilliSeconds= totalBreaksInMilliSeconds + b.totalMilliSeconds;
            });

            const checkInEvent = this.getCheckInEvent();
            const checkOutEvent = this.getCheckOutEvent();

            if(checkOutEvent){
                if(checkInEvent){
                    totalMilliSeconds =  checkOutEvent.eventTime.getTime() - checkInEvent.eventTime.getTime();// global.getTotalMinutesBetween(checkInEvent.eventTime, checkOutEvent.eventTime);
                }
            }
            else if(this.currentEvent && this.currentEvent().uniqueId === "PAUSE"){
                if(checkInEvent){
                    totalMilliSeconds =  this.currentEvent().eventTime.getTime() - checkInEvent.eventTime.getTime();//  global.getTotalMinutesBetween(checkInEvent.eventTime, this.currentEvent().eventTime);
                }
            }
            else{
                if(checkInEvent){
                    totalMilliSeconds =  new Date().getTime() - checkInEvent.eventTime.getTime(); // global.getTotalMinutesBetween(checkInEvent.eventTime, new Date());
                }
            }

            return totalMilliSeconds - totalBreaksInMilliSeconds;
        }
    }
    
    

    getTimesheetStartTimeFormatted(){
        const data = this.associatedData ? JSON.parse(this.associatedData) : "";

        if(data.TimeSheetStartTime){
            return global.formatDate(data.TimeSheetStartTime).format("HH:mm").toString();
        }

        return "";

    }

    getDurationString(){
        const duration = this.getClockDuration();
        return  `${duration.hours}:${duration.minutes}`;
    }
    

    getClockDuration(){

        const milliseconds = this.getTotalMilliseconds();

        const hours = milliseconds / (1000*60*60);
        const absoluteHours = Math.floor(hours);
        const h = absoluteHours > 9 ? absoluteHours : '0' + absoluteHours;
      
        //Get remainder from hours and convert to minutes
        const minutes = (hours - absoluteHours) * 60;
        const absoluteMinutes = Math.floor(minutes);
        const m = absoluteMinutes > 9 ? absoluteMinutes : '0' +  absoluteMinutes;
      
        //Get remainder from minutes and convert to seconds
        const seconds = (minutes - absoluteMinutes) * 60;
        const absoluteSeconds = Math.floor(seconds);
        const s = absoluteSeconds > 9 ? absoluteSeconds : '0' + absoluteSeconds;
      
        const result: DurationInfo = {
            hours: h.toString(),
            minutes:m.toString(),
            seconds:s.toString()
        };

        return result;
    }
}

export class TimesheetEvent {
    Time: Date;
    UniqueId: string;
    Options: string;
    Integrator: string;
    ClockStateId: string;
    IgnoreActiveClocks = false;
    IanaTimeZone: string;
}

export class TimesheetCheckInEventRequest {
    StartDate: Date;
    EndDate: Date;
    Id: string;
}




export interface BreakItem{
    startTime: Date;
    endTime: Date;
    duration: string;
    totalMinutes: number;
    totalMilliSeconds: number;
}



export class ClockStateEventInsertResponse {
    event: ClockStateEvent;
    currentState: string;
    clockStateId: string;
}


export class TimesheetSubmittedRequest{
    clockStateId: string;
    associatedData: string;
    timesheetDate: Date;
    ianaTimeZone: string;
}


export class TimesheetSubmittedResponse {
    clockStateId: string;
    clockState: string;
}