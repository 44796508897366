<template>
    <div>
      <div class="d-flex justify-space-between text-subtitle-1 jobresult-summary pb-2">
            <div class="light">
                {{keywords}}<span v-if="keywords && location">,</span> {{location}}
            </div>
            <div class="text-subtitle-1" v-if="totalRecords > 0">
                
                <b :data-totaljobs="totalRecords"> {{totalRecords}}</b>  <span class="light">Job Results</span>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
    import ViewModel from './JobResultSummary'  
    export default ViewModel;
</script>

<style lang="scss" scoped>
   
  </style>