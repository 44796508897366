
<div>
  <JobResultsSummary :keywords="jobSearchRequest.Keywords" :location="jobSearchRequest.LocationText" :totalRecords="totalResults"></JobResultsSummary>
  <JobNoResults  v-if="totalResults ==0 && !isLoadingLocal" @retrySearch="retrySearch"></JobNoResults>

  <div class="map-loading">
    <LoadingIndicator :isVisible="isLoadingLocal" messageText=" "></LoadingIndicator>
  </div>

  <div class="map-area" v-show="showMap">
    <Map :recycle="true" :zoom="8" recyclePrefix="gm2" :lattitude="lattitude" :longitude="longitude" :markers="markers" @markerclicked="onMarkerClicked">
      <div slot="cardview" class="card" @click="showJob(selectedJob)" v-if="selectedJob && showCardView" >
        <div>

          <JobSummaryCard :job="selectedJob" 
              class="pr-4 mb-0" 
              @setShortlisted="setShortlisted"
              @removeShortlisted="removeShortlisted"
            >
          </JobSummaryCard>
      </div>
      </div>
    </Map>
  </div>
</div>
