import store from '@/store';
import BaseService from './baseService';

export class UserProfileResponse {
    emailAddress: string;
    phoneCountryCode: number;
    phoneNumber: string;
    firstName: string;
    surname: string;
    middleName: string;
    title: string;
}

export class UserProfileUpdateRequest{
    phoneCountryCode: number;
    phoneNumber: string; 
    originalPhoneCountryCode: number;
    originalPhoneNumber: string; 
    firstName: string;
    surname: string;
    middleName: string;
    title: string;

}

export class UserProfile  extends BaseService{

    baseUrl: string;

    updateRCRM(profile: UserProfileUpdateRequest){
        
            return new Promise<UserProfileResponse>((resolve,reject)=>
            {
                if(store.getters.AppState.featureEnabled("RCRMJOBS")){
                        return this.httpClient("RCRM").post("/phonenumber/Update",profile).then(async res => {
                            return  res.data;
                        }).then(async r => {
                            resolve(r);
                        })
                }else{
                    resolve();
                }
            });
    }

    update(profile: UserProfileUpdateRequest) {
        return new Promise<UserProfileResponse>((resolve,reject)=>
        {
            return this.httpClient("MOB").post("/UserProfile",profile).then(async res => {
                return  res.data;
             }).then(async r => {

                this.updateRCRM(profile).then(() =>{
                    resolve(r);
                }).catch((ex)=>{
                    reject(ex);
                });

             }).catch((e)=> {
                reject(e);
             });
        }
        );
    }

    getProfile() {
        return new Promise<UserProfileResponse>((resolve,reject)=>
        {
            return this.httpClient("MOB").get("/UserProfile").then(async res => {
                return  res.data;
             }).then(async r => {
               resolve(r);
             }).catch((e)=> {
                reject(e);
             });
        }
        );
    }
}

export default new UserProfile();