import Vue from 'vue'
import Component from 'vue-class-component'
import {  PropSync } from 'vue-property-decorator';



@Component({
    name:"connectionlostcardview"
    
})
export default class ConnectionLostCardView extends Vue {
    @PropSync("isVisible", { type: Boolean }) show: boolean;
   
    
    constructor() {
        super();
    }


    Retry()
    {
        this.show = false;
        this.$emit("close");
    }
   
    
}