
<v-card v-show="showAlert" class="mx-auto aep-bluebackground mb-0 elevation-0" tile style="height:120px; width:100%">
  <v-container style="padding-right: 0px;">
     <v-alert  class="mb-0 mt-n5 aep-nobackground" tile style="height:120px; width: 100%" >
   <template v-slot:prepend>
       <div>
         <v-icon class="group9799 mt-n7 ml-n4" ></v-icon>
       </div>
     </template>

     <div class="d-flex justify-space-between justify-center pl-2 " style="height:20px">
       
       <div class="text-h1 aep-blue" >{{$t("earlyPay.earlyPayTitle")}}</div>
       <v-icon v-if="showClose" class="aep-blue" @click="close()">mdi-close</v-icon>
     </div>
           
     <div class="d-flex pt-1 pr-5 pl-2" style="height:34px">
       <div class="text-subtitle-2 grey--text text--darken-3">{{$t("earlyPay.earlyPayMessage")}}</div>
     </div>  
     <div class="pt-4 pl-2">
       <v-btn class="aep-button"
         rounded
         color="#337EB7"
         dark
         elevation=0
         @click="earlyPayLink()"
       >
       {{$t("earlyPay.earlyPayButtonText")}}
     </v-btn>
     </div>
   
 </v-alert>
  </v-container>  
 </v-card>

 
