<template>

    <div class="period-background">
      <div class="pt-2 pb-2 pl-4 pr-1" >
      <div class="d-flex flex-row-reverse pt-1 pr-1">
        <div style="width: 150px;height: 30px;">
            <v-select dense flat small class="grey-select" 
            :items="periodTypes"
            label="Solo field"
            solo
            v-model="selectedTimesheetPeriod"
            @change="periodChanged"
            ></v-select>
        </div>
       
      </div>

        <div class="pt-3" style="min-height:36px">
          <span class="date-title">{{dateTitle}}</span>
            
        </div>

      <div class="d-flex justify-sm-space-between pr-4">
        <div>
          <span class="date-text"> {{startDateText}} {{endDateText}}</span>
        </div>

        <div class="ml-auto">
            <div class="d-flex justify-sm-space-between" style="width:75px">
              <div>
              <v-btn class="action-last-period period-button" text outlined fab x-small elevation="0" @click="previous">
                <v-icon>mdi-chevron-left</v-icon>
              </v-btn>
            </div>
              <div class="ml-auto">
                <v-btn class="action-next-period period-button" text outlined fab x-small elevation="0" @click="next">
                  <v-icon>mdi-chevron-right</v-icon>
                </v-btn>
              </div>
            </div>
        </div>
    </div>
  </div>
    </div>

    
    
</template>
  
  
<script lang="ts">
  import ViewModel from "./TimesheetDateRangePicker"
  export default ViewModel;
</script>

<style scoped>
.period-background{
    background-color: #E8EAF1;
    height: 129px;
}

.date-text {
  font-size:14px;
  font-weight: bold;
}

.period-button{
  background-color: white;
  height:28px;
  width:28px;
}

.date-title{
  font-size:14px;
}



  </style>
    