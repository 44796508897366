<template>
  <div class="d-flex justify-space-between">
    <div class="welcome-message line-34" v-html="$t('messages.welcomeMessage', { userName: userName })"></div>
    <div>
      <v-btn fab elevation="0" disabled outlined color="white" class="logo-btn">
        <v-icon class="header-logo"></v-icon>
      </v-btn>
    </div>
  </div>
</template>

<script lang="ts">
import ViewModel from "./UserToolbar";
export default ViewModel;
</script>

<style scoped>
@media screen and (max-width: 320px) {
  .welcome-message {
    font-size: 13px;
  }
}

.line-34 {
  line-height: 34px;
}

.logo-btn {
  height: 34px;
  width: 34px;
  border-color: #f1f2f5;
}

.header-logo {
  content: url("../assets/logo.png");
}
</style>
