import JobService,{  Job } from "../../jobs/JobService";
import { Vue, Component, PropSync,Watch } from 'vue-property-decorator';
import JobSummaryCard from "../../jobs/components/JobSummaryCard.vue";

@Component({
  components:{JobSummaryCard}
})
export default class JobOfferSummaryCard extends Vue {
  @PropSync("job", { type: Object }) jobLocal!: Job;
  @PropSync("showHeart", { type: Boolean,default:true }) showHeartLocal!: boolean;
  logoUrl = "";
  showShortlistIcon = false;
  @Watch('job')
  jobChanged(val: Job, oldVal: Job) {
    this.logoUrl = "";
    if(val.HasLogo) {
        this.setImage();
    }

    this.setShortListIcon();
  }

  constructor() {
         super();
    }

    setImage() {
      JobService.getCompanyLogo(this.jobLocal.JobId,40,40,false).then(e=> {
          this.logoUrl = e;
      });
    }

    setShortListIcon(){
      if (!this.jobLocal.IsApplied && this.showHeartLocal) {
        this.showShortlistIcon = true;
      }
    }

    mounted(){
      if(this.jobLocal.HasLogo) {
        this.setImage();
      }
      this.setShortListIcon();
    }

    setShortlisted(job: Job) {
      job.IsShortlisted = !job.IsShortlisted;

      if(job.IsShortlisted) {
        this.$emit("setShortlisted",job);
      } else {
        this.$emit("removeShortlisted",job);
      }
    }

    rejectButtonClicked(e){
      e.preventDefault();
      e.stopPropagation();
      this.$emit("rejectJob",this.jobLocal);
    }

    applyButtonClicked(e){
      e.preventDefault();
      e.stopPropagation();
      this.$emit("acceptJob",this.jobLocal);
    }
}