import { Vue, Component, PropSync, Watch } from 'vue-property-decorator';
import {JobShift} from '../TimesheetService';
import LoadingIndicator from "../../../../LoadingIndicator.vue";
import JobShiftSummaryCard from "./JobShiftSummaryCard.vue";
import moment from 'moment';
import { ClockStateResult } from '@/services/checkInDomain';
import global from '@/utils/global';
import { NetworkMixin } from '@/components/mixins/networkMixin';

@Component({
    name:"checkIn",
    components:{ LoadingIndicator, JobShiftSummaryCard}
})

export default class CheckInView extends NetworkMixin {
    isScrolling = false;
    @PropSync("isLoading", { type: Boolean,default:false }) isLoadingLocal: boolean;
    @PropSync("jobShifts", { type: Array }) shifts!: JobShift[];
    @PropSync("clockState", { type: Object }) clockStateResult!: ClockStateResult;
    @PropSync("startDate", { type: Date,default:new Date() }) start!: Date;
    @PropSync("endDate", { type: Date }) end!: Date;
    @PropSync("selectedDate", { type: Date }) selectedDateLocal!: Date;

    @Watch("selectedDate")
    selectedDateChanged(val,old){
        this.scrollIntoView(this.jobshiftDayTarget);
    }   

    formatDateFromNow(date){
        return global.formatDateFromNow(date);
    }

    formatDateToTime(date){
        return global.formatDateToTime(date);
    }

    get currentDate(){
        return Date();
    }

    get shiftGroups(){
        const result = [];
        
        const today = this.todaysEvents;
        const future = this.futureEvents;
        const past = this.pastEvents;
        
        if(today && today.length  > 0){
            result.push({text:"Today",shifts:today,time:`${moment(today[0].ShiftStartTime).format("HH:mm")} - ${moment(today[0].ShiftEndTime).format("HH:mm")} `});
        }
        if(future && future.length > 0){  

            result.push({text:moment(future[0].ShiftStartTime).fromNow() ,shifts:future,time:`${moment(future[0].ShiftStartTime).format("HH:mm")} - ${moment(future[0].ShiftEndTime).format("HH:mm")} `});
        }

        if(past && past.length > 0){
            result.push({text: moment(past[0].ShiftStartTime).fromNow(),shifts:past,time:`${moment(past[0].ShiftStartTime).format("HH:mm")} - ${moment(past[0].ShiftEndTime).format("HH:mm")} `});
        }

        return result;
    }

    get jobshiftDayTarget() {
        return '#d' + this.selectedDateLocal.toLocaleDateString().split("/").join("");
    }

    get topListStartTarget() {
        return '#checkInView';
    }

    scrollIntoView(targetSelector,duration = 300) {
        this.isScrolling = true;
        this.$nextTick(() => {
            const options = {
                duration: duration,
                offset: 0
            };

            const findEle = document.querySelector(targetSelector);
            if (findEle) {
                setTimeout(()=>{
                    this.$vuetify.goTo(targetSelector, options).then(()=>{
                    this.isScrolling = false;
                    });
                },100);
            }
        });
    }

    get todaysEvents(){
        return this.shifts.filter(s => moment(s.ShiftDate).isSame(moment(this.currentDate),'day'));
    }

    get futureEvents(){
        return this.shifts.filter(s => moment(s.ShiftDate).isAfter(moment(this.currentDate),'day'));
    }

    get pastEvents(){
        return this.shifts.filter(s => moment(s.ShiftDate).isBefore(moment(this.currentDate),'day'));
    }

    get startOfWeek(){
        return moment(this.start).utc();
    }

    get endOfWeek(){
        return moment(this.end).utc();
    }

    get calendarStart(){
        return this.startOfWeek.format("YYYY-MM-DD");
    }

    get calendarEnd(){
        return this.endOfWeek.format("YYYY-MM-DD");
    }

    manualTimesheet = false;

    constructor() {
        super();
    }

    checkIn(jobShift: JobShift){
        this.$emit("checkIn", jobShift);
    }

    batchTimesheet(jobShift: JobShift)
    {
        this.$emit("batchTimesheet", jobShift);
    }

    selecteDateChanged(dateRange){
        this.$emit("refreshJobShifts", dateRange);
    }

    onIntersect (entries, observer, isIntersecting) {
        if(isIntersecting && !this.isScrolling){
            this.$emit("scrolledToDate", entries[0].target.dataset.date);
        }
    }

    updated()
    {
        this.scrollIntoView(this.jobshiftDayTarget);
    }

    options ={
       // root:this.$refs.checkInView,
      
        handler: this.onIntersect,
        options:{
            root:this.$refs.checkInView,
            rootMargin:"-250px 0px 0px 0px",
            threshold:0
        }
      };
    
}
