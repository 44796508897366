import HttpClient from './httpClient'
import FingerPrint2 from "@fingerprintjs/fingerprintjs"
export class PushNotificationPostRequest {
    subscription: Subscription;
    browserId: string;
}

export interface Subscription {
    subscription: SubscriptionData;
}
export class SubscriptionData {
    endpoint: string;
    expirationTime: object;
    keys: Keys;
}

export class Keys {
    p256dh: string;
    auth: string;
}


export default class NotificationService {
    baseUrl: string;
    httpClient: HttpClient;

    getHttpClient(){
        if(!this.httpClient){
            this.httpClient = new HttpClient("MOB");
        }
        return this.httpClient;
    }

    getVapidKey(): Promise<string>
    {
       
        return this.getHttpClient().get("/vapid").then((result) =>{
            return result.data;
        })
    }

    async postNotificationSubscription(data: Subscription): Promise<void> {

        const subscriptionPostRequest = new PushNotificationPostRequest();
        subscriptionPostRequest.subscription = data;


        const options = {
            excludes: {
                extendedJsFonts: true,
                excludeAdBlock: true,
                userAgent: true,
                pixelRatio: true,
                fontsFlash: true,
                enumerateDevices: true,
                doNotTrack: true,
                hardwareConcurrency: true,
                touchSupport: true,
                timezone: true,
                timezoneOffset: true,
                plugins: true,
                canvas: true,
                webgl: true,
                audio: true,
                fonts: true,
                screenResolution: true,
                availableScreenResolution: true

            }
        }


        //need to make sure if page is loading , then wait.  
        

            FingerPrint2.get(options, (components) => {
                const values = components.map((component) => { return component.value })
                const murmur = FingerPrint2.x64hash128(values.join(''), 31)
                subscriptionPostRequest.browserId = murmur;
                return this.getHttpClient().post("/pushnotification", subscriptionPostRequest).then(async res => {
                    return res.data;
                }).then(async r => {
                    return r;
                });
            })
        
        

    }
}
