export class HttpHelpers{

    objectToQueryString(path,queryStringObject){

        if(queryStringObject){
            const queryVars = [];
            Object.keys(queryStringObject).forEach(i=>{
                if(queryStringObject[i] === null){
                    queryVars.push(`${i}=`);
                }
                else if(Object.prototype.toString.call(queryStringObject[i]) === "[object Date]"){
                    queryVars.push(`${i}=${ (new Date(queryStringObject[i])).toISOString().replace("Z","")}`);
                }
                else{
                    queryVars.push(`${i}=${queryStringObject[i]}`);
                }
            })

            if(path.indexOf("?") > 0){
                path = `${path}&${queryVars.join("&")}`
            }else{
                path = `${path}?${queryVars.join("&")}`
            }
        }

        return path;
    }

    updateDates(data){
        if(data){
            Object.keys(data).forEach(i=>{
                if(Object.prototype.toString.call(data[i]) === "[object Object]"){
                    this.updateDates(data[i]);
                }
                else if(Object.prototype.toString.call(data[i]) === "[object Date]"){
                   // data[i]= `${new Date(data[i]).toISOString().replace("Z","")}`;
                   data[i]= `${this.ds(data[i])}`;
                }
            });
        }

        return data;
    }

    private pad = function(number) {
        if (number < 10) {
            return '0' + number;
        }
        return number;
    }
    
    private ds = function (date) {
            return date.getFullYear() +
                '-' + this.pad(date.getMonth() + 1) +
                '-' + this.pad(date.getDate()) +
                'T' + this.pad(date.getHours()) +
                ':' + this.pad(date.getMinutes()) +
                ':' + this.pad(date.getSeconds()) +
                '.' + (date.getMilliseconds() / 1000).toFixed(3).slice(2, 5) ;
        };
}

export default new HttpHelpers();