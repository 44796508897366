<template style="height: 100%;">
    <div>
        <v-app-bar dense app color="white" class="top-toolbar pt-0">
            <v-tabs class="job-tabs" >
                <v-tab class="action-tab-listview no-transition" :disabled="isLoading" data-id="list"   ref="listtogglebtn"  @click="switchView('list')">List</v-tab>
                <v-tab v-show="isMapsEnabled" class="action-tab-mapview no-transition" :disabled="isLoading" data-id="map"   ref="maptogglebtn" @click="switchView('map')">Map</v-tab>
                <v-tab class="action-tab-swipeview no-transition" :disabled="isLoading" data-id="swipe" ref="swipetogglebtn" @click="switchView('swipe')">Swipe</v-tab>
            </v-tabs>

            <v-btn class="action-search-filters" icon small @click="filterDialogVisible=true" >
                <img class="icon-filter" src="@/assets/img/icons/filer.png" height="22px" width="23px">
            </v-btn>
            <v-spacer></v-spacer> 
        </v-app-bar>

        <swipe-view class="jobs-container-swipe-view" v-if="!showNoresults && selectedView == 'swipe' && !showNetworkError" 
        @showJob="showJob"> </swipe-view> 


    <v-container class="jobs-container">
        <v-dialog v-model="showJobDetails" max-width="500px" fullscreen>
            <JobDetails :isOpen="showJobDetails" :selectedJob="selectedJob" @close="showJobDetails = false" :closeTopDialog="closeTopDialog"></JobDetails>
        </v-dialog>

        <FilterDialog  @close="filterDialogVisible=false" v-bind:filters="resultFilters"
            v-bind:visible="filterDialogVisible" @closedialog="closeFilterDialog()" @applyfilter="applyFilter()"
            v-bind:jobsearchrequest="jobSearchRequest"></FilterDialog>

        <div>

            <map-view v-if="selectedView == 'map'" 
                @showJob="showJob"
                ></map-view>
                
            <job-list-view v-if="selectedView == 'list' && !showNetworkError"  @showJob="showJob"> </job-list-view>

            <ConnectionLostCardView :isVisible="showNetworkError" @close="retryClicked"></ConnectionLostCardView>
            </div>
           
    </v-container>
</div>
</template>


<script lang="ts">
    import ViewMode from "./Jobs"
    export default ViewMode;
</script>

<style lang="scss" scoped>
    /* .jobs-container {
        height: calc(100vh - 136px);
        overflow: hidden;
    } */

.jobs-container-swipe-view{
    height: calc(100vh - 180px);
        overflow: hidden;
        padding-left: 10px;
        padding-right: 10px;
}
    .no-results-container {
        width: 100%;
        height: 85%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .no-results-description {
        text-align: center;
        font-size: 4.6vw;
    }

    .tryAgainButton {
        max-height: 45px;
    }
    
    .icon-search {
        content: url("../../../../assets/img/icons/search 1.png");
        width: 24px;
        height: 24px;
    }

    .icon-filter {
        /* margin-bottom: 13px; */
    }

    .icon-shortlist {
        content: url("../../../../assets/img/icons/filer.png");
    }

    .searchbox{
        background-color: #F7F7F7;
    }

    .jobresult-summary{
        width: 100%;
    }

    .job-tabs{
        padding-left:16px;
    }

    .h1-large {
        font-size: 6.4vw;
        text-align: center
    }
</style>