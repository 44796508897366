<template>
  <v-container class="background-cloud">
    <UserToolbar class="pb-8"></UserToolbar>
    <v-row justify="center" >
      <v-col cols="8" md="1" class="pt-7 pb-0" style="height:185px;position: relative;" >
        <img class="header-searchJobs" src="@/assets/img/jobs/search/main.png" height="185px" width="224px">
      </v-col>
    </v-row>

    <v-row justify="center" style="box-shadow: 0px 0px 15px 4px #d2d2d2d9; margin: 0; border-radius: 6px;">
      <v-col cols="" md="6" class="search-row search-row-top">
        <div class="h1-large pl-4" >
          {{ $t("jobs.searchBoxTitle") }}
        </div>
        <v-text-field  class="input-search-keywords search-textfield" dense solo v-model="jobSearchRequest.Keywords" v-on:keyup.13="search" :label="$t('jobs.searchBoxText')"
            hide-details prepend-inner-icon="mdi-map-marker">
            <template v-slot:prepend-inner>
              <div class="keywords-icon">
              <img class="header-searchJobs" src="@/assets/img/icons/search 1.png" height="30px"/>
            </div>
            </template>
        </v-text-field>
      </v-col>
      <v-col cols="" md="6" class="search-row search-row-bottom">
        <div class="h1-large pl-4">
          {{ $t("jobs.locationTitle") }}
        </div>
        <LocationAutoCompleteGoogle v-if="isMapsEnabled && mapService == 'GOOGLE'"  :textvalue="jobSearchRequest.LocationText" @placechanged="onPlaceChanged" @inputChange="onLocationChanged" @search="search" :label="$t('jobs.locationText')" /> 
        <LocationAutoComplete v-if="!isMapsEnabled || mapService == 'HEREMAPS'" :textvalue="jobSearchRequest.LocationText" @placechanged="onPlaceChanged" @inputChange="onLocationChanged" :label="$t('jobs.locationText')" @search="search"></LocationAutoComplete>
      </v-col>
      <v-col  cols="" md="6" class="search-row search-row-bottom search-distance pt-2 pb-1" style="height:61px">
        <div class="fs-14 pl-4 font-weight-bold">
          {{$t("jobs.withinTitle")}} {{jobSearchRadiusMiles}} {{$t("jobs.miles")}}
        </div>
        <v-slider class="input-search-radius pl-2 ml-0 pr-2"
          v-model="jobSearchRadiusMiles"
          step="1"
          :min="minDistance"
          :max="maxDistance"
        />
      </v-col>
    </v-row>

    <v-row justify="center" ></v-row>

    <v-row justify="center" class="pt-3">
      <v-col cols="12" md="6">
        <v-btn depressed block class="primary action-find-jobs" @click="search">{{ $t("jobs.searchButtonTitle") }}</v-btn>
      </v-col>
    </v-row>

    <v-row justify="center" class="pt-5" v-if="recentSearches.length > 0">
      <v-col cols="12" md="6">
        <div class="text-h1 pb-2">
            Recent Searches
        </div>
        <v-list dense class="pa-0 recents-list">
          <template  v-for="item in recentSearches" >              
            <v-list-item  class="card-border recent-search-item pt-3 pb-3 pl-0"  :key="item.createdOn"  @click="recentSearchSelected(item)">
              <v-row no-gutters align="center">
                <v-col cols="1" sm="1" md="1" >
                  <div class="recent-image">
                    <img alt="" src="@/assets/img/icons/jobs filled.png" height="auto" width="30px">
                  </div>
                </v-col>
                <v-col cols="10" sm="10" md="10" class="details-container pl-3">
                  <div class="d-flex justify-space-between">
                    <div class="text-h1" :data-keywords="item.SearchRequest.Keywords">{{item.SearchRequest.Keywords}}
                    </div>
                  </div>
                  <div class="text-subtitle-2 grey--text text--darken-2 pt-1" :data-location="item.SearchRequest.LocationText"> {{item.SearchRequest.LocationText}}</div>
                </v-col>
              </v-row>
            </v-list-item>
          </template>
        </v-list> 
      </v-col>
    </v-row>
  </v-container>
</template>

<script lang="ts">
  import ViewModel from "./SearchJobsView"
  export default ViewModel;
</script>

<style lang="scss" scoped>

  .logo-image{
      height:38px;
      width:38px;
      display: table-cell;
      vertical-align: middle;
      text-align: center;
    }

  .header-searchJobs {
    vertical-align: bottom;
    position: absolute;
    bottom: 0;
  }

  .background-cloud {
    background-image: url("../../../../../assets/img/onboarding/Path 10739.png");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: left -10px top 10px;
  }

  .search-row {
    background-color: White;
    border-bottom: 1px solid #EFF0F3;
  }

  .search-row-top {
    border-radius: 4px 4px 0px 0px;
  }

   .search-row-bottom {
     border-radius: 0px 0px 4px 4px;
  }

  .search-distance {
    background-color: #EFF0F3;
  }

  .keywords-icon {
    padding-right:30px;
    padding-left:2px;
  }

  .location-icon{
    padding-right:27px;
    padding-left:5px;
    height: 30px;
    width: 30px;
  }

  .location-icon > img {
    margin-bottom: 7px;
  }

  .keywords-icon > img {
    margin-bottom: 3px;
  }

  .recents-list{
    background-color: transparent;
  }

  .recent-search-item{
    border: none;
    border-bottom: 1px solid #0000000F;
    background-color: transparent;

  }

  .recent-image {
    border-radius: 50%;
    background-color: #555B701C;
    height: 30px;
    width: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: 2px;
  }

  .recent-image > img {
      height:12px;
      width:14px;
  }

  .distanceTemplate {
    color: red;
  }
</style>

<style lang="scss">
 
  .pac-item{
    padding: 10px !important;
    height: auto !important;
    font-family: Muli !important;
    font-size:14px!important;
  }

  .pac-item-query {
    font-size:14px!important;

  }
   /* .hdpi .pac-icon {
     background-image: url("../../../../../assets/img/icons/location.png")!important;
   } */
</style>
