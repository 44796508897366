import TimesheetService,{ Timesheet } from "../TimesheetService";
import { Vue, Component, PropSync, Watch } from 'vue-property-decorator';


@Component({
  })
export default class TimesheetSummaryCard extends Vue {
  @PropSync("timesheet", { type: Object }) TimesheetLocal!: Timesheet;
  logoUrl = "";
  @Watch('Timesheet') 
  TimesheetChanged(val: Timesheet, oldVal: Timesheet) {
    this.logoUrl = "";
    if(val.HasLogo){
        this.setImage();
    }
  }

  get statusClass(){

    if(this.TimesheetLocal){
    switch(this.TimesheetLocal.StatusSystemCode){
        case "APPROVED":
            return "approved-icon";
        case "AWAITING_APPROVAL":
            return "pending-icon";
        case "REJECTED":
          return "cancel-icon";
      default:
        return "query-icon";
      }
    }
    return "query-icon";
  }

  constructor() {
         super();
    }

    setImage() {
      TimesheetService.getCompanyLogo(this.TimesheetLocal.PlacementId,40,40,false).then(e=>{
          this.logoUrl = e;
      });
    }

    mounted(){
      if(this.TimesheetLocal.HasLogo){
        this.setImage();
      }
    }
}