
 
    <div >

      <v-card
      
      class="mx-auto"
    >
    <v-btn @click="refreshAccessToken">Refresh Access Token</v-btn>
      <v-list two-line subheader>
        <v-subheader>General
        </v-subheader>
  
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title>Version</v-list-item-title>
            <v-list-item-subtitle>{{version}}<br>
              </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
  
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title>BuildTime</v-list-item-title>
            <v-list-item-subtitle>{{buildTime}}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>

        
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title>Internet Connected</v-list-item-title>
            <v-list-item-subtitle>{{isOnline}}</v-list-item-subtitle>
            
          </v-list-item-content>
        </v-list-item>

        <v-list-item>
          <v-list-item-content>
            <v-list-item-title>Language</v-list-item-title>
            <div class="locale-changer">

              <v-select
                v-model="$i18n.locale"
                :items="langs"
              ></v-select>

            </div>
          </v-list-item-content>
        </v-list-item>
      
        <v-divider></v-divider>
        <v-subheader>
          
          User</v-subheader>

          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>Username</v-list-item-title>
              <v-list-item-subtitle>{{userProfile}}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>


          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>accessToken</v-list-item-title>
              
              <span id="accessToken">{{userAuth}}</span> 
            </v-list-item-content>
          </v-list-item>

        <v-list-item>
          <v-list-item-content>
            <v-list-item-title>IsAuthenticated</v-list-item-title>
            <v-list-item-subtitle>{{isAuthenticated}}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>

        
        <v-divider></v-divider>
        <v-subheader>
          
          API</v-subheader>

          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>Discovery response</v-list-item-title>
              
                  {{discoveryDocument}}
            </v-list-item-content>
          </v-list-item>

          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>RCRM Endpoint</v-list-item-title>
              
              {{rcrmEndpoint}}
            </v-list-item-content>
          </v-list-item>

          
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>RCRM Softwarekey</v-list-item-title>
              
              {{rcrmEndpointSoftwareKey}}
            </v-list-item-content>
          </v-list-item>

          <v-divider></v-divider>
          <v-subheader>
            
            Device Info</v-subheader>
  
          <v-list-item>
          <v-list-item-content>
            <v-list-item-title>IndexDB Support</v-list-item-title>
            {{getFeatures.IndexDB}}
          </v-list-item-content>
      </v-list-item>


      <v-list-item v-for="item in getFeatures.NavigatorInfo" v-bind:key="item.name">
        <v-list-item-content>
          <v-list-item-title>{{item.name}}</v-list-item-title>
            {{item.value}}
       </v-list-item-content>
  </v-list-item> 

       <v-list-item>
            <v-list-item-content>
              <v-list-item-title>Browser Detect</v-list-item-title>
                   {{Browser}}

            </v-list-item-content>
          </v-list-item>

        
    

          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>Request Notification Permission</v-list-item-title>
                  <v-btn @click="requestNotificationPermission">Request</v-btn>

            </v-list-item-content>
          </v-list-item>

          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>Show Notification</v-list-item-title>
                  <v-btn @click="showNotification">Show Notification</v-btn>

            </v-list-item-content>
          </v-list-item>

  <v-list-item>
          <v-list-item-content>
            <v-list-item-title >Update</v-list-item-title>
            
            <v-btn class="primary" @click="showUpdateBanner">Show Update Banner</v-btn>
          </v-list-item-content>
        </v-list-item>

        <Alert 
          :isVisible="message"
          :alertType="alertType"
          @close="closeAlert"
        >
          <template v-slot:message>{{messageText}}</template>
        </Alert>

      <v-list-item>
          <v-list-item-content>
            <v-list-item-title >Give Push Message Permission</v-list-item-title>
            
            <v-switch :disabled="pushDisabled" flat :input-value="pushNotification" @change="setNotificationPermission"></v-switch>
          </v-list-item-content>
        </v-list-item>
        
    
     
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title >Error Logs</v-list-item-title>
            
          <v-btn @click="showErrorsClicked">View</v-btn>
          </v-list-item-content>
        </v-list-item>
   
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title >Clear Cache</v-list-item-title>
            
          <v-btn @click="clearCache">Clear Cache</v-btn>
          </v-list-item-content>
        </v-list-item>
    </v-list>
    <!-- <v-btn @click="showAlert">Show Alert</v-btn> -->
    
    <v-btn @click="showConfirm">showConfirm</v-btn>
   <v-btn @click="showSuccessAlert">showSuccessAlert</v-btn>
   <v-btn @click="shoWarningAlert">shoWarningAlert</v-btn>
   <v-btn @click="showInfoAlert">showInfoAlert</v-btn>
   <v-btn @click="shoUpdateAlert">shoUpdateAlert</v-btn>
   <v-btn @click="showToast">showToast Error</v-btn>
   <v-btn @click="showToastSuccess">showToast Success</v-btn>
   

<v-row>
   <v-btn @click="loadDirectionsWithGoogle">Load Map With Google</v-btn>
   <v-btn @click="loadDirectionsWithAppleMapProtocol">Load Map With apple maps protocol</v-btn>
   <v-btn @click="loadDirectionsWithAppleHttpProtocol">Load Map With apple maps http</v-btn>
</v-row>

<v-row>
     <v-col cols="6">
       Start
        <vue-timepicker id="checkIn1"  :minute-interval="1"></vue-timepicker>
      
     </v-col>  
     <v-col cols="6">
       End
        <vue-timepicker id="checkIn2"  :minute-interval="1"></vue-timepicker>
       
     </v-col>  
   </v-row>  

   
    

      

    <img alt="Vue logo" src="@/assets/logo.png">
     
  
     
    </v-card>
    
    <v-dialog v-model="showErrorLogs" max-width="500px" fullscreen @closed="showErrorLogs = false">


      <v-expansion-panels focusable>
        <v-expansion-panel
        v-for="(item,index) in errorLogs" :key="index"
        >
          <v-expansion-panel-header>     <div class="errorTitle">
            
            {{item.message}}
        </div></v-expansion-panel-header>
          <v-expansion-panel-content>
            {{item.data}}
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
        <v-btn @click="syncErrors">Sync</v-btn>
        <v-btn @click="showErrorLogs = false">Close</v-btn>
    </v-dialog>

    </div>
 
  
  