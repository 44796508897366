import Component from 'vue-class-component';
import Vue from 'vue';
import SwipeView from "./components/SwipeView.vue";
import JobListView from "./components/JobListView.vue";
import MapView from "./components/MapView.vue";
import SearchJobsView from "./components/SearchJobsView.vue";
import FilterDialog from "./components/FilterDialog.vue";
import JobDetails from "./JobDetails.vue";
import JobService, { JobSearchResult, JobSearchRequest,Job } from "../../../rdb/components/jobs/JobService";
import store from "../../../../store/index";
import Router from '@/router/router';
import {mobileBackButtonDialogComponentMixin} from '../../../mixins/mobileDialogBackButtonHandler';

import { NetworkMixin} from '@/components/mixins/networkMixin'
import { Mixins } from 'vue-property-decorator';
import { EventBus } from '@/utils/events';

Component.registerHooks([
    'beforeRouteEnter',
    'beforeRouteUpdate',
    'beforeRouteLeave'
  ]);
@Component({
    name:"jobs",
    components:{SwipeView,JobListView,SearchJobsView,FilterDialog,MapView,JobDetails},
    mixins:[mobileBackButtonDialogComponentMixin],
    watch: {
        showJobDetails: 'notifyDialogStateViaEventBus',
        filterDialogVisible: 'notifyDialogStateViaEventBus'
    }
})
export default class JobsComponent extends Mixins(NetworkMixin) {
    filterDialogVisible = false;
    items = [];
    selectedView = "list";
    showNoresults = false;
    jobSearchResult: JobSearchResult = new JobSearchResult();
    el: '#app';
    filterApplied = false;
    lastSelectedView = "list";
    selectedJob: Job = null;
    showJobDetails = false;
    closeTopDialog = false;
    swipeCount = 0;
    resultFilters = [];

    constructor() {
        super();
        this.items = [];
    }
    
    closeAllDialogs(){
            this.showJobDetails = false;
            this.filterDialogVisible = false;
    }

    get jobSearchRequest(){
         return store.getters.AppState.jobSearchRequest;
    }

   applyFilter () {
        this.closeFilterDialog();
        this.filterApplied = true;
        //this.loadSearchResult();
   }

   closeFilterDialog() {
        this.filterDialogVisible = false;
   }

   clearFilters() {
        const keywords = this.jobSearchRequest.Keywords;
        const jobSearchRequest = new JobSearchRequest;
        jobSearchRequest.Keywords = this.jobSearchRequest.Keywords;
        jobSearchRequest.LocationText = this.jobSearchRequest.LocationText;
        jobSearchRequest.Latitude = this.jobSearchRequest.Latitude;
        jobSearchRequest.Longitude = this.jobSearchRequest.Longitude;
        jobSearchRequest.Radius = this.jobSearchRequest.Radius;
        store.commit.AppState.UpdateJobSearchRequest(jobSearchRequest);
   }

   mounted(){
    EventBus.$on("ShowJobSearchFilters",this.onShowFiters);
    EventBus.$on("SetSearchFilters",this.setSearchFilters);   
   }

   setSearchFilters(e){
     this.resultFilters = e;
   }

   beforeDestroy(){
     EventBus.$off("ShowJobSearchFilters",this.onShowFiters); 
     EventBus.$off("SetSearchFilters",this.setSearchFilters);
   }

   onShowFiters(){
       this.filterDialogVisible = true;
   }

   showJob(job: Job){
        this.selectedJob = null;
        this.showJobDetails = true;
        this.selectedJob = job;
   }

   get isMapsEnabled(){
       return store.getters.AppState.featureEnabled("RCRMGOOGLEMAPS");
    }

    beforeRouteUpdate(to, from, next) {
        if (this.showJobDetails) {
            this.closeTopDialog = !this.closeTopDialog;
            next(false);
        } else if (this.showJobDetails || this.filterDialogVisible) {
            this.$emit('closeAllDialogs');
            next(false);
        } else {
            next();
        }
    }

    beforeRouteLeave(to, from, next) {
        if (this.showJobDetails) {
            this.closeTopDialog = !this.closeTopDialog;
            next(false);
        } else if (this.showJobDetails || this.filterDialogVisible) {
            this.$emit('closeAllDialogs');
            next(false);
        } else {
            next();
        }
    }

    switchView(view: string) {
        this.selectedView = view;
    }
}