<template>
  <div>

    <div  class="padding-test" v-if="showNoresults && !isLoading"> 
      <div class="header-noTimesheets ma-auto">
  
      </div>
      <div class="text-align-center no-results-text">
        <span class="no-results-title "  v-if="selectedTimesheetPeriod === 'Weekly'">
          {{ $t("timesheets.expenseNoSearchResultsTitleWeek") }}
        </span>
        <span class="no-results-title"  v-if="selectedTimesheetPeriod === 'Monthly'">
          {{ $t("timesheets.expenseNoSearchResultsTitleMonth") }}
        </span>
      </div>
      <div class="pt-2 ma-auto no-results-text">
        {{ $t("timesheets.noResultsDescription") }} <b>{{ $t("timesheets.noResultsNextPrevious") }} </b>  {{ $t("timesheets.noResultsbuttons") }}
      </div>
  </div>


  <ConnectionLostCardView :isVisible="showNetworkError" @close="retryClicked"></ConnectionLostCardView>
  <LoadingIndicator class="mt-n10 pt-5" :isVisible="isLoading"></LoadingIndicator>

  <div  class="mt-n10 pt-5" v-if="this.timesheetExpenseGroups !== undefined && !showNoresults && !isLoading && !showNetworkError">

    <div v-for="(group,index) in this.timesheetExpenseGroups" :key="index" >  
      <div class="text-h1 centered pb-2" :key="group.PeriodStarting" v-if="selectedTimesheetPeriod === 'Weekly'" :data-WorkedOn="group.PeriodStarting">{{ formatDate(group[0].PeriodStarting , "Do MMM, YYYY") }}</div>
      <div class="text-h1 centered pb-2" :key="group.PeriodStarting" v-if="selectedTimesheetPeriod !== 'Weekly'" :data-WorkedOn="group.PeriodStarting">{{ formatDate(group[0].PeriodStarting,  'Do MMM, YYYY') }}</div>
  
  
        <div v-for="timesheetExpense in group" :key="timesheetExpense.TimesheetExpenseId">   
          <div :data-PeriodStarting="timesheetExpense.PeriodStarting" class="pb-2"></div>

              <div :data-TimesheetExpenseId="timesheetExpense.TimesheetExpenseId" class="rounded-corners card-border job-list-item mb-3"  :src="timesheetExpense.TimesheetExpenseId" @click="timesheetSelected(timesheetExpense)">
                  <div>
                    
                    <TimesheetExpenseSummaryCard :timesheet="timesheetExpenses.Timesheets.find(x => x.TimesheetId === timesheetExpense.TimesheetId)"
                      :timesheetExpense="timesheetExpenses.TimesheetExpenses.find(x => x.TimesheetExpenseId === timesheetExpense.TimesheetExpenseId)">
                    </TimesheetExpenseSummaryCard>
                    
                  </div>
              </div>
        </div>
        
    </div>
  </div>

   
     
      <v-dialog v-model="showTimesheetDetails"  fullscreen v-if="selectedTimesheetId > 0">
            <TimesheetDetails :isOpen="showTimesheetDetails" :showExpense="true" @submitTimesheet="submitTimesheet" :selectedTimesheetId="selectedTimesheetId" @close="closeDetails" @closeDialog="closeTimesheetDialog" submitTimesheetExpense="submitTimesheetExpense"></TimesheetDetails>
        </v-dialog>

        <v-dialog v-model="showTimesheetExpenseSubmitted" fullscreen>
           <v-row justify="center" class="background-cloud">
                <v-col cols="6" md="1" >
                    <img alt="Timesheet Submitted" height="200px" src="@/assets/img/timesheets/empty1.png" class="header-timesheetSubmitted">
                </v-col>
                </v-row>

                
                <v-row justify="center">
                    <v-icon>mdi-check</v-icon>
                
                </v-row>

                
                <v-row justify="center">
                <v-col cols="12" md="6">
                    <h1 style="text-align:center" class="h1-large">
                     {{ $t("timesheets.yourTimesheetSubmitted") }}
                    </h1>
                </v-col>
                </v-row>
<v-footer fixed>
      <v-container>
                <v-row justify="center">
                    <v-col cols="12" md="6">
                        <v-btn depressed block color="primary" @click="showTimesheetExpenseSubmitted = false"> {{ $t("timesheets.yourTimesheetSubmittedButton") }}</v-btn>
                    </v-col>
                    </v-row>
      </v-container>
      </v-footer>
        </v-dialog> 

  </div>
</template>


<script lang="ts">
  import ViewMode from "./ExpenseView"
  export default ViewMode;
</script>
  
  <style lang="scss" scoped>  
    .header-noTimesheets {
      content: url("../../../../../assets/img/timesheets/empty1.png");
      width:120px;
      height:120px ;
    }

  .no-results-title{
    font-size:22px;
    font-weight: bold;
    text-align: center;;
  }

  .no-results-text{
    font-size:16px;
    text-align: center;
  }

.time-description{
  font-weight: bold;
  font-size:12px;
  color:#6E6E6E;
}

.time-shift{
  font-size:12px;
  color:#6E6E6E;
}


  </style>