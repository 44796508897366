import { Vue, Component, PropSync, Watch, Mixins } from "vue-property-decorator";
import JobService, { JobOffersRequest, JobOffersResult ,JobOffer, AcceptJobOfferRequest, RejectJobOfferRequest } from "../../jobs/JobService";
import JobOfferSummaryCard from "./JobOfferSummaryCard.vue";
import moment from "moment";
import { NetworkMixin } from "@/components/mixins/networkMixin";
import { NotificationMixin } from '@/components/mixins/notificationMixin';
import JobOfferDetails from "./JobOfferDetails.vue";
import store from "../../../../../store/index";
import global from '@/utils/global';

@Component({
    name:"applied-view",
    components:{ JobOfferSummaryCard, JobOfferDetails }
})

export default class JobOfferView extends Mixins(NetworkMixin, NotificationMixin) {
    jobOffers: JobOffersResult = new JobOffersResult;
    showAcceptMessage = false;
    valid = true;
    AcceptMessage = "";
    selectedJobOffer: JobOffer = new JobOffer;
    showJobOfferDetails = false;
    closeTopDialog = false;
    logoUrl = "";

    constructor() {
        super();
    }

    mounted() {
      this.selectedJobOffer.OfferDate = new Date();
      this.getJobOffers();
    }

    loadJobOffers() {
      this.isLoading = true;
      this.jobOffers = new JobOffersResult;
      this.getJobOffers();
    }

    getJobOffers(page = 1) {
        const jobOffersRequest = new JobOffersRequest();
        jobOffersRequest.Page = page;
        this.doWork<any>(JobService.jobOffers(jobOffersRequest),() => { this.getJobOffers(); }).then((result) => {
            this.jobOffers.PageInfo = result.data.PageInfo;
            store.commit.AppState.UpdateJobOffers(result.data.PageInfo.TotalRecords);
            result.data.Jobs.forEach(i =>this.jobOffers.Jobs.push(i));
            if (this.jobOffers.PageInfo.TotalRecords === 0){
              this.toastSuccess(this.$t("jobs.noMoreOffers").toString());
              this.back();
            }
        });
    }

    setShortlisted(job: JobOffer) {
        job.IsShortlisted = !job.IsShortlisted;

        if(job.IsShortlisted){
          this.$emit("setShortlisted", job);
        } else {
          this.$emit("removeShortlisted", job);
        }
    }

    jobSelected(job: JobOffer){
        //navigate to details
        this.selectedJobOffer = job;
        this.showJobOfferDetails = true;
      }

    infiniteScrolling(entries, observer, isIntersecting) {
      if (this.jobOffers.PageInfo !== undefined) {
        if (this.jobOffers.PageInfo.HasNextPage) {
          if (isIntersecting  && !this.isLoading) {
            this.isLoading = true;
            this.getJobOffers(this.jobOffers.PageInfo.Page+1);
          }
        }
      }
    }

    back() {
      this.$emit("close");
  }

  showJobOfferAccept(job: JobOffer) {
    this.AcceptMessage = "";
    this.showAcceptMessage = true;
    this.selectedJobOffer = job;
    this.setImage();
  }

  rejectJobClicked(job: JobOffer) {
    this.selectedJobOffer = job;

    this.confirm(this.$t("jobs.rejectJobOffer").toString(),this.$t("confirm").toString()).then((result) => {
        if(result) {
          this.rejectJobOffer();
        }
    });
  }

  rejectJobOffer() {
    const rejectJobOfferRequest: RejectJobOfferRequest = new RejectJobOfferRequest;
    rejectJobOfferRequest.JobId = this.selectedJobOffer.JobId;
    rejectJobOfferRequest.ApplicantActionId = this.selectedJobOffer.ApplicantActionId;

    this.doWork<any>(JobService.rejectJobOffer(rejectJobOfferRequest),() => {
        this.rejectJobOffer();
    }).then((result) => {
      this.toastSuccess(this.$t("submittedSuccessFully").toString());
      this.showJobOfferDetails = false;
      this.loadJobOffers();
    });
  }

  setImage() {
    if (this.selectedJobOffer.HasLogo) {
      JobService.getCompanyLogo(this.selectedJobOffer.JobId,40,40,false).then(e=> {
          this.logoUrl = e;
      });
    }
  }

  acceptJobOfferClick() {
    const acceptJobOfferRequest: AcceptJobOfferRequest = new AcceptJobOfferRequest;
      acceptJobOfferRequest.JobId = this.selectedJobOffer.JobId;
      acceptJobOfferRequest.Message = this.AcceptMessage;
      acceptJobOfferRequest.ApplicantActionId = this.selectedJobOffer.ApplicantActionId;

      this.doWork<any>(JobService.acceptJobOffer(acceptJobOfferRequest),() => {
          this.acceptJobOfferClick();
      }).then((result) => {
        this.toastSuccess(this.$t("submittedSuccessFully").toString());
        this.showAcceptMessage = false;
        this.showJobOfferDetails = false;
        this.loadJobOffers();
      });
  }

  formatTimeFromNow(date){
    return global.formatTimeFromNow(date);
  }
}