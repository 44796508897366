import TimesheetService,{ TimesheetExpense, Timesheet } from "../TimesheetService";
import { Vue, Component, PropSync, Watch } from 'vue-property-decorator';


@Component({
  })
export default class TimesheetExpenseSummaryCard extends Vue {
  @PropSync("timesheetExpense", { type: Object }) TimesheetExpenseLocal!: TimesheetExpense;
  @PropSync("timesheet", { type: Object }) TimesheetLocal!: Timesheet;
  logoUrl = "";
  // @Watch('Timesheet')
  // TimesheetChanged(val: Timesheet, oldVal: Timesheet) {
  // }

  get statusClass() {
    if(this.TimesheetExpenseLocal) {
    switch(this.TimesheetExpenseLocal.ExpenseApprovalStatusSystemCode) {
        case "APPROVED":
            return "approved-icon";
        case "AWAITING_APPROVAL":
            return "pending-icon";
        case "REJECTED":
          return "cancel-icon";
      default:
        return "query-icon";
      }
    }
    return "query-icon";
  }

  constructor() {
         super();
    }
}