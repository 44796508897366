import { Vue, Component, PropSync,Watch, Mixins } from 'vue-property-decorator';
import JobService, { JobOffer } from "../../../../rdb/components/jobs/JobService";
import JobOfferSummaryCard from "./JobOfferSummaryCard.vue";
import store from '@/store';
import Map from '@/components/Map.vue';
import { NetworkMixin } from '@/components/mixins/networkMixin';
import {GoogleMarkerConfig} from '@/components/GoogleMap';

@Component({
    name:"JobOfferDetails",
    components:{JobOfferSummaryCard, Map}
})
export default class JobDetails extends Mixins(NetworkMixin) {
    @PropSync("selectedJob", { type: Object }) selectedJobLocal!: JobOffer;
    @PropSync("isOpen", { type: Boolean }) isOpenLocal!: boolean;
    @PropSync("closeTopDialog", { type: Boolean }) isCloseDialog!: boolean;
    selectedTabIndex = 0;
    selectedView = "job";
    markers: GoogleMarkerConfig[] =[];

     @Watch('isOpen')
    isOpenChanged(val: JobOffer, oldVal: JobOffer) {
        if(val) {
            this.selectedTabIndex = 0;
            this.selectedView = "job";
            this.markers.splice(0);
            this.markers.push({height:64,width:48,lat:this.selectedJobLocal.Latitude,lng:this.selectedJobLocal.Longitude,imageUrl:store.getters.AppState.url + "img/icons/google_marker.png",uniqueId:this.selectedJobLocal.JobId.toString(),imageUrlSelected:store.getters.AppState.url + "img/icons/google_marker.png"});
        }
    }

    @Watch('closeTopDialog')
    closeDialogChanged() {
      this.$emit('close');
    }

    mounted()
    {
        this.markers.splice(0);
        this.markers.push({height:64,width:48,lat:this.selectedJobLocal.Latitude,lng:this.selectedJobLocal.Longitude,imageUrl:store.getters.AppState.url + "img/icons/google_marker.png",uniqueId:this.selectedJobLocal.JobId.toString(),imageUrlSelected:store.getters.AppState.url + "img/icons/google_marker.png"});
    }

    setShortlisted(job: JobOffer) {
          this.$emit("setShortlisted", this.selectedJobLocal);
    }

    back() {
        if(this.$route.params.jobId !== undefined){
            this.$router.back();
        }
        else{
            this.$emit("close");
        }
    }

    showJobOfferAccept(job: JobOffer) {
        this.$emit("acceptJob", this.selectedJobLocal);
      }

    rejectJobClicked(job: JobOffer) {
        this.$emit("rejectJob", this.selectedJobLocal);
    }
}