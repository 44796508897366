import Vue from 'vue'
import Component from 'vue-class-component'
import store from '@/store'
import notifications from '@/utils/notifications'
import UserProfile ,{UserProfileResponse, UserProfileUpdateRequest} from '@/services/userProfile'
import PhoneNumberInput from '@/components/PhoneInput/PhoneNumberInput.vue'
import Alert from '@/components/Alert.vue'
import { NetworkMixin} from '@/components/mixins/networkMixin'
import { Mixins } from 'vue-property-decorator'

@Component({components:{PhoneNumberInput,Alert}})
export default class Profile extends Mixins(NetworkMixin) {

    forceEntry= false;
    valid = true;
    alertType = "Warning";
    showErrorDialog = false;
    defaultCountryCode = 44;
    returnCountryWithCountryCode = false;
    prependCountryCode = false;
    profile = new UserProfileResponse();
    profileOriginal = new UserProfileResponse();
    isVisible = true;
    titles: string[] = [];
    errors: any[];
    requiredRule = [
        value => !!value || 'Required.'
      ];

    pushNotification = false;
    pushDisabled = false;
    pushChoice;

    constructor() {
        super();
    }

    loadTitles(){
         this.titles.push(this.$t("titles.mr").toString());
         this.titles.push(this.$t("titles.mrs").toString());
         this.titles.push(this.$t("titles.dr").toString());
         this.titles.push(this.$t("titles.miss").toString());
         this.titles.push(this.$t("titles.sir").toString());
         this.titles.push(this.$t("titles.dame").toString());
         this.titles.push(this.$t("titles.rev").toString());
    }

	supportsPushNotifications(): boolean {
        let supportsPushNotifications = false;
        //Check if it is an iOS device
        if (/iP(hone|od|ad)/.test(navigator.userAgent) || (navigator.userAgent.includes("Mac") && "ontouchend" in document)) {
			const v = (navigator.appVersion).match(/OS (\d+)_(\d+)_?(\d+)?/);
            if((parseInt(v[1], 10) >= 16 && parseInt(v[2], 10) >= 4) || parseInt(v[1], 10) > 16){
                supportsPushNotifications = true;
            }          
			//return [parseInt(v[1], 10), parseInt(v[2], 10), parseInt(v[3] || "0", 10)];
		}
        else{
            supportsPushNotifications = true;
        }
        return supportsPushNotifications;
	}
 

    async updateProfile(){
     
        const isValid  = (this.$refs.form as any).validate();

        if(isValid){
           
            const request = new UserProfileUpdateRequest;
            request.phoneCountryCode = this.profile.phoneCountryCode;
            request.phoneNumber = this.profile.phoneNumber;
            request.originalPhoneCountryCode = this.profileOriginal.phoneCountryCode;
            request.originalPhoneNumber = this.profileOriginal.phoneNumber;
            request.firstName = this.profile.firstName;
            request.surname = this.profile.surname;
            request.title = this.profile.title;
            request.middleName = this.profile.middleName;

            if(this.pushChoice != undefined)
            {
                if(this.pushChoice == true)
                {
                    const result = await notifications.subscribeUser().then(() =>
                    {
                        this.pushNotification = true;
                    });
                    
                       
                }
                else
                {
                    const result = await notifications.unsubscribeUser().then(() =>{
                        this.pushNotification = false;  
                    });
                    
                }
            }

            this.isLoading = true;

            this.doWork<UserProfileResponse>(UserProfile.update(request),this.updateProfile, this.$t("saving").toString()).then((result)=>{
                this.profileOriginal = JSON.parse(JSON.stringify(this.profile));
                store.commit.AppState.UpdateProfileSaved(true);
                store.commit.AppState.UpdateFirstName(this.profile.firstName);
                this.close();
            }).catch((e)=>{
                this.showErrorDialog = true;
            });
        }
    }

    async loadProfile(){
        
        this.doWork<UserProfileResponse>(UserProfile.getProfile(),this.loadProfile).then((profile)=>{

            if(profile.phoneCountryCode === null){
                profile.phoneCountryCode = this.defaultCountryCode;
            }
            this.profile = profile;
            this.profileOriginal = JSON.parse(JSON.stringify(profile));
        });
    }

    close(){
        this.isVisible = false;
        this.$router.go(-1);
    }

    closeButtonClicked(){
        if(!this.forceEntry){
            this.close();
        }
    }

    mounted(){
        const force = this.$route.params.force ? this.$route.params.force: false;
        this.forceEntry =  force as boolean;

        this.loadTitles();
        this.isVisible = true;
        this.loadProfile();               
        if(this.supportsPushNotifications())
        {
            notifications.checkPushDisabled().then((result) =>{
                this.pushDisabled = result;
            });
            notifications.checkPushStatus().then((result) =>{
                this.pushNotification = result;
            });
        }
    }

    onCountryChanged(e){
        this.profile.phoneCountryCode = e;
    }

    phoneNumberChanged(e){
        this.profile.phoneNumber = e.phoneNumber;
        this.profile.phoneCountryCode = e.phoneCountryCode;
    }

    setNotificationPermission(e)
    {
        this.pushChoice = e;

    }

    
}


