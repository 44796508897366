
<div>
  <v-snackbar
    color="#000000b3"
    timeout="-1"
    v-model="showOffline">
      <div   class="no-connection d-flex  justify-space-between">
        <div style="width:95%" class="text-center">
          {{ $t("offlineNotification.offlineMessage") }}
        </div>
        <div>
          <v-btn  x-small icon class="close-btn pb-1" @click="showOffline = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </div>
      </div>
  </v-snackbar>
  <v-snackbar
    color="#4caf50b3"
    timeout="-1"
    v-model="showOnline">
        <div  class="connection-restored  d-flex  justify-space-between">
          <div style="width:95%" class="text-center">
            {{ $t("offlineNotification.onlineMessage") }}
          </div>
          <div>
            <v-btn x-small icon class="close-btn pb-1"  @click="showOnline = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </div> 
        </div>
  </v-snackbar>        
</div>
