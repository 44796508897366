export default {
    en: {
      genericErrorTryAgain:"An error has occured please try again.",
      confirmAccount:"Confirm Account",
      next:'Next',
      skip:'Skip',
      cancel:"Cancel",
      ok:"OK",
      save:"Save",
      results: "Results",
      apply: "Apply",
      login:"Login",
      back: "Back",
      close: "Close",
      accept: "Accept",
      reject: "Reject",
      decline: "Decline",
      send: "Send",
      claimShifts: "Claim Shifts",
      allFieldsRequired:"Please ensure all fields have been completed.",
      updateFailed:"Failed to update.",
      saving:"Saving...",
      thankYou:"Thank you",
      yes:"Yes",
      no:"No",
      confirm:"Confirm",
      failedToRemove:"Failed to remove",
      removedSuccessFully:"Successully removed",
      submittedSuccessFully:"Submitted Successully",
      failedToSubmit:"Failed to submit",
      failedToLoad:"Fail to Load",
      savedSuccessFully:"Saved Successully",
      done:"Done",
      typeMessage:"Type your message here",
      messages: {
        welcomeMessage: 'Hi <b class="user-name">{userName}</b>',
        loadingMessage: 'Loading'
      },
      onboarding:{
          slide1Title:"Find your dream job",
          slide1SecondaryText:"Search and find work when you want and where you want easily & quickly",
          slide2Title:"Get your interview sorted",
          slide2SecondaryText:"Connect with recruiters to arrange interviews in seconds",
          slide3Title:"Submit your timesheet & get paid quickly",
          slide3SecondaryText:"Simple way to submit your timesheet and get your payment to your bank account",
          slide4Title:"Get a job",
          slide4SecondaryText:"Get your job in seconds!",
          slide4ButtonText:"Get started",
      },
      cvUpload:{
        CVUploadTitle:"Upload your CV",
        BrowseMessage:"Press here to browse to documents",
        uploadSuccess:"Uploaded file successfully.",
        failedUpload:"Uploaded failed.",
        manageCVTitle: "Manage CVs",
        recentlyUploaded: "Recently Uploaded",
        noteAboutDeletingCv: "NOTE: Some of your CVs may be linked with existing applications.  If you delete these CVs, then they will also be removed from the application.",
        warningDeletingCv: "This CV is linked with existing applications.  If you delete this CV, then it will also be removed from any associated applications.",
        dropboxSelect: "Upload from Dropbox"
      },
      jobs:{
        swipeViewTravelDistanceNA:"N/A",
        job:"Job",
        map:"Map",
        recruiter:"Recruiter",
        searchBoxTitle:"What",
        searchBoxText:"Enter job title, keywords or company",
        locationTitle:"Where",
        locationText:"Enter city or postcode",
        searchButtonTitle:"Find Jobs",
        listView:"List",
        mapView:"Map",
        swipeView:"Swipe",
        contractTypeTitle:"Employment Type",
        postedTitle:"Job Posted",
        hourlyRateTitle:"Rate",
        salaryTitle:"Salary",
        applyButtonTitle:"Apply",
        filterApplyButton:"Save & Apply",
        saveButtonTitle:"Save",
        NoSearchResultsTitle:"We've nothing to show!",
        NoSearchResultsDescription:"Try refining your search criteria to get more results",
        NoMoreSearchResultsTitle:"No more results to show",
        SearchTryAgain:"Try Again",
        aboutTheRoleTitle:"About the role",
        commuteOptions:"Commute options",
        useExistingCVButtonTitle:"Use Existing CV",
        uploadCVButtonTitle:"Upload CV",
        uploadCVContinueButtonTitle:"Complete Application",
        ApplicationSuccessMessage:"Your job application has been submitted",
        ApplicationSuccessMessageButton:"Go to jobs",
        headerShortlisted: "Shortlisted",
        headerApplied: "Applied",
        headerRecent: "Recent",
        headerStatus: "Status",
        headerPublished: "Open Shifts",
        miles: "miles",
        radiusTitle: "Distance",
        JobApplyCVMessageHeading:"Apply with a new or existing CV?",
        JobApplyCVMessage:"",
        withinTitle: "Within",
        jobOffers: "Job Offers",
        message: "Message",
        rejectJobOffer: "Are you sure you want to reject this Job Offer?",
        jobOfferText: "new offers are waiting. Please review the job detail and respond.",
        noMoreOffers: "No More Job Offers",
        noCVConfirm: "Are you sure you want to apply without adding a CV?"
      },
      recentJobs:{
        header: "Recently viewed jobs",
      },
      shortlistedJobs:{
        header: "Shortlisted jobs",
      },
      jobApplicationStatus:{
        header: "Job application status",
      },
      termsAndConditions:{
        termsHeader: "Terms Of Service",
        updateErrorMessage: "There was an error updating your preference. If the problem persists, please contact your recruitment consultant."
      },
      appliedJobs:{
        header: "Applied jobs",
        applied: "Applied",
        interview: "Interview",
        offer: "Offer"
      },
      broadcastJobs:{
        header: "Open Shifts",
        SelectLabel: "Select Open Shifts",
        ShiftsLabel: "Select Shifts",
        noShiftsSelectedMessage: "You cannot accept the job without first selecting shift. Please select shift to confirm your availability for this job.",
        totalShiftsLabel: "Total Shifts",
        approved: "Approved",
        awaitingApproval: "Awaiting Approval",
        rejected: "Not Approved",
        successMessage: "You have successfully requested the shifts! Your request is being considered. You will receive a shift status update via push notification/text in the near future. Thank You!",
        errorMessage: "There was an error claiming the shifts, please try again.",
        noResultsTitle: "Sorry, no shifts are available for this job",
        noResultsDescription: "You will be notified if additional shifts are available"
      },
      nav:{
          search:"Search",
          jobs:"Jobs",
          timesheets:"Timesheets",
          calendar:"Availability",
          more:"More",
          logout: "Sign out",
          myjobs: "My Jobs",
          manageCv: "Manage CVs",
          notifications: "Notifications",
          settings: "Settings",
          moreFromAccess: "More from Access",
          myAccount: "My Account",
          paySummary: "Pay Summary",
          earlyPay: "Access Early Pay",
          installApp: "Install App"
      },
      availability:{
        availability:"Availability",
        addBookingHeaderTitle:"Would you like to add a Booking or Holiday?",
        addBooking:"Add Booking",
        addHoliday:"Add Holiday",
        addBookDetailsTitle:"Add Booking Details",
        companyName:"Company Name",
        jobTitle: "Job Title",
        companyLocation:"Company's Location",
        selectDate:"Set Calendar",
        holidayInfo:"Holiday Info",
        holidayType:"Holiday Type",
        holidayDetailsTitle:"Add Holiday Details",
        month:"Month",
        week:"Week",
        day:"Day",
        upComingEvents:"Upcoming events...",
        setAvailability: "Set Availability Preferences",
        available: "I'm available",
        unavailable: "I'm unavailable",
        startDate: "Start Date",
        endDate: "End Date",
        allDay: "All Day",
        startTime: "Start time",
        endTime: "End time",
        selectTime: "Select Time",
        addDescription: "Add Description",
        inclusive: "Inclusive",
        eachDay: "Each Day",
        selectDates: "Select Dates",
        maxDays: "(Max 31 days)",
        allDayEvent: "All Day Event",
        daysSelectedErrorMessage: "More than 31 days selected. Please remove one before selecting another",
        timeWarningMessage: "End time is before Start Time. Did you mean this? For example a Night Shift.",
        daysSelectedCaption: "day(s) selected",
        selectedDatesHeading: "Selected Dates",
        daysSubHeading: "day(s)",
        setTimes: "Set Times",
        deleteEventMessage: " Are you sure you wish to delete this event?",
        breakBetween: " break between two shifts"
      },
      timesheets:{
        timesheet:"Timesheet",
        checkIn:"Check-in",
        expense:"Expense",
        checkoutHeaderTitle: "Check-out",
        checkoutBodyText: "Are you sure you would like to check out now?",
        checkoutCheckoutButtonText: "Check-out",
        manualTimesheetHeaderTitle: "Manually Save Working Hours",
        manualEntryOnlyTimesheetHeaderTitle: "Enter Working Hours",
        autoTimesheetHeaderTitle: "Auto Submit Working Hours",
        manualTimesheetAutoSave: "Autosave my working hours",
        manualTimesheetCreateTimesheetButton: "Submit Hours",
        manualTimesheetCheckInTime: "Check-in time",
        manualTimesheetCheckOutTime: "Check-out time",
        editTimesheetHeaderTitle: "Edit Timesheet",
        editTimesheetCheckInTime: "Start time",
        editTimesheetCheckOutTime: "End time",
        editTimesheetSaveTimesheetButton: "Save",
        manualTimesheetTotalBreakTime: "Total break time",
        noSearchResultsTitleWeek: "You haven't submitted your timesheet for this week yet!",
        noSearchResultsTitleMonth: "You haven't submitted your timesheet for this month yet!",
        noResultsDescription: "You can check your previous timesheet by clicking the ",
        expenseNoSearchResultsTitleWeek: "You haven't added any expenses for this week yet!",
        expenseNoSearchResultsTitleMonth: "You haven't added any expenses for this month yet!",
        missingShiftSubmit: "You haven't added your working hours for the last shift in this period, are you sure you want to submit this timesheet?",
        noResultsNextPrevious:"next / previous",
        noResultsbuttons:"buttons",
        onboardingTitleText:"Check-in Onboarding",
        onboardingSlide1Title:"Grant camera access",
        onboardingSlide1SecondaryText:"Give your camera access so you can scan the QR code",
        onboardingSlide2Title:"Scan QR Code",
        onboardingSlide2SecondaryText:"Scan code to check-in when you arrive at your shift venue",
        onboardingSlide3Title:"Add breaks",
        onboardingSlide3SecondaryText:"Pause the clock when you take a break and check out when you are ready",
        onboardingSlide4Title:"Submit your timesheet",
        onboardingSlide4SecondaryText:"Your timesheet is created automatically, simply press submit to get paid hassle-free",
        onboardingSlide4ButtonText:"Get started",
        timesheetSubmitted: "Timesheet Submitted",
        submitTimesheet:"Submit Timesheet",
        yourTimesheetSubmitted:"Your timesheet has been submitted",
        yourTimesheetSubmittedButton:"Go to Timesheet",
        previousWeek:"Previous Week",
        futureWeek:"Future week",
        currentWeek:"This week",
        Quantity: "Quantity",
        Note:"Note",
        ExpenseType:"Expense Type",
        ExpenseQuantityTitle: "Qty",
        ExpenseAmountTitle: "Amount",
        ExpenseTotalTitle: "Total",
        ExpenseStatusTitle: "Status",
        ExpenseSubmit: "Submit",
        quantityPlaceholder:"Enter quantity",
        addExpense:"Add Expense",
        editExpense:"Edit expense",
        amountPlaceholder:"Enter Rate",
        Amount:"Rate",
        selectedExpenseTypePlaceHolder:"Select expense type",
        notePlaceholder:"Type here",
        removeExpenseMessage:"Are you sure you want to remove {expenseDescription}?",
        submitExpenseMessage:"Are you sure you want to submit this expense {expenseDescription}?",
        removeExpenseDocMessage:"Are you sure you want to remove this receipt?",
        noExpensesTitle:"No expenses to show!",
        noExpensesDescription:"You haven't submitted any expenses yet. You can add a new expense by clicking the add button.",
        lastShiftSubmit:"This is the last shift in the period, would you like to submit this timesheet?",
        addReceipt:"Add Receipt",
        takeAPhoto:"Take a Photo",
        fromCamera:"From Camera Roll",
        fromFiles:"From Files",
        autoSaveTabTitle: "Autosave",
        manualTabTitle: "Manual",
        autoTimesheetWorkedTime: "Worked Time",
        autoTimesheetOverTime: "Over Time",
        manualTimesheetDayRate: "Day rate",
        timesheetErrorNote: "The following errors were encountered, please correct these before continuing:"
      },
      qrcode:
      {
        scanMessage : "Please give access to your camera so that you can scan QR codes to check-in"
      },
      logTimeDialog:{
        checkIn: "Check-in",
        checkOut: "Check-out",
        time: "time",
        pause: "Pause",
        resume: "Resume",
        hours: "Hours",
        minutes: "Minutes",
        breaks:"Break",
        shiftStartTime: "Shift Start Time",
        shiftEndTime: "Shift End Time"
      },
      alertMessages:{
        warning:"Warning",
        success: "Success",
        info: "Info",
        update: "Update",
        warningHeaderText:"Warning",
        successHeaderText: "Success",
        infoHeaderText: "Information",
        updateHeaderText: "Update Available",
        futureShift:"You cannot check into a shift that is in the future",
        futureShiftManual:"You cannot log time for a shift that is in the future",
        historicTimesheet: "You cannot check into a job shift in the past, please checkout and create a manual timesheet for approval",
        timesheetSuccessfullyInserted:"Your timesheet has been successfully submitted!",
        timesheetInsertUnsuccessful:"Unfortunately we have been unable to save your timesheet, please speak to your recruitment consultant to resolve",
        installApp: "Your App Has been Installed! Please go to your home screen and open the app via the icon",
        checkInTimeNotSelectedError: "Please select a check in time",
        checkInHourNotSelectedError: "Please select the hour for the check in time",
        checkInMinuteNotSelectedError: "Please select the minutes for the check in time",
        checkOutTimeNotSelectedError: "Please select a check out time",
        checkOutHourNotSelectedError: "Please select the hour for the check out time",
        checkOutMinutesNotSelectedError: "Please select the minutes for the check out time",
        jobShiftCheckInAfterCheckOutError: "Your shift check in time cannot be after the shift check out time",
        jobShiftCheckInSameAdCheckOutError: "Your shift check in time cannot be the same as the shift check out time",
        jobShiftBreakDurationSameAsShiftDurationError: "Your break cannot be the same duration as your shift",
        jobShiftBreakDurationGreaterThanShiftDurationError: "Your break cannot be the same duration as your shift",
        invalidJobShift: "Unfortunately we have been unable to load the job shift, please try again from the main menu",
        checkoutDurationWarning: "Please make sure you log at least one minute before checking out",
        timesheetHoursSuccessfullyInserted:"Working Hours Saved",
        invalidQrCodeError: "Unfortunately the QR code that you have scanned cannot be verified against the job that you have selected",
        unrecognisedQrCodeError: "The QR code that you have scanned is invalid for this application",
        networkError: "Error connecting to the network, please check your connectivity and try again",
        availabilitySavedOK: "Availability has been updated successfully",
        availabilityDeleteOK: "Event has been deleted successfully",
        availabilityDeleteFail: "There was an error deleting the event",
        fileTypeError: "Upload failed, invalid file type.",
        dropBoxDownloadError: "Unable to download file from dropbox",
        existingCheckin:"You are already checked into a shift please check out of this one first.",
        earlyCheckin:"This shift doesn't start until {shiftStartTime}. Are you sure you want to checkin?",
        batchTimesheetNotReady:"There is no timesheet available at the moment. Please check back later.",
        availabilityValidationError:"Sorry, there were some errors whilst attempting to save your availability.  Please review the selected dates and try again",
        previouslySetAvailability:"Availability already set",
        previouslySetAvailabilityConfimation:"You have already set your availability for some of the dates selected, as highlighted below. Please note if you click Save, this will overwrite the availability selected before. Are you sure you want to proceed?",
        pastAvailability:"Unable to set availability for a historic date",
        startTimeEndTimeAvailability:"Unable to set the same start and end time",
      },
      noAccess:
      {
        noAccessTitle: "Access Denied",
        noAccessMessage: "Sorry, you do not appear to have access to the Recruitment app. Please contact your administrator.",
        noAccessButtonText: "Sign Out"
      },
      connectionLost:
      {
        connectionLostTitle: "Connection Interrupted",
        connectionLostMessage:"There seems to a problem with your internet connection. Please check your settings and try again.",
        connectionLostButtonText: "Retry"
      },
      earlyPay:
      {
        earlyPayTitle: "Access EarlyPay",
        earlyPayMessage: "Get instant access to the pay you have already earned",
        earlyPayButtonText: "Get It"
      },
      installApp:
      {
        installAppTitle: "Install App",
        installAppMessage: "Would you like to install our app on your device?",
        installAppInstalling: "Installing App",
        installAppLaterButtonText: "Continue to Website",
        installAppInstallButtonText: "Install",
        installAppManualInstall: "Install this webapp using 'Add To Home Screen' or 'Install App' on your browser !",
        installAppSuccessMessage: "The app was successfully installed.",
        installAppSuccess: "Install Succeeded",
        addToHomeScreenTitle: "Add to your Home Screen?",
        addToHomeScreenMessage: "You can easily add this website to your Home screen to have instant access and browse faster with an app-like experience.",
        installAppShowMeButton: "Show Me How",
        installAppShowMeMessagePt1: "Install this web app on your home screen: tap",
        installAppShowMeMessagePt2: "then 'Add to Home Screen'",
      },
      updateApp:
      {
        updateAppTitle: "Update Available",
        updateAppMessage: "There's a new version available. Would you like to install it?",
        updateAppButtonText: "Update"
      },
      registerConfirm:{
        accountAlreadyConfirmed:"You account has already been confirmed",
        verifiedSuccessFully:"Thanks for confirming your account",
        failedToVerify:"Failed to verify account",
        loadingText:"Verifying email",
        confirmAccountMessage:"Please confirm your account to start using the app"
      },
      profile:{
        firstName:"First name",
        firstNamePlaceholder:"Enter first name here",
        surname:"Last name",
        surnamePlaceholder:"Enter last name here",
        phoneNumber: "Phone number",
        phoneNumberPlaceholder:"Enter phone number here",
        middleName:"Middle name",
        middleNamePlaceholder:"Enter middle name here",
        title:"Title",
        titlePlaceholder:"Select title",
        profile:"My Account",
        allowPushNotifications: "Allow Push Notifications"
      },
      titles:{
        mr:"Mr",
        mrs:"Mrs",
        dr:"Dr",
        miss:"Miss",
        sir:"Sir",
        dame:"Dame",
        rev:"Rev",
      },
      offlineNotification:{
        offlineMessage: "No Internet Connection",
        onlineMessage: "Internet Connection Restored"
      },
      payslip:
      {
        payslipTitle: "Pay Summary",
        netPay: "My Net Pay",
        grossPay: "Gross Pay",
        deductions: "Deductions",
        hours: "Hours",
        days: "Days",
        niNumber:"NI number",
        niNumberPlaceholder:"Enter NI number here",
        nothingToShowTitle: "We've nothing to show!",
        nothingToShowMessage: "Sorry, there are no pay details available right now. Please check back later.",
        verifyMessage: "In order to view your pay, please verify your identity.",
        verifyButtonText: "Verify",
        verifyError: "There was a problem with your verification. Please check your details and try again. If the problem persists, please contact your consultant.",
        payerrorMessage: "Sorry, there was an error retrieving your pay details."
      },
      maps:
      {
        viewOnMap: "View On Map"
      }

    },


    enCat: {
        next:'Meow',
        skip:'Meow',
        messages: {
          welcomeMessage: 'Hi <b>Meow</b>'
        },
        onboarding:{
            slide1Title:"Meow",
            slide1SecondaryText:"Meow",
            slide2Title:"Meow",
            slide2SecondaryText:"Meow",
            slide3Title:"Meow",
            slide3SecondaryText:"Meow",
            slide4Title:"Meow",
            slide4SecondaryText:"Meow",
            slide4ButtonText:"Meow",
        },
        jobs:{
          searchBoxTitle:"Meow",
          searchBoxText:"Meow",
          locationTitle:"Meow",
          locationText:"Meow",
          searchButtonTitle:"Meow",
          listView:"Meow",
          mapView:"Meow",
          swipeView:"Meow",
          contractTypeTitle:"Meow",
          postedTitle:"Meow",
          hourlyRateTitle:"Meow",
          salaryTitle:"Meow",
          applyButtonTitle:"Meow",
          filterApplyButton:"Meow",
          saveButtonTitle:"Meow",
          NoSearchResultsTitle:"Meow",
          NoSearchResultsDescription:"Meow",
          NoMoreSearchResultsTitle:"Meow",
          SearchTryAgain:"Meow",
          useExistingCVButtonTitle:"Meow",
          uploadCVButtonTitle:"Meow",
          uploadCVContinueButtonTitle:"Meow",
          ApplicationSuccessMessage:"Meow",
          ApplicationSuccessMessageButton:"Meow",
          rejectJobOffer:"Meow"
        },
        nav:{
            search:"Meow",
            jobs:"Meow",
            timesheets:"Meow",
            calendar:"Meow",
            more:"Meow",
            logout:"Meow"
        },
        timesheets:{
          checkoutHeaderTitle: "Meow",
          checkoutBodyText: "Meow",
          checkoutCheckoutButtonText: "Meow",
          manualTimesheetHeaderTitle: "Meow",
          manualTimesheetAutoSave: "Meow",
          manualTimesheetCreateTimesheetButton: "Meow",
          manualTimesheetCheckInTime: "Meow",
          manualTimesheetCheckOutTime: "Meow",
          manualTimesheetTotalBreakTime: "Meow",
          noSearchResultsTitleWeek: "Meow",
          noSearchResultsTitleMonth: "Meow",
          noResultsDescription: "Meow",
          onboardingTitleText:"Meow",
          onboardingSlide1Title:"Meow",
          onboardingSlide1SecondaryText:"Meow",
          onboardingSlide2Title:"Meow",
          onboardingSlide2SecondaryText:"Meow",
          onboardingSlide3Title:"Meow",
          onboardingSlide3SecondaryText:"Meow",
          onboardingSlide4Title:"Meow",
          onboardingSlide4SecondaryText:"Meow",
          onboardingSlide4ButtonText:"Meow",
          timesheetSubmitted:"Meow",
          submitTimesheet:"Meow",
          yourTimesheetSubmitted:"Meow",
          yourTimesheetSubmittedButton:"Meow",
        }
      }
  }