<template>
  <v-container class="subtraction735">
    <div class="mainContent">
      <div>
        <LoadingIndicator :isVisible="isLoadingLocal"></LoadingIndicator>
      </div>
      <v-row justify="center" class="pt-5">
        <v-col col="12">
          <v-alert v-if="resultError != ''" dense type="error">
            {{ resultError }}
          </v-alert>
        </v-col>
      </v-row>
      <div class="mainCameraBody">
        <qrcode-stream
          :camera="camera"
          @decode="onDecodeScan"
          v-show="showvideo"
          @init="onInit"
        >
          <div class="elementOverlay"></div>
        </qrcode-stream>
      </div>
      <qrcode-capture
        ref="myref"
        @detect="onDetect"
        class="v-input theme--light v-text-field v-text-field--is-booted v-file-input"
        v-show="false"
        @decode="onDecodeFile"
      />
    </div>

    <v-row justify="center">
      <footer class="footer">
        <v-row justify="center" v-if="noStreamApiSupport">
          <v-col cols="12">
            <v-btn
              class="action-capture"
              depressed
              block
              color="primary"
              @click="
                resultError = '';
                $refs.myref.$el.value = '';
                $refs.myref.$el.click();
              "
            >
              <v-icon left>mdi-camera</v-icon> Capture
            </v-btn>
          </v-col>
        </v-row>

        <v-row justify="center">
          <v-col cols="12">
            <v-btn
              depressed
              block
              class="action-close"
              v-bind:class="{
                'grey-button': noStreamApiSupport,
                primary: !noStreamApiSupport,
              }"
              @click="close"
              >Cancel</v-btn
            >
          </v-col>
        </v-row>
      </footer>
    </v-row>
  </v-container>
</template>

<script lang="ts">
import ViewModel from "./QRCodeScan";
export default ViewModel;
</script>
<style lang="scss" scoped>
.subtraction735 {
  background: rgba($color: #000000, $alpha: 0.5);
  height: 100%;
}

.mainContent {
  min-height: calc(100vh - 200px);
}

.footer {
  position: absolute;
  bottom: 1px;
  display: block;
  width: 85%;
  flex-shrink: 0;
}
.mainCameraBody {
  display: block;
  height: calc(100vh - 400px);
  margin-top: 100px;
  padding-left: 50px;
  padding-right: 50px
}

.elementOverlay {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba($color: #000000, $alpha: 0.8);
  -webkit-clip-path: polygon(
    0% 0%,
    0% 100%,
    5% 100%,
    5% 5%,
    95% 5%,
    95% 95%,
    5% 95%,
    5% 100%,
    100% 100%,
    100% 0%
  );
  clip-path: polygon(
    0% 0%,
    0% 100%,
    5% 100%,
    5% 5%,
    95% 5%,
    95% 95%,
    5% 95%,
    5% 100%,
    100% 100%,
    100% 0%
  );
}
</style>
