import TimesheetService, { JobShift } from "../TimesheetService";
import { Vue, Component, PropSync, Watch, Mixins } from 'vue-property-decorator';
import moment, { Moment } from 'moment';
import global from '@/utils/global';
import { BreakItem } from '@/services/checkInDomain';
import { NotificationMixin } from '@/components/mixins/notificationMixin';

@Component({
})

export default class JobShiftSummaryCard extends Mixins(NotificationMixin) {
  @PropSync("jobShift", { type: Object }) jobShiftLocal!: JobShift;

  logoUrl = "";
  overlay = false;

  // @Watch('jobShift')
  // jobChanged(val: JobShift, oldVal: JobShift) {
  //   this.logoUrl = "";
  //   if(val.HasLogo){
  //       this.setImage();
  //   }
  // }

  get timesheetId(){
    if(this.jobShiftLocal.ClockState && this.jobShiftLocal.ClockState.hasEvents() && this.jobShiftLocal.ClockState.associatedData){
      const associatedData = JSON.parse(JSON.stringify(this.jobShiftLocal.ClockState.associatedData));
      return associatedData.TimesheetId;
    }
    else{
      return 0;
    }
  }

  get canClockInOrManual()
  {
    if(!this.jobShiftLocal.ClockInOut && ! this.jobShiftLocal.ManualEntry && !this.jobShiftLocal.BatchTimesheet)
    {
      return true;
    }
    
    if(this.jobShiftLocal.ClockInOut || this.jobShiftLocal.ManualEntry)
    {
      return true;
    }

    return false;

  }

  get canClockIn()
  {
    //nothing set?
    if(!this.jobShiftLocal.ClockInOut && ! this.jobShiftLocal.ManualEntry && !this.jobShiftLocal.BatchTimesheet)
    {
      return true;
    }

    if(this.jobShiftLocal.ClockInOut)
    {
      return true;
    }

    return false;

  }

  get manualEntryOnly()
  {
    if(!this.jobShiftLocal.ClockInOut && this.jobShiftLocal.ManualEntry)
    {
      return true;
    }

    return false;

  }

  get batchTimesheetOnly()
  {
    if(this.jobShiftLocal.BatchTimesheet && !this.jobShiftLocal.ClockInOut && !this.jobShiftLocal.ManualEntry)
    {
      return true;
    }

    return false;
  }
 
  get shiftDate(){
    return moment(this.jobShiftLocal.ShiftDate).format("DD/MM/yyyy");
  }

  get duration(){
    if(this.jobShiftLocal && this.jobShiftLocal.ClockState){

      if(this.jobShiftLocal.ClockState.getCheckInEvent() || this.jobShiftLocal.ClockState.getCheckOutEvent() ){
        return this.jobShiftLocal.ClockState.getDurationString();
      }
    }
    
    if(this.jobShiftLocal.ManualEntry && !this.jobShiftLocal.ClockInOut){
      return "Log Time";
    }

    if(this.jobShiftLocal.BatchTimesheet && !this.jobShiftLocal.ClockInOut && !this.jobShiftLocal.ManualEntry)
    {
     
      return "View";
     
    }
    return "Start Shift";
}

  get canCheckIn(){
    const shiftStartTime = moment(this.jobShiftLocal.ShiftStartTime);
    const timeToShiftStart = moment(this.jobShiftLocal.ShiftStartTime).diff(moment(), 'minute');
    
    if(this.jobShiftLocal.ClockState && this.jobShiftLocal.ClockState.hasEvents()){
        return true;
    } else if (timeToShiftStart < 15) {
      return true;
    }
    else if(shiftStartTime.format("YYYY-MM-DD") == moment().format("YYYY-MM-DD") && shiftStartTime >= shiftStartTime.add(-15, "minutes")){
        return true;
    }
    else if(shiftStartTime.format("YYYY-MM-DD") < moment().format("YYYY-MM-DD")){
      return true;
    }
    else if(this.timesheetId == 0 && shiftStartTime.format("YYYY-MM-DD") <= moment().format("YYYY-MM-DD")){
      return true;
    }

    return false;
  }


  constructor() {
    super();
  }
    
  setImage() {
    TimesheetService.getCompanyLogo(this.jobShiftLocal.PlacementId,40,40,false).then(e=>{
        this.logoUrl = e;
    });
  }

  checkInClicked(e){

    const timeToShiftStart = moment(this.jobShiftLocal.ShiftStartTime).diff(moment(), 'minute');
    if (timeToShiftStart > 15 && this.canCheckIn && (!this.jobShiftLocal.ClockState || !this.jobShiftLocal.ClockState.hasEvents())) {
      
      
        this.confirm(this.$t("alertMessages.earlyCheckin",{shiftStartTime:`${global.formatDate(this.jobShiftLocal.ShiftStartTime).format("HH:mm").toString()}`}).toString(),this.$t("confirm").toString()).then((result) => {
            if(result) {
              e.preventDefault();
              e.stopPropagation();
              this.$emit("checkIn", this.jobShiftLocal);
            }
        });
      
    } else {
      e.preventDefault();
      e.stopPropagation();
      this.$emit("checkIn", this.jobShiftLocal);
    }
  }

  mounted(){
    if(this.jobShiftLocal.HasLogo && this.logoUrl == ""){
      this.setImage();
    }
  }

  batchTimesheetClicked(e)
  {
    e.preventDefault();
    e.stopPropagation(); 
    this.$emit("batchTimesheet", this.jobShiftLocal)
   
  
  }

  
}