import Vue from 'vue'
import Component from 'vue-class-component'
import ConnectionLostCardView from '@/components/ConnectionLostCardView.vue';
import LoadingIndicator from "@/components/LoadingIndicator.vue";
import { CustomAxiosResponse } from '@/utils/httpClient';

@Component({ components:{ConnectionLostCardView,LoadingIndicator}})
export class NetworkMixin extends Vue {

    loadingMessageText = "";
    isLoading = false;
    lastAction: () => any;
    showNetworkError = false;
    retryClicked(){
        this.showNetworkError = false;
        if(this.lastAction){
            this.lastAction();
        }
    }   

    doWork<T>(promise: Promise<T>,retryAction: () => any = null,loadingMessage: string = null){

        if(retryAction){
            this.lastAction = () =>{retryAction()};
        }
        
        this.loadingMessageText = loadingMessage;

        const promiseResult = new Promise<T>((resolve,reject) =>{

            this.isLoading = true;

            promise.then(result => {
                this.isLoading = false;
                this.loadingMessageText = null;
                resolve(result);
            })
            .catch((error: CustomAxiosResponse) =>{
                this.loadingMessageText = null;

                this.isLoading = false;
                if(error.isNetworkError){
                
                     this.showNetworkError = true;
                 }else{
                    reject(error);
                 }
            });      
        });

        return promiseResult;
    }
}