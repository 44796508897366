import Vue from 'vue'
import Component from 'vue-class-component'

@Component
export class NotificationMixin extends Vue {

    confirm(message: string,title: string = null): Promise<boolean>{
        return  (this.$root as any).$confirm(title?title:this.$t("confirm"),message);
    }

    messageBox(message: string,type: string,title: string = null): Promise<boolean>{
        return  (this.$root as any).$messageBox(message,type,title);
    }

    messageBoxSuccess(message: string): Promise<boolean>{
        return this.messageBox(message,"Success");
    }

    messageBoxWarning(message: string): Promise<boolean>{
        return this.messageBox(message,"Warning");
    }

    messageBoxUpdate(message: string): Promise<boolean>{
        return this.messageBox(message,"Update");
    }

    messageBoxInfo(message: string): Promise<boolean>{
        return this.messageBox(message,"Info");
    }

    messageDeleteFailed(message: string): Promise<boolean>{
        return this.messageBox(message,"Warning");
    }

    toast(message: string,type = ""){
         (this.$root as any).$toasts(message,type);
    }

    toastSuccess(message: string){
        this.toast(message,"Success");
    }

    toastError(message: string){
        this.toast(message,"Error");
    }
}