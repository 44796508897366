<template>
  <div>
    <v-app-bar
      dense
      app
      color="white"
      class="top-toolbar pt-0"
      :extension-height="xsExtensionHeight"
    >
      <div class="h1-xl">{{ $t("availability.availability") }}</div>
      <v-spacer />
      <v-btn icon @click="showDiary">
        <v-icon class="avail-plus">mdi-plus</v-icon>
      </v-btn>

      <template v-slot:extension>
        <div class="div-cal" v-show="showNetworkError">
        <ConnectionLostCardView
          :isVisible="showNetworkError"
          @close="retryClicked"
        ></ConnectionLostCardView>
        </div>
        <div class="div-cal" v-show="!showNetworkError">
          <v-divider></v-divider>
          <div class="div-cal2">
            <AvailabilityCalendar
              :eventsLocal="eventDays"
              :pickerDateSync="pickerDate"
              
              :modelDateLocal="modelDate"
              @pickerChanged="pickerChanged"
              @dateChanged="dateChanged"
              @vmodelDate="vModelChanged"
              @pickDate="pickDateChanged"
            ></AvailabilityCalendar>
          </div>
        </div>
      </template>
    </v-app-bar>

    <v-container>
      <v-dialog v-model="showJobDetails" max-width="500px" fullscreen>
            <AvailabilityJobDetails :isOpen="showJobDetails" :event="eventToShow" @close="showJobDetails = false"></AvailabilityJobDetails>
        </v-dialog>
      <v-dialog
        v-model="addDiaryEvent"
        max-width="500px"
        fullscreen
        scrollable
        persistent
      >
        <!-- <AvailabilitySet @close="addDiaryEvent = false" @save="saveEvent" :monthStartDate="monthToPass" :startDateLoc="dateToPass"></AvailabilitySet> -->
        <AvailabilitySet
          @close="addDiaryEvent = false"
          @save="saveEvent"
          :startDateLocal="dateToPass"
          :forceChildReload="forceChildReload"
          v-bind:localErrors="availabilityErrors"
        >
        </AvailabilitySet>
      </v-dialog>

      <LoadingIndicator :isVisible="isLoading"></LoadingIndicator>

      <div>
        <v-timeline class="avail-timeline " dense clipped>
          <v-timeline-item
            v-for="event in visibleEvents"
            :key="event.UniqueId"
            color="grey lighten-1"
            small
            fill-dot
            class="mb-2"
          >
            <AvailabilityCardView
              class="ml-2 mt-n1"
              :event="event"
              @deleteEvent="deleteEvent(event)"
              @selectedEvent="selectedEvent(event)"
            ></AvailabilityCardView>
            <div v-if="event.NextShiftStartime" class="break ml-2 mt-3 fs-13">
               {{ timeBetweenShifts(event) }} {{ $t("availability.breakBetween") }}
            </div>
          </v-timeline-item>
        </v-timeline>
      </div>

      <div v-if="showDelete">
        <v-dialog max-width="500px" v-model="showDelete" persistent>
          <v-card>
            <v-card-title class="d-flex justify-center">
              <div class="d-flex flex-column">
                <img src="@/assets/img/icons/Warning.png" height="100px" />
                <v-row justify="center">
                  <b>{{ $t("alertMessages.warningHeaderText") }}</b>
                </v-row>
              </div>
            </v-card-title>
            <v-card-text class="alertBody">
              <v-spacer></v-spacer>
              <v-row justify="center" class="centered">
               {{ $t("availability.deleteEventMessage") }} 
              </v-row>

              <AvailabilityCardView
                class="ml-2 mt-2"
                :event="eventToDelete"
                :isDeleteEvent="true"
              ></AvailabilityCardView>
            </v-card-text>
            <v-card-actions>
               <v-col class="action-delete-cancel text-right">
                <v-btn block depressed @click="cancelDelete">
                  <b>{{ $t("cancel") }}</b>
                </v-btn>
              </v-col>
              <v-col>
                <v-btn
                  class="action-delete-ok primary"
                  block
                  depressed
                  @click="closeDelete"
                >
                  <b>{{ $t("ok") }}</b>
                </v-btn>
              </v-col>
             
            </v-card-actions>
          </v-card>
        </v-dialog>
      </div>
    </v-container>
    <Alert 
      :isVisible="message"
      :alertType="alertType"
      :isConfirmation="isConfirmation"
      :result.sync="availabilityOverride"
      @close="closeAlert"
    >
      <template v-slot:message>
        <div v-html="messageText"></div>
      </template>
    </Alert>
  </div>
</template>
<script lang="ts">
import ViewModel from "./Availability";
export default ViewModel;
</script>

<style lang="scss">

.v-timeline--dense {
  padding-top: 0px !important;
}
.v-timeline--dense .v-timeline-item__body {
  max-width: calc(100% - 18px) !important ;
}
.v-timeline-item__divider {
  min-width: 18px !important;
  align-items: normal !important;
}
.v-timeline-item__dot--small {
  width: 14px !important;
  height: 14px !important;
}
.v-application--is-ltr .v-timeline--dense:not(.v-timeline--reverse):before {
  left: 7px !important;
  background: none;
  border-right-color: #dbe0e5;
  border-right-style: dotted;
}
</style>

<style lang="scss" scoped>
.hide-expand-cal {
  height: 135px !important;
  overflow: hidden !important;
}
#topBtn {
  position: fixed;
  bottom: 87px;
  right: 15px;
  z-index: 999;
}

.h1-xl {
  font-size: 20px;
  font-weight: bold;
  font-family: "Muli";
}
.avail-plus {
  font-size: 28px !important;
  color: black !important;
}

.div-cal {
  width: 100%;
  background-color: white;
}

@media (min-width: 960px) {
  .div-cal2 {
    max-width: 900px;
    margin-right: auto;
    margin-left: auto;
    background-color: white;
  }
}
@media (min-width: 1264px) {
  .div-cal2 {
    max-width: 1185px;
    margin-right: auto;
    margin-left: auto;
    background-color: white;
  }
}
@media (min-width: 1904px) {
  .div-cal2 {
    max-width: 1785px;
    margin-right: auto;
    margin-left: auto;
    background-color: white;
  }
}

.break {
  background-color: #F5F5F7;
  text-align: center;
  line-height: 2 !important;
  border-top-left-radius: 25px;
  border-bottom-left-radius: 25px;
  border-bottom-right-radius: 35px;
}
</style>
