

<v-container>
  <div class="login ma-auto">
     
    

  <div class="centered">
    <img alt="Vue logo" src="@/assets/logo.png" height="100">
    
  </div>
    <div class="login-header pt-10 pb-10" >{{$t('login')}}</div>
    

  

  

  <v-btn depressed block class="grey-button action-find-jobs" to="/">{{ $t("login") }}</v-btn>






 




  
    

      <!-- <b-btn  to="/">Login</b-btn> -->
      
  </div>
</v-container>
