

<div class="main-container">


  
  <GoogleMap v-if="mapType == 0" :recycle="true" :location="location" :lattitude="lattitude" :longitude="longitude" :markers="markers" @markerclicked="markerClicked" @hideCard="hideCard"></GoogleMap>
  <HereMap v-if="mapType == 1" :recycle="false" :location="location" :lattitude="lattitude" :longitude="longitude" :markers="markers" @markerclicked="markerClicked" @hideCard="hideCard"></HereMap>

    
  <slot name="cardview" v-if="cardVisible">
     
  </slot>
  
</div>

