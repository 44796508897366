import { Vue, Component } from 'vue-property-decorator'
import JobService, { Job, ShortlistedJobsRequest, ShortlistedJobsResult } from "../../../../rdb/components/jobs/JobService";
import JobSummaryCard from "../../jobs/components/JobSummaryCard.vue";
import { NetworkMixin} from '@/components/mixins/networkMixin'
import { Mixins } from 'vue-property-decorator';

@Component({
    name:"shortlisted-view",
    components:{ JobSummaryCard }

})

export default class ShortlistedView extends Mixins(NetworkMixin) {
    
    shortlistedJobs: ShortlistedJobsResult = new ShortlistedJobsResult();

    constructor() {
        super();
    }

    mounted() {
        this.getShortlistedJobs();
    }

    getShortlistedJobs(page = 1) {
        const shortlistedJobsRequest = new ShortlistedJobsRequest();
        shortlistedJobsRequest.Page = page;
        this.doWork<any>(JobService.shortlistedJobs(shortlistedJobsRequest),() =>{this.getShortlistedJobs()}).then((result) => {
            this.shortlistedJobs.PageInfo = result.data.PageInfo;
            result.data.Jobs.forEach(i =>this.shortlistedJobs.Jobs.push(i));
        });
    }

    setShortlisted(job: Job) {
        job.IsShortlisted = !job.IsShortlisted;
        
        if(job.IsShortlisted){
          this.$emit("setShortlisted", job);
        }else{
          this.$emit("removeShortlisted", job);
        }
    }

    jobSelected(job: Job){
        //navigate to details
        this.$emit("showJob",job);
      }

      infiniteScrolling(entries, observer, isIntersecting) {
        if (this.shortlistedJobs.PageInfo !== undefined) {
          if (this.shortlistedJobs.PageInfo.HasNextPage) {
            if (isIntersecting  && !this.isLoading) {
                   this.isLoading = true;
                   this.getShortlistedJobs(this.shortlistedJobs.PageInfo.Page+1);
           }
          }
        }
      }
}