
<v-card class="rounded-corners card-border job-list-item">
  <div class="d-flex justify-space-between align-center">
    <div class="d-flex justify-start align-center">
      <v-col>                                
        <v-icon v-bind:size="getIconSize()">{{retrieveFileIcon(localCv.FileExtension)}}</v-icon>
      </v-col>
      <v-col>
        <v-row>
          <div v-bind:class="{'text-h1 ellipsis headerClass': isListType,  'text-h1 ellipsis headerClass small': !isListType}" :data-cvFileName="localCv.FileName" style="max-width: 185px;">{{localCv.FileName}}</div>
        </v-row>
        <v-row>
          <div v-bind:class="{'text-secondary ellipsis': isListType,  'text-secondary ellipsis small': !isListType}" :data-CreatedOn="localCv.CreatedOn">{{ formatDate(localCv.CreatedOn) }}</div>
        </v-row>
      </v-col>
    </div>
    <div class="d-flex pa-2 justify-end align-center">
      <v-btn outlined fab x-small icon color="primary" @click="deleteCv()">
        <v-icon small>mdi-trash-can-outline</v-icon>
      </v-btn>
    </div>
  </div>
  <v-card class="rounded-corners card-border job-list-item" v-show="localAppliedJobs.length > 0">
    <v-list style="padding-top: 0px; padding-bottom: 0px;">
      <v-list-group>
        <template v-slot:activator>
          <v-list-item-title>Linked applications...</v-list-item-title>
        </template>
          <v-list-item
            v-for="(item, i) in localAppliedJobs"
            :key="i"
            link
            style="padding-top: 0px; padding-bottom: 0px;"
            @click="loadJobDetails(item.JobId)">
            <v-list-item-content>
              <p class="caption"><b>JobTitle: </b>{{ item.JobTitle }} <b>Company: </b>{{ item.Company }}</p>
            </v-list-item-content>
          </v-list-item>
      </v-list-group>
    </v-list>
  </v-card>
</v-card>
