import { Vue, Component, Mixins } from "vue-property-decorator";
import * as _ from "lodash";
import PayslipService, { PAYBRegistrationRequest } from "./PayslipService"
import EarlyPay from "../../../EarlyPay.vue"
import store, {AppStore} from '../../../../store/';
import PayslipDetail from "./PayslipDetail.vue";
import { NetworkMixin } from '@/components/mixins/networkMixin';
import UserProfile ,{UserProfileResponse, UserProfileUpdateRequest} from '@/services/userProfile';
import ConnectionLostCardView from '@/components/ConnectionLostCardView.vue';
import LoadingIndicator from "@/components/LoadingIndicator.vue";
import { NotificationMixin } from '@/components/mixins/notificationMixin';
import moment from "moment";
import global from "@/utils/global";
import AuditEventLog from "@/services/auditEventLog";

@Component({
  components:{EarlyPay, PayslipDetail,ConnectionLostCardView,LoadingIndicator},
    name: "Payslip"
  
  })
  export default class Payslip  extends Mixins(NetworkMixin,NotificationMixin) {

     payslipService: PayslipService = new PayslipService();
     payslipData = []
     earlyPay = "126px";
     showDetail = false;
     dataToPass = null;
     showRegister = false;
     firstName = "";
     surname = "";
     niNumber = "";
     requiredRule = [
      value => !!value || 'Required.'
    ];

     niRule = [
       value => !!value || "Required",
       value => /^[A-Z]{2}[0-9]{6}[A-Z]$/.test(value) || 'Invalid Ni Number'
     ]
    titles: string[] = [];
    errors: any[];
    valid = true;
    
    
    constructor() {
      super();
    }

    beforeMount()
    {
      if(!store.getters.AppState.featureEnabled("RCRMEARLYPAY"))
      {
        this.earlyPay = "0px;"
      }
     
     
      this.retrivePayslips();
     
      
    }

    get nino()
    {
      return this.niNumber.toUpperCase();
    }

    set nino(e)
    {
      this.niNumber = e.toUpperCase()
    }

    async loadProfile(){
        
      this.doWork<UserProfileResponse>(UserProfile.getProfile(),this.loadProfile).then((profile)=>{

          this.firstName = profile.firstName;
          this.surname = profile.surname;
          this.showRegister = true;
      });
    }

    

    get yearGroup()
    {
      if(this.payslipData.length <= 0)
      {
        return;
      }
      const values =  _.keys(_.countBy(this.payslipData, function(payload) { return payload.payDay.substring(0,4) }));
      return values.sort(function(a,b){ return b-a});
    }

    yearTotal(year)
    {
      const yearData =this.payslipData.filter(function(data)
      {
        return data.payDay.substring(0,4) == year;
      })
      const total =  _.sumBy(yearData,function(data)
      {
        return (data.grossPay - data.deductions);
      })

      return parseFloat(total).toFixed(2);        
      
    }

    get hasData()
    {
    
      if(this.payslipData.length <= 0) return false;

      return true;
    }

    dataByYear(year)
    {
      
      return this.payslipData.filter(function(data)
      {
       
        return data.payDay.substring(0,4) == year;
      })
    }

    getMonth(strDate) {
      const y = strDate.substr(0,4),
        m = strDate.substr(4,2) - 1,
        d = strDate.substr(6,2);
        return new Date(y,m,d).toLocaleDateString('default', { month: 'long' });
    
    }

    getFormattedDate(strDate)
    {
      return global.formatDateSpecific(strDate, "Do MMM YYYY");
     
      
    }

    getNetPay(item)
    {
      const netPay = (+item.grossPay - +item.deductions).toFixed(2);
      return `£${netPay}`
    }

    async retrivePayslips()
    {
     
  
     this.doWork<any>(this.payslipService.getPayslips(),this.retrivePayslips)
    .then((result) => {
              this.payslipData = result.data.data;
              this.payslipAudit();
            }
    ).catch((e) =>
    {
     
      //need to trap error and show registration form if applicable
      if(e.response.status == 403)
      {
        this.loadProfile();
        
      }
      else
      {
        this.showWarningAlert(this.$t("payslip.payerrorMessage").toString())
      }
    });
     
    }

    showDetails(data) {
  
      this.dataToPass = data;
      this.showDetail = true;
    }

    async payslipAudit()
    {
       AuditEventLog.postAuditEventLog("PAYSLIP");
    }


    verify()
    {
      const isValid  = (this.$refs.form as any).validate();

        if(isValid)
        {
          const request = new PAYBRegistrationRequest();
          request.FirstName = this.firstName;
          request.Surname = this.surname;
          request.NINumber = this.niNumber;
          
          this.doWork<any>(this.payslipService.postPAYBRegistration(request), this.verify).then((result) =>
          {
            this.showRegister = false;
            this.retrivePayslips();
          }).catch((e) =>
          {
            this.showWarningAlert(this.$t("payslip.verifyError").toString());
          });
          
        }
    }

    showWarningAlert(message: string){
      this.messageBoxWarning(message);
    }

  }