import Vue from 'vue'
import Component from 'vue-class-component'
import store, { AppStore } from '@/store';
import AppState from '@/store/AppState';
import pwaInstallHandler from "../plugins/pwaInstallHandler"
import { Watch } from 'vue-property-decorator';
import auditLogger from '@/utils/auditLogger';
@Component({
  components: {  },
  methods: {

  }

})
export default class InstallBanner extends Vue {

  canInstall = false;
  message = false;
  messageText = "";
  alertType = "";
  showInstalling = false;
  showSelfInstall = false;
  interval;
  value = 0;
  show = false;
  helpScreen = false;

  @Watch('canInstall')
  canInstallChanged(val, oldVal) {
    this.show = val;

  }

  @Watch('showSelfInstall')
  selfInstallChanged(val, oldVal) {
    this.show = val;

  }

  constructor() {
    super();
    if (typeof window === "undefined") {
      return;
    }


    window.addEventListener("appinstalled", () => {
      let isPWAinBrowser = true;
      // replace standalone with fullscreen or minimal-ui according to your manifest
      if (matchMedia('(display-mode: standalone)').matches) {
        //  // Android and iOS 11.3+
        isPWAinBrowser = false;
      } else if ('standalone' in navigator) {
        //  // useful for iOS < 11.3
        isPWAinBrowser = !navigator['standalone'];
      }
      //check mobile as desktop open pwa instantly after install
      const ua = navigator.userAgent;
      const isMobile = /Android|webOS|iPhone|iPad|iPod/i.test(ua);
      if (isPWAinBrowser && isMobile) {

        this.startProgress();

        setTimeout(() => {
          this.showAlert();
        }, 10000);
      }
      else
      {
        this.noAlert();
       
      }
      this.postAudit();
    });

  }


  created() {
    if (!store.getters.AppState.isAuthenticated) {
      return false;
    }
    if (!store.getters.AppState.onBoarded) {
      return false;
    }

    if (navigator.webdriver == true) {
      return false;
    }

    if (matchMedia('(display-mode: standalone)').matches) {
      return false;
    }


    const browserCanInstall = this.canBrowserInstall();
    
    if ((!browserCanInstall) || this.iOS()) {
      if (store.getters.AppState.installAppExpiry == undefined || store.getters.AppState.installAppExpiry == null) {
        this.showSelfInstall = true;
      }
      const expiryDate = store.getters.AppState.installAppExpiry;
      const date = new Date();
      if (expiryDate < date) {
        store.commit.AppState.UpdateInstallAppExpiry(null);
        this.showSelfInstall = true;
        return;
      }
      return;
    }

    pwaInstallHandler.addListener((canInstall) => {
      if (store.getters.AppState.installAppExpiry == undefined || store.getters.AppState.installAppExpiry == null) {
        this.canInstall = canInstall;
      }
      else {
        const expiryDate = store.getters.AppState.installAppExpiry;
        const date = new Date();
        if (expiryDate < date) {
          store.commit.AppState.UpdateInstallAppExpiry(null);
          this.canInstall = canInstall;
        }
        else {
          this.canInstall = false;
        }
      }
    })

  }

  get isIOS()
  {
    return this.iOS();
  }

  stopProgress() {
    clearInterval(this.interval)
  }
  startProgress() {
    this.interval = setInterval(() => {
      if (this.value === 100) {
        this.stopProgress();
        return (this.value = 0)
      }
      this.value += 10
    }, 1000)
  }

  async postAudit()
  {
     
     auditLogger.logEvent("DOWNLOADEDAPP");
  }


  noAlert() {
    this.showInstalling = false;
  }

  showAlert() {
    this.showInstalling = false;
    // this.messageText = this.$t("alertMessages.installApp").toString();
    // this.alertType = this.$t("alertMessages.success").toString();
    this.message = true;
  }

  closeAlert() {
    this.message = false;
  }

  cancelInstall() {
    //ask again in 24hrs
    const today = new Date();
    today.setDate(today.getDate() + 1);
    store.commit.AppState.UpdateInstallAppExpiry(today);
    this.canInstall = false;
    this.showSelfInstall = false;
  }

  promptInstall() {
    this.canInstall = false;
    this.showInstalling = true
    pwaInstallHandler.install().then(result => {

      if (result == true) {
        //set date to far in the future
        const today = new Date(2050, 11, 24, 12, 0, 0, 0);
        store.commit.AppState.UpdateInstallAppExpiry(today);
       

      }
      else {
        this.showInstalling = false;
      }
    });
  }

  selfInstall() {
    //ask again in 24hrs
    const today = new Date();
    today.setDate(today.getDate() + 1);
    store.commit.AppState.UpdateInstallAppExpiry(today);
    this.showSelfInstall = false;
    this.helpScreen = true;

  }

  helpOk(){
    this.helpScreen = false;
  }

  canBrowserInstall(): boolean {

    const isIE = /*@cc_on!@*/false || !!document["documentMode"];
    const isEdge = !isIE && (navigator.userAgent.indexOf("Edge") != -1 || navigator.userAgent.indexOf("Edg") != -1) ;
    if ( navigator.userAgent.indexOf("Opera") != -1 || navigator.userAgent.indexOf('OPR') != -1 )
        {
        return false;
    }
    else if (navigator.userAgent.indexOf('Opera Mini') != -1)
    {
        return false;
    }
    else if(navigator.userAgent.indexOf("Chrome") != -1 && !isEdge)
    {
        return true;
    }
    else if(navigator.userAgent.indexOf("Safari") != -1 && !isEdge)
    {
        return false;
    }
    else if(navigator.userAgent.indexOf("Firefox") != -1 ) 
    {
        return false;
    }
    else if((navigator.userAgent.indexOf("MSIE") != -1 ) || (!!document["documentMode"] == true )) //IF IE > 10
    {
        return false;
    }
    else if(isEdge)
    {
        return true;
    }
    else 
    {
        return false;
    }
}

iOS(): boolean {
  return /iphone|ipod|ipad/.test( navigator.userAgent ) || (navigator.userAgent.includes("Mac") && "ontouchend" in document);
}

}