import { Vue, Component, PropSync, Prop, Watch } from 'vue-property-decorator'
import {Loader, LoaderOptions} from 'google-maps';
import store from "@/store/index";
import MarkerClusterer from "@google/markerclustererplus";


  export interface GoogleMarkerConfig{
    imageUrl: string;
    imageUrlSelected: string;
    height: number;
    width: number;
    lat: number;
    lng: number;
    uniqueId: string;
  }

 @Component({
 })
 export default class GoogleMap extends Vue {
  constructor(){
    super();
  }
  @Prop(Array) readonly appendMarkers: GoogleMarkerConfig[];
  @Prop(Array) readonly markers: GoogleMarkerConfig[];
  @Prop({default:true}) readonly recycle: boolean;
  @Prop(Number) readonly lattitude: number
  @Prop(Number) readonly longitude: number;
  @Prop(String) readonly location: string;
  
  @Prop({default:12})  readonly zoom: number;
  @Prop({default:'gm1'})  readonly recyclePrefix: string;
  

  mapObject: google.maps.Map;
  internalmarkers: google.maps.Marker[] = [];
  mapBounds = new google.maps.LatLngBounds();
  markerCluster: MarkerClusterer;
  mapClickEvent = null;

  cardVisible = false;
  showMapLink = false;
  @Watch('markers')
  markersChanged(val: any, oldVal: any) {
      this.clearMarkers();
      this.createMarkers(val);
      this.addMarkerCluster();
  }

  @Watch('appendMarkers')
  appendMarkersChanged(val: any, oldVal: any) {
       this.createMarkers(val);
       this.addMarkerCluster();
  }


  @Watch('lattitude')
  lattitudeChanged(val: any, oldVal: any) {
    this.setCenter(this.lattitude,this.longitude);
  }

  @Watch('longitude')
  longitudeChanged(val: any, oldVal: any) {
    this.setCenter(this.lattitude,this.longitude);
  }

  beforeMount()
  {
    this.showMapLink = !store.getters.AppState.featureEnabled("RCRMGOOGLEMAPS")
  }

  beforeDestroy(){
      this.clearMarkers();
      google.maps.event.removeListener(this.mapClickEvent);
      
  }

  mounted(){
    if(!this.showMapLink)
    {
      this.loadMapLibraries().then(()=>{
        this.createMapControl();
        this.setCenter(this.lattitude,this.longitude);
        this.setMapBounds(this.lattitude,this.longitude);
        this.setZoom(this.zoom);
        this.createMarkers(this.markers);
      });
    }
  }

  get locationString()
  {

    if(this.lattitude !== 0 && this.longitude !== 0)
    {
    return this.lattitude.toString() + "," +  this.longitude.toString(); 
    }
    
    return this.location;

    
  }

  createMarkers(markers: GoogleMarkerConfig[]){
    
    if(markers){
      const repositioned = this.moveOverLappingMarkers(markers);
      repositioned.forEach(i=>{
        this.addMarker(i);
      });
   } 
  }

  clearMarkers(){

    this.clearMarkerClusters();

    for (let i = 0; i < this.internalmarkers.length; i++) {
      google.maps.event.removeListener((this.internalmarkers[i] as any).clickEvent);
      this.internalmarkers[i].setMap(null);
    }
    this.internalmarkers = [];
  }

  clearMarkerClusters(){
    if(this.markerCluster){
      this.markerCluster.setMap(null);
      this.markerCluster.clearMarkers();
      this.markerCluster = null;
    }
  }
 
  addMarker(markerConfig: GoogleMarkerConfig){
    const finalLatLng = new google.maps.LatLng(markerConfig.lat,markerConfig.lng);

    const marker = new google.maps.Marker({
      map: this.mapObject,
      icon: {url: markerConfig.imageUrl,scaledSize:new google.maps.Size(markerConfig.width, markerConfig.height)}, 
      position: finalLatLng
    });
    
    (marker as any).config = markerConfig;

    (marker as any).clickEvent = google.maps.event.addListener(marker, "click", (e)=>{
        this.markerClicked(e,marker);
    });

    this.internalmarkers.push(marker);
  }

  moveOverLappingMarkers(markers: GoogleMarkerConfig[]){

       const groups = this.groupMarkersByLatLng(markers);
       const min = .999999;
       const max = 1.000001;
       const  newMarkers = [];
       Object.keys(groups).forEach(key =>{
        
            if(groups[key].length > 1){

              //Skip first item increment others
              for (let g=1; g < groups[key].length; g++) {
                
                groups[key][g].lat =  groups[key][g -1].lat * (Math.random() * (max - min) + min);
                groups[key][g].lng =  groups[key][g -1].lng * (Math.random() * (max - min) + min);
              }
            }

            groups[key].forEach(n =>{
              newMarkers.push(n);
            })
       });
       return newMarkers;
  }

  groupMarkersByLatLng(items) {

    const grouped = {}; 
    for (let i=0,len=items.length,p;i<len;i++) {
      p = items[i];

      if (grouped[`${p.lat}${p.lng}`] === undefined) 
        grouped[`${p.lat}${p.lng}`] = [];

      grouped[`${p.lat}${p.lng}`].push(p); 
    }

    return grouped;
  }
  

  addMarkerCluster(){
    this.markerCluster = new MarkerClusterer(this.mapObject, this.internalmarkers,
        {imagePath: store.getters.AppState.url + 'img/icons/cluster_images/m',maxZoom: this.zoom
      });
  }

  setMapBounds(lat,lng){
    this.mapBounds.extend({lat:lat, lng: lng});
 }

  setCenter(lat,long){
    if(this.mapObject){
      this.mapObject.setCenter({lat:lat, lng:long});
    }
  }

  setZoom(zoom){
    if(this.mapObject){
      this.mapObject.setZoom(zoom);
    }
  }

  createMapControl(){

    const mapElement = this.$refs.googleMapControl as Element;

      if(this.recycle && window[this.recyclePrefix]){
        mapElement.appendChild(window[this.recyclePrefix].map.getDiv())
        this.mapObject = window[this.recyclePrefix].map;
      }else{

        this.mapObject =new google.maps.Map(mapElement, {
            zoom: 12
          });

        window[this.recyclePrefix] = { map: this.mapObject }
      }
            
      this.mapClickEvent = this.mapObject.addListener("click",this.mapClicked);
  }

  mapClicked(){
    this.cardVisible = false;
    this.$emit("hideCard");
  }

  loadMapLibraries(){

    const result = new Promise<any>((success,reject)=>{

      if(!window.google || !window.google.maps){
        const options: LoaderOptions = { libraries: ['places', 'geometry'] };
          const loader = new Loader(store.state.AppState.discovery.googleApiKey, options);
          loader.load().then(()=>{
            success();
          });
      }

      success();

    });

    return result;
  }

  markerClicked(event,marker: google.maps.Marker){
    this.cardVisible = true;
    this.setMarkerSelected(marker);
    this.$emit("markerclicked",{event:event,marker:marker});
  }

  setMarkerSelected(marker: google.maps.Marker){

    this.internalmarkers.forEach(m => {
     const config = (m as any).config as GoogleMarkerConfig;
      m.setIcon({url: config.imageUrl,scaledSize:new google.maps.Size(config.width, config.height)});
    });

    const config = (marker as any).config as GoogleMarkerConfig;
    marker.setIcon({url: config.imageUrlSelected,scaledSize:new google.maps.Size(config.width, config.height)});
  }

}