import { Vue, Component, PropSync, Watch } from 'vue-property-decorator';
import TimesheetService, { JobShift, Timesheet } from '../TimesheetService';
import moment, { Moment } from 'moment';
import {  ClockStateEvent, ClockStateResult, BreakItem, ClockState, DurationInfo } from '@/services/checkInDomain';
import Alert from "@/components/Alert.vue";
import { mdiBellSleep } from '@mdi/js';
import CheckinSummaryCard from './CheckinSummaryCard.vue';
import global from '@/utils/global.ts';
import { NetworkMixin } from '@/components/mixins/networkMixin';


@Component({
    name:"logTimeCopy",
    components: { Alert,CheckinSummaryCard },
})

export default class LogTimeDialogCopy extends NetworkMixin {
    @PropSync("visible", { type: Boolean }) showDialog!: boolean;
    @PropSync("jobShift", { type: Object }) jobShiftLocal!: JobShift;
    @PropSync("clockState", { type: Object }) clockStateResult!: ClockStateResult;
    @PropSync("qrCode", { type: String }) qrCodeData!: string;
    
    logoUrl = "";
    message = false;
    messageText = "";
    alertType = "";
    timerRef = null;
    stateOverride = "";

    currentDuration: DurationInfo = {hours:0,minutes:0,seconds:0};

    @Watch('clockState')
    timesheetEventsChanged(val: ClockStateResult, oldVal: ClockStateResult){
      this.stateOverride = null;
      
    }

    get breaks(){
        return (this.jobShiftLocal && this.jobShiftLocal.ClockState)? this.jobShiftLocal.ClockState.getBreaks():[];
    }

    @Watch('visible')
    visibleChanged(val: boolean, oldVal: boolean) {
        this.logoUrl = "";
        if(val){
            this.setImage();
            this.startTimer();
            this.setDuration();

            const shiftStartTime = moment(this.jobShiftLocal.ShiftStartTime);
            if(!this.getCheckInEvent() && this.currentClockState === 'notStarted' && shiftStartTime.format("YYYY-MM-DD") == moment().format("YYYY-MM-DD")){
                this.checkIntoShift();
            }
        }else{
            this.stopTimer();
        }
    }

    constructor() {
        super();
    }

    startTimer(){
       this.stopTimer();
        this.timerRef = setInterval(()=>{
            this.setDuration();
        }, 1000);
    }

    stopTimer(){
        if(this.timerRef){
            clearTimeout(this.timerRef);
            this.timerRef = null;
        }
    }

    beforeDestroy(){
       this.stopTimer();
    }



    get shift(){
        return this.jobShiftLocal;
    }

    get historicJobShift(){
        const clockState = this.currentClockState.toLowerCase();

        //If the shift start time is within 15 minutes of the current time then return false
        const timeToShiftStart = moment(this.shift.ShiftStartTime).diff(moment(), 'minute');
        if(timeToShiftStart <= 15 && timeToShiftStart >= 0){
            return false;
        }
        return moment(this.shift.ShiftDate) < moment().startOf('day') && (clockState == 'stopped' || clockState == 'notstarted' || clockState == 'submitted');
    }

    get playButtonColor(){
        return this.currentClockState == ClockState.Active?"white":"#EA5876";
    }

    get checkInTime() {
        const checkinEvent = this.getCheckInEvent();

        if(checkinEvent){
            return global.formatDate(checkinEvent.eventTime).format("HH:mm").toString();
        }

        const checkOutEvent = this.getCheckOutEvent();
        if(checkOutEvent){
            return this.jobShiftLocal.ClockState.getTimesheetStartTimeFormatted();
        }

        return "HH:MM";
    }

    get checkOutTime() {
        const checkOutEvent = this.getCheckOutEvent();
        if(checkOutEvent){
            return global.formatDate(checkOutEvent.eventTime).format("HH:mm").toString();
        }
        else if(this.shift.ShiftEndTime && this.shift.ShiftEndTime <= new Date()) {
            return global.formatDate(this.shift.ShiftEndTime).format("HH:mm").toString();
        }
        return "HH:MM";
    }

    get shiftDate(){
        return moment(this.shift.ShiftDate).format("DD/MM/yyyy")
    }

    get daySpan(){
        const checkOutEvent = this.getCheckOutEvent();
        const checkInEvent = this.getCheckInEvent();

        //We only need to do this check if we have both a checkin event and a checkout event
        if(checkInEvent && checkOutEvent){
            const checkInDateTime = moment(checkInEvent.eventTime).startOf('day');
            const checkOutDateTime = moment(checkOutEvent.eventTime).startOf('day');
            
            if(!checkInDateTime.isSame(checkOutDateTime, 'day')){
                return checkOutDateTime.diff(checkInDateTime, 'day');
            }
        }
        return 0;
    }

    getCheckInEvent(){
        if(this.shift && this.shift.ClockState){
            return this.shift.ClockState.getCheckInEvent();
        }
        return null;
    }



    getCheckOutEvent(){
        if(this.shift && this.shift.ClockState){
            return this.shift.ClockState.getCheckOutEvent();
        }
        return null;
    }

    get currentEvent(){

        return (this.shift && this.shift.ClockState)?this.shift.ClockState.currentEvent():null;
    }

    get currentStateClass(){
        
        return this.currentClockState == ClockState.Active?"active":"paused";
    }

    setDuration(){
        if(this.shift && this.shift.ClockState){
            this.currentDuration =this.shift.ClockState.getClockDuration(); 
        }
    }

    get currentClockState(){
        if(this.stateOverride){
            return this.stateOverride;
        }

        let result = ClockState.NotStarted;
        
        if(this.shift && this.shift.ClockState && this.shift.ClockState.hasEvents()){
            const hasCheckInEvent =this.getCheckInEvent() == null?false:true;

            if(hasCheckInEvent || this.shift.ClockState.eventCount() == 1){
                    result = this.shift.ClockState.getClockState();
            }
            else{
                result = ClockState.NotStarted;
            }
        }
        return result;
    }
  

    setImage() {
        if(this.shift.HasLogo){
            TimesheetService.getCompanyLogo(this.shift.PlacementId,100,100,false).then(e=>{
                this.logoUrl = e;
            });
        }
    }

    checkIntoShift(){
        this.$emit("checkInShift", this.shift, new Date());
    }

    checkOutOfShift(){
        if(this.shift.ClockState.hasEvents() && this.shift.ClockState.getClockDuration().minutes < 1 && this.shift.ClockState.getClockDuration().hours < 1){
            this.showAlert(this.$t("alertMessages.checkoutDurationWarning").toString(), this.$t("alertMessages.warning").toString());
        }
        else{
            this.$emit("checkOutShift", this.shift);
        }
    }

    pauseShift(){
        this.currentClockState === ClockState.Active ? this.$emit("shiftpause", this.shift, new Date()) : this.$emit("shiftUnpause", this.shift, new Date());
        if( this.currentClockState === ClockState.Active){
            this.stopTimer();
        }else{
            this.startTimer();
        }
        //this.stateOverride = this.currentClockState === ClockState.Active?ClockState.Paused:ClockState.Active;
    }

    close() {
        this.$emit("closedialog");
    }
    
    showAlert(alertMessage: string, type: string){
        this.messageText = alertMessage;
        this.alertType = type;
        this.message = true;
    }

    closeAlert(){
        this.message = false;
    }
}

