import TimesheetService, { Timesheet, TimesheetResult, TimesheetRequest, TimesheetHour } from "../../../../rdb/components/timesheets/TimesheetService";
import store from "../../../../../store/index";
import { Vue, Component, PropSync,Watch, Mixins } from 'vue-property-decorator'
import moment from "moment";
import LoadingIndicator from "../../../../LoadingIndicator.vue";
import TimesheetSummaryCard from "./TimesheetSummaryCard.vue";
import TimesheetDetails from "./TimesheetDetails.vue";
import TimesheetDateRangePicker from "./TimesheetDateRangePicker.vue";
import { NetworkMixin } from '@/components/mixins/networkMixin';
@Component({
    name:"timesheet",
    components:{LoadingIndicator, TimesheetSummaryCard, TimesheetDetails,TimesheetDateRangePicker},
})

export default class TimesheetView extends Mixins(NetworkMixin) {
    timesheets: TimesheetResult = new TimesheetResult;
    timesheetRequest: TimesheetRequest;
    isLoading = false;
    today: Date;
    timesheetGroups: any = [];
    showNoresults = false;
    showTimesheetDetails = false;
    showTimesheetSubmitted = false;
    closeTimesheetDialog = false;
    selectedTimesheetId = 0;
    @PropSync("closeTopDialog", { type: Boolean }) isCloseDialog!: boolean;
    @PropSync("selectedTimesheet", { type: Number }) selectedTimesheetLocal!: number;
    @PropSync("dateRange", { type: Object,default:{
      start: new Date(),
      end: new Date()
    } }) dateRangeLocal: any;
    @Watch('dateRange')
    dateRangeChanged(val: any, oldVal: any){
      if(val){
        this.timesheetRequest.StartDate = this.getDateUTC(val.start);
        this.timesheetRequest.EndDate = this.getDateUTC(val.end);
        this.selectedTimesheetPeriod = val.period;
        this.loadTimesheets();
      }
    }
    @Watch('closeTopDialog')
    closeDialogChanged() {
        if (this.showTimesheetDetails ) {
          this.closeTimesheetDialog = !this.closeTimesheetDialog;
        } else if (this.showTimesheetSubmitted) {
           this.showTimesheetSubmitted = false;
        }
        else {
           this.$emit('close');
        }
    }



    //selectedTimesheet: Timesheet = null;
    selectedTimesheetPeriod = "Weekly";



    constructor() {
        super();

        this.timesheetRequest = new TimesheetRequest();

        this.today = new Date();
        this.today.setHours(0,0,0,0);

          this.timesheetRequest.StartDate = this.getDateUTC( moment().clone().weekday(1).toDate());
          this.timesheetRequest.EndDate = this.getDateUTC( moment().clone().weekday(7).toDate());
    }

    getDateUTC(date){
      return new Date(moment.utc(moment(date).format("YYYYMMDD")).toDate());
   }

    getShiftDateSummary(timesheetHour){
      
      return `${this.formatTime(timesheetHour.StartTime)} - ${this.formatTime(timesheetHour.EndTime)}`;
    }

    getShiftDateText(timesheetHour){
      
      return  moment(timesheetHour.WorkedOn).isSame(new Date(),'day')? "Today": moment(timesheetHour.WorkedOn).fromNow();
    }

    mounted(){
        this.loadTimesheets();

        if (this.selectedTimesheetLocal > 0 && this.selectedTimesheetId === 0)  {
          this.showTimesheetDetails = true;
          this.selectedTimesheetId = this.selectedTimesheetLocal;
        }
    }

    timesheetloaded() {
      this.$emit('loaded');
    }

    loadTimesheets() {
        this.isLoading = true;

        this.doWork<TimesheetResult>(TimesheetService.timesheetSelect(this.timesheetRequest),this.loadTimesheets).then((res)=>{
          this.timesheets = res;

          this.isLoading = false;
          this.showNoresults = this.timesheets.TimesheetHours.length === 0;
          this.timesheetGroups = this.groups();
        }).catch((e)=>{
           this.showNetworkError = true;
        });
    }

    getTimesheetForHour(timesheetId): Timesheet {
        return this.timesheets.Timesheets.find(x => x.TimesheetId === timesheetId);
    }

    formatDate(arg, format) {
      return moment(arg).format(format);
    }

    formatTime(arg) {
      return moment(arg).format('HH:mm');
    }

    // setGroup(arg) {
    //     debugger;
    //     this.lastGroupDate = arg;
    // }

    groupBy(array, key){
        const result = {};
        array.forEach(item => {
          if (!result[`${item[key]}|${item["TimesheetId"]}`]) {
            result[`${item[key]}|${item["TimesheetId"]}`] = [];
            result[`${item[key]}|${item["TimesheetId"]}`].push(item);
          }
        });
        return result;
      }

      groups(){
          if (this.selectedTimesheetPeriod === "Weekly") {
            return this.groupBy(this.timesheets.TimesheetHours, "WorkedOn");
          }
            return this.groupBy(this.timesheets.TimesheetHours, "PeriodStarting");

      }

      timesheetSelected (timesheetHour: TimesheetHour) {
        const timesheet = this.getTimesheetForHour(timesheetHour.TimesheetId);
        this.showTimesheetDetails = true;
        this.selectedTimesheetId = timesheet.TimesheetId;
      }


      submitTimesheet(timesheetId: number) {
        this.showTimesheetDetails = false;
        TimesheetService.submitTimesheet(timesheetId).then(result => {
            if (result) {
                this.showTimesheetSubmitted = true;
                this.loadTimesheets();
            }
         });

      }
}
