import { Vue, Component, Mixins, Prop, Watch,PropSync } from "vue-property-decorator";
import { NetworkMixin } from '@/components/mixins/networkMixin';
import TimesheetService, { TimesheetExpense, AllowedExpenses, InsertExpenseRequest, UpdateExpenseRequest, DocumentUploadResponse } from "../TimesheetService";
import Alert from "@/components/Alert.vue";
import { NotificationMixin } from '@/components/mixins/notificationMixin';

@Component({
  name: "TimesheetExpenseView",
  components:{Alert}

})
export default class TimesheetExpenseView extends Mixins(NetworkMixin, NotificationMixin)  {
@Prop() timesheetExpenseId: number;
@Prop() timesheetId: number;
@Prop() visible: boolean;

@Watch("visible")
onvVsibleChanged(){
  if(this.visible){
    this.setDefault();
     (this.$refs.form as any).resetValidation();
      this.load(); 
  }
}

timesheetExpense = null;
selectedPlacementExpenseId: number;
currencySymbol = "£";
allowedExpenses: AllowedExpenses[] = [];
valid =false;
showErrorDialog = false;
showAddReceiptDialog = false;
isSaving = false;
uploadFieldName = "Expenses";
formData: FormData;
componentKey = 0;
imageUrl = "";
showReceiptDialog = false;
messageText = "";
documentDeleted = false;

requiredRule = [
  value => !!value || 'Required.'
];

numberRules = [
  value => !!value || 'Required.',
  value => value >=0.01 || 'Value must be greater than 0.01'
];

constructor() {
    super();
  }

  
  setDefault(){
    this.selectedPlacementExpenseId = 0;
    const timsheetExpense = new TimesheetExpense;
   
    timsheetExpense.AllowedToEdit = true;
    timsheetExpense.TimesheetExpenseId = 0;
    timsheetExpense.PayRate  = 0;
    timsheetExpense.Note  = '';
    timsheetExpense.Quantity  = 1;
    this.timesheetExpense= timsheetExpense;
    this.imageUrl = "";
    this.formData = null;
  }

  beforeMount(){
     this.setDefault();
  }

  load() {
    if(this.timesheetExpenseId && this.timesheetExpenseId > 0){
      this.doWork(TimesheetService.getExpense(this.timesheetExpenseId)).then((result)=> {
        this.timesheetExpense = result;
      });
    }
    else{
      this.loadAllowedExpenses();
    }
  }

  loadExpense() {
    this.doWork(TimesheetService.getExpense(this.timesheetExpenseId)).then((result)=> {
      this.timesheetExpense = result;
    });
  }

  loadimage() {
    if (this.timesheetExpense.SupportingDocumentId > 0) {
      this.doWork(TimesheetService.getExpenseDocument(this.timesheetExpenseId)).then((result)=> {
        if (result.substr(0, 10) === "data:image") {
          this.imageUrl = result;
        }
      });
    }
  }

  loadAllowedExpenses() {
    this.doWork(TimesheetService.getAllowedExpenses(this.timesheetId)).then((result)=> {
      result.forEach(i=> {
        let currencySymbol = "";
        if (i.CurrencySymbol !== null) {
          currencySymbol = i.CurrencySymbol;
       }
       i.Description =`${i.Description} - ${currencySymbol}${i.PayRate}`;
      });
      this.allowedExpenses = result;

      //this.selectedExpense = this.allowedExpenses.filter(i => i.InvoiceLineItemCodeId == this.timesheetExpense.InvoiceLineItemCodeId)[0];
    });
  }

  saveExpenseClicked(){
    const isValid  = (this.$refs.form as any).validate();
    if(isValid) {
      if (this.documentDeleted) {
          TimesheetService.deleteExpenseDocument(this.timesheetExpense.TimesheetExpenseId).then((deleteResponse)=> {
            if(deleteResponse.Success) {
                 this.documentDeleted = false;
                 this.saveExpense();
              } else {
                  this.toastError(this.$t("failedToRemove").toString());
              }
          }).catch((e)=> {
              this.toastError(this.$t("failedToRemove").toString());
          });
      } else {
        this.saveExpense();
      }
    }
  }

  expenseTypeSelected(){
    const rate = this.allowedExpenses.filter(i=> i.PlacementExpenseId === this.selectedPlacementExpenseId)[0].PayRate;
    this.timesheetExpense.PayRate = rate;
    this.$forceUpdate();
  }

  payRateChanged(){
    console.log("Payrate changed");
    this.$forceUpdate();
  }

  cancelButtonClicked(){
    this.$emit("cancelled");
  }

  removeExpenseDocClicked() {
      this.confirm(this.$t("timesheets.removeExpenseDocMessage").toString(),this.$t("confirm").toString()).then((result) => {
        if(result) {
          if (this.timesheetExpense.SupportingDocumentId !== -1) {
            this.documentDeleted = true;
            this.showReceiptDialog = false;
            this.imageUrl = "";
          }
          this.timesheetExpense.SupportingDocumentId = 0;
          this.timesheetExpense.SupportingDocumentFileExtension = "";
          this.timesheetExpense.SupportingDocumentDescription = "";
        }
    });
  }

  saveExpense(){
    if(!this.timesheetExpense.TimesheetExpenseId || this.timesheetExpense.TimesheetExpenseId == 0){

      const insertRequest = new InsertExpenseRequest;
      insertRequest.Note = this.timesheetExpense.Note;
      insertRequest.Quantity = this.timesheetExpense.Quantity;
      insertRequest.TimesheetId =this.timesheetId;
      insertRequest.PlacementExpenseId =this.selectedPlacementExpenseId
      insertRequest.PayRate= this.timesheetExpense.PayRate;
      this.doWork(TimesheetService.insertExpense(insertRequest),this.saveExpense, this.$t("saving").toString()).then((result)=>{
          if(result.Success) {
             this.timesheetExpense.TimesheetExpenseId = result.TimesheetExpenseId;
             if (!this.formData){
                this.$emit("expenseSaved");
                this.setDefault();
             } else {
              this.saveDocument();
             }
           }else{
            this.messageText = this.$t('updateFailed').toString();
            this.showErrorDialog = true;
           }
      }).catch((e)=>{
            this.messageText = this.$t('updateFailed').toString();
            this.showErrorDialog =true;
      });

    } else {
      const  updateRequest: UpdateExpenseRequest = {
        Note: this.timesheetExpense.Note,
        Quantity:this.timesheetExpense.Quantity,
        TimesheetExpenseId:this.timesheetExpense.TimesheetExpenseId,
        TimesheetId:this.timesheetId,
        PayRate : this.timesheetExpense.PayRate }
        this.doWork(TimesheetService.updateExpense(updateRequest),this.saveExpense, this.$t("saving").toString()).then((result)=>{
            if(result.Success){
              if (!this.formData){
                this.$emit("expenseSaved");
                this.setDefault();
             } else {
              this.saveDocument();
             }
            }else{
              this.showErrorDialog = true;
            }
        }).catch((e)=>{
          this.showErrorDialog =true;
        });
    }
  }

    mounted(){
      this.load();
    }

    addReceiptClicked(){
      this.showAddReceiptDialog = true;
    }

    selectAFileClicked(fieldName, fileList) {
      if (!fileList.length) return;

      if(!this.isValid(fileList[0].name)){
        this.showErrorDialog = true;
        this.messageText = this.$t('alertMessages.fileTypeError').toString();
      }
      else{
        // handle file changes
        this.formData = new FormData();

        const re = /(?:\.([^.]+))?$/;

        // append the files to FormData
        Array
        .from(Array(fileList.length).keys())
        .map(x => {
          this.resizeImage(fileList[0]).then(result => {
            this.formData.append(fieldName, (result as File), fileList[x].name);
            this.timesheetExpense.SupportingDocumentId = -1;
            this.timesheetExpense.SupportingDocumentDescription = fileList[x].name;
            this.timesheetExpense.SupportingDocumentFileExtension = re.exec(fileList[x].name)[1];
          });
        });
        this.componentKey++;
        this.showAddReceiptDialog = false;
      }
    }

    //Adapted from: https://jsfiddle.net/ascorbic/wn655txt/2/
    resizeImage(file: File, maxWidth = 800, maxHeight = 600){
      return new Promise((resolve, reject) => {
        const validTypes = ['image/gif', 'image/jpeg', 'image/png'];
        if (validTypes.includes(file.type)){
          const image = new Image();
          image.src = URL.createObjectURL(file);
          image.onload = () => {
            const width = image.width;
            const height = image.height;

            //If the existing image wdth and height are already below our max then resolve with the current image
            if(width <= maxWidth && height <= maxHeight){
              resolve(file);
            }

            const newWidth = width > height ? maxWidth : width * (maxHeight / height);
            const newHeight = width > height ? height * (maxWidth / width) : maxHeight;

            const canvas = document.createElement('canvas');
            canvas.width = newWidth;
            canvas.height = newHeight;
  
            const context = canvas.getContext('2d');
            context.drawImage(image, 0, 0, newWidth, newHeight);
  
            canvas.toBlob(resolve, file.type);
          };

          image.onerror = reject;
        }
        //The uploaded file is not an image so just return it as normal
        else{
          resolve(file);
        }
      });     
    }

    selectAPhotoClicked(){
      this.showAddReceiptDialog = false;
    }

    takeAPhotoClicked(){
      this.showAddReceiptDialog = false;
    }

    showFileClicked(){
      if (this.timesheetExpense.SupportingDocumentId > 0) {
        this.doWork(TimesheetService.getExpenseDocumentBlob(this.timesheetExpenseId)).then((result)=> {
             const link = document.createElement('a');
            link.href = window.URL.createObjectURL(result);
            link.download = this.timesheetExpense.SupportingDocumentDescription;
            link.click();
        });
      }
    }

    saveDocument() {
      this.doWork(TimesheetService.expenseDocumentUpload(this.formData, this.timesheetExpense.TimesheetExpenseId),
        this.saveDocument, this.$t("saving").toString()).then((result)=> {
          if(result.DocumentId > 0) {
              this.$emit("expenseSaved");
              this.setDefault();
          }else{
            this.showErrorDialog = true;
            this.messageText = this.$t('updateFailed').toString();
          }
      }).catch((e)=>{
        this.showErrorDialog =true;
        this.messageText = this.$t('updateFailed').toString();

      });
    }

    retrieveFileIcon(fileExtension: string){
      switch(fileExtension){
          case ".pdf":
              return "mdi-file-pdf";
          case ".docx":
          case ".doc":
            return "mdi-microsoft-word";
          case ".png":
          case ".jpg":
          case ".jpeg":
          case ".gif":
          case ".tif":
            return "mdi-file-image";
          default:
              return "mdi-file";
      }
  }

  isValid(fileName: string){
    const allowedExtensions = /(\.doc|\.docx|\.pdf|\.jpg|\.jpeg|\.png|\.tif|\.tiff|\.odt|\.wps|\.rtf)$/i;
    if(allowedExtensions.exec(fileName)){
      return true;
    } 
    return false;
  }
}
