import { Vue, Component, PropSync,Watch, Mixins } from 'vue-property-decorator'
import JobCards from './JobCards.vue'
import JobService,{ JobSearchResult,Job} from "../../../../rdb/components/jobs/JobService";
import store from "@/store/index";
import locationServices, { TraveltimeRequest } from "@/utils/locationServices";
import { NetworkMixin } from '@/components/mixins/networkMixin';
import { JobMixin } from '@/components/mixins/jobMixin';
import { EventBus } from '@/utils/events';
import JobResultsSummary from "./JobResultSummary.vue";
import JobNoResults from "./JobNoResults.vue";

@Component({
  name:"swipe-view",
  components:{JobCards,JobResultsSummary,JobNoResults} 
})
export default class SwipeView extends Mixins(NetworkMixin,JobMixin) {

  startInteraction = "ss";
  cards = [];
  removedCards =[];
  isRemoving = false;
  excludeButtonClass = "big-button exclude-job";
  shortListButtonClass = "big-button heart-button short-list-job";
  notAvailableText = "N/A";
  dismissedCount = 0;
  constructor() {
        super();
    }

    cardSelected(e){
        this.$emit("showJob",e.card);
    }

    async setTravelTimes(){
      const job = this.cards[0];
      if(store.getters.AppState.jobSearchRequest.Latitude !== 0 && store.getters.AppState.jobSearchRequest.Longitude !== 0 && store.getters.AppState.featureEnabled("RCRMGOOGLEMAPS")){

          if(job.Latitude !== 0 && job.Longitude !== 0){
            const args: TraveltimeRequest = {
                start:{
                  lat:store.getters.AppState.jobSearchRequest.Latitude,
                  lng:store.getters.AppState.jobSearchRequest.Longitude
                },
                destination:{
                  lat:job.Latitude,
                  lng:job.Longitude
                }
            };

          locationServices.getCarTravelTime(args).then(result=>{
            job.carTime = result.travelTimeText;
            Vue.set(this.cards,0,this.cards[0]);
          });

          locationServices.getTrainTravelTime(args).then(result=>{
            job.trainTime = result.travelTimeText;
            Vue.set(this.cards,0,this.cards[0]);
          });

          locationServices.getBusTravelTime(args).then(result=>{
            job.busTime = result.travelTimeText;
            Vue.set(this.cards,0,this.cards[0]);
          });

          locationServices.getWalkingTravelTime(args).then(result=>{
            job.walkTime = result.travelTimeText;
            Vue.set(this.cards,0,this.cards[0]);
          });

          locationServices.getBicycleTravelTime(args).then(result=>{
            job.cycleTime = result.travelTimeText;
            Vue.set(this.cards,0,this.cards[0]);
          });
        }
      }
    }


    createCardsFromResults(){
      if(this.jobSearchResult && this.jobSearchResult.Jobs){
          if((this.jobSearchResult.PageInfo && this.jobSearchResult.PageInfo.Page  == 1)){
            this.dismissedCount = 0;
            this.cards = [];
            this.removedCards = this.isRemoving?this.removedCards:[];
          }

          this.jobSearchResult.Jobs.forEach(i =>{
            if(i.IsShortlisted !== true && !i.IsExcluded){
              if(this.cards.filter(m => m.JobId == i.JobId).length == 0 && this.removedCards.filter(m => m.JobId == i.JobId).length == 0){
                i.busTime = "";
                i.trainTime = "";
                i.carTime = "";
                i.walkTime = "";
                i.cycleTIme = "";
                this.cards.push(i);
              }
            }
          });
          if(this.cards.length > 0){
            this.setTravelTimes();
          }
          this.isRemoving = false;
      }
    }


    beforeDestroy(){
      EventBus.$off("LoadSearchResults",this.loadSearchResult);
    }
  
    mounted(){
      EventBus.$on("LoadSearchResults",this.loadSearchResult);
      this.loadSearchResult();
      this.notAvailableText = this.$t("jobs.swipeViewTravelDistanceNA").toString();
    }

    

    left(){
      this.startInteraction = "cardLeft";
    }

    right(){
      this.startInteraction = "cardRight";
    }

    down(){
      this.startInteraction = "cardDown";
    }

    up(){
      this.startInteraction = "cardUp";
    }

    restore(){
      if(this.removedCards.length > 0) {

        this.totalResults++;
          if(this.removedCards[0].removalReason === "exclude")
          {
            this.removeExcludedJob(this.removedCards[0]);
          }

          if(this.removedCards[0].removalReason === "shortlist")
          {
            this.removeShortlisted(this.removedCards[0]);
          }
          if(this.removedCards[0].removalReason === "dismiss")
          {
            this.dismissedCount--;
          }
          

          this.removedCards[0].removalReason = "";

        (this.removedCards[0] as any).isRestoring = true;
        this.cards.unshift(this.removedCards[0]);
        this.removedCards.splice(0,1);
        setTimeout(() => {
          this.startInteraction = "cardRestore";
          setTimeout(() => {
            this.startInteraction = "";
          }, 0);
        }, 0);
      }
    }

    cardMoved(e){
      if(e.interactPostion.x == 0){
        this.excludeButtonClass = "big-button exclude-job";
        this.shortListButtonClass = "big-button heart-button short-list-job";
      }
      else
      if(e.interactPostion.x > 1){//Shortlist
        this.excludeButtonClass = "big-button exclude-job";
        this.shortListButtonClass = "big-button shortlist-active heart-button short-list-job";
      }
      else if(e.interactPostion.x < -2){//Exclude
        this.excludeButtonClass = "big-button exclude-active exclude-job";
        this.shortListButtonClass = "big-button heart-button short-list-job";
      }
    }

    handleCardLeft(job) {
      this.isRemoving = true;

      this.startInteraction = "";
      job.removalReason = "exclude";

      this.excludeJob(job);
      this.jobSearchResult.PageInfo.TotalRecords--;
      //this.removedCards.unshift(this.cards[0]);
      //this.cards.shift();
      //this.isRemoving = false;
    }

    handleCardRight(job) {
      this.startInteraction = "";
      job.removalReason = "shortlist";
      this.setShortlisted(job);

    }

    handleCardDown() {
      this.startInteraction = "";
    }

    handleCardUp(job) {
      this.startInteraction = "";
      job.removalReason = "dismiss";
      this.dismissedCount++;
    }

    async cardChanged(){
      if(this.cards[0].carTime === ""){
        this.setTravelTimes();
      }
    }

    removeCardFromDeck() {
      this.totalResults--;
      this.startInteraction = "";
      this.removedCards.unshift(this.cards[0]);
      this.cards.shift();

      if(this.cards.length == 4){
        this.loadNextPage();
      }

      
        this.excludeButtonClass = "big-button exclude-job";
        this.shortListButtonClass = "big-button heart-button short-list-job";
      
    }

    loadSearchResult(pageNo = 1) {
      this.jobSearchRequest.Page = pageNo;
      this.jobSearchRequest.PageSize = 10;
      this.jobSearchRequest.IncludeExcludedJobs = false;
      this.jobSearchRequest.IncludeShortListedJobs = false;
      this.doWork<JobSearchResult>(JobService.searchJobs(this.jobSearchRequest),
      this.loadSearchResult)
      .then((res) =>{
        this.jobSearchRequest.IncludeExcludedJobs = false;
        if(pageNo !== 1){
          this.jobSearchResult.PageInfo = res.PageInfo;
          res.Jobs.forEach(item => this.jobSearchResult.Jobs.push(item));
          this.createCardsFromResults();
        }
        else{
          this.jobSearchResult = res;
          this.searchLoaded();
          this.createCardsFromResults();
          //Check if we ave any cards left after removing shorlisted and excluded jobs
          if(this.cards.length <= 4 && this.jobSearchResult.PageInfo.HasNextPage){
            this.loadSearchResult(this.jobSearchResult.PageInfo.Page+1);

          }
          // else{
          //   this.totalResults = this.jobSearchResult.PageInfo.TotalRecords;
          // }
        }
        this.totalResults = this.jobSearchResult.PageInfo.TotalRecords - this.dismissedCount;
      });
    }
//still a bug if a card is skipped and then paging happens, re-adss the skipped cards
    loadNextPage(){
      if(this.jobSearchResult.PageInfo.HasNextPage){
        this.loadSearchResult(this.jobSearchResult.PageInfo.Page+1);
      }
    }
}
