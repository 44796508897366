import { Vue, Component } from "vue-property-decorator";
import { QrcodeStream, QrcodeDropZone, QrcodeCapture } from "vue-qrcode-reader";
import LoadingIndicator from "../../../../LoadingIndicator.vue";
@Component({
  name: "QRCode-Scan",
  components: {
    LoadingIndicator,
    QrcodeStream,
    QrcodeDropZone,
    QrcodeCapture,
    
  },
})
export default class QRCodeScan extends Vue {
  results = "";
  resultError = "";
  errors = "";
  noStreamApiSupport = false;
  camera = "auto";
  showvideo = false;
  isLoadingLocal = true;
 
  constructor() {
    super();
  }

  close()
  {
    this.$emit("close");
  }

  async onDetect(promise) {
    try {
      const {
        imageData, // raw image data of image/frame
        content, // decoded String
        location, // QR code coordinates
      } = await promise;
     
      if (content === null) {
        this.resultError = "Sorry not a valid QR Code";
      }
      
      // ...
    } catch (error) {
      // ...
    }
  }

  

  async onDecodeScan(result) {
    this.camera = 'off';
    await this.timeout(600);
    this.results = result;
    this.$emit("result", result)
    
  }

  timeout (ms) {
    return new Promise(resolve => {
      window.setTimeout(resolve, ms)
    })
  }

  onDecodeFile(result) {
    this.resultError = ""
    this.results = result;
    this.$emit("result", result)
  }

  async onInit(promise) {
    
    try {
     
      await promise
      this.showvideo = true
    } catch (error) {
      //Not currently doing anything with the error message - 
      //
      if (error.name === "NotAllowedError") {
        this.errors = "ERROR: you need to grant camera access permisson";
        
      } else if (error.name === "NotFoundError") {
        this.errors = "ERROR: no camera on this device";
        
      } else if (error.name === "NotSupportedError") {
        this.errors = "ERROR: secure context required (HTTPS, localhost)";
        
      } else if (error.name === "NotReadableError") {
        this.errors = "ERROR: is the camera already in use?";
        
      } else if (error.name === "OverconstrainedError") {
        this.errors = "ERROR: installed cameras are not suitable";
        
      } else if (error.name === "StreamApiNotSupportedError") {
        this.errors = "ERROR: Stream API is not supported in this browser";
      }
      this.showvideo = false
      this.noStreamApiSupport = true
    }
    finally
    {
      this.isLoadingLocal = false;
    }
  }
}
