import Vue from 'vue'
import Component from 'vue-class-component'
import store from '@/store'
import UserLoginService , {VerifyEmailResponse} from '@/services/userLogin'
import LoadingIndicator from "@/components/LoadingIndicator.vue"
@Component({
    components:{LoadingIndicator}
  })
export default class RegisterConfirm extends Vue {

    loadingMessageText: string;
    successMessage = "";
    errorMessage = "";
    emailVerified = false;
    redirectUri: string;
    isLoading=false;
    showVerifyButton = true;
    constructor() {
        super();
    }

    beforeMount(){
        this.loadingMessageText = this.$t("registerConfirm.loadingText").toString();
    }


    onVerifyClick(){
        this.showVerifyButton = false;
        const verificationCode = this.$route.query.code? this.$route.query.code.toString():"";
        const id = this.$route.query.id? this.$route.query.id.toString():"";
        
        if(verificationCode && id){
            this.verifyCode(verificationCode,id);
        }else{
             this.isLoading = false;
             this.errorMessage = this.$t("registerConfirm.failedToVerify").toString();
        }
    }

    verifyCode(code: string,id: string){
        this.isLoading = true;
        UserLoginService.verifyEmailAddress(id,code).then(result =>{
            this.isLoading = false;                        
            if(result.success){
                this.emailVerified = result.success;
                this.redirectUri = result.redirectUri;
                if(result.alreadyConfirmed){
                    this.successMessage = this.$t("registerConfirm.accountAlreadyConfirmed").toString();
                }else{
                    this.successMessage = this.$t("registerConfirm.verifiedSuccessFully").toString();
                }
            }else{ 
                if(result.alreadyConfirmed){
                    this.successMessage = this.$t("registerConfirm.accountAlreadyConfirmed").toString();
                }else{
                    this.errorMessage = result.errorMessage;
                }
            }

        }).catch((e)=>{
            this.isLoading = false;    
            this.errorMessage = this.$t("registerConfirm.failedToVerify").toString();
        });
    }

    redirect(){
        if(this.redirectUri){
            window.location.href =this.redirectUri;
        }else{
            this.$router.push("/");
        }
    }
}


