<template>
    <div class="pa-2 summary-container">
     <v-row no-gutters>
        <v-col cols="2" sm="2" md="1" >
          <div class="rounded img-round logo-image">
            <img v-if="!this.TimesheetLocal.HasLogo"  alt="" src="@/assets/logo.png" height="30px" width="36px">
            <img v-if="logoUrl !== ''"  alt="" :src="logoUrl" height="36px" width="36px">
          </div>
        </v-col>
        <v-col cols="7" sm="7" md="7" class="details-container">
          <div class="d-flex justify-space-between">
            <div class="text-h1" :data-Jobtitle="TimesheetLocal.JobTitle">{{TimesheetLocal.JobTitle}}
            </div>
          </div>          
          <div class="text-subtitle-1 padding-2 companyName" :data-companyname="TimesheetLocal.CompanyName">{{TimesheetLocal.CompanyName}}</div>
          <div class="text-subtitle-2 grey--text text--darken-2 pt-1" :data-location="TimesheetLocal.Location">{{TimesheetLocal.Location}}</div>
        </v-col>
         <v-col cols="3" sm="3" md="1" class="centered" >
          <div class="ma-auto">
            
            <img  alt="" :class="statusClass"  height="auto" width="30px" />
          </div>
           <div class="text-subtitle-1 padding-2" :data-timesheetstatus="TimesheetLocal.StatusDescription">{{TimesheetLocal.StatusDescription}}</div>
         
        </v-col>
      </v-row>  
    </div>
</template>

<script lang="ts">
    import ViewModel from './TimesheetSummaryCard'  
    export default ViewModel;
</script>



<style lang="scss" scoped>
  .logo-image{
    height:38px;
    width:38px;
  }


  .companyName{
    font-size: 14px!important;
    color: #000000;
    line-height: 20px;
  }

  .pending-icon {
    content:url('../../../../../assets/img/timesheets/status/pending.png');
  }

  .approved-icon {
    content:url('../../../../../assets/img/timesheets/status/approved.png');
  }

  .query-icon {
    content:url('../../../../../assets/img/timesheets/status/query.png');
  }

  .cancel-icon {
    content:url('../../../../../assets/img/timesheets/status/cancel.png');
  }

  .payslip-icon {
    content:url('../../../../../assets/img/timesheets/status/payslip.png');
  }

</style>