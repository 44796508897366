import Discovery, { DiscoveryResponse, DiscoveryEndpoint, DiscoveryEndpointAdditionalInfo,DiscoveryFeature } from '@/services/discovery';
import localStorageService from './localStorageService';
import mainAuth from '../auth';
import { JobSearchRequest } from "../components/rdb/components/jobs/JobService";
import { DiaryEventsRequest } from '@/components/rdb/components/availability/DiaryService';

export enum MapType{
  Google,
  HereMaps,
  None
}

export type AppStateType = {
  discovery: DiscoveryResponse;
  initialised: false;
  accessToken: string;
  userName: string;
  emailAddress: string;
  isAuthenticated: boolean;
  jobSearchRequest: JobSearchRequest;
  diaryEventsRequest: DiaryEventsRequest;
  timesheetOnboardingStatus: boolean;
  onBoarded: boolean;
  installAppExpiry: Date;
  isOnline: boolean;
  profileSaved: boolean;
  hasLoggedIn: boolean;
  firstName: string;
  lastUpdateCheck: Date;
  checkForUpdate: boolean;
  hasAcceptedTerms: boolean;
  jobOffers: number;
  mapService: MapType;
}

export default {
  namespaced: true as true,
  state: {
    discovery: null,
    jobSearchRequest: new JobSearchRequest(),
    diaryEventsRequest: new DiaryEventsRequest(),
    timesheetOnboardingStatus: false as false,
    onBoarded: false as boolean,
    installAppExpiry: null as Date,
    isOnline: navigator.onLine,
    profileSaved: false,
    hasLoggedIn: false,
    lastUpdateCheck: new Date(),
    checkForUpdate: false,
    firstName: null as string,
    hasAcceptedTerms: false as boolean,
    jobOffers: null as number,
    mapService: MapType.Google
  } as AppStateType,
  getters: {
    accessToken: (state): string => mainAuth.client().accessToken,
    isAuthenticated: (state): boolean => mainAuth.client().isAuthenticated,
    url: (state): string => mainAuth.client().appUrl,
    userName: (state): string => mainAuth.client().userProfile.name,
    emailAddress: (state): string => mainAuth.client().userProfile.email,
    discovery: (state): DiscoveryResponse => state.discovery,
    jobSearchRequest: (state): JobSearchRequest => state.jobSearchRequest,
    diaryEventsRequest: (state): DiaryEventsRequest => state.diaryEventsRequest,
    timesheetOnboardingStatus: (state): boolean => state.timesheetOnboardingStatus,
    onBoarded: (state): boolean => state.onBoarded,
    installAppExpiry: (state): Date => state.installAppExpiry,
    firstName: (state): string => state.firstName,
    lastUpdateCheck: (state): Date => state.lastUpdateCheck,
    checkForUpdate: (state): boolean => {
      if( state.lastUpdateCheck){
        const today = new Date();
        const lastUpdate = state.lastUpdateCheck as Date;
        const diffMs = (lastUpdate.getTime() - today.getTime()); 
        const diffMins = Math.round(((diffMs % 86400000) % 3600000) / 60000); // minutes
        
        return diffMins >= 5;
      }
     
      return true;
    
    },
    hasAcceptedTerms: (state): boolean => state.hasAcceptedTerms,
    endPoint: (state) => {
      return (endPointName): DiscoveryEndpoint => state.discovery.endpoints.filter(item => {
        return item.name === endPointName
      })
    },
    endPointAdditionalInfo: (state: AppStateType) => {
      return (endPointName, key): DiscoveryEndpointAdditionalInfo => {

        return state.discovery.endpoints.filter(item => {
          return item.name === endPointName
        })[0].additionalInfo.filter(item => {
          return item.key === key
        })[0];

      }
    },
    feature: (state: AppStateType) => {
      
      return (uniqueId): DiscoveryFeature => state.discovery.features.filter(item => {
        return item.uniqueId === uniqueId
      })[0]
    },
    featureEnabled: (state: AppStateType,uniqueId) => {
      return (uniqueId): boolean => { 

        const features = state.discovery.features.filter(item => {
          return item.uniqueId === uniqueId
        });

        if(features.length  > 0){
          return features[0].permission == "HasAccess";
        }

        return false;
      }
    },
    isOnline: (state): boolean => navigator.onLine,
    profileSaved: (state): boolean => state.profileSaved,
    hasLoggedIn: (state): boolean => state.hasLoggedIn,
    jobOffers: (state): number => state.jobOffers, 
    mapService: (state): MapType => state.mapService },
  mutations: {
    UpdateDiscovery(state, discovery: DiscoveryResponse) {
      state.discovery = discovery;
    },
    UpdateJobSearchRequest(state, jobSearchRequest: JobSearchRequest) {
      state.jobSearchRequest = jobSearchRequest;
    },
    UpdateDiaryEventsRequest(state, diaryEventsRequest: DiaryEventsRequest){
        state.diaryEventsRequest = diaryEventsRequest;
    },
    UpdateTimesheetOnboardingStatus(state, val: boolean) {
      state.timesheetOnboardingStatus = val;
    },
    UpdateOnBoarded(state, val: boolean)
    {
      state.onBoarded = val;
    },
    UpdateInstallAppExpiry(state, val: Date)
    {
      state.installAppExpiry = val;
    },
    UpdateProfileSaved(state, val: boolean)
    {
      state.profileSaved = val;
    },
    UpdateHasLoggedIn(state, val: boolean)
    {
      state.hasLoggedIn = val;
    },
    UpdateFirstName(state, val: string)
    {
      state.firstName = val;
    },
    UpdateLastUpdateCheck(state, date: Date){
      state.lastUpdateCheck = date;
    },
    UpdateHasAcceptedTerms(state, val: boolean)
    {
      state.hasAcceptedTerms = val;
    },
    
    UpdateJobOffers(state, val: number)
    {
      state.jobOffers = val;

    },
    UpdateMapService(state, val: MapType)
    {
      state.mapService = val;
    }
  },
  actions: {
    initialiseStore(state) {
 
      
      const result = new Promise<any>((success,reject)=>{

        const keys = Object.keys(state.state);
        const store = localStorageService.getStore("state");
        

        const promise = Promise.all(keys.map((key) => {
        
            return store.getItem(key).then((dbItem)=>{
              if(dbItem){
                state.state[key] = dbItem;
                return Promise.resolve();
              }else{
                return store.setItem(key,state.state[key]);
              }

            }).catch((e)=>{
              return Promise.reject();
            });
            
        })).then((r)=>{
              success();
          }).catch((e)=>{
            console.log(e);
          });
      });

      return result;
    },
    getAccessToken(){

      return  mainAuth.client().getAccessToken(false);
       
    },
    getAccessTokenForceRefresh(){

      return  mainAuth.client().getAccessToken(true);
       
    }
  }
};