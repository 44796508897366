
import HereMaps from '@here/maps-api-for-javascript'
import { LocationService,TraveltimeRequest,TravelTimeResponse,TextSearchResponse } from './locationServices';
import axios, { AxiosInstance } from "axios";
import errorLogger from '@/utils/errorLogger';
import moment, { Moment } from 'moment';

export class HereMapsHelper implements LocationService {

    platform: HereMaps.service.Platform;
    notAvailableText = "N/A";

    getApiKey(){
        return "yyiRaUdZ8umEEQWM5XX6xdAt80sx8Gtd_rzFErEF5wI";
    }

    initialise(){

        return new Promise<any>((s,r)=>{

            this.platform = new HereMaps.service.Platform({
                apikey:this.getApiKey()
            });
            s();
        });
    }

    textSearch(location: string): Promise<TextSearchResponse[]> {
        const results: TextSearchResponse[] = [];
        const url = `https://autocomplete.geocoder.ls.hereapi.com/6.2/suggest.json?apiKey=${this.getApiKey()}&query=${encodeURI(location)}`

        return new Promise<TextSearchResponse[]>((s,e)=>{
            axios.get(url).then(result=>{

                result.data.suggestions.forEach(i=>{
                 results.push({lat:0,lng:0,locationText:i.label,id:i.locationId});
                });

                s(results);

            }).catch((e)=> {
                errorLogger.logError(e);
                e(e);
            });
        });
    }

    getGeoCodeData(locationId: string): Promise<TextSearchResponse> {
        const result = new TextSearchResponse();
        const url = `https://geocoder.ls.hereapi.com/6.2/geocode.json?locationid=${locationId}&jsonattributes=1&gen=9&apiKey=${this.getApiKey()}`

        return new Promise<TextSearchResponse>((s,e)=>{
            axios.get(url).then(response=>{

                const location  = result.locationText = response.data.response.view[0].result[0].location;
                const address = result.locationText = response.data.response.view[0].result[0].location.address;
                result.lat = location.displayPosition.latitude;
                result.lng = location.displayPosition.longitude;
                result.locationText = address.label;

                s(result);

            }).catch((e)=>{
                errorLogger.logError(e);
                e(e);
            });
        });
    }

    getCarTravelTime(args: TraveltimeRequest): Promise<TravelTimeResponse> {
        const result = new TravelTimeResponse();
        const url = `https://route.ls.hereapi.com/routing/7.2/calculateroute.json?apiKey=${this.getApiKey()}&waypoint0=geo!${args.start.lat},${args.start.lng}&waypoint1=geo!${args.destination.lat},${args.destination.lng}&mode=fastest;car`;

        return new Promise<TravelTimeResponse>((s,e)=> {
            axios.get(url).then(response=> {

            if(response.data.response.route[0]) {
                result.travelTime = response.data.response.route[0].summary.travelTime;
                result.travelTimeText = response.data.response.route[0].summary.text.split("\"time\">")[1].replace("</span>","");
            } else {
                result.travelTime = 0;
                result.travelTimeText = this.notAvailableText;
            }
            s(result);

            }).catch((e)=> {
                errorLogger.logError(e);
                e(e);
            });
        });
    }
    getBusTravelTime(args: TraveltimeRequest): Promise<TravelTimeResponse> {
        const result = new TravelTimeResponse();
        const url = `https://transit.router.hereapi.com/v8/routes?apikey=${this.getApiKey()}&modes=subway,bus&origin=${args.start.lat},${args.start.lng}&destination=${args.destination.lat},${args.destination.lng}`;

        return new Promise<TravelTimeResponse>((s,e)=> {
            axios.get(url).then(response=> {
            if(response.data.routes[0]) {

                const startTime = response.data.routes[0].sections[0].departure.time;
                const endTime = response.data.routes[0].sections[response.data.routes[0].sections.length-1].arrival.time;

                const duration = moment.duration((moment(endTime).diff(startTime)));

                result.travelTime = duration.asMinutes();
                result.travelTimeText = result.travelTime.toString() + " mins";
            } else {
                result.travelTime = 0;
                result.travelTimeText = this.notAvailableText;
            }
            s(result);

            }).catch((e)=> {
                errorLogger.logError(e);
                e(e);
            });
        });
    }
    getTrainTravelTime(args: TraveltimeRequest): Promise<TravelTimeResponse> {
        const result = new TravelTimeResponse();
        const url = `https://transit.router.hereapi.com/v8/routes?apikey=${this.getApiKey()}&origin=${args.start.lat},${args.start.lng}&destination=${args.destination.lat},${args.destination.lng}`;

        return new Promise<TravelTimeResponse>((s,e)=> {
            axios.get(url).then(response=> {

            if(response.data.routes[0]) {

                const startTime = response.data.routes[0].sections[0].departure.time;
                const endTime = response.data.routes[0].sections[response.data.routes[0].sections.length-1].arrival.time;

                const duration = moment.duration((moment(endTime).diff(startTime)));

                result.travelTime = duration.asMinutes();
                result.travelTimeText = result.travelTime.toString() + " mins";
            } else {
                result.travelTime = 0;
                result.travelTimeText = this.notAvailableText;
            }
            s(result);

            }).catch((e)=> {
                errorLogger.logError(e);
                e(e);
            });
        });
    }
    getBicycleTravelTime(args: TraveltimeRequest): Promise<TravelTimeResponse> {
        const result = new TravelTimeResponse();
        const url = `https://route.ls.hereapi.com/routing/7.2/calculateroute.json?apiKey=${this.getApiKey()}&waypoint0=geo!${args.start.lat},${args.start.lng}&waypoint1=geo!${args.destination.lat},${args.destination.lng}&mode=fastest;bicycle`;

        return new Promise<TravelTimeResponse>((s,e)=> {
            axios.get(url).then(response=> {

            if(response.data.response.route[0]) {
                result.travelTime = response.data.response.route[0].summary.travelTime;
                result.travelTimeText = response.data.response.route[0].summary.text.split("\"time\">")[1].replace("</span>","");
            } else {
                result.travelTime = 0;
                result.travelTimeText = this.notAvailableText;
            }
            s(result);

            }).catch((e)=> {
                errorLogger.logError(e);
                e(e);
            });
        });
    }
    getWalkingTravelTime(args: TraveltimeRequest): Promise<TravelTimeResponse> {
        const result = new TravelTimeResponse();
        const url = `https://route.ls.hereapi.com/routing/7.2/calculateroute.json?apiKey=${this.getApiKey()}&waypoint0=geo!${args.start.lat},${args.start.lng}&waypoint1=geo!${args.destination.lat},${args.destination.lng}&mode=fastest;pedestrian`;

        return new Promise<TravelTimeResponse>((s,e)=> {
            axios.get(url).then(response=> {

            if(response.data.response.route[0]) {
                result.travelTime = response.data.response.route[0].summary.travelTime;
                result.travelTimeText = response.data.response.route[0].summary.text.split("\"time\">")[1].replace("</span>","");
            } else {
                result.travelTime = 0;
                result.travelTimeText = this.notAvailableText;
            }
            s(result);

            }).catch((e)=> {
                errorLogger.logError(e);
                e(e);
            });
        });
    }
}

let HereMapsHelperInstance;
export default HereMapsHelperInstance = new HereMapsHelper();