<template>

    <div class="text-center">
      <v-form ref="form">
      <div class="d-flex">
        <div class="country-container">
          <v-select class="input-phonecountrycode" :data-phonecountrycode="phoneCodeInternal" @change="onPhoneCountryCodeInputChanged" v-model="phoneCodeInternal" :items="countryCodes" item-text="text" item-value="code" dense >
          </v-select>
        </div>
        <div>
          <v-text-field class="input-phonenuber" :data-phonenumber="phoneNumberInternal" :rules="rules" v-model="phoneNumberInternal" @change="onPhoneNumberInputChanged"  :placeholder="$t('profile.phoneNumberPlaceholder')" dense></v-text-field>
        </div>
      </div>
    </v-form>
    </div>
  </template>
  
  
  <script lang="ts">
    import ViewModel from "./PhoneNumberInput"
    export default ViewModel;
  </script>

<style scoped>
.country-container{
  width: 130px;
}
</style>
    