<template>
    <div>
     <div class="d-flex justify-space-between text-subtitle-1 jobresult-summary" v-if="broadcastJobs.PageInfo">
        <div class="light">
            {{ $t("broadcastJobs.header") }}
        </div>
        <div class="text-subtitle-1">
            <b>{{broadcastJobs.PageInfo.TotalRecords}}</b> <span class="light">{{ $t("results") }}</span>
        </div>
      </div>
      <ConnectionLostCardView :isVisible="showNetworkError" @close="retryClicked"></ConnectionLostCardView>
      <v-list dense >
        <template  v-for="job in broadcastJobs.Jobs">              
          <v-list-item class="action-select-job rounded-corners card-border job-list-item mb-5" :key="job.JobId" :src="job.JobId" @click="jobSelected(job)" >
            <v-list-item-content :data-jobtitle="job.JobTitle" :data-jobid="job.JobId">
              <broad-cast-job-summary-card 
                :job="job"
                >
              </broad-cast-job-summary-card>
            </v-list-item-content>
          </v-list-item>
        </template>
        <v-list-item class="mt-5 ">
          <v-list-item-content>
            <LoadingIndicator :isVisible="isLoading"></LoadingIndicator>
          </v-list-item-content>
        </v-list-item>
      </v-list>
      <v-card intersect="infiniteScrolling"></v-card>
      <v-dialog v-model="showJobDetails" max-width="500px" fullscreen>
        <BroadCastJobDetails :isOpen="showJobDetails" :selectedJob="selectedJob" @close="showJobDetails = false" :closeTopDialog="closeTopDialog"></BroadCastJobDetails>
      </v-dialog>
    </div>
    
</template>
 

<script lang="ts">
    import ViewMode from "./BroadCastJobs"
    export default ViewMode;
</script>

