import TimesheetService, { Timesheet, TimesheetExpenseResult, TimesheetExpenseRequest, TimesheetHour, TimesheetExpense } from "../TimesheetService";
import store from "../../../../../store/index";
import { Vue, Component, PropSync,Watch, Mixins } from 'vue-property-decorator'
import moment from "moment";
import LoadingIndicator from "../../../../LoadingIndicator.vue";
import TimesheetExpenseSummaryCard from "./TimesheetExpenseSummaryCard.vue";
import TimesheetDetails from "./TimesheetDetails.vue";
import TimesheetDateRangePicker from "./TimesheetDateRangePicker.vue";
import { NetworkMixin } from '@/components/mixins/networkMixin';
@Component({
    name:"timesheet",
    components:{LoadingIndicator, TimesheetExpenseSummaryCard, TimesheetDetails,TimesheetDateRangePicker},
})

export default class ExpenseView extends Mixins(NetworkMixin) {
    timesheetExpenses: TimesheetExpenseResult = new TimesheetExpenseResult;
    timesheetExpenseRequest: TimesheetExpenseRequest;
    isLoading = false;
    today: Date;
    timesheetExpenseGroups: any = [];
    showNoresults = false;
    showTimesheetDetails = false;
    showTimesheetExpenseSubmitted = false;
    showTimesheetSubmitted = false;
    closeTimesheetDialog = false;
    selectedTimesheetId = 0;
    @PropSync("closeTopDialog", { type: Boolean }) isCloseDialog!: boolean;
    @PropSync("dateRange", { type: Object,default:{
      start: new Date(),
      end: new Date()
    } }) dateRangeLocal: any;
    @Watch('dateRange')
    dateRangeChanged(val: any, oldVal: any){
      if(val){
        this.timesheetExpenseRequest.StartDate = this.getDateUTC(val.start);
        this.timesheetExpenseRequest.EndDate = this.getDateUTC(val.end);
        this.selectedTimesheetPeriod = val.period;
        this.loadTimesheets();
      }
    }
    @Watch('closeTopDialog')
    closeDialogChanged() {
      if (this.showTimesheetDetails ) {
          this.closeTimesheetDialog = !this.closeTimesheetDialog;
          this.loadTimesheets();
        } else if (this.showTimesheetExpenseSubmitted) {
           this.showTimesheetExpenseSubmitted = false;
        }
        else {
           this.$emit('close');
        }
    }

    selectedTimesheetPeriod = "Weekly";

    constructor() {
        super();
        this.timesheetExpenseRequest = new TimesheetExpenseRequest();

        this.today = new Date();
        this.today.setHours(0,0,0,0);

        this.timesheetExpenseRequest.StartDate = this.getDateUTC( moment().clone().weekday(1).toDate());
        this.timesheetExpenseRequest.EndDate = this.getDateUTC( moment().clone().weekday(7).toDate());
    }

    getDateUTC(date){
      return new Date(moment.utc(moment(date).format("YYYYMMDD")).toDate());
   }

    getShiftDateSummary(timesheetHour){
      return `${this.formatTime(timesheetHour.StartTime)} - ${this.formatTime(timesheetHour.EndTime)}`;
    }

    getShiftDateText(timesheetHour){
      return  moment(timesheetHour.WorkedOn).isSame(new Date(),'day')? "Today": moment(timesheetHour.WorkedOn).fromNow();
    }

    mounted(){
        this.loadTimesheets();
    }

    loadTimesheets() {
        this.isLoading = true;
        this.doWork<TimesheetExpenseResult>(TimesheetService.timesheetExpenseSelect(this.timesheetExpenseRequest),this.loadTimesheets).then((res)=>{
          this.timesheetExpenses = res;
          this.isLoading = false;
          this.showNoresults = this.timesheetExpenses.TimesheetExpenses.length === 0;
          this.timesheetExpenseGroups = this.groups();
        }).catch((e)=> {
           this.showNetworkError = true;
        });
    }

    getTimesheet(timesheetId: number): Timesheet {
        return this.timesheetExpenses.Timesheets.find(x => x.TimesheetId === timesheetId);
    }

    formatDate(arg, format) {
      return moment(arg).format(format);
    }

    formatTime(arg) {
      return moment(arg).format('HH:mm');
    }

    groupBy(array, key){
        const result = {};
        array.forEach(item => {
          if (!result[item[key]]) {
            result[item[key]] = [];
          }
          result[item[key]].push(item);
        });
        return result;
      }

      groups() {
            return this.groupBy(this.timesheetExpenses.TimesheetExpenses, "PeriodStarting");
      }

      timesheetSelected (timesheetExpense: TimesheetExpense) {
        const timesheet = this.getTimesheet(timesheetExpense.TimesheetId);
        this.showTimesheetDetails = true;
        this.selectedTimesheetId = timesheet.TimesheetId;
      }


      submitTimesheetExpense(timesheetExpenseId: number) {
        this.showTimesheetDetails = false;
        TimesheetService.submitTimesheetExpense(timesheetExpenseId).then(result => {
            if (result) {
                this.showTimesheetExpenseSubmitted = true;
                this.loadTimesheets();
            }
         });
      }

      submitTimesheet(timesheetId: number) {
        this.showTimesheetDetails = false;
        TimesheetService.submitTimesheet(timesheetId).then(result => {
            if (result) {
                this.showTimesheetSubmitted = true;  ///qqq switch to timesheet view
                this.loadTimesheets();
            }
         });
      }

      closeDetails() {
        this.showTimesheetDetails = false;
        this.loadTimesheets();
      }
}
