
 <div>
     
<v-container class="div-background">
  <div class="h1-large" v-if="isManageCv">{{ $t("cvUpload.manageCVTitle") }}</div>
  <div class="h1-large" v-else>{{ $t("cvUpload.CVUploadTitle") }}</div>
   
  <div class="dropbox">
      <input type="file" 
        :name="uploadFieldName" 
        :disabled="isSaving || isSuccess" 
        @change="filesChange($event.target.name, $event.target.files); fileCount = $event.target.files.length"
        accept=".doc,.rtf,.docx,.pdf,.txt,.odt,.wps"
        class="input-file">
      <v-row justify="center" class="background-cloud pl-5 pr-5" style="text-align: center">
        <v-col cols="12">
          <img alt="Upload CV" height="50px" src="@/assets/img/timesheets/FileBig.png" class="header-noJobs">
        </v-col>
        <div v-if="!isSaving" >{{ $t("cvUpload.BrowseMessage") }}</div>
        <div v-if="isSaving" >Uploading {{ fileCount }} files...</div>
        <div v-if="isSuccess">{{ $t("cvUpload.uploadSuccess") }}</div>
      </v-row>
  </div>
 
            
            <!--FAILED-->
            <v-row justify="center" v-if="isFailed">
              <h2>{{ $t("cvUpload.failedUpload") }}</h2>
              <p>
                <a href="javascript:void(0)" @click="reset()">Try again</a>
              </p>
              <pre>{{ uploadError }}</pre>
            </v-row>

            <v-divider class="pb-2"></v-divider> 
            <v-row class="pr-5">
              <v-spacer/>
             <VueDropboxPicker
                  :api-key="dropBoxAPIKey"
                  class="action-dropbox-selectFile"
                  link-type="direct"
                  :multiselect="false"
                  :extensions="['.doc','.rtf','.docx','.pdf','.txt','.odt','.wps']"
                  :folderselect="false"
                  :sizeLimit="2048000"
                  @picked="onDropBoxPicked"
                  :disabled="isSuccess">
               <v-icon class="btn-dropbox mr-1"></v-icon>
               <span class="dropbox-text">{{ $t("cvUpload.dropboxSelect") }}</span>
          </VueDropboxPicker>
            </v-row>
          <v-list dense style="padding: 0px;">
              <template v-for="file in uploadedFiles">              
                      <v-list-item  class="rounded-corners card-border job-list-item" :key="file.CVId">
                          <v-list-item-content>
                            <v-row no-gutters>
                              <v-col cols="2" sm="2" md="1" >                                
                                <div>
                                  <v-icon v-if="file.FileExtension !== '.pdf'">{{'mdi-microsoft-word '}}</v-icon>
                                  <v-icon v-if="file.FileExtension === '.pdf'"  alt="PDF document">mdi-file-pdf</v-icon>
                                </div>
                              </v-col>
                              <v-col cols="6" sm="6" md="6" class="details-container">
                              <div class="d-flex justify-space-between">
                                <div class="text-h1 ellipsis" :data-fileName="file.FileName">{{file.FileName}}
                                </div>
                              </div>
                              <div class="text-secondary" :data-Created="file.CreatedOn">{{ formatDate(new Date(), 'DD MMM YYYY') }}
                              </div>        
                            </v-col>
                            <v-spacer></v-spacer>
                            <v-col cols="2" sm="2" md="1" class="action-container">
                               <v-btn icon small class="">
                                  <v-icon>mdi-tick</v-icon>
                                </v-btn>
                                <v-btn class="action-remove-document" icon small @click="removeDocument(file.CVId)">
                                  <v-icon>mdi-close</v-icon>
                                </v-btn>
                              </v-col>
                            </v-row>
                        </v-list-item-content>
                      </v-list-item>
                    </template>
          </v-list>
          <v-card-text v-if="isManageCv">
            <v-row class="d-flex justify-start align-center">
              <v-col>
                <div class="d-flex justify-space-around">
                  <b>{{ $t("cvUpload.recentlyUploaded") }} ({{cvCount}})</b>
                </div>
                <div class="caption">
                  {{ $t("cvUpload.noteAboutDeletingCv") }}
                </div>
              </v-col>
              <v-col hidden>
                <v-row class="d-flex justify-end" dense>
                <v-btn-toggle mandatory>
                  <v-btn @click="recentlyUploadedCss = 'list'" small>
                    <v-icon small>mdi-format-list-bulleted</v-icon>
                  </v-btn>
                  <v-btn @click="recentlyUploadedCss = 'grid'" small>
                    <v-icon small>mdi-view-grid</v-icon>
                  </v-btn>
                </v-btn-toggle>
                </v-row>
              </v-col>
            </v-row>
          </v-card-text>
          <ul :class="recentlyUploadedCss">
            <template v-for="cv in CVs">              
              <li :key="cv.CVId" style="padding-bottom: 5px;">           
                <CVCard 
                  :cv="cv" 
                  :isList="recentlyUploadedCss == 'list'" 
                  :appliedJobs="appliedJobs.filter(item => item.CVId === cv.CVId)"
                  :cvId.sync="deleteCvId"
                  @loadJob="loadJobDetails"
                  @refresh="loadRecentCvs"
                  @alert="showAlert"
                  @deleteCv="deleteCv" />
              </li>
            </template>
          </ul>
          <v-dialog v-model="showJobDetails" max-width="500px">
            <AppliedJobSummaryCard  
              style="background-color: white; padding: 10px !important;"
              :job="selectedJob">
            </AppliedJobSummaryCard>
          </v-dialog>
          <Alert 
            :isVisible="message"
            :alertType="alertType"
            :isConfirmation="isConfirmation"
            :result.sync="deleteCvConfirmation"
            @close="closeAlert">
            <template v-slot:message>{{messageText}}</template>
            <template v-slot:isConfirmation>{{isConfirmation}}</template>
          </Alert>
          <v-footer fixed class="pa-2" v-if="!isManageCv">
            <v-btn v-if="isSuccess" class="action-uploaded-cv" depressed block color="primary" @click="apply">{{ $t("jobs.uploadCVContinueButtonTitle") }}</v-btn>
            <v-btn class="action-skip-cv mt-2" depressed block  @click="skip">{{ $t("skip") }}</v-btn>
          </v-footer>        
</v-container>
    </div>
