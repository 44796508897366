import { Vue, Component, PropSync, Watch } from 'vue-property-decorator';
import {parsePhoneNumberFromString,getCountries, getCountryCallingCode} from 'libphonenumber-js';

 @Component({
 })
 export default class PhoneNumberInput extends Vue {
  
  rules = [
    value => !!value || 'Required.',
    value => (value || '').length <= 20 || 'Max 20 characters',
    value => {
      return this.validateNumber(value,this.phoneCodeInternal) || 'Invalid Number';
    },
  ];

  phoneNumberInternal = "";
  phoneCodeInternal = 44;
  countryCodes = [];
  @PropSync("phoneNumber", { type: String }) phoneNumberLocal!: string;
  @Watch('phoneNumber')
  phoneNumberChanged(val: string, oldVal: string){
    this.onPhoneNumberModelChanged();
  }
  @PropSync("phoneCountryCode", { type: Number }) phoneCountryCodeLocal!: number;
  @Watch('phoneCountryCode')
  phoneCountryCodeChanged(val: number, oldVal: number){
    this.onPhoneCountryCodeModelChanged();
  }

  onPhoneNumberModelChanged(){
    this.phoneCodeInternal = this.phoneCountryCodeLocal;
    this.phoneNumberInternal = this.phoneNumberLocal;
  }

  onPhoneCountryCodeModelChanged(){
    this.phoneCodeInternal = this.phoneCountryCodeLocal;
    this.phoneNumberInternal = this.phoneNumberLocal;
  }

  onPhoneNumberInputChanged(){
    this.updateNumber();
  }

  onPhoneCountryCodeInputChanged(e){
    (this.$refs.form as any).validate();
    this.updateNumber();
  }

  updateNumber(){
    if(this.validateNumber(this.phoneNumberInternal,this.phoneCodeInternal)){
      this.$emit("phoneNumberChanged",{phoneNumber: this.phoneNumberInternal,phoneCountryCode:this.phoneCodeInternal});
    }
  }


  loadCountryCodes(){
    const countries = [];
    getCountries().forEach(i=>
      {
        const code = getCountryCallingCode(i);
        countries.push({name:i,code:parseInt(code.toString()),text:`${i} ( +${code} )`})
      }
     );

    const selections = countries.length ? countries : countries.sort((a, b) => Number(a.code) - Number(b.code));
    this.countryCodes = selections;
  }

    validateNumber(number: string,countryCode: number){
      const phoneNumber = parsePhoneNumberFromString(`+${countryCode}${number}`);
      let isvalid = false;
   
      if(phoneNumber){
        isvalid = phoneNumber.isValid();
      } 
      return isvalid;
    }

  mounted(){
      this.loadCountryCodes();
  }

 }