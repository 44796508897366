import { Vue, Component, PropSync } from 'vue-property-decorator'
import store from "../store/index";
import axios, { AxiosInstance } from "axios";
import BaseService from '@/services/baseService';

interface ErrorLogger {
    logError(errorMessage: string,data: any);
    getErrors();
}



export class ErrorLoggerImp extends BaseService implements ErrorLogger {

    static instance: ErrorLoggerImp;
    intervalRef = 0;
    constructor() {
       super();

       if(import.meta.env.NODE_ENV === 'production'){
       
                this.intervalRef  = setInterval(() =>{
                        this.syncErrors();
                },10000);
        }
        
        window.onerror = (message, source, lineno, colno, error)=> { 
            this.logError(message.toString(),{message:message,source:source,lineno:lineno,colno:colno,error:error});
            return false;
        };

        Vue.config.errorHandler = (err, vm, info) => {
            //Cannot log VM unable to serialze,
            this.logError(err.toString(),{message:err.message,stack:err.stack,name:err.name,info:info});
            };

        Vue.config.warnHandler = (msg, vm, trace) => {
            //Cannot log VM unable to serialze,
            this.logError(msg,{message:msg,stack:trace,name:"warning"});
        };
    }



    logError(errorMessage: string,data: any = null){
        errorMessage = `${window.location.href}\r\n${errorMessage}`;


        const key = new Date().toISOString();
        const item = sessionStorage.getItem("errorMessages");
        if(item){
            const errors = JSON.parse(item);
            errors.push({message:errorMessage,data:data});
            sessionStorage.setItem("errorMessages",JSON.stringify(errors));
        }
        else{
            const errors = [];
            errors.push({message:errorMessage,data:data});
            sessionStorage.setItem("errorMessages",JSON.stringify(errors));
        }

        console.log(errorMessage,data);
    }

    getErrors(){
        let results = [];
        
        if(sessionStorage && sessionStorage.length > 0){
            const errors = sessionStorage.getItem("errorMessages");
            results = JSON.parse(errors);
        }

        return results;
    }
  
    syncErrors(){   
        
        const request ={Errors:[]}
        const errors =   this.getErrors();
        if(errors && errors.length > 0){
            errors.forEach(e => {
                request.Errors.push({
                    message:e.message,
                    data:JSON.stringify(e.data)
                    });
            });

            if(request.Errors.length > 0){
                this.httpClient("MOB").postAnnonymous("/diagnostics",request).catch((e) =>{
                        clearInterval(this.intervalRef);
                });
                sessionStorage.removeItem("errorMessages");
            }
        }
    }
}

if(ErrorLoggerImp.instance == null){
    ErrorLoggerImp.instance = new ErrorLoggerImp();
}

export  default ErrorLoggerImp.instance;

//export default new ErrorLogger();