import { Vue, Component, PropSync, Watch } from 'vue-property-decorator';
import JobService, { Job, AppliedJob, CVUploadResult } from "../JobService";
import global from '../../../../../utils/global'


@Component({
    name:"cvCard",
    components: {  }
})

export default class CVCard extends Vue {
@PropSync("cv", { type: Object }) localCv: CVUploadResult;
@PropSync("isList", { type: Boolean }) isListType: boolean;
@PropSync("appliedJobs", { type: Array }) localAppliedJobs: AppliedJob[];
@PropSync("cvId", { type: Number }) localcvId: number;
selectedJob: Job = null;

    constructor() {
        super();
    }

    retrieveFileIcon(fileExtension: string){
        switch(fileExtension){
            case '.pdf':
                return 'mdi-file-pdf';
            case '.docx':
            case '.doc':
                return 'mdi-microsoft-word';
            default:
                return 'mdi-file';
        }
    }

    loadJobDetails(jobId: number){
      console.log(jobId);
      if (jobId > 0) {
        JobService.selectJob(jobId).then(async (res) =>  {
            this.selectedJob = (res.data as Job);
            this.$emit("loadJob", this.selectedJob);
          });
        }
    }

    deleteCv(){
        this.$emit("update:cvId", this.localCv.CVId);
        if(this.localAppliedJobs.length > 0){
            this.$emit("alert", this.$t("cvUpload.warningDeletingCv").toString(), "Warning", true);
        }
        else{
            this.$emit("deleteCv");
        }
    }

    formatDate(date: Date){
        return global.formatDateSpecific(date, "DD MMM YYYY");
    }

    getIconSize(){
        if(this.isListType){
            return 36;
        }
        return 18;
    }
}

