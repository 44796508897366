import BaseService from "@/services/baseService";
import HttpClient from "../../../../utils/httpClient";
import ErrorLogger from '@/utils/errorLogger';
import store from "../../../../store/index";
export default class PayslipService extends BaseService {
    baseUrl: string;
    productCode = "PAYB";
    
  getPayslips(): Promise<any>{

    let tenantId: string;
    if  (store.getters.AppState.endPointAdditionalInfo(this.productCode,"PAYBTenantId") !== undefined) {
      tenantId = store.getters.AppState.endPointAdditionalInfo(this.productCode,"PAYBTenantId").value;
    }
    
    return new Promise<any>((resolve,reject)=>
    {
      
      return this.httpClient(this.productCode).get(`/${tenantId}/payslips`).then(async response =>{
           return response;
        
         }).then(async r =>{
           resolve(r);
         })
         .catch((e)=> {
           ErrorLogger.logError(e.toString(),e);
            reject(e);
         })
        })        
  }

  postPAYBRegistration(paybRegRequest: PAYBRegistrationRequest): Promise<any> {

    let tenantId: string;
    if  (store.getters.AppState.endPointAdditionalInfo(this.productCode,"PAYBTenantId") !== undefined) {
      tenantId = store.getters.AppState.endPointAdditionalInfo(this.productCode,"PAYBTenantId").value;
    }
    return new Promise<any>((resolve,reject)=>
    {
        return this.httpClient(this.productCode).get("/register",  
        {firstname:paybRegRequest.FirstName,
          surname:paybRegRequest.Surname,
          niNumber:paybRegRequest.NINumber,
          tenancyId: tenantId}).then(async response =>{
          return response;
      
        }).then(async r =>{
          resolve(r);
        })
        .catch((e)=> {
        ErrorLogger.logError(e.toString(),e);
          reject(e);
        })
    })
   }
}

export class PAYBRegistrationRequest {
  FirstName: string;
  Surname: string;
  NINumber: string;
 
  
}


 