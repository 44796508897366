<template>
    <JobSummaryCard :job="jobLocal"
      @setShortlisted="setShortlisted"
      @removeShortlisted="removeShortlisted">
      <template v-slot:bottom>
      <v-row no-gutters style="padding-top: 20px;">
        <v-divider></v-divider>
      </v-row>
      <v-row no-gutters>
        <v-stepper v-model="jobLocal.Stage" alt-labels>
          <v-stepper-header>
            <v-stepper-step step="1" :class="`text-subtitle-1 padding-2 pl-1 ${ getCssClass }`" :complete="getStage >= 0">{{ $t("appliedJobs.applied") }}</v-stepper-step>
            <v-divider></v-divider>
            <v-stepper-step step="2" :class="`text-subtitle-1 padding-2 ${ getCssClass }`" :complete="getStage > 1">{{ $t("appliedJobs.interview") }}</v-stepper-step>
            <v-divider></v-divider>
            <v-stepper-step step="3" :class="`text-subtitle-1 padding-2 pr-1 ${ getCssClass }`" :complete="getStage > 2">{{ $t("appliedJobs.offer") }}</v-stepper-step>
          </v-stepper-header>
        </v-stepper>
      </v-row>
      </template>
    </JobSummaryCard>
</template>

<script lang="ts">
    import ViewModel from './AppliedJobSummaryCard'  
    export default ViewModel;
</script>

<style lang="scss" scoped>
  .v-stepper{
    width: 100%;
    box-shadow: none;
    pointer-events: none;

    .v-divider {
      border: 1px dashed #eaeaea;
      margin-left: -56px !important;
      margin-right: -50px !important;
    }
  }

  .summary-container{
    height:auto;
  }  
</style>